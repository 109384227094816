import React, { useEffect, useState } from 'react';
import classNames from 'classnames';
import { connect } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import { Link, Redirect, withRouter } from 'react-router-dom';
import FieldView from '../../common/field-view';
import m from './messages';
import Modal from '../../common/modal';
import PageLinkModel from '../../common/old-pagination/model';
import PageNumber from '../../common/old-pagination/number';
import Pagination from '../../common/pagination';
import Progress from '../../common/progress';
import Section from '../../common/old-section';
import { convertToDate, mapObjToQueryString } from '../../utils';
import { selectCurrentThread, getPrefix } from '../../../ducks/old_thread';
import { isBusy } from '../../../ducks/common/busy';
import { showError } from '../../../ducks/common/alert';
import { threadLink } from '../../utils/old_link';
import {
  requestManageSpam,
  selectThread,
  isSelected,
  requestDeleteSpam,
  selectAllThreads,
  selectItems,
  selectQuery,
  deletePrefix, fetchPrefix
} from '../../../ducks/manage-spam';
import { threadSelector, getThreadById, loadingSelector } from '../../../ducks/thread-page';
import { sizeNameOf } from '../../common/paging/mock';
import useQuery from '../../../hooks/use-query-params';

export function ManageSpamResults(
  {
    busy,
    fetch,
    fetchThread,
    items,
    loadingThread,
    loading,
    query,
    remove,
    removingBusy,
    select,
    selectAll,
    thread,
    fetchedThread
  }) {
  const [modal, setModal] = useState(null);
  const parsedQueryInObj = useQuery();

  useEffect(() => {
    if (modal) fetchThread(modal.path, modal.id);
  }, modal ? [modal.id, modal.path] : [null, null]);

  if (!items) {
    return null;
  }
  query.change = newQuery => { fetch(newQuery); return true; };
  query.busy = busy;

  function showModal(item) {
    return function (e) {
      e.preventDefault();
        setModal(item);
    };
  }

  let results;
  const hasItems = !!items.length;
  if (items.length > 0) {
    const threads = items.map(item => {
      const className = classNames({
        selected: item.selected,
        reply: item.subject.indexOf('Re:') >= 0
      });
      const selectItem = () => {
        select(item);
      };
      return (
        <tr
          key={item.id + item.path}
          className={className}
        >
          <td className="select">
            <input type="checkbox" checked={item.selected} onChange={selectItem} />
          </td>
          <td className="username" onClick={selectItem}>
            {item.user ? item.user.username : ''}
          </td>
          <td className="path">
            <Link
              onClick={showModal(item)}
              to={threadLink(item.path, item.id)}
            >
              {item.subject}
            </Link>
            ({item.repliesCount})
          </td>
          <td className="name-path" onClick={selectItem}>{item.namePath}</td>
        </tr>
      );
    });
    const selectedItems = items.filter(item => item.selected);
    const isAllSelected = !items.some(item => !item.selected);
    const deleteButtonClassNames = classNames('submit ancBtn orange', {
      loading: removingBusy
    });
    const removeSelected = () => {
      remove(selectedItems);
    }
    results = (
      <>
        <Pagination hasItems={hasItems} paging={query} countType={'thousand'}/>
        <div className="controls spam-control">
          <label className="select-all">
            <input
              type="checkbox"
              checked={isAllSelected}
              onChange={e => selectAll(e.target.checked)}
            />
            <FormattedMessage {...m.selectAll} />
          </label>
          <button
            type="button"
            disabled={busy || !isSelected(items)}
            onClick={removeSelected}
            className={deleteButtonClassNames}
          >
            <FormattedMessage {...m.deleteSelected} />
          </button>
        </div>        
        <table className="table">
          <tbody>{threads}</tbody>
        </table>
      </>
    );
  } else {
    results = 'No results';
  }

  let modalContent;
  if (!fetchedThread || loading) {
    modalContent = <Progress />;
  } else {
    modalContent = (
      <>
        <FieldView name="date" label={<FormattedMessage {...m.date} />}>
          {convertToDate(fetchedThread.lastPost)}
        </FieldView>
        <div className="body">{fetchedThread.body}</div>
      </>
    );
  }

  const threadTitle = () => {
    if(loading) return '';
    if(fetchedThread) return fetchedThread.subject;
    return thread.subject;
  }

  if(query.totalPages !== 0 && query.currentPage > query.totalPages){
    const queryUrl = mapObjToQueryString(parsedQueryInObj, { itemsPerPage: sizeNameOf(query.itemsPerPage), page: query.totalPages });
    const fullUrl = `spam${queryUrl}`;
    return <Redirect to={fullUrl} />
  }

  return (
    <Section title={<FormattedMessage {...m.results} />} classes="results">
      {results}
      <Modal
        classes="thread-preview"
        close={() => setModal(null)}
        title={threadTitle()}
        visible={!!modal}
      >
        {modalContent}
      </Modal>
    </Section>
  );
}

function mapStateToProps(state) {
  return {
    items: selectItems(state),
    busy: isBusy(state, `${fetchPrefix}/`),
    removingBusy: isBusy(state, `${deletePrefix}/`),
    query: selectQuery(state),
    newQuery: new PageLinkModel(state.router.location.search),
    thread: selectCurrentThread(state),
    fetchedThread: threadSelector(state),
    loadingThread: isBusy(state, `${getPrefix}/`),
    loading: loadingSelector(state)
};
}

function mapDispatchToProps(dispatch) {
  return {
    fetch(query) {
      dispatch(requestManageSpam(query));
    },
    setError(message) {
      dispatch(showError(message));
    },
    remove(selected) {
      dispatch(requestDeleteSpam(selected));
    },
    select(item) {
      dispatch(selectThread(item.id, item.path));
    },
    selectAll(select = false) {
      dispatch(selectAllThreads(select));
    },
    fetchThread(path, id) {
      dispatch(getThreadById(path, id));
    }
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(ManageSpamResults));
