import { basename } from '../../config';
export * from '../../../utils/link';

export const getURL = () => window.location.href;
export const getOrigin = () => window.location.origin;

export const threadPathname = '/:path/:threadId';

export const rssLink = path => `${getOrigin()}${basename}/rss/${path}`;
export const profileLink = id => `${getOrigin()}/connect/Profile/${id}`;
export const feedbackLink = () => `${getOrigin()}/community/feedback.aspx?cat=message+boards&kurl=${getURL()}`;
export const communityGuidelinesLink = () => `${getOrigin()}/cs/legal/CommunityGuidelines`;
export const favoritesLink = path => `/favorites/${path}`;
