/*
  eslint-disable import/prefer-default-export
*/
import { bool, string, number, shape, arrayOf, oneOfType, node, oneOf, instanceOf } from 'prop-types';
import { RolesPriorities } from '../../ducks/_user';

export const TimeType = oneOfType([number, string, instanceOf(Date)]);

export const UserType = {
  authenticated: bool,
  createDate: string,
  displayName: string,
  firstName: string,
  imageLink: string,
  lastLoginDate: string,
  lastName: string,
  userId: string,
  username: string
};

export const RoleType = oneOf(RolesPriorities);
export const UndefinedRoleType = oneOf(['', ...RolesPriorities]);

export const AdminNodeType = {
  path: string,
  title: string,
  objectionCount: number
};

export const CurrentUserType = {
  email: string,
  role: RoleType,
  username: string,
  adminNodes: arrayOf(shape(AdminNodeType))
};

export const ThreadType = {
  active: bool,
  body: string,
  edited: TimeType,
  id: string,
  isFavorite: bool,
  isFavoriteAuthor: bool,
  lastPost: TimeType,
  namePath: string,
  path: string,
  repliesCount: number,
  sendAlert: bool,
  subject: string,
  surnames: string,
  user: shape(UserType).isRequired,
  viewed: bool.isRequired
};

export const ChildrenType = oneOfType([
  string,
  node,
  arrayOf(oneOfType([node, string]))
]);

export const BreadcrumbsType = arrayOf(shape({
  path: string,
  title: ChildrenType
}));

export const BusyType = {
  busy: bool
};

export const WidgetType = {
  classes: string,
  description: ChildrenType,
  title: ChildrenType
};

export const PageType = {
  ...WidgetType,
  breadcrumbs: BreadcrumbsType,
  children: ChildrenType
};

export const WidgetDefaults = {
  classes: '',
  description: '',
  title: ''
};

export const BusyWidgetType = {
  ...BusyType,
  ...WidgetType
};

export const QueryType = shape({
  currentPage: number,
  itemsPerPage: oneOfType([string, number]),
  page: number
});

export const PathMatchType = { params: shape({ path: string.isRequired }) };
