import React from 'react';
import ThreadDetail from './thread-detail';


export default function PreviewForm({ isReply, thread, fields, user, hideUserLink }) {
  return (
    <div className='form-preview'>
      { isReply && <ThreadDetail {...thread} /> }
      <ThreadDetail user={user} {...fields} hideUserLink={hideUserLink} /> 
    </div>
  )
}