import { call, put, spawn, take, takeEvery } from 'redux-saga/effects';
import { Record, OrderedSet } from 'immutable';
import { createSelector } from 'reselect';
import { replace } from 'connected-react-router';

import settings from '../local-settings';
import { appName } from '../config';
import api from '../services/threads-service';
import url from "../url-service";
import { Http, ROLE_TYPES } from '../constants';

/**
 * Constants
 */

export const moduleName = 'thread-page';
export const prefix = `${appName}/${moduleName}`;

export const GET_THREAD_BY_ID_REQUEST = `${prefix}/GET_THREAD_BY_ID_REQUEST`;
export const GET_THREAD_BY_ID_SUCCESS = `${prefix}/GET_THREAD_BY_ID_SUCCESS`;
export const GET_THREAD_BY_ID_FAIL = `${prefix}/GET_THREAD_BY_ID_FAIL`;

// DELETE THREAD OR WITH REPLIES
export const DELETE_THREAD_REQUEST = `${prefix}/DELETE_THREAD_REQUEST`;
export const DELETE_THREAD_SUCCESS = `${prefix}/DELETE_THREAD_SUCCESS`;
export const DELETE_THREAD_FAIL = `${prefix}/DELETE_THREAD_FAIL`;


export const CLEAR_THREAD_RECORD = `${prefix}/CLEAR_THREAD_RECORD`;

// THREAD LAYOUT VIEW
export const CHANGE_LAYOUT_VIEW = `${prefix}/CHANGE_LAYOUT_VIEW`;
  // ___ value constants for layout view
  export const LAYOUT_TREE_VIEW = 'TREE_VIEW';
  export const LAYOUT_VIEW_FLAT = 'FLAT_VIEW';
  export const DEFAULT_VIEW = LAYOUT_TREE_VIEW;
  export const KEY_STORAGE = 'thread-layout';

// REPLIES SORT VIEW, DOESNT PARTICIPATES IN REDUCER FLOW (FOR QUERY ARGUMENT - API CALL)
export const ASCENDING = 'ASCENDING';
export const DESCENDING = 'DESCENDING';

// FAVOIRTES
export const SET_THREAD_TO_FAVORITE = `${prefix}/SET_THREAD_TO_FAVORITE`;
export const DELETE_THREAD_FROM_FAVORITE = `${prefix}/DELETE_THREAD_FROM_FAVORITE`;

export const SET_USER_TO_FAVORITE = `${prefix}/SET_USER_TO_FAVORITE`;
export const DELETE_USER_FROM_FAVORITE = `${prefix}/DELETE_USER_FROM_FAVORITE`;


export const SET_ALERT_TO_FAVORITE = `${prefix}/SET_ALERT_TO_FAVORITE`;
export const DELETE_ALERT_FROM_FAVORITE = `${prefix}/DELETE_ALERT_FROM_FAVORITE`;

/**
 * Reducer
 */

export const UserRecord = Record({
  userId: null,
  username: null,
  firstName: null,
  lastName: null,
  displayName: null,
  imageId: null,
  authenticated: null,
  isFavorite: null
})

export const MovedRecord = Record({
  path: null,
  threadId: null
})

export const ThreadRecord = Record({
  id: null,
  path: null,
  subject:null,
  repliesCount: null,
  namePath: null,
  lastPost: null,
  user: UserRecord(),
  body: null,
  posted: null,
  updated: null,
  sendAlert: null,
  surnames: null,
  classification: null,
  deleted: null,
  userMarkedDeleted: null,
  markedDeletedBy: null,
  attachments: [],
  isFavorite: null,
  viewed: null,
  moderate: null,
  moved: MovedRecord()
})

export const ToolsRecord = Record({
  volunteerLink: false,
  infoLink: false
})

export const OptionsRecord = Record({
  posts: null,
  moderation: null
})

export const EntitiesRecord = Record({
  breadcrumbs: null,
  prevThreadId: null,
  parentSubject: null,
  repliesCount: null,
  isBoardsAlert: false,
  isFavorite: false,
  thread: ThreadRecord(),
  nextThreadId: null,
  replies: OrderedSet([]),
  repliesPaging: null,
  tools: ToolsRecord(),
  userRole: ROLE_TYPES.guest,
  options: OptionsRecord([])
})



export const ReducerRecord = Record({
  loading: null,
  entities: null,
  threadView: settings.get(KEY_STORAGE) || DEFAULT_VIEW,
  repliesDirection: ASCENDING,
  err: null
})



export default function reducer(state = ReducerRecord(), { type, payload, err }) {
  switch(type) {
    case GET_THREAD_BY_ID_REQUEST:
    case DELETE_THREAD_REQUEST:
        return state.set('loading', true)

    case GET_THREAD_BY_ID_SUCCESS:
        return state
                  .set('loading', false)
                  .set('entities', EntitiesRecord(payload))

    case GET_THREAD_BY_ID_FAIL:
      return state
                .set('loading', false)
                .set('entities', EntitiesRecord())
                .set('err', err)


    // cases for favorites
    case SET_USER_TO_FAVORITE:
      return state.setIn(['entities', 'thread', 'user', 'isFavorite' ], true)
    case DELETE_USER_FROM_FAVORITE:
      return state.setIn(['entities', 'thread', 'user', 'isFavorite' ], false)

    case SET_THREAD_TO_FAVORITE:
      return state.setIn(['entities', 'thread', 'isFavorite' ], true)
    case DELETE_THREAD_FROM_FAVORITE:
      return state.setIn(['entities', 'thread', 'isFavorite' ], false)

    case SET_ALERT_TO_FAVORITE:
      return state.setIn(['entities', 'isBoardsAlert'], true)
    case DELETE_ALERT_FROM_FAVORITE:
      return state.setIn(['entities', 'isBoardsAlert'], false)

    // layout view
    case CHANGE_LAYOUT_VIEW: {
      settings.set(KEY_STORAGE, payload)
      return state.set('threadView', payload)
    }

    // delete posts and replis
    case DELETE_THREAD_SUCCESS:
        return state.set('loading', false);

    case DELETE_THREAD_FAIL:
      return state
        .set('loading', false)
        .set('err', err)
    case CLEAR_THREAD_RECORD:
      return state.clear()

    default:
      return state;
  }
}

 /**
 * Actions
 */

 export const getThreadById = (path, threadId, itemsPerPage = 'TEN', page = 1, viewType = DEFAULT_VIEW, sortDirection = ASCENDING) => ({
   type: GET_THREAD_BY_ID_REQUEST,
   payload: [ path, threadId, itemsPerPage, page, viewType, sortDirection ]
  })
// === delete thread
export const deleteThread = (path, id, withReplies = false) => ({ type: DELETE_THREAD_REQUEST, payload: {path, id, withReplies} })
// === Thread layout view toggle action
export const changeThreadLayout = (threadView) => ({ type: CHANGE_LAYOUT_VIEW, payload: threadView })

export const clearThreadData = () => ({ type: CLEAR_THREAD_RECORD })


 /**
 * Selectors
 */

export const stateSelector = state => state[moduleName];
export const loadingSelector = createSelector(
  stateSelector, state => state.loading
)

export const entitiesSelector = createSelector(
  stateSelector, state => state.entities && state.entities.toJS()
)

export const errorSelector = createSelector(
  stateSelector, state => state.err
)

export const threadSelector = createSelector(
  entitiesSelector, entities => entities && entities.thread
)

export const userThreadSelector = createSelector(
  threadSelector, thread => thread && thread.user
)

export const threadUserRoleSelector = createSelector(
  entitiesSelector,
  entities => entities && entities.userRole
);

// ==========  author favorite selectors
export const isFavoriteUserSelector = createSelector(
  userThreadSelector, user => user && user.isFavorite
)



export const layoutSelector = createSelector(
  stateSelector, state => state.threadView
)


// admin tools links
export const threadToolsSelector = createSelector(
  entitiesSelector, entities => entities && entities.tools
)

export const volunteerLinkSelector = createSelector(
  threadToolsSelector, tools => tools && tools.volunteerLink
)
export const infoLinkSelector = createSelector(
  threadToolsSelector, tools => tools && tools.infoLink
)

// ========== favorites thread
export const isFavoriteSelector = createSelector(
  entitiesSelector, entities => entities && entities.thread && entities.thread.isFavorite
)

// ==========  favorites alert
export const isAlertSelector = createSelector(
  entitiesSelector, entities => entities && entities.isBoardsAlert
)

 /**
 * Saga
 */

 export const getThreadByIdSaga = function*({ payload }) {
  try {
    const { data } = yield call(api.getThreadById, ...payload);
    yield put({ type: GET_THREAD_BY_ID_SUCCESS, payload: data });
  } catch(err) {
    let status = err.response.status;
    if (err.response && status === Http.NotFound) {
      err.messageKey = 'retrievingMessage';
      yield put({ type: GET_THREAD_BY_ID_FAIL, err });
      return null;
    }
    err.messageKey = 'default';
    yield put({type: GET_THREAD_BY_ID_FAIL, err })
  }
 }



 export const deleteThreadSaga = function* () {
  while(true) {
    const { payload: {path, id, withReplies} } = yield take(DELETE_THREAD_REQUEST);
    try {
      if (withReplies) yield call(api.deletesThreadAndReplies, path, id);
      else yield call(api.deleteThread, path, id);

      yield put({ type: DELETE_THREAD_SUCCESS });

      // case for defining url for redirect
      let threadIds = id.split('.');
      let typePath = 'category'; // by default
      let args = [path]; // by default

      if (threadIds.length > 1) {
        typePath = 'thread';
        args = [path, threadIds[0]]
      }

      yield put(replace(url.createPath(typePath, ...args)));
    } catch(err) {
      yield put({ type: DELETE_THREAD_FAIL, err })
    }
  }
 }





 export const saga = function* () {
  yield takeEvery(GET_THREAD_BY_ID_REQUEST, getThreadByIdSaga);
  yield spawn(deleteThreadSaga);
 }
