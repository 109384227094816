import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';

// data for listening
import {
  // selector
  addedCategorySuccessSelector, adddedCategoryFailSelector,
  removedCategorySuccessSelector, removedCategoryFailSelector,
  // action
  resetCategryFavoriteState
} from '../../ducks/category-favorite';
import m from './message';


export default function CategoryNotice() {
  const dispatch = useDispatch();
  const { path } = useParams();
  // data from redux
  const addSuccess = useSelector(addedCategorySuccessSelector);
  const addFail = useSelector(adddedCategoryFailSelector);
  const removeSuccess = useSelector(removedCategorySuccessSelector);
  const removeFail = useSelector(removedCategoryFailSelector);

  const active = [addSuccess, addFail, removeSuccess, removeFail].find(state => state);
  if (!active) return null;


  const isSuccess = addSuccess || removeSuccess;
  const isError = addFail || removeFail;
   

  function getCategoryType() {
    const nestedCategoryLevel = 4;
    const boardCategory = (path.split('.').length === nestedCategoryLevel);
    if (boardCategory) return '';
    return 'Category';
  } 

  
  function getTitleMessage() {
    if (isSuccess) {
      return addSuccess 
        ? <FormattedMessage {...m.success_add} values={{ name: getCategoryType() }} />
        : <FormattedMessage {...m.success_remove} values={{ name: getCategoryType() }} />
    } 

    if (isError) {
      return addFail
        ? <FormattedMessage {...m.fail_add} values={{ name: getCategoryType() }} />
        : <FormattedMessage {...m.fail_remove} values={{ name: getCategoryType() }} />
    }

    return null;
  }

  return (
    <div className={`notice-favorite alert${ isError ? ' notice-favorite--error' : ''}`}>
      { getTitleMessage() }
      <button className='notice-favorite__btn-hide' onClick={() => dispatch(resetCategryFavoriteState())}/>
    </div>
  )
}