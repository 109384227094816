import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams, Redirect } from 'react-router-dom';
import { FormattedMessage, injectIntl } from 'react-intl';

import m from './messages';
import useQuery from '../../hooks/use-query-params';
import {
  // action
  fetchByTypeFavorites,
  // selectors
  loadingSelector,
  favoritesByTypeSelector,
  favoritesPagingSelector,
  deletingSelector
} from '../../ducks/favorites';
import PageLayout from '../../components/hoc/page-layout';
import ProcessLayout from '../../components/hoc/process-layout';
import MenuItems from './menu-items';
import TableFavorites from '../../components/favorites/table';
import Pagination from '../../components/common/pagination';
import EmptyMessages from './empty-message';
import { trackPageView } from '../../utils/tealium';

export default injectIntl(_ => {
  //  redux
  const dispatch = useDispatch();
  const loading = useSelector(loadingSelector);
  const deletingProcess = useSelector(deletingSelector);
  const favorites = useSelector(favoritesByTypeSelector) || [];
  const favoritesPaging = useSelector(favoritesPagingSelector);

  // routing data
  const { path: urlPath } = useParams();
  const { itemsPerPage, page } = useQuery();

  const menuListItems = ['categories', 'boards', 'authors', 'threads']; // values are name of keys from lang dictionary
  const existsPath = menuListItems.includes(urlPath);

  useEffect(() => {
    if (existsPath) {
      dispatch(fetchByTypeFavorites(urlPath, itemsPerPage, page));
    }
  }, [urlPath, itemsPerPage, page, existsPath]);

  useEffect(() => trackPageView(`favorites : ${urlPath}`), [urlPath]);

  if (!existsPath) return <Redirect to={`/favorites/${menuListItems[0]}`} />;

  const activeLink = menuListItems.find(item => item === urlPath.toLowerCase());
  // meta data for template
  const iconStar = <span className="icon iconStarOutline boardsFavoritesIcon" />;
  const titleSection = <FormattedMessage {...m.myFavorites} />;
  const descSection = <FormattedMessage {...m.manage} values={{ icon: iconStar }} />;

  const hasItems = !!favorites.length;

  function renderBody() {
    if (favorites.length) {
      return (
        <>
          <TableFavorites type={activeLink} data={favorites} listByType />
          { favoritesPaging && <Pagination hasItems={hasItems} paging={favoritesPaging} /> }
        </>
      );
    }
    if(favoritesPaging && page > favoritesPaging.totalPages) return <Redirect to={`/favorites/${urlPath}/?page=${favoritesPaging.totalPages}`} />;
    return <EmptyMessages />;
  }

  return (
    <PageLayout classModificator="favorites" title={titleSection} description={descSection} linkToHome>
      <div className="favorites-full">
        <MenuItems listItems={menuListItems} activeLink={activeLink} />
        <div className="favorites-full__content">
          <ProcessLayout loading={loading || deletingProcess}>
            { renderBody() }
          </ProcessLayout>
        </div>
      </div>
    </PageLayout>
  );
});
