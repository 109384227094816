import React from 'react';
import { Link } from 'react-router-dom';
import {FormattedMessage} from 'react-intl';

import url from '../../url-service';
import { convertToDate } from '../utils';
import m from '../../components/admin/tools/messages';


export default {
  categories: {
    titles: ['Categories', 'Tools'],
    getCellItems: (item) => {
      if (!item) return null;
      const { path, title } = item;
      return (
        <td className='table__col'>
          <Link to={url.createPath('category', path)}>{title}</Link>
        </td>
      )
    }
  },

  boards: {
    titles: ['Boards', 'Posts', 'Last Post', 'Tools'],
    getCellItems: (item) => {
      if (!item) return null;
      const { path, title, messages, lastPost } = item;
      return (
        <>
          <td className='table__col'>
            <Link to={url.createPath('category', path)}>{title}</Link>
          </td>
          <td className='table__col'>{messages}</td>
          <td className='table__col'>{lastPost && convertToDate(lastPost)}</td>
        </>
      )
    }
  },

  authors: {
    titles: ['Authors', 'Posts', 'Tools'],
    getCellItems: (item) => {
      if (!item) return null;
      const { userId, username } = item;
      return (
        <>
          <td className='table__col'>
            <a href={url.createPath('profile', userId)}>{username}</a>
          </td>
          <td className='table__col'>
            <button
              type='button'
              className='menu-nav__item-btn-link'
              onClick={() => location.href = `/boards/author-posts/${userId}`}
            >
              <FormattedMessage {...m.viewPosts} />
            </button>
          </td>
        </>
      )
    }
  },
  threads: {
    titles: ['Threads', 'Replies', 'Last Post', 'Tools'],
    getCellItems: (item) => {
      if (!item) return null;
      const { subject, path, id, repliesCount, lastPost } = item;
      return (
        <>
          <td className='table__col'>
            <Link to={url.createPath('thread', path, id)}>{subject}</Link>
          </td>
          <td className='table__col'>{repliesCount}</td>
          <td className='table__col'>{lastPost && convertToDate(lastPost)}</td>
        </>
      )
    }
  }
}
