import React from 'react';
import { connect } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import m from './messages';
import ManageList from '..';
import { selectSuggestionsItemsWithNumber } from '../../../../ducks/suggestion';
import ManageRight from './right';

export function ManageRights({ items }) {
  let rights;
  if (items) {
    rights = items.map(item => <li key={item.uid}><ManageRight {...item} /></li>);
  }
  return (
    <ManageList title={<FormattedMessage {...m.title} />}>
      {rights}
    </ManageList>
  );
}

export function mapStateToProps(state) {
  return {
    items: selectSuggestionsItemsWithNumber(state)
  };
}

export function mapDispatchToProps() {
  return {
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(ManageRights);
