import React from 'react';
import { string } from 'prop-types';
import classNames from 'classnames';
import { ChildrenType } from '../utils/prop-types';
import { spaceless } from '../../utils/strings';

/**
 * @return {string|Element}
 */

export default function Section({ title, children, classes }) {
  const h2 = title ? <h2 className="conTitle">{title}</h2> : null;
  const className = classNames(spaceless(`con ${classes}`, {
    empty: !children
  }));
  return (
    <section className={className}>
      {h2}
      <div className="conBody">
        {children}
      </div>
    </section>
  );
}

Section.propTypes = {
  children: ChildrenType,
  classes: string,
  title: ChildrenType
};

Section.defaultProps = {
  children: '',
  classes: '',
  title: ''
};
