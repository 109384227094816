/*
  eslint-disable import/prefer-default-export
*/
export function singleSpace(string) {
  return string.replace(/\s+/g, ' ');
}

export function spaceless(string) {
  return singleSpace(string).trim();
}

export function stringPostfix(string, separator = '/') {
  const parts = string.split(separator);
  return parts[parts.length - 1];
}

export function trimed(string) {
  return (string || '').trim();
}

export function parseBoolean(string) {
  if (!string) {
    return false;
  }
  if (string === 'true' || string === 'false') {
    return string === 'true';
  }
  if (+string === 0) {
    return false;
  }
  return !!string.trim();
}

/**
 * Show number with grouped digits
 * @param {number} number
 * @param {string} separator
 * @returns {string}
 */
export function prettyNumber(number, separator = ' ') {
  if (number < 1000) {
    return number.toString();
  }
  if (!number) {
    return '';
  }
  const original = number.toString();
  const reversed = original.split('').reverse();
  const result = [];
  const max = reversed.length - 1;
  for (let i = 0; i < max; i++) {
    result.push(reversed[i]);
    if (i % 3 === 2) {
      result.push(separator);
    }
  }
  return original[0] + result.reverse().join('');
}

export const LinkIdChars = 'a-z0-9_-';
export const LinkIdPartialRegExp = `[${LinkIdChars}]+`;
export const LinkIdRegExpString = `^${LinkIdPartialRegExp}$`;
export const LinkIdRegExp = new RegExp(LinkIdRegExpString);

export const PathRegExpString = `^${LinkIdPartialRegExp}(\\.${LinkIdPartialRegExp})*$`;
export const PathRegExp = new RegExp(PathRegExpString);

export const nameFormat = string => string;

const CategoryPathFormatRegExp = new RegExp(`[^.${LinkIdChars}]+`, 'g');
export const categoryPathFormat = string => string
  .trim()
  .toLowerCase()
  .replace(CategoryPathFormatRegExp, '');

const MoveBoardsPathFormatRegExp = new RegExp(`[^.a-z0-9-_ ]+`, 'g');
export const MoveBoardsPathFormat = string => string
  .toLowerCase()
  .replace(MoveBoardsPathFormatRegExp, '')
  .replace(/\s+/g, ' ');

export const usernameFormat = string => string
  .trim()
  .replace(/[^a-z0-9_]/ig, '');

export const emailFormat = string => string
  .trim()
  .replace(/\s+/g, '');

const LinkIdFormatRegExp = new RegExp(`[^${LinkIdChars}]+`, 'g');
export const linkIdFormat = string => string
  .trim()
  .toLowerCase()
  .replace(LinkIdFormatRegExp, '');

export const VariableRegex = /{([^}]+)}/g;

export const substitute = (string, vars) => string.replace(VariableRegex, (match, name) => vars[name] || `{${name}}`);
export const getterSubstitute = (string, get) => string.replace(
  VariableRegex,
  (match, name) => get(name) || `{${name}}`
);
export const lowercaseSubstitute = (string, vars) => string.replace(
  VariableRegex,
  (match, name) => vars[name.toLowerCase()] || `{${name}}`
);

export const minify = xmlString => xmlString
  .replace(/>\s+</g, '><')
  .replace(/\s+/g, ' ');

export const escape = htmlString => htmlString.trim()
  .replace(/</g, '&lt;')
  .replace(/>/g, '&gt;')
  .replace(/&/g, '&amp;');

export const tag = (name, content) => (content ? `<${name}>${content}</${name}>` : '');
