import React, {useEffect, useRef} from 'react';
import classNames from 'classnames';
import { bool, oneOfType, string, func, oneOf } from 'prop-types';
import { connect } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import { ChildrenType } from '../utils/prop-types';
import { closeAlert, selectAlert } from '../../ducks/common/alert';
import { spaceless } from '../../utils/strings';

const scrollToElement = (scroll, type) => element => {
  if (element && (scroll === true || type === 'error')) {
    try {
      element.scrollIntoView(true);
    } catch (err) {
    }
  }
};

/**
 * @return {null|Element}
 */
export function Alert({ type = 'error', text, classes = '', scroll, close, expires, closeAlert }) {
  if (close === true) {
    close = closeAlert;
  }
  const timer = useRef(-1);
  useEffect(() => {
    const now = Date.now();
    if (close && expires > now) {
      clearTimeout(timer.current);
      timer.current = setTimeout(close, expires - now);
    }
  }, [expires]);
  if (text) {
    if (text.id) {
      text = <FormattedMessage {...text} />;
    }
    const typeClass = type[0].toUpperCase() + type.slice(1).toLowerCase();
    const className = classNames(spaceless(`widget alert alert${typeClass} ${classes}`), {
      empty: !text
    });
    let closeButton;
    if (close) {
      closeButton = (
        <button type="button" className="alert-close" onClick={() => close()}>×</button>
      );
    }
    return (
      <div ref={scrollToElement(scroll, type)} className={className}>
        <div className="alert-content">
          {text || ''}
        </div>
        {closeButton}
      </div>
    );
  }
  return null;
}

Alert.propTypes = {
  classes: string,
  close: oneOfType([func, bool]),
  closeAlert: func,
  scroll: oneOf([true, false, null]),
  text: ChildrenType,
  type: string
};

Alert.defaultProps = {
  classes: '',
  close: true,
  closeAlert: null,
  scroll: null,
  type: 'error'
};

function mapStateToProps(state) {
  return selectAlert(state).toJSON();
}

function mapDispatchToProps(dispatch) {
  return {
    closeAlert() {
      dispatch(closeAlert());
    }
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(Alert);
