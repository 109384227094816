import React, { useEffect,useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import { useParams } from 'react-router-dom';
import m from './messages';
import { trackPageView } from '../../utils/tealium';

// data
import {
  getContent,
  // selectors
  loadingSelector,
  entitiesSelector,
  errorSelector
} from '../../ducks/home-category-page';
import { isActiveToolbar } from '../../ducks/admin-panel';
import { isAdminRoleSelector, userEntitiesSelector } from '../../ducks/user';
// templates
import PageLayout from '../../components/hoc/page-layout';
import Categories from '../../components/categories';
import KeywordsSearch from '../../components/searches/advanced/keywords-search';
import SearchBoards from '../../components/searches/boards';
import AdminTool, { ToolsTypes } from '../../components/admin/tools';
import NoticeAddOrRemove from '../../components/notice-add-remove';
import useQuery from '../../hooks/use-query-params';
import { CATEGORY_TYPES } from '../../constants';
import NoticeReadOnly from '../../components/notice-read-only';


export default function CategoriesPage() {
  const [called, setcalled] = useState(false);
  // routing
  const { path } = useParams();
  const { itemsPerPage, page } = useQuery();
  // redux
  const dispatch = useDispatch();

  // data from redux
  const loadingContent = useSelector(loadingSelector);
  const entities = useSelector(entitiesSelector);
  const errorContent = useSelector(errorSelector);

  // user data
  const activeToolbar = useSelector(isActiveToolbar);
  const isAdmin = useSelector(isAdminRoleSelector);
  const { adminNodes } = useSelector(userEntitiesSelector);


  useEffect(() => {
    dispatch(getContent(path, itemsPerPage, page));
   setcalled(false);
  }, [path, itemsPerPage, page]);

  //useEffect(() => trackPageView(`board : ${path}`), []);
  

/*useEffect(() => {
  return()=>{
    trackPageView(`board : ${path}`)
  }  
});
*/

/*useEffect(() => {
  setTimeout(() => trackPageView(`board : ${path}`),10000);    
});
*/

  const getAdminMessage = () => {
    const isAdminOfCurrentPath = adminNodes.find(node => node.path === path);
    const messageKey = isAdminOfCurrentPath ? 'areAdmin' : 'notAdmin';
    return <div className={`categories__admin-board ${isAdminOfCurrentPath ? 'icon iconSettings': ''}`}><FormattedMessage {...m[messageKey]} /></div>
  }

  // meta data
  const sectionTitle = <FormattedMessage {...m.messageBoards} />;

  const isBoardCategory = entities.nodeType === CATEGORY_TYPES.board;
  const classModification = isBoardCategory ? 'category-board' : '';

  const getAdminToolType = () => {
    if (isBoardCategory) return ToolsTypes['category-board'];
    if (path.includes('.')) return ToolsTypes['category-nested'];
    return ToolsTypes['category-root'];
  };

  const getError = () => {
    if (errorContent) return errorContent;

    const deleted = entities && entities.deleted;
    const isBoard = entities.nodeType === CATEGORY_TYPES.board;
    const isCategory = entities.nodeType === CATEGORY_TYPES.category;

    if (deleted && isCategory) return ({ messageKey: 'errorDeletedCategory' });
    if (deleted && isBoard) return ({ messageKey: 'errorDeletedBoard' });
  };
  const currentPage= entities && entities.threads && entities.threads.paging && entities.threads.paging.currentPage;
  const dartContentSide=document.getElementById('dartContentSide');
  const dartContentTop=document.getElementById('dartContentSide');
  // tealium tracking
  useEffect(() => {
    try {
      if(entities.breadcrumbs &&  !entities.threads && !called){
        const lastItem = entities.breadcrumbs.links[entities.breadcrumbs.links.length - 1];
      if(entities.breadcrumbs.links && 
        // entities.breadcrumbs.links.some((link)=>link.path === path)){
          lastItem.path === path){
            trackPageView(`board : ${path}`);
            setcalled(true);
      }
      }
    } catch (err) {
    console.log(err)
    }
  }, [dartContentSide,dartContentTop,path,itemsPerPage,page,sectionTitle,isBoardCategory,classModification]);

  useEffect(() => {
    try {
      if(entities.breadcrumbs &&  entities.threads && !called){
        const lastItem = entities.breadcrumbs.links[entities.breadcrumbs.links.length - 1];
      if(entities.breadcrumbs.links && 
        // entities.breadcrumbs.links.some((link)=>link.path === path)){
          lastItem.path === path){
        trackPageView(`board : ${path}`);
        setcalled(true);
      }
    }
    }catch (err) {
        console.log(err)
        }
    }, [dartContentSide,dartContentTop,currentPage]);
    
    // tealium tracking


  return (
    // <PageLayout></PageLayout>
    <PageLayout
      title={sectionTitle}
      loading={loadingContent}
      error={getError()}
      breadcrumbs={entities.breadcrumbs}
      classModificator={classModification}
      advertOpt={{
        show: true,
        position: 'top'
      }}
    >
      { entities && <KeywordsSearch {...entities} /> }
      <Categories {...entities}>
        {adminNodes && activeToolbar && isAdmin && getAdminMessage()}
        <NoticeAddOrRemove />
        { entities.options && !entities.options.posts && <NoticeReadOnly /> }
        { !isBoardCategory && <AdminTool type={getAdminToolType()} /> }
      </Categories>
      { entities && <SearchBoards {...entities} /> }
      { isBoardCategory && <AdminTool type={getAdminToolType()} /> }
    </PageLayout>
  );
}
