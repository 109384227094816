import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { useParams } from 'react-router-dom';
import { injectIntl } from 'react-intl';

//data
import { editThread, loadingSelector } from '../../../../ducks/thread-edit';
// templates
import UserInfo from '../../user-info'
import FiledSubject from '../../field-subject';
import FieldMessage from '../../field-message';
import FieldCheckbox from '../../field-checkbox';
import FiledSurnames from '../../field-surnames';
import ClassificationSelect from '../../classification-select';
import FieldAttachments from '../../field-attachments';
import BtnSubmit from '../../btn-submit';
import CancelLink from '../../cancel-link.js';
import Progress from '../../../common/progress';
import PreviewForm from '../../preview';
import BtnToogleEditPreview from '../../btn-edit-preview';
// helpers
import m from '../../messages';
import { Classification as dictionary } from '../../../../constants';


export default injectIntl(function AuthorForm({ thread, intl, sameUser }) {
  AuthorForm.propTypes = {
    thread: PropTypes.object.isRequired
  }
  const [ fields, setFileds ] = useState(() => ({
    subject: thread.subject || '',
    surnames: thread.surnames || '',
    classification: thread.classification || dictionary.QUERIES,
    body: thread.body || '',
    sendAlert: !!thread.sendAlert,
  }))

  const [ showPreview, setShowPreview ] = useState(false);
  // field name - prop showEmptyField, in the edit page a value for fields (subject and message already exists)
  // this prop need to show empty filed on mounting if data is not exists
  const [ showEmptyField, setShowEmptyField ] = useState(''); 

  const { path, threadId } = useParams();
  const dispatch = useDispatch();
  const submitLoading = useSelector(loadingSelector)

  const emptyFields = (() => {
    let fieldsEmpty = ''

    if (!fields.subject) fieldsEmpty = 'subject';
    if (!fields.body) fieldsEmpty = 'body';
    if (!fields.subject && !fields.body) fieldsEmpty ='subject body';

    return fieldsEmpty;
  })()

  const handleChangeField = ({ target: { value, name } }) => setFileds(prevState => ({ ...prevState, [name]: value }))
  const handleChangeSelect = ({ target: { value } }) => setFileds(prevState => ({ ...prevState, classification: value }))
  const handleChangeCheckbox = ({ target: { name } }) => setFileds(prevState => ({ ...prevState, [name]: !prevState.sendAlert }))

  const handleSubmit = () => {
    emptyFields ? setShowEmptyField(emptyFields) : dispatch(editThread(false, fields, path, threadId)) // first arg for checking form alien
  }

  const toggleBtnPreview = () => emptyFields ? setShowEmptyField(emptyFields) : setShowPreview(!showPreview)

  return (
    <div className='thread-form thread-form--author'>
      { showPreview
          ? <PreviewForm user={thread.user} fields={fields} hideUserLink />
          : (<>
              <div className='thread-form__user-info'>
                <UserInfo user={thread.user} />
              </div>
              <div className='thread-form__field thread-form__field--subject'>
                <FiledSubject
                  name='subject'
                  value={fields.subject}
                  handleChange={handleChangeField}
                  placeholder={intl.formatMessage(m.subjectPalceholder)}
                />
              </div>
              <div className='thread-form__field thread-form__field--textarea'>
                <FieldMessage name='body' value={fields.body} handleChange={handleChangeField} sameUser={sameUser} />
              </div>
              <div className='thread-form__field thread-form__field--checkbox'>
                <FieldCheckbox name='sendAlert' value={fields.sendAlert}  handleChange={handleChangeCheckbox}/>
              </div>
              <div className='thread-form__field thread-form__field--surnames'>
                <FiledSurnames
                  name='surnames'
                  value={fields.surnames}
                  placeholder={intl.formatMessage(m.surnamePalceholder)}
                  handleChange={handleChangeField}
                />
              </div>
              <div className='thread-form__field thread-form__field--classification'>
                <ClassificationSelect
                  name='classification'
                  value={fields.classification}
                  handleChange={handleChangeSelect}
                  helpLink
                />
              </div>
              <div className='thread-form__field thread-form__field--attachemnt'>
                <FieldAttachments
                  canDownload={true}
                  uploadedList={thread.attachments}
                />
              </div>
            </>)
      }
        <div className='thread-form__btns'>
          <div className='thread-form__btn-submit'>
            <BtnSubmit handleSubmit={handleSubmit} title={intl.formatMessage(m.saveChanges)}/>
          </div>
          <div className='thread-form__btn-preview'>
            <BtnToogleEditPreview showPreview={showPreview} handleBtnClick={toggleBtnPreview} />
          </div>
          <div className='thread-form__cancel-link'>
            <CancelLink />
          </div>
        </div>
      { submitLoading && (
          <div className='thread-form__progress'>
            <Progress small />
          </div>
      )}
    </div>
  )
})



