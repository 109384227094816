import React, { useState } from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import ChangeBoard from '../change';
import Field from '../../../common/field';
import m from './messages';
import SelectCategory from '../../../choose-category';
import { Alert } from '../../../common/alert';
import { categoryPathFormat } from '../../../../utils/strings';

export function MergeBoard({ intl }) {
  const [newPath, setNewPath] = useState('');

  const footer = <Alert type="info" text={<FormattedMessage {...m.takeTime} />} close={false} />;

  return (
    <ChangeBoard
      tracking="merge-board"
      canSubmit={!!newPath}
      data={{ newPath }}
      footer={footer}
      operation="merge"
      successMessage={intl.formatMessage(m.success)}
      title={<FormattedMessage {...m.title} />}
    >
      <SelectCategory choose={setNewPath} />
      <Field
        format={categoryPathFormat}
        label={<FormattedMessage {...m.newPath} />}
        name="path"
        setValue={value => setNewPath(value)}
        validationClasses="required"
        value={newPath}
      />
      <FormattedMessage {...m.hint} />
    </ChangeBoard>
  );
}

export default injectIntl(MergeBoard);
