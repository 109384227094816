import React from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';

// data
import {
  categoryUserRoleSelector
} from '../../../../ducks/home-category-page';
import { ROLE_TYPES } from '../../../../constants';
import { isActiveToolbar } from '../../../../ducks/admin-panel';
// links views
import { 
  ListAdminLink, EditOptionsLink, CreateNewCategoryLink, 
  CreateNewBoardLink, RenameCategoryLink, MoveCategoryLink,
  DeleteCategoryLink, RecycleBinLink
} from '../links/list';
import AddRemoveFavorites from '../links/add-remove-favorites';
import Menu from '../../../menu';






export default function CategoryList(props) {
  // user roles
  const userRole = useSelector(categoryUserRoleSelector);
  const isSuperAdmin = userRole === ROLE_TYPES.superAdmin;
  const isAdmin = userRole === ROLE_TYPES.admin;
  const isAuthRole = userRole === ROLE_TYPES.authenticated;
  const isAuthenticated = isSuperAdmin || isAdmin || isAuthRole;

  // admin panel state
  const activeToolbar = useSelector(isActiveToolbar);


  function renderSuperAdminList() {
    if (activeToolbar) return (  
      <>
        <AddRemoveFavorites />
        <ListAdminLink {...props} />
        <EditOptionsLink {...props} />
        <CreateNewCategoryLink {...props} />
        <CreateNewBoardLink {...props} />
        {props.isNested && (
          <>
            <RenameCategoryLink {...props} />
            <MoveCategoryLink {...props} />
            <DeleteCategoryLink {...props} />
          </>
        )}
        <RecycleBinLink {...props} />
      </>
    )

    return <AddRemoveFavorites />
  }
  return (
    <> 
      { isAuthenticated && (
        <Menu classModificator={props.classes}>
          { isSuperAdmin && renderSuperAdminList() }
          { isAdmin && <AddRemoveFavorites /> }
          { isAuthRole && <AddRemoveFavorites /> }
        </Menu>
      )}
    </>
  )
}

CategoryList.propTypes = {
  path: PropTypes.string,
  classes:PropTypes.string,
  isNested:PropTypes.bool,
}


