import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import { useLocation, useHistory } from 'react-router-dom';
// data
import {
  // actions
  fetchSearchResult, resetSearchResult,
  // selectors
  loadingSelector, entitiesBoardsSelector, errorSelector,
} from '../../ducks/search-adv-result'
// hook
import useQuery from '../../hooks/use-query-params';
// templates
import PageLayout from '../../components/hoc/page-layout';
import SearchResultsForm from '../../components/forms/search-results';
import SearchResultItems from '../../components/searches/advanced/results';
import { trackPageView } from '../../utils/tealium';
// helpers
import m from './messages';
import { mapObjToQueryString } from '../../components/utils';


export default function SearchResults() {
  const radioBtnValueAll = 'All';
  const dispatch = useDispatch();

  // redux data
  const loadingResult = useSelector(loadingSelector);
  const entities = useSelector(entitiesBoardsSelector);
  const error = useSelector(errorSelector);

  // routing data - query params as object
  const { pathname } = useLocation();
  const history = useHistory();
  const urlQueryParams = useQuery();


  const getUrlPageValue = (pageValue) => {
    if (isNaN(pageValue)) {
      const queryUrl = mapObjToQueryString(urlQueryParams, { page: 1 });
      return history.replace(`${pathname}${queryUrl}`);
    }
    return pageValue;
  }

  const allQueryProperties = {
    author: urlQueryParams.author || '',
    classification: urlQueryParams.classification && urlQueryParams.classification.toUpperCase() || 'ALL',
    keyword: urlQueryParams.keyword || '',
    postedWithin: urlQueryParams.postedWithin && urlQueryParams.postedWithin.toUpperCase() || 'ANYTIME',
    path: urlQueryParams.selectedPath || '',
    subject: urlQueryParams.subject || '',
    surnames: urlQueryParams.surnames || '',
    useSoundex: !!urlQueryParams.useSoundex,
    page: getUrlPageValue(urlQueryParams.page),
    itemsPerPage: urlQueryParams.itemsPerPage || 'TEN'
  };

  function getFetchPayload() {
    const cloneObjUrlParams = { ...allQueryProperties };
    // this is property path selected by user, line 41 - override
    if ((cloneObjUrlParams.path && cloneObjUrlParams.path.trim().toLowerCase()) === radioBtnValueAll.toLowerCase()) {
      delete cloneObjUrlParams.path;
    }

    if (urlQueryParams.path) { // this is origin property path from category
      cloneObjUrlParams.pathName = urlQueryParams.path;
    }

    const paging = {
      itemsPerPage: cloneObjUrlParams.itemsPerPage || 'TEN',
      page: cloneObjUrlParams.page || 1
    }
    delete cloneObjUrlParams.itemsPerPage
    delete cloneObjUrlParams.page
    delete cloneObjUrlParams.fullForm
    cloneObjUrlParams.useSoundex = !!cloneObjUrlParams.useSoundex;

    return {
      params: cloneObjUrlParams,
      paging
    };
  }

  useEffect(() => {
    const payload = getFetchPayload();
    dispatch(fetchSearchResult(payload));
    return () => dispatch(resetSearchResult());
  }, Object.values(allQueryProperties));

  useEffect(() => trackPageView('search: results'), []);

  const pageTitle = <FormattedMessage {...m.title} />;
  const breadcrumbs = {
    links: [
      { path: null, title: pageTitle }
    ]
  };

  return (
    <PageLayout
      title={pageTitle}
      breadcrumbs={breadcrumbs}
      classModificator='search-results'
      error={error}
    >
      <SearchResultsForm
        allQueryProperties={{...allQueryProperties}}
        fullForm={urlQueryParams.fullForm}
        radioBtnValueAll={radioBtnValueAll}
        locPath={urlQueryParams.path}
      />
      <SearchResultItems
        boards={entities}
        urlQueryParams={urlQueryParams}
        loadingResult={loadingResult}
      />
    </PageLayout>
  )
}
