import React from 'react';
import { FormattedMessage } from 'react-intl';

import m from './messages';
import url from '../../url-service';
import { convertToDate } from '../utils';
import { Classification as dictionary } from '../../constants';
import UserLink from '../common/user-link';


export default function ThreadFormDetail({ posted, classification, surnames, user, subject, body, hideUserLink }) {
  const renderUserSection = () => {
    if (!user || !subject) return null;

    return (
      <div className='form-thread-detail__meta'>
        { subject && <div className='form-thread-detail__subject'>{subject}</div>}
        { !hideUserLink 
            ? <UserLink userData={user} />
            : <div className='form-thread-detail__user hide'>{ user.displayName || user.username }</div>
        }
      </div>
    )
  }


  const renderInfoSection = () => (
    <ul className='form-thread-detail__info'>
      { posted 
          ? (<li className='form-thread-detail__info-item'>
              <FormattedMessage {...m.posted}/>: {convertToDate(posted)}
            </li>)
          : null 
      }
      { classification 
          ? (<li className='form-thread-detail__info-item'>
              <FormattedMessage {...m.classification}/>: {dictionary[classification.toUpperCase()]}
            </li>)
          : null 
      }
      { surnames 
          ? (<li className='form-thread-detail__info-item'>
              <FormattedMessage {...m.surnames}/>: {surnames}
            </li>) 
          : null 
      }
    </ul>
  )

  
  return (
    <div className='form-thread-detail'>
      {!hideUserLink && (
        <p className='form-thread-detail__title'>
          <FormattedMessage {...m.originalMessage} />
        </p>
      )}
      <div className='form-thread-detail__content'>
        { renderUserSection() }
        { renderInfoSection() }
      </div>
      { body && <div className='form-thread-detail__message'>{body}</div> }
    </div>
  )
}