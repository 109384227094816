import React from 'react';
import PropTypes from 'prop-types';
import CircularProgress from '@material-ui/core/CircularProgress';


export default function Progress({ color, small, classModificator, ...otherProps }) {
  const props = {
    className: `progress${ small ? ' progress--min-wrap': ''}${classModificator ? ` ${classModificator}`: ''}`,
    color: color || 'black'
  }

  return (
    <div {...props}>
      <CircularProgress color="inherit" thickness={5} width='10px' {...otherProps} />
    </div>
  );
}

Progress.propTypes = {
  small: PropTypes.bool,
  color: PropTypes.string
};

Progress.defaultProps = {
  small: true,
  color: 'black'
};
