import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';


export default function MenuItem({ children, to, classModificator, ...otherProps }) {
  return (
    <li className={`menu-nav__item${ classModificator ? ` ${classModificator}` : '' }`} {...otherProps}>
      { renderLink(children, to) }
    </li>
  )
}


function renderLink(children, to) {
  if (to) return (
    <Link to={to} className='menu-nav__item-link'>
      { children }
    </Link>
  );
  return children;
}


MenuItem.propTypes = {
  to: PropTypes.string,
  onClick: PropTypes.func,
  classModificator: PropTypes.string
} 