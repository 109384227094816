import React from 'react';
import { FormattedMessage } from 'react-intl';

import m from '../../pages/request-boards/messages';

//templates
import MessagesList from './messages-list';

export default function RequestContent() {
  return (
    <div className="request-content">
      <p className="request__title--bold"><FormattedMessage {...m.topicTitle} /></p>
      <MessagesList />
      <p className="request__title"><FormattedMessage {...m.topicFooter} /></p>
    </div>
  );
}
