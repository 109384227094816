import QueryModel from '../../../ducks/common/query-model';
import { countsItems } from '../paging/mock';

/**
 * @property {boolean} isLink Detects if anchor may be parsed by search engines
 * @property {boolean} busy When client is loading a request
 * @property {array[]} sizes List of available page sizes
 */
export default class PageLinkModel extends QueryModel {
  assign(options) {
    super.assign(options);
    this.sizes = options.sizes || countsItems;
    this.isLink = typeof options.isLink === 'boolean' ? options.isLink : this.isValid && this.isSizeDefault;
    this.busy = typeof options.busy === 'boolean' ? options.busy : false;
    if (typeof options.change === 'function') {
      this.change = options.change;
    }
  }

  /**
   * Pagination do nothing if change returns true
   * @returns {boolean}
   */
  change() {
    return this.isValid;
  }
}

PageLinkModel.create = function create(options) {
  return new PageLinkModel(options);
};

/**
 *
 * @param props
 * @returns {{model: PageLinkModel}}
 */
PageLinkModel.initProps = props => (props.model ? props : ({
  ...props,
  model: new PageLinkModel(props)
}));
