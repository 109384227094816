import m from '../../pages/volunteer/messages';
import { FormattedMessage } from 'react-intl';
import React from 'react';

export default function MessagesList() {
  let messages = [
    m.responsibilityItem1,
    m.responsibilityItem2,
    m.responsibilityItem3,
    m.responsibilityItem4
  ];

  return (
    <ul className='volunteer__list'>
      {messages.map((message, index) => (
        <li className='volunteer__list-item' key={index}>
          <FormattedMessage {...message} />
        </li>
      ))}
    </ul>
  );
};
