import { defineMessages } from 'react-intl';

export default defineMessages({
  retrievingMessage: 'Error retrieving message',
  retrievingBoard: 'Error retrieving board',
  pageNotFound: 'Error: Page Not Found',
  error: 'An Error has occurred.',
  unavailable: "We're sorry, this page is temporarily unavailable.",
  deletedThread: 'The requested message has been deleted. Please remove any bookmarks to this message.',
  movedThreadPartOne: 'The requested message has been moved.',
  movedThreadPartTwo: 'Please update your bookmarks accordingly.',
  movedThreadPartThree: 'If you are not redirected automatically then use this link:',
  errorDeletedBoard: 'The requested board has been deleted.',
  errorDeletedCategory: 'The requested category has been deleted.'
});
