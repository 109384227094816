import React from 'react';
import { useSelector } from 'react-redux';

import {
  userEntitiesSelector,
  isSuperAdminRoleSelector,
  isAdminRoleSelector,
  isAuthenticatedRoleSelector,
} from '../../ducks/user';
import config from '../../config';

export function authAdminSuperAdminPrivate(Component) {
  return function WrappedComponent(props) {
    const userData = useSelector(userEntitiesSelector);
    const encodeUri = encodeURIComponent(window.location.href);
    const redirectUrl = `/account/signin?returnurl=${encodeUri}`;

    const isAuthenticated = useSelector(isAuthenticatedRoleSelector);
    const isSuperAdmin = useSelector(isSuperAdminRoleSelector);
    const isAdmin = useSelector(isAdminRoleSelector);

    return ((isAuthenticated || isSuperAdmin || isAdmin) && !config.institution)
      ? <Component {...props} user={userData} />
      : location.replace(redirectUrl)
  };
}
