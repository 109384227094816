import React from 'react';
import PropTypes from 'prop-types';

import Progress from '../common/progress';
import ErrorView from '../error';


export default function ProcessLayout({ children, loading, hideContent, error, errorMessage }) {

  function renderChildren() {
    if (hideContent) return null;
    return children || null;
  }

  function getBody() {
    if (loading) {
      return (
        <div className='process__wrap'>
          <div className='process__overlay'/>
          <div className='process__content'>
            { renderChildren() }
          </div>
          <div className='process__spinner'>
            <Progress/>
          </div>
        </div>
      );
    }
    if (error || errorMessage) return <ErrorView error={error} message={errorMessage} />;
    return <div className='process__content'>{ children }</div>;
  }

  return (
    <div className='process'>
      { getBody() }
    </div>
  );

}

ProcessLayout.defaultProps = {
  hideContent: true
}

ProcessLayout.propTypes = {
  children: PropTypes.node,
  loading: PropTypes.bool
};
