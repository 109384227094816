import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { FormattedMessage, injectIntl } from 'react-intl';
import { push } from 'connected-react-router';
import m from './messages';
import { categoryLink } from '../../../../../utils/link';
import { deletePrefix, requestNodeRemove } from '../../../../ducks/node';
import { isBusy } from '../../../../ducks/common/busy';
import { mapStateToProps, mapDispatchToProps, RouterChangeBoard } from '../change';
import { showSuccess } from '../../../../ducks/common/alert';
import { parentPath } from '../utils';
import { trackPageView } from '../../../../utils/tealium';

export const RemoveBoard = connect(
  state => ({
    busyChange: isBusy(state, `${deletePrefix}/`),
    ...mapStateToProps(state)
  }),
  dispatch => ({
    change(path, operation, data, message) {
      dispatch(requestNodeRemove(path, [
        push(categoryLink(parentPath(path))),
        showSuccess(message)
      ]));
    },
    ...mapDispatchToProps(dispatch)
  })
)(RouterChangeBoard);

export function DeleteBoard({ intl, match }) {
  useEffect(() => trackPageView(`delete-board : ${match.params.path}`), []);
  return (
    <RemoveBoard
      title={<FormattedMessage {...m.title} />}
      successMessage={intl.formatMessage(m.success)}
    />
  );
}

export default injectIntl(DeleteBoard);
