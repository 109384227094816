import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';

// data
import {
  // action
  getThreadById,
  // selectors
  loadingSelector,
  threadSelector, userThreadSelector,
  errorSelector,
  entitiesSelector
} from '../../ducks/thread-page';
// templates
import PageLayout from '../../components/hoc/page-layout';
import AuthorForm from '../../components/forms/edit/author-form';
import AlienForm from '../../components/forms/edit/alien-form';
import m from './messages';
import NoticeReadOnly from '../../components/notice-read-only';
import { trackPageView } from '../../utils/tealium';

export default function ThreadEditPage({ user }) {
  const dispatch = useDispatch();
  // routing data
  const { path, threadId } = useParams();

  // redux thread data
  const loading = useSelector(loadingSelector);
  const error = useSelector(errorSelector);

  const thread = useSelector(threadSelector);
  const authorThread = useSelector(userThreadSelector);

  const entities = useSelector(entitiesSelector);
  let isReadOnly;
  if (entities) isReadOnly = !entities.options.posts;

  const authorId = authorThread && authorThread.userId;
  const sameUser = authorId === user.userId;
  const titleKey = sameUser ? 'titleAuthor' : 'titleAlien';

  useEffect(() => {
    dispatch(getThreadById(path, threadId));
  }, [path, threadId]);

  useEffect(() => trackPageView(`edit-message : ${path}`), [path]);

  function renderThreadForm() {
    if (sameUser) return <AuthorForm sameUser={sameUser} thread={thread} user={user} />;
    return <AlienForm sameUser={sameUser} thread={thread} />;
  }

  function getBreadcrumbs() {
    let threadSurnames = '';
    if (thread && thread.namePath) threadSurnames = thread.namePath.split('|').slice(-1)[0];

    return {
      links: [{
        path: `${path}/${threadId}`,
        title: <FormattedMessage {...m.returnLink} values={{ item: threadSurnames }} />
      }]
    };
  }

  return (
    <PageLayout
      loading={loading}
      error={error}
      title={<FormattedMessage {...m[titleKey]} />}
      breadcrumbs={getBreadcrumbs()}
      aloneLink={true}
    >
      { thread && !isReadOnly ? renderThreadForm() :  <NoticeReadOnly />}
    </PageLayout>
  );
}

ThreadEditPage.propTypes = {
  user: PropTypes.object.isRequired // The current visitor
};
