import React, { useEffect, useState } from 'react';
import classNames from 'classnames';
import { FormattedMessage } from 'react-intl';
import { bool, number, string } from 'prop-types';
import Judge from '../judge';
import m from './messages';
import Fieldset from '../fieldset';
import Field from '../../../common/field';
import Actions from '../actions';
import { JudgeOperations } from '../../../../ducks/suggestion';
import {
  categoryPathFormat,
  linkIdFormat,
  LinkIdRegExp,
  PathRegExp,
  singleSpace,
  spaceless
} from '../../../../utils/strings';

export default function ManageBoard(props) {
  const { classes, id, path, isLocked, uid, creatorName } = props;
  const className = classNames(spaceless(`manage-item manage-board ${classes}`), {
    loaded: !!path
  });
  const [boardId, setBoardId] = useState('');
  const [boardPath, setBoardPath] = useState('');
  const [name, setName] = useState('');
  const [makeAdmin, setMakeAdmin] = useState(false);
  const [comments, setComments] = useState('');
  const isValid = LinkIdRegExp.test(boardId.trim())
    && PathRegExp.test(boardPath.trim())
    && !!name.trim();
  useEffect(() => { if (!name && props.name) { setName(props.name); } }, [id]);

  const username = <strong>{creatorName}</strong>;
  return (
    <div className={className}>
      <Fieldset
        name="board"
        fields={props}
        messages={m}
        names={['creatorName', 'requestDate', 'email', 'name', 'comment', 'status']}
      />
      <Judge
        classes="judge-board"
        id={uid}
        title={<FormattedMessage {...m.judge} />}
      >
        <Fieldset
          name="modal"
          fields={props}
          messages={m}
          names={['creatorName', 'email', 'name', 'requestDate', 'comment']}
        />
        <form className={spaceless(`manage-form ${classes}`)}>
          <div className="board-fields">
            <Field
              format={categoryPathFormat}
              label={<FormattedMessage {...m.boardPath} />}
              name="boardPath"
              setValue={setBoardPath}
              value={boardPath}
            />
            <Field
              format={linkIdFormat}
              label={<FormattedMessage {...m.boardId} />}
              name="boardId"
              setValue={setBoardId}
              value={boardId}
            />
            <Field
              format={singleSpace}
              label={<FormattedMessage {...m.boardName} />}
              name="name"
              setValue={setName}
              value={name}
            />
            <Field
              label={<FormattedMessage {...m.makeAdmin} values={{ username }} />}
              name="makeAdmin"
              setValue={setMakeAdmin}
              type="checkbox"
              value={makeAdmin}
            />
          </div>
          <Field
            label={<FormattedMessage {...m.comments} />}
            name="comments"
            setValue={setComments}
            type="textarea"
            value={comments}
          />
          <Actions
            data={{ comments, boardPath, boardId, name, makeAdmin, type: 'boards' }}
            disabled={!isValid}
            id={id.toString()}
            names={[
              JudgeOperations.Accept,
              JudgeOperations.Reject,
              JudgeOperations.Remove,
              isLocked ? JudgeOperations.Unlock : JudgeOperations.Lock
            ]}
            path={path}
          />
        </form>
      </Judge>
    </div>
  );
}

ManageBoard.propTypes = {
  classes: string,
  id: number.isRequired,
  isLocked: bool.isRequired,
  name: string.isRequired,
  path: string.isRequired,
  uid: string.isRequired,
  creatorName: string
};

ManageBoard.defaultProps = {
  classes: '',
  creatorName: ''
};
