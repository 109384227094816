import React, { useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { FormattedMessage } from 'react-intl';
import { Link } from 'react-router-dom';

import m from './messages';
import url from '../../../url-service';
import { convertToDate, Timezone } from '../../utils';
import Time from '../../common/time';


export default function LastNews({ newsData, newsError }) {
  const [lastCollapsed, setLastCollapsed] = useState(true);

  if (!newsData) return null;
  if (newsError) {
    console.error(`Error with last news data`)
    return null;
  }

  const renderTitleSection = () => (
    <h3>
      <FormattedMessage {...m.boardAdminNews} />
      {' - '}
      <Link to={url.createPath('category', newsData.path)}>
        <FormattedMessage {...m.viewAllNews} />
      </Link>
    </h3>
  )

  const renderBodyContent = () => {
    const lastClassName = classNames('last-news', {
      collapsed: lastCollapsed,
      expanded: !lastCollapsed
    });
    const buttonClassName = classNames('icon', {
      iconArrowSmallRight: lastCollapsed,
      iconArrowSmallDown: !lastCollapsed
    });

    return (
      <div className={lastClassName}>
        <button type="button" onClick={() => setLastCollapsed(!lastCollapsed)} className={buttonClassName}>
          <Link
            to={url.createPath('thread', newsData.path, newsData.id)}
            onClick={e => e.stopPropagation(e)}
          >
            {newsData.subject}
          </Link>
          {convertToDate(newsData.posted)}
        </button>
        <div className="text">{newsData.body}</div>
      </div>
    )
  }

  return (
    <div className="last-news">
      { renderTitleSection() }
      { renderBodyContent() }
    </div>
  );
}

LastNews.propTypes = {
  // object means, can be null or object of data
  newsData: PropTypes.object,
  newsError: PropTypes.object
}
