import { spawn, take, call, put } from 'redux-saga/effects';
import { Record } from 'immutable';

import api from '../services/suggestions-service';
import { appName } from '../config';

/**
 * Constants
 */

export const moduleName = "request-board";
export const prefix = `${appName}/${moduleName}`;

export const CREATE_BOARD_REQUEST = `${prefix}/CREATE_BOARD_REQUEST`;
export const CREATE_BOARD_SUCCESS = `${prefix}/CREATE_BOARD_SUCCESS`;
export const CREATE_BOARD_FAIL = `${prefix}/CREATE_BOARD_FAIL`;

/**
 * Reducer
 */

export const ReducerRecord = Record({
  entity: null,
  err: null
});

export default function reducer(state = ReducerRecord(), { type, payload, err }) {
  switch (type) {
    case CREATE_BOARD_SUCCESS:
      return state.set("entity", payload);

    case CREATE_BOARD_FAIL:
      return state.set("err", err);

    default:
      return state;
  }
}

/**
 * Actions
 */

export const createRequest = (path, returnUrl, data) => ({
  type: CREATE_BOARD_REQUEST,
  payload: { path, returnUrl, data }
});

/**
 * Sagas
 */

export const requestBoardSaga = function*() {
  while(true) {
    const {
      payload: { path, returnUrl, data }
    } = yield take(CREATE_BOARD_REQUEST);
    try {
      yield call(api.createBoardSuggestion, data, path);
      yield put({
        type: CREATE_BOARD_SUCCESS,
        payload: data
      });
      location.href = returnUrl;
    } catch (err) {
      yield put({
        type: CREATE_BOARD_FAIL,
        err
      });
    }
  }
};

export function* saga() {
  yield spawn(requestBoardSaga);
}
