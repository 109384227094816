import React from 'react';
import { FormattedMessage } from 'react-intl';

import m from './messages';


export default function BtnSearch(type) {
  return (
    <button className='ancBtn' type={type}>
      <FormattedMessage {...m.searchBtn} />
    </button>
  )
}