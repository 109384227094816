import { take, call, put, spawn  } from 'redux-saga/effects';
import { Record } from 'immutable';
import { createSelector } from 'reselect';

import { appName } from '../config';
import api from '../services/contents-service';
import localstorage from '../local-settings';



/**
 * Constants
 */
export const moduleName = 'admin-panel';
export const prefix = `${appName}/${moduleName}`;

export const TOGGLE_ADMIN_TOOLBAR = `${prefix}/TOGGLE_ADMIN_TOOLBAR`;
export const ADMIN_ENABLED_KEY = 'admin.enabled';

export const LAST_NEWS_REQUEST = `${prefix}/LAST_NEWS_REQUEST`;
export const LAST_NEWS_SUCCESS = `${prefix}/LAST_NEWS_SUCCESS`;
export const LAST_NEWS_FAIL = `${prefix}/LAST_NEWS_FAIL`;


/**
 * Reducer
 */

 // --- record for last news
 export const LastNewsDataRecord = Record({
  id: null,
  path: null,
  subject:  null,
  posted: null,
  body:  null
})

 export const LastNewsRecord = Record({
  loading: true,
  data: null,
  err: null
})

// --- main record state
export const ReducerRecord = Record({
  toolbar: localstorage.get(ADMIN_ENABLED_KEY),
  lastNews: LastNewsRecord()
})


export default function reducer(state = ReducerRecord(), action) {
  const { type, payload, err } = action;
  switch(type) {
    case TOGGLE_ADMIN_TOOLBAR: {
      localstorage.rewrite(ADMIN_ENABLED_KEY, 'active');
      return state.set('toolbar', localstorage.get(ADMIN_ENABLED_KEY));
    }

    case LAST_NEWS_SUCCESS:
        return state
          .setIn(['lastNews', 'loading'], false)
          .setIn(['lastNews', 'data'], LastNewsDataRecord(payload))

    case LAST_NEWS_FAIL:
        return state
          .setIn(['lastNews', 'loading'], false)
          .setIn(['lastNews', 'err'], err)
          .setIn(['lastNews', 'data'], LastNewsDataRecord())

    default: return state
  }
}


/**
 * Actions
 */

  export const toggleAdminToolbar = () => ({ type: TOGGLE_ADMIN_TOOLBAR });
  export const getLastAdminNews = () => ({ type: LAST_NEWS_REQUEST });



/**
 * Selectors
 */

export const stateSelector = state => state[moduleName];

export const isActiveToolbar = createSelector(
  stateSelector,
  state => !!state.toolbar
)

export const newsSelector = createSelector(
  stateSelector,
  state => state.lastNews
)

export const newsLoadingSelector = createSelector(
  newsSelector,
  lastNews => lastNews.loading
)

export const newsDataSelector = createSelector(
  newsSelector,
  lastNews => lastNews.data
)

export const newsErrorSelector = createSelector(
  newsSelector,
  lastNews => lastNews.err
)


/**
 * Sagas
 */

  export const getLastNewsSaga = function*() {
    while(true) {
      yield take(LAST_NEWS_REQUEST);
      try {
        const { data } = yield call(api.getAdminLastNews);
        yield put({ type: LAST_NEWS_SUCCESS, payload: data })
      } catch(err) {
        yield put({ type: LAST_NEWS_FAIL, err })
      }
    }
  }


 export const saga = function* () {
  yield spawn(getLastNewsSaga);
 }