import React, { useEffect, useState } from 'react';
import classNames from 'classnames';
import { connect } from 'react-redux';
import { FormattedMessage, injectIntl } from 'react-intl';
import { withRouter } from 'react-router-dom';
import Field from '../../common/field';
import Form from '../../common/form';
import m from './messages';
import PageLinkModel from '../../common/old-pagination/model';
import QueryModel from '../../../ducks/common/query-model';
import Section from '../../common/old-section';
import { isBusy } from '../../../ducks/common/busy';
import { showError } from '../../../ducks/common/alert';
import { fetchPrefix, Parameters, requestManageSpam, resetManageSpam, selectQuery } from '../../../ducks/manage-spam';
import { parseDate } from '../../utils';

export function validateDate(string) {
  const time = parseDate(string);
  //todo there is no translate for 'Invalid date'
  return time && isFinite(time.getTime())
    ? [time.toISOString(), null]
    : ['', string ? <span className="errorMessage">Invalid date</span> : null];
}

function ManageSpamForm(
  {
    fetch,
    reset,
    setError,
    history,
    busy,
    query,
    newQuery,
    intl,
    searchBusy
  }) {
  const [authorUsername, setAuthorUsername] = useState('');
  const [authorEmail, setAuthorEmail] = useState('');
  const [subject, setSubject] = useState('');
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');

  const [startDateISOString, startDateError] = validateDate(startDate);
  const [endDateISOString, endDateError] = validateDate(endDate);

  const isValid = [authorUsername, authorEmail, subject].some(string => string.length > 0);

  useEffect(() => {
    if (newQuery.hasParams) {
      fetch(newQuery);
    }
  }, newQuery.toDependencies());

  query.change = fetch;
  query.busy = busy;

  function submit() {
    if (isValid) {
      const params = {
        authorUsername: authorUsername.trim(),
        authorEmail: authorEmail.trim(),
        subject: subject.trim()
      };
      if (startDateISOString) {
        params.startDate = startDateISOString;
      }
      if (endDateISOString) {
        params.endDate = endDateISOString;
      }
      const q = new QueryModel({ params });
      reset();
      history.push(q.toString());
    } else {
      setError(intl.formatMessage({ id: m.enterSearchCriteria.id }));
    }
  }

  const canSubmit = !busy && isValid;

  const submitClass = classNames('submit ancBtn orange', {
    loading: searchBusy
  });

  const fieldClass = (value, width = '50') => `${value.trim() ? 'filled' : 'empty'} ancCol w${width}`;

  return (
    <Section title={<FormattedMessage {...m.searchMessages} />} classes="form">
      <Form submit={submit} canSubmit={canSubmit}>
        <fieldset className="ancGrid full320 topSpacing">
          <Field
            classes={fieldClass(authorUsername)}
            label={<FormattedMessage {...m.username} />}
            name="username"
            setValue={setAuthorUsername}
            type="search"
            value={authorUsername}
          />
          <Field
            classes={fieldClass(authorEmail)}
            label={<FormattedMessage {...m.email} />}
            name="email"
            setValue={setAuthorEmail}
            type="search"
            value={authorEmail}
          />
          <Field
            classes={fieldClass(subject)}
            label={<FormattedMessage {...m.subject} />}
            name="subject"
            setValue={setSubject}
            type="search"
            value={subject}
          />
          <Field
            classes={fieldClass(startDate, '25')}
            label={<FormattedMessage {...m.beginDate} />}
            message={startDateError}
            name="beginDate"
            placeholder={intl.formatMessage(m.beginDatePlaceholder)}
            setValue={setStartDate}
            type="search"
            value={startDate}
          />
          <Field
            classes={fieldClass(endDate, '25')}
            label={<FormattedMessage {...m.endDate} />}
            message={endDateError}
            name="endDate"
            placeholder={intl.formatMessage(m.endDatePlaceholder)}
            setValue={setEndDate}
            type="search"
            value={endDate}
          />
        </fieldset>
        <div className="controls ancCol ancColRow formFooter w100">
          <button disabled={!canSubmit} type="submit" className={submitClass}>
            <FormattedMessage {...m.search} />
          </button>
        </div>
      </Form>
    </Section>
  );
}

function mapStateToProps(state) {
  const newQuery = new PageLinkModel(state.router.location.search);
  newQuery.getParamKeys = () => Parameters;
  return {
    busy: isBusy(state),
    searchBusy: isBusy(state, `${fetchPrefix}/`),
    query: selectQuery(state),
    newQuery
  };
}

function mapDispatchToProps(dispatch) {
  return {
    fetch(query) {
      dispatch(requestManageSpam(query));
    },
    reset() {
      dispatch(resetManageSpam());
    },
    setError(message) {
      dispatch(showError(message));
    }
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(injectIntl(ManageSpamForm)));

