import React from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { useParams } from 'react-router-dom';
// data
import {
  categoryUserRoleSelector
} from '../../../../ducks/home-category-page';
import { ROLE_TYPES } from '../../../../constants';
import { isActiveToolbar } from '../../../../ducks/admin-panel';
import { volunteerLinkSelector, infoLinkSelector } from '../../../../ducks/home-category-page';
// links views
import {
  RSSLink, BoardInformationLink,
  ListAdminLink, EditOptionsLink,
  RenameBoardLink, MoveBoardLink, MergeBoardLink, AddBoardPathLink, DeleteBoardLink, EditBoardLink, EmailBoardLink,
  RecycleBinLink, VolunteerLink
} from '../links/list';
import AddRemoveFavorites from '../links/add-remove-favorites';
import AddRemoveAlerts from '../links/add-remove-alerts';
import Menu from '../../../menu';
import m from '../messages';


export default function CategoryBoardList(props) {
  // user roles
  const userRole = useSelector(categoryUserRoleSelector);

  const isSuperAdmin = userRole === ROLE_TYPES.superAdmin;
  const isAdmin = userRole === ROLE_TYPES.admin;
  const isAuthRole = userRole === ROLE_TYPES.authenticated;
  const isAuthenticated = isSuperAdmin || isAdmin || isAuthRole;

  // admin panel state
  const activeToolbar = useSelector(isActiveToolbar);
  // additional link
  const activeVolunteer = useSelector(volunteerLinkSelector);
  const activeInfo = useSelector(infoLinkSelector);

  // routing
  const { path } = useParams();
  const adminNews = 'adminnews';
  const isAdminNews = path === adminNews;

  function renderSuperAdminList() {
    return (
      <>
        <RSSLink {...props} />
        { activeInfo && <BoardInformationLink {...props} /> }
        { activeVolunteer && <VolunteerLink {...props} /> }
        <AddRemoveFavorites />
        <AddRemoveAlerts {...props} />
        { activeToolbar && (
          <>
            <ListAdminLink {...props}  />
            <EditOptionsLink {...props}  />
            {!isAdminNews && <RenameBoardLink {...props}  />}
            {!isAdminNews && <MoveBoardLink {...props} />}
            {!isAdminNews && <MergeBoardLink {...props} />}
            <AddBoardPathLink {...props} />
            {!isAdminNews && <DeleteBoardLink {...props} />}
            <EditBoardLink {...props} />
            <EmailBoardLink {...props} />
            <RecycleBinLink {...props} />
          </>
        )}
      </>
    )
  }

  function renderAdminList() {
    return (
      <>
        <RSSLink {...props} />
        { activeInfo && <BoardInformationLink {...props} /> }
        { activeVolunteer && <VolunteerLink {...props} /> }
        <AddRemoveFavorites />
        <AddRemoveAlerts {...props} />
        { activeToolbar && isAuthenticated &&(
          <>
            <EditBoardLink {...props} />
            <EmailBoardLink {...props} />
            <RecycleBinLink {...props} />
          </>
        )}
      </>
    )
  }


  function renderAuthenticatedList() {
    return (
      <>
        <RSSLink {...props} />
        { activeInfo && <BoardInformationLink {...props} /> }
        { activeVolunteer && <VolunteerLink {...props} /> }
        <AddRemoveFavorites />
        <AddRemoveAlerts {...props} />
      </>
    )
  }



  return (
    <Menu classModificator={props.classes}>
      <li className='aside-tools__title aside-tools__title--black'>
        <FormattedMessage {...m.title} />
      </li>
      { isAuthenticated
        ? (<>
            { isSuperAdmin && renderSuperAdminList() }
            { isAdmin && renderAdminList() }
            { isAuthRole && renderAuthenticatedList() }
          </>)
        : (<>
          <RSSLink {...props} />
            { activeInfo && <BoardInformationLink {...props} /> }
            { activeVolunteer && <VolunteerLink {...props} /> }
          </>)
      }
    </Menu>
  )
}

CategoryBoardList.propTypes = {
  path: PropTypes.string,
  classes:PropTypes.string,
  isNested:PropTypes.bool,
}


