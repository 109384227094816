import React from 'react'; 
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';

import m from './messages';


export default function FiledCheckbox({ name, handleChange, value }) {
  const fieldProps = { 
    type: 'checkbox',
    name,
    className: `form-filed__inp-checkbox`,
    onChange: handleChange,
    checked: value,
  }



  return (
    <div className='form-filed form-filed--checkbox'>
      <label className='form-filed__label'>
        <FormattedMessage {...m.checkbox} />
        <input { ...fieldProps } />
        <span className='form-filed__toggle' />
      </label>
      
    </div>
  )
}

FiledCheckbox.propTypes = {
  handleChange: PropTypes.func,
  value: PropTypes.bool,
  readOnly: PropTypes.bool
}