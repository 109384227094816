import { call, put, takeEvery, select } from 'redux-saga/effects';
import { Record } from 'immutable';
import { createSelector } from 'reselect';
import { replace } from 'connected-react-router';
import { appName } from '../config';
import api from '../services/threads-service';
import apiMedia from '../services/medias-service';

import { uploadedFilesSelector, newAddedFilesSelector, RESET_STATE as RESET_STATE_FILES } from './attachments';

import url from '../url-service';
import { extractIds } from './utils';


/**
 * Constants
 */

export const moduleName= 'thread-edit';
export const prefix = `${appName}/${moduleName}`;

export const EDIT_THREAD_REQUEST = `${prefix}/EDIT_THREAD_REQUEST`;
export const EDIT_THREAD_SUCCESS = `${prefix}/EDIT_THREAD_SUCCESS`;
export const EDIT_THREAD_FAIL = `${prefix}/EDIT_THREAD_FAIL`;

export const RESET_STATE = `${prefix}/RESET_STATE`;


export const ReducerRecord = Record({
  loading: false,
  err: null,
})

/**
 * Reducer
 */




export default function reducer(state = ReducerRecord(), { type, _, err }) {
  switch(type) {
      case EDIT_THREAD_REQUEST:
        return state.set('loading', true);
      case EDIT_THREAD_FAIL:
        return state
          .set('loading', false)
          .set('err', err)
      case RESET_STATE:
        return state.clear();
    default:
      return state;
  }

}


 /**
  * Actions
  */

  export const editThread = (alienForm, body, path, threadId) => ({ type: EDIT_THREAD_REQUEST, payload: { alienForm, body, path, threadId } })


/**
 * Selectors
 */

export const stateSelector = state => state[moduleName];
export const loadingSelector = createSelector(
  stateSelector, state => state.loading
)

/**
 * Saga
 */



 export const alienCreateFilesBodySaga = function* (body) {
  let filesIds = yield select(uploadedFilesSelector);
  filesIds = extractIds(filesIds);
  return {...body, attachmentIds: [...filesIds] }
 }


export const authorCreateFilesBodySaga = function* (body) {
  let filesIds = yield select(uploadedFilesSelector);
      filesIds = extractIds(filesIds);
  const filesStreams = yield select(newAddedFilesSelector);

  if (!filesStreams.length) return {...body, attachmentIds: [...filesIds] };

  try {
    const { data } = yield call(apiMedia.uploadFiles, filesStreams);
    return {...body, attachmentIds: [...filesIds, ...data] };
  } catch(err) {
    yield put({ type: EDIT_THREAD_FAIL, err })
  }
}


 export const editThreadSaga = function* ({ payload: { alienForm, body, path, threadId  } }) {
  if (alienForm) body = yield alienCreateFilesBodySaga(body);
  else body = yield authorCreateFilesBodySaga(body);

  try {
    yield call(api.updateThread, path, threadId, body);
    yield put(replace(url.createPath('thread', path, threadId)));
    yield put({ type: RESET_STATE });
    if (!alienForm) yield put({ type: RESET_STATE_FILES });
  } catch(err) {
    yield put({ type: EDIT_THREAD_FAIL, err });
  }
 }


export const saga = function* () {
  yield takeEvery(EDIT_THREAD_REQUEST, editThreadSaga)
}