import { defineMessages } from 'react-intl';

export default defineMessages({
  accept: 'Accept',
  author: 'Author',
  board: 'Board',
  classification: 'Classification',
  creatorName: 'Name:',
  email: 'Email:',
  judge: 'Judge Objection',
  location: 'Location:',
  lock: 'Lock',
  locked: 'ERROR: Currently locked by another user',
  lockedBy: 'Locked by {lockerName}',
  message: 'Message',
  name: 'New Board Name:',
  path: 'Location:',
  posted: 'Posted:',
  reject: 'Reject',
  remove: 'Remove',
  requestDate: 'Request Date:',
  status: 'Status:',
  subject: 'Subject:',
  surnames: 'Surnames',
  unlock: 'Unlock',
  unlocked: 'Unlocked'
});
