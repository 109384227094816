import React, { useEffect, useRef, useState } from 'react';
import { connect } from 'react-redux';
import { FormattedMessage, injectIntl } from 'react-intl';
import { shape } from 'prop-types';
import { withRouter } from 'react-router-dom';
import ChangeBoard from '../change';
import m from './messages';
import { ChildrenType, PathMatchType } from '../../../utils/prop-types';
import { parentPath, linkIdOf } from '../utils';
import { categoryPathFormat, PathRegExp } from '../../../../utils/strings';
import CategoryHint from '../category-hint';
import Field from '../../../common/field';
import {
  createOptionsFetch,
  optionsMapStateToProps,
  requestFetchOptions,
  requestPathProbe, selectNodeConflict
} from '../../../../ducks/options';
import { trackPageView } from '../../../../utils/tealium';

export function MoveBoard(
  {
    tracking,
    busy,
    conflict,
    hasNode,
    intl,
    match,
    messages = m,
    requestFetchOptions,
    requestPathProbe,
    title
  }) {
  const [newPath, setNewPath] = useState('');
  const linkId = linkIdOf(match.params.path);
  const path = `${newPath}.${linkId}`;
  // useEffect(createOptionsFetch(busy, newPath, requestFetchOptions, useRef(0)), [newPath]);
  useEffect(() => {
    if (!path || conflict.path !== path) {
      requestPathProbe(path);
    }
  }, [path]);
  useEffect(() => trackPageView(`${(tracking || 'move-board')} : ${match.params.path}`), []);
  let message;
  if (!hasNode) {
    message = intl.formatMessage(m.notCategory);
  } else if (conflict.path && conflict.path === path) {
    message = intl.formatMessage(m.exists);
  }

  const isValid = newPath;

  return (
    <ChangeBoard
      canSubmit={isValid && !busy}
      data={{ newPath }}
      messages={messages}
      operation="move"
      path={parentPath(match.params.path)}
      successMessage={intl.formatMessage(messages.success)}
      title={title}
    >
      <Field
        about={<CategoryHint />}
        format={categoryPathFormat}
        label={<FormattedMessage {...(messages.newPath || m.newPath)} />}
        message={message}
        name="path"
        setValue={value => setNewPath(value)}
        validationClasses="required"
        value={newPath}
      />
    </ChangeBoard>
  );
}

MoveBoard.propTypes = {
  match: shape(PathMatchType).isRequired,
  title: ChildrenType
};

MoveBoard.defaultProps = {
  title: <FormattedMessage {...m.title} />
};

function mapStateToProps(state) {
  return {
    ...optionsMapStateToProps(state),
    conflict: selectNodeConflict(state).toJSON()
  };
}

export default connect(mapStateToProps, { requestFetchOptions, requestPathProbe })(withRouter(injectIntl(MoveBoard)));
