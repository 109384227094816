import { appName } from './config';
import { parseBoolean } from './utils/strings';

const prefix = `${appName}.`;
// Without it server-side code compilation will fail
const localStorageMock = {
  getItem(key) {},
  setItem(key) {},
  removeItem(key) {}
};

const getStorage = () => (typeof window !== 'undefined' ? window.localStorage : localStorageMock);

const settings = {
  get(key) {
    return getStorage().getItem(prefix + key);
  },

  getNumber(key) {
    return + this.get(key);
  },

  getBoolean(key) {
    return parseBoolean(settings.get(key));
  },
  
  set(key, value) {
    getStorage().setItem(prefix + key, value);
  },

  rewrite(keyName, value) {
    if (this.get(keyName)) return this.remove(keyName);
    return this.set(keyName, value);
  },

  remove(key) {
    getStorage().removeItem(prefix + key);
  }
};

export default settings;
