import React from 'react';
import PropTypes from 'prop-types';
import { Link, useParams } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';

import url from '../../../../url-service';
import m from '../../messages';
import { replaceBarSymbolToGreaterThan } from '../../../utils'


export default function BoardLink({ namePath }) {
  const { path } = useParams()
  const formatedLinkTitle = replaceBarSymbolToGreaterThan(namePath)
  
  return (
    <div className='board-link'>
      <p className='board-link__title'>
        <FormattedMessage {...m.board}/>
      </p>
      <Link to={url.createPath('category', path)} className='board-link__link'>{formatedLinkTitle}</Link>
    </div>
  )
}

BoardLink.propTypes = {
  namePath: PropTypes.string
}