import React from 'react';
import { Link } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';
import PropTypes from 'prop-types';

import Breadcrumbs from '../breadcrumbs';
import Progress from '../common/progress';
import ErrorView from '../error';
import AdvertAnchor from '../common/advert-anchor';
import m from './messages';

export default function PageLayout({
    linkToHome,
    breadcrumbs,
    aloneLink,
    children,
    classModificator,
    description,
    title,
    loading,
    error,
    advertOpt
  }) {
  const rootPageClassValues = `page page-boards${classModificator && !loading ? ` page-boards--${classModificator}` : ''}`;

  const getContentClass = () => {
    let classValue = 'page__content';
    if (loading) classValue += ' page__content--loading';
    if (error) classValue += ' page__content--error';

    return classValue;
  };

  function getBody() {
    if (error) return <div className="page__section"><ErrorView error={error} /></div>;
    if (loading) return <Progress />;
    if (Array.isArray(children)) {
      children = children.filter(child => child); // remove empty elements - child can be null
      return children.map((element, index) => <div className="page__section" key={index}>{element}</div>)
    }
    return children && <div className="page__section">{children}</div>;
  }

  function getBreadcrumbs() {
    if (loading) return null;
    if (breadcrumbs.links) return <Breadcrumbs links={breadcrumbs.links} aloneLink={aloneLink}/>
    return <Breadcrumbs aloneLink={aloneLink} />; // default case if active but without links (like search page)
  }

  function renderAdvertising() {
    if (!advertOpt || !advertOpt.show) return null;
    return <AdvertAnchor advertPosition={advertOpt.position} />;
  }

  return (
    <div className={rootPageClassValues}>
      {linkToHome && (
        <div className="page__home-link">
          <Link to="/"><FormattedMessage {...m.returnToHomepage} /></Link>
        </div>
      )}
      {breadcrumbs && (
        <div className="page__breadcrumb">
          { getBreadcrumbs() }
        </div>
      )}
      { renderAdvertising() }
      {!loading && (
        <header className="page__header">
          {title && <h1 className="page__title">{title}</h1>}
          {description && <p className="page__desc">{description}</p>}
        </header>
      )}
      <div className={getContentClass()}>
        { getBody() }
      </div>
    </div>
  );
}

PageLayout.defaultProps = {
  breadcrumbs: false,
  aloneLink: false
};

PageLayout.propTypes = {
  children: PropTypes.node,
  breadcrumbs: PropTypes.oneOfType([ PropTypes.bool, PropTypes.object ]),
  classModificator: PropTypes.string,
  title: PropTypes.node,
  description: PropTypes.element,
  linkToHome: PropTypes.bool,
  loading: PropTypes.bool
};

