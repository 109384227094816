import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { FormattedMessage, injectIntl } from 'react-intl';
import m from './messages';
import AlertConfirmDelete from './alert-confirm-delete';
import mockTableLists from './mock-table-lists';
import url from '../../url-service';



function TableFavorites({ type, data, countItems, listByType }) {
  const [itemForDelete, setItemForDelete] = useState(null);
  const [tableList, setTableList] = useState(mockTableLists[type])

  useEffect(
    () => {
      setTableList(mockTableLists[type])
    }, [type]
  )


  if (!tableList) return null;

  const renderTitleCell = (title, index) => {
    title = title.split(/[ ,]+/).map((e, i) => i === 0 ? (e === "Replies" ? `${e.toLowerCase()}Count` : e.toLowerCase()) : e).join("");

    if (index === 0 && listByType) return 'Name';
    if (index !== 0) return <FormattedMessage {...m[title]} />;

    const minCountItems = 5;
    return (
      <>
        {<FormattedMessage {...m[title]} />}
        {(!listByType && countItems > minCountItems) &&
          (<>
            {' '}
            <FormattedMessage
              {...m.itemsCount}
              values={{ number: <Link to={`/favorites/${type}`}>{countItems}</Link> }}
            />
          </>
          )
        }
      </>
    )
  }

  const renderDeleteButton = (type, item) => {
    return <button className='icon iconTrash link' onClick={() => setItemForDelete({ type, item })} />
  }

  const getHeaderRow = () => (
    <thead>
      <tr className='table__row table__row--header'>
        {tableList.titles.map(
          (title, index) => (<td key={title} className='table__col'>{renderTitleCell(title, index)}</td>)
        )}
      </tr>
    </thead>
  )

  if (!data.length) return null;
  return (
    <>
      <table className={`table table--favorites-${type}`}>
        {getHeaderRow()}
        <tbody>
          {data.map((item, index) => (
            <tr key={index} className='table__row'>
              {tableList.getCellItems(item)}
              <td className='table__col'>
                {renderDeleteButton(type, item)}
              </td>
            </tr>
          )
          )}
        </tbody>
      </table>
      {itemForDelete && <AlertConfirmDelete itemForDelete={itemForDelete} setItemForDelete={setItemForDelete} listByType={listByType} />}
    </>
  )
}

TableFavorites.defaultProps = {
  data: []
}

TableFavorites.propsTypes = {
  type: PropTypes.string,
  countItems: PropTypes.number,
  data: PropTypes.arrayOf(PropTypes.object),
  listByType: PropTypes.bool
}

export default injectIntl(TableFavorites);