/* eslint-disable max-len */
import HttpServices from './index';

class SuggestionsService extends HttpServices {
    createBoardSuggestion = body => this.http.post('/suggestions/boards', body);
    createObjection = (path, body) => this.http.post('/suggestions/objections', body, this._setHeaderBoardsPath(path));
    getSingleSuggestion = (id, path) => this.http.get(`/suggestions/${id}`, this._setHeaderBoardsPath(path));
    getSuggestions = query => this.http.get(query.toString());
    judgeSuggestions = (id, path, operation, body) => this.http.put(`/suggestions/${id}?operation=${operation}`, body, this._setHeaderBoardsPath(path));
    createRights = path => this.http.post('/suggestions/rights', null, this._setHeaderBoardsPath(path));
    checkVolunteerSubmit = (userId, path) => this.http.head(`/suggestions/users/${userId}/rights`, this._setHeaderBoardsPath(path));
    // TODO: This MUST BE REWRITTEN
    deleteThreadBySuggestionId = (id, path) => this.http.delete(`/suggestions/${id}/threads`, this._setHeaderBoardsPath(path));
}

export default new SuggestionsService();
