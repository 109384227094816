import { defineMessages } from 'react-intl';

export default defineMessages({
  author: 'Author',
  board: 'Board',
  classification: 'Classification',
  date: 'Date',
  messageBoards: 'Message Boards',
  subject: 'Subject',
  surnames: 'Surnames',
  url: 'URL'
});
