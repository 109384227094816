import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { FormattedMessage } from 'react-intl';


import BoardsSearch from '../../components/searches/boards';
// import ErrorModal from '../../common/error-modal';
import KeywordsSearch from '../../components/searches/advanced/keywords-search';
import m from './messages';
import { trackPageView } from '../../utils/tealium';
import PageLinkModel from '../../components/common/old-pagination/model';
import pageSizes, { getSizes } from '../../components/common/paging/mock';
import QueryModel from '../../ducks/common/query-model';
import SurnameLettersBoard from '../../components/surname';
import SurnameResultsBoard from '../../components/surname/results';
import { Busy } from '../../components/common/busy';
import { getSurnames, surnamesLoading, surnamesEntities, surnamesError, resetSurnamesState } from '../../ducks/search';
import { isSuperAdminRoleSelector } from '../../ducks/user';
import { isActiveToolbar } from '../../ducks/admin-panel';
import PageLayout from '../../components/hoc/page-layout';

import {
  ListAdminLink, EditOptionsLink, CreateNewBoardLink, RecycleBinLink
} from '../../components/admin/tools/links/list';

function SearchSurnamePage(
  {
    entities: { title, surnames, boards },
    err,
    getSurnames,
    loading,
    newQuery,
    activeToolbar,
    isSuperAdmin,
    resetSurnamesState
  }) {
  const searchQuery = newQuery.params.sname || '';
  const dependencies = [searchQuery, newQuery.itemsPerPageName, newQuery.currentPage];
  useEffect(() => {
    if (searchQuery) {
      getSurnames(...dependencies);
    }
  }, dependencies);

  useEffect(() => {
    trackPageView('search: surname')
    return () => resetSurnamesState()
  }, []);

  const query = new PageLinkModel({
    ...(boards ? boards.paging : {}),
    params: {
      query: searchQuery
    },
    sizes: getSizes(pageSizes.HUNDRED),
    change: newPage => history.push(newPage.toString())
  });
  query.path = '/search/boards/:query';


  const renderAdminTools = () => (
    <ul className='surname-page__admin-tools'>
      <ListAdminLink path='surnames' />
      <EditOptionsLink path='surnames' />
      <CreateNewBoardLink path='surnames' />
      <RecycleBinLink path='surnames' />
    </ul>
  )


  let results;
  if (boards && boards.items) {
    results = (
      <Busy busy={loading}>
        <div className="surname-page__content-result">
          { isSuperAdmin && activeToolbar && renderAdminTools() }
          <SurnameResultsBoard {...boards} newQuery={newQuery} />
        </div>
      </Busy>
    );
  }

  const breadcrumbs = {
    links: [
      { title: <FormattedMessage {...m.searchSurname} /> }
    ]
  }

  return (
    <PageLayout
      loading={loading}
      title={<FormattedMessage {...m.messageBoards} />}
      classModificator='surname-page'
      error={err}
      breadcrumbs={breadcrumbs}
    >
      <>
        <div className="surname-page__search">
          <KeywordsSearch />
        </div>
        {surnames && (
          <div className="surname-page__content-letters">
            <SurnameLettersBoard title={title} {...surnames} />
          </div>
        )}

        {results}
        <div className="surname-page__search">
          <BoardsSearch />
        </div>
      </>
    </PageLayout>
  );
}

function mapStateToProps(state, props) {
  return {
    loading: surnamesLoading(state),
    entities: surnamesEntities(state),
    err: surnamesError(state),
    newQuery: new QueryModel(props.location.search),
    isSuperAdmin: isSuperAdminRoleSelector(state),
    activeToolbar: isActiveToolbar(state)
  };
}

export default connect(mapStateToProps, { getSurnames, resetSurnamesState })(SearchSurnamePage);
