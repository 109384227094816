import HttpServices from './index';

class ContentsServices extends HttpServices {

  static createQueryForPath(itemsPerPage, page) {
    let queryParams = '';
    if (itemsPerPage) { 
      queryParams = `?itemsPerPage=${itemsPerPage}`;
    }
    if (page) {
      const symbol = !itemsPerPage ? '?' : '&';
      queryParams = `${queryParams}${symbol}page=${page}`;
    }
    return queryParams;
  }

  getContent = (path, itemsPerPage, page) => {
    const url = `/contents`;
    if (!path) return this.http.get(url, this._setHeaderBoardsPath());
    
    const queryPagingParams = HttpServices.createQueryForPaging(itemsPerPage, page);
    return this.http.get(`${url}${queryPagingParams}`, this._setHeaderBoardsPath(path));
  };

  getAdminLastNews = () => this.http.get('/contents/admin-news', this._setHeaderBoardsPath(''));
  getAdmins = path => this.http.get('/users/admins', this._setHeaderBoardsPath(path));
  removeAdmin = (userId, path) => this.http.delete(`/users/admins/${userId}`, this._setHeaderBoardsPath(path));
}

export default new ContentsServices();
