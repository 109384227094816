import React from 'react';
import { FormattedMessage } from 'react-intl';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInfoCircle } from '@fortawesome/free-solid-svg-icons';

import m from '../../pages/category/messages';

export default function NoticeModerate() {
  return (
    <div className="categories__notice">
      <div className="categories__notice-info">
        <FontAwesomeIcon icon={faInfoCircle} />
        <span className="categories__notice-msg">
          <FormattedMessage {...m.moderate} />
        </span>
      </div>
    </div>
  );
}
