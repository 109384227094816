import { defineMessages } from 'react-intl';

export default defineMessages({
  boardId: 'Board ID',
  boardName: 'Board Name',
  boardPath: 'Board Path',
  comment: 'Comment',
  judge: 'Judge Board',
  makeAdmin: 'Make {username} the admin of this board',
  comments: 'Rejection Comments',
  title: 'Review Boards'
});
