import { call, put, spawn, take, select } from 'redux-saga/effects';
import { Record } from 'immutable';
import { createSelector } from 'reselect';

import { appName } from '../config';


/**
 * Constants
 */
export const moduleName = 'attachments';
export const prefix = `${appName}/${moduleName}`;

export const INIT_PREV_UPLOADED = `${prefix}/INIT_PREV_UPLOADED`;
export const ADD_NEW_FILE = `${prefix}/ADD_NEW_FILE`;

export const DELETE_FILE = `${prefix}/DELETE_FILE`;
export const RESET_NEW_ADDED_FILES = `${prefix}/RESET_NEW_ADDED_FILES`;
export const RESET_STATE = `${prefix}/RESET_STATE`;


/**
 * Reducer
 */




export const FetchStateRecord = Record({
  loading: false,
  ids: null,
  err: null
})

export const FilesRecord = Record({
  loading: true,
  oldUploaded: [], // array of files ids
  newAdded: [] // array of files streams
})


export const ReducerRecord = Record({
  files: FilesRecord(),
})



export default function reducer(state = ReducerRecord(), {type, payload, err } ) {
  switch(type) {

    case INIT_PREV_UPLOADED:
      return state  
            .setIn(['files', 'oldUploaded'], payload)
            .setIn(['files', 'loading'], false)

    case ADD_NEW_FILE:
      return state.setIn(['files', 'newAdded'], [...state.files.newAdded, payload])

    case DELETE_FILE: {
      const { type, file } = payload
      let key = 'newAdded';
      let files = state.files.newAdded.filter((oldFile) => oldFile !== file);
      
      if (type === 'uploaded') {
        key = 'oldUploaded'
        files = state.files.oldUploaded.filter((oldFile) => oldFile.id !== file.id)
      }

      return state.setIn(['files', key], files)
    }

    case RESET_NEW_ADDED_FILES:
      return state.setIn(['files', 'newAdded'], [])

    case RESET_STATE:
      return state.clear()
    default: 
      return state;
  }
}


/**
* Actions
*/

export const initPrevUploaded = (ids) => ({ type: INIT_PREV_UPLOADED, payload: ids });
export const addNewFile = (file) => ({ type: ADD_NEW_FILE, payload: file });
export const deleteFile = (type, file) => ({ type: DELETE_FILE, payload: { type, file } })
export const resetNewAddedFiles = () => ({ type: RESET_NEW_ADDED_FILES })
export const resetAttachmentsStore = () => ({ type: RESET_STATE })

/**
 * Selectors
 */

export const stateSelector = state => state[moduleName];

export const filesSelector = createSelector(
  stateSelector, state => state.files
)

export const loadingUploadedListSelector = createSelector(
  filesSelector, files => files.loading
)

export const uploadedFilesSelector = createSelector(
  filesSelector, files => files.oldUploaded
)

export const newAddedFilesSelector = createSelector(
  filesSelector, files => files.newAdded
)



export const saga = function* () {

}