import React from 'react';
import { useSelector } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import { useParams } from 'react-router-dom';
// data
import { isActiveToolbar } from '../../../../ducks/admin-panel';
import { volunteerLinkSelector, infoLinkSelector, threadUserRoleSelector } from '../../../../ducks/thread-page';
// links views
import {
  RSSLink,
  BoardInformationLink,
  ListAdminLink, EditOptionsLink,
  RenameBoardLink, MoveBoardLink, MergeBoardLink, AddBoardPathLink, DeleteBoardLink, EditBoardLink, EmailBoardLink,
  RecycleBinLink, VolunteerLink
} from '../links/list';
import AddRemoveFavorites from '../links/add-remove-favorites';
import AddRemoveAlerts from '../links/add-remove-alerts';
import Menu from '../../../menu';
import m from '../messages';
import { ROLE_TYPES } from '../../../../constants';


export default function ThreadList(props) {
  // user roles
  const userRole = useSelector(threadUserRoleSelector) || ROLE_TYPES.guest;

  const isSuperAdmin = userRole === ROLE_TYPES.superAdmin;
  const isAdmin = userRole === ROLE_TYPES.admin;
  const isAuthRole = userRole === ROLE_TYPES.authenticated;
  const isAuthenticated = isSuperAdmin || isAdmin || isAuthRole;

  // admin panel state
  const activeToolbar = useSelector(isActiveToolbar);
  // additional link
  const activeVolunteer = useSelector(volunteerLinkSelector);
  const activeInfo = useSelector(infoLinkSelector);

  // routing
  const { path } = useParams();
  const adminNews = 'adminnews';
  const isAdminNews = path === adminNews;

  function renderSuperAdminList() {

    return (
      <>
        <RSSLink {...props} />
        { activeInfo && <BoardInformationLink {...props} /> }
        { activeVolunteer && <VolunteerLink {...props} /> }
        <AddRemoveFavorites />
        <AddRemoveAlerts {...props} isThread/>
        { activeToolbar && (
          <>
            <ListAdminLink {...props}  />
            <EditOptionsLink {...props}  />
            {!isAdminNews && <RenameBoardLink {...props}  />}
            {!isAdminNews && <MoveBoardLink {...props} />}
            {!isAdminNews && <MergeBoardLink {...props} />}
            <AddBoardPathLink {...props} />
            {!isAdminNews && <DeleteBoardLink {...props} />}
            <EditBoardLink {...props} />
            <EmailBoardLink {...props} />
            <RecycleBinLink {...props} />
          </>
        )}
      </>
    )
  }

  function renderAdminList() {

    return (
      <>
        <RSSLink {...props} />
        { activeInfo && <BoardInformationLink {...props} /> }
        { activeVolunteer && <VolunteerLink {...props} /> }
        <AddRemoveFavorites />
        <AddRemoveAlerts {...props} isThread/>
        { activeToolbar && (
          <>
            <EditBoardLink {...props} />
            <EmailBoardLink {...props} />
            <RecycleBinLink {...props} />
          </>
        )}
      </>
    )
  }


  function renderAuthenticatedList() {

    return (
      <>
        <RSSLink {...props} />
        { activeInfo && <BoardInformationLink {...props} /> }
        { activeVolunteer && <VolunteerLink {...props} /> }
        <AddRemoveFavorites />
        <AddRemoveAlerts {...props} isThread/>
      </>
    )
  }


  return (
    <aside className='aside-tools'>
      <h2 className='aside-tools__title'>
        <FormattedMessage {...m.title} />
      </h2>
      <nav className='aside-tools__menu'>
      <Menu classModificator={props.classes}>
      { isAuthenticated
        ? (<>
            { isSuperAdmin && renderSuperAdminList() }
            { isAdmin && renderAdminList() }
            { isAuthRole && renderAuthenticatedList() }
          </>)
        : (<>
            { activeInfo && <BoardInformationLink {...props} /> }
            { activeVolunteer && <VolunteerLink {...props} /> }
          </>)
        }
      </Menu>
      </nav>
    </aside>
  )
}
