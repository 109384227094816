import React from 'react'; 
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';

import m from './messages';


const BtnSubmit = ({ handleSubmit, title }) => (
  <button className='btn btn--submit' onClick={handleSubmit}>
    { title 
      ? title
      : <FormattedMessage {...m.submit} />
    }
  </button>
)

BtnSubmit.propTypes = {
  handleSubmit: PropTypes.func,
  title: PropTypes.string,
}
export default BtnSubmit;