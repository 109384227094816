import { createSelector } from 'reselect';
import { Record } from 'immutable';
import { call, put, takeEvery } from 'redux-saga/effects';
import api from '../services/contents-service';
import settings from '../local-settings';
import { action, fetching, freeze, modification } from '../components/utils/redux';
import { appName } from '../config';
import {
  setAdvancedSearchError
} from './advanced-search';

export const moduleName = 'old_admin';
export const prefix = `${appName}/${moduleName}`;
export const ADMIN_ENABLE = `${prefix}/enable`;
export const lastPrefix = `${prefix}/last`;
export const ADMIN_NEWS_LAST_REQUEST = `${lastPrefix}/REQUEST`;
export const ADMIN_NEWS_LAST_RECEIVE = `${lastPrefix}/RECEIVE`;
export const adminListPrefix = `${prefix}/admins/fetch`;
export const ADMIN_ADMIN_LIST_REQUEST = `${adminListPrefix}/REQUEST`;
export const ADMIN_ADMIN_LIST_RECEIVE = `${adminListPrefix}/RECEIVE`;
export const removeAdminPrefix = `${prefix}/admins/remove`;
export const ADMIN_REMOVE_REQUEST = `${removeAdminPrefix}/REQUEST`;
export const ADMIN_REMOVE_SUCCESS = `${removeAdminPrefix}/SUCCESS`;
export const ADMIN_ENABLED_KEY = 'admin.enabled';

export const requestLastAdminNews = () => action(ADMIN_NEWS_LAST_REQUEST);
export const requestAdminList = (path = '') => ({ type: ADMIN_ADMIN_LIST_REQUEST, payload: path });
export const requestRemoveAdmin = (path, userId, next) => action(ADMIN_REMOVE_REQUEST, { path, userId }, next);

export const ArticleRecord = Record({
  body: '',
  id: '',
  path: '',
  posted: '',
  subject: ''
});

export const AdminRecord = Record({
  enabled: settings.getBoolean(ADMIN_ENABLED_KEY),
  last: null,
  admins: null
});

export const fetchLast = fetching(
  () => [api.getAdminLastNews],
  ADMIN_NEWS_LAST_RECEIVE
);

export const receiveAdminList = payload => action(ADMIN_ADMIN_LIST_RECEIVE, payload);

export function* fetchAdminList({ payload: path }) {
  try {
    const { data } = yield call(api.getAdmins, path);
    yield put(receiveAdminList(data));
  } catch (e) {
    yield put(setAdvancedSearchError(messageOf(e)));
  }
}

export const removeAdmin = modification(
  ({ userId, path }) => [api.removeAdmin, userId, path],
  ADMIN_REMOVE_SUCCESS
);

export const enableAdminToolbar = (enabled = true) => ({ type: ADMIN_ENABLE, payload: enabled });

export default function reducer(state = AdminRecord({}), { type, payload }) {
  switch (type) {
    case ADMIN_ENABLE: {
      const enabled = !!payload;
      settings.setBoolean(ADMIN_ENABLED_KEY, enabled);
      return state.set('enabled', enabled);
    }
    case ADMIN_NEWS_LAST_RECEIVE:
      return state
        .set('last', payload ? ArticleRecord(payload) : null);
    case ADMIN_ADMIN_LIST_REQUEST:
      return state
        .set('admins', null);
    case ADMIN_ADMIN_LIST_RECEIVE:
      return state
        .set('admins', freeze(payload));
    default:
      return state;
  }
}

export function selectAdmin(state) {
  return state[moduleName];
}

export const isAdminToolbarEnabled = createSelector(
  selectAdmin,
  state => state.get('enabled')
);

export const selectLastAdminNews = createSelector(
  selectAdmin,
  state => state.get('last')
);

export const selectAdminList = createSelector(
  selectAdmin,
  state => state.get('admins')
);

export function* saga() {
  yield takeEvery(ADMIN_ADMIN_LIST_REQUEST, fetchAdminList);
  yield takeEvery(ADMIN_NEWS_LAST_REQUEST, fetchLast);
  yield takeEvery(ADMIN_REMOVE_REQUEST, removeAdmin);
}
