import {defineMessages} from 'react-intl';

export default defineMessages({
  back: 'Return to previous page',
  heading404: "We’re sorry, this page is no longer available.",
  heading500: "We're sorry. This page is temporarily unavailable.",
  homeTitle: 'Genealogy, Family Trees and Family History Records online - Ancestry®',
  reloadPrompt: 'You can try {buttonStartTag}reloading the page{buttonEndTag}, or come back later.',
  title404: 'Page Not Found',
  title500: 'Page Unavailable',
  unknown: 'Don\'t know where to go from here? You might find one of these links useful.',
  usefulLinkCreateTree: 'Create a free online family tree',
  usefulLinkDna: 'Learn about AncestryDNA',
  usefulLinkGetStarted: 'See how to get started in family history',
  usefulLinkLooking: 'Looking for Family Tree Maker software?',
  usefulLinkReady: 'Ready to make a family history book?',
  usefulLinkSearch: 'Search for historical records',
  usefulLinksText: 'You might also find one of these links useful.'
});
