import React from 'react';
import classNames from 'classnames';
import Highlighter from 'react-highlight-words';
import { FormattedMessage } from 'react-intl';
import { Link } from 'react-router-dom';
import m from './messages';

import Pagination from '../../common/pagination';
import Section from '../../common/old-section';
import { Busy } from '../../common/busy';
import { categoryLink } from '../../utils/old_link'
import { convertToDate, replaceBarSymbolToGreaterThan } from '../../utils';
import { toKeywords, reduceSpaces, toTextFragment } from '../utils';
import NoMatchesResult from './no-matches-notice';
import UserLink from '../../common/user-link';
import url from '../../../url-service';


export function SearchResults(
  {
    boards: { description, items, paging },
    urlQueryParams,
    loadingResult
  }) {
  const hasItems = !!items.length;

  const getNumberOfMessages = () => {
    const countMatchedItems = hasItems ? description : 0

    return (
      <span className="number-of-messages">
        {countMatchedItems}
        {' '}
        <FormattedMessage {...m.messages} />
      </span>
    )
  }
  
  const renderThreadText = (item,text) =>
  (<Link to={url.createPath('thread', item.path, item.threadId)} className="search-results-thread">
      {text}
    </Link>)

  const getResultTitle = () => {
    const searchResultsTitle = (
      <span className="text">
        <FormattedMessage {...m.searchResults} />
      </span>
    );

    if (loadingResult) return searchResultsTitle;

    return (
      <span role="status" aria-live="assertive">
        {searchResultsTitle}
        { hasItems && (urlQueryParams.keyword || '').trim() &&
            (<>{' '}&nbsp;<FormattedMessage {...m.searchFor} values={{ name: urlQueryParams.keyword }} /></>)
        }
        {' - ' }
        { getNumberOfMessages() }
      </span>
    )
  }

  const keywordList = toKeywords(urlQueryParams.keyword || '');
  const list = items.map(item => {
    const postedOnText = convertToDate(item.postedOn);
    let text = toTextFragment(reduceSpaces(item.text), keywordList);
    if (keywordList.length > 0) {
      text = (
        <Highlighter
          autoEscape
          searchWords={keywordList}
          textToHighlight={text}
        />
      );
    }
    let postenOn;
    if (postedOnText) {
      postenOn = (
        <span className="item-posted-on">
          <FormattedMessage {...m.postedOn} /> {postedOnText},{' '}
        </span>
      );
    }
    return (
      <li key={item.threadId}>
        <div className="item-header">
          <h3 className="item-title">
              <Link to={url.createPath('thread', item.path, item.threadId)}>{item.title}</Link>
          </h3>
          <div className="item-by">
            {postenOn}
            <span className="item-author">
              <FormattedMessage {...m.by} />
              {' '}
              <UserLink userData={item.user} />
            </span>
          </div>
        </div>
        <Link to={categoryLink(item.path)} className="item-name-path">
          { replaceBarSymbolToGreaterThan(item.namePath) }
        </Link>
        <div className="item-text">
          { (keywordList.length > 0)? 
		    (<Link to={url.createPath('thread', item.path, item.threadId)} className="search-results-thread">
			    {text}
		    </Link>):text }
        </div>
      </li>
    );
  });

  

  const className = classNames('results', {
    'has-results-count': hasItems,
  });

  const renderResultContent = () => hasItems
    ? (<ul className="search-results-list">
        {list}
      </ul>)
    : <NoMatchesResult />


  return (
    <Section title={getResultTitle()} classes={className}>
      { loadingResult &&  <Busy busy={loadingResult} classes='search-result-loading' />}
      { !loadingResult && (
        <>
          { hasItems && <Pagination hasItems={hasItems} paging={paging} /> }
          { renderResultContent() }
          { hasItems && <Pagination hasItems={hasItems} paging={paging} /> }
        </>
      ) }

    </Section>
  );
}


export default SearchResults;
