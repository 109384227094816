import React from 'react';
import PropTypes from 'prop-types';
import { Link, useParams } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';
import m from './messages';


export default function PrevNext({ prevThreadId, nextThreadId, classModificator }) {
  const { path } = useParams();
  return (
    <div className={`thread__prev-next${ classModificator ? ` ${classModificator}`: '' }`}>
      { prevThreadId && (<Link className='thread__prev' to={`/${path}/${prevThreadId}`}>
          <FormattedMessage {...m.previous} />
        </Link>)
      }
      { prevThreadId && nextThreadId && ' | '}
      { nextThreadId && (<Link className='thread__next' to={`/${path}/${nextThreadId}`}>
          <FormattedMessage {...m.next} />
        </Link>)
      }
    </div>
  )
  
}


PrevNext.propTypes = {
  prevThreadId: PropTypes.any, // string, object or null
  nextThreadId: PropTypes.any, // string, object or null,
  classModificator: PropTypes.string
}