import { spawn, take, call, put, takeEvery } from 'redux-saga/effects';
import { appName } from '../config';
import api from '../services/alerts-service';
import { OrderedSet, Record } from 'immutable';
import { createSelector } from 'reselect';
import { errorHandlerSaga } from './error';
import { Http } from '../constants';


/**
 * Constants
 */

export const moduleName = 'alerts-page';
export const prefix = `${appName}/${moduleName}`;

export const GET_ALERTS_REQUEST = `${prefix}/GET_ALERTS_REQUEST`;
export const GET_ALERTS_SUCCESS = `${prefix}/GET_ALERTS_SUCCESS`;
export const GET_ALERTS_FAIL = `${prefix}/GET_ALERTS_FAIL`;

export const DELETE_ALERT_REQUEST = `${prefix}/DELETE_ALERT_REQUEST`;
export const DELETE_ALERT_SUCCESS = `${prefix}/DELETE_ALERT_SUCCESS`;
export const DELETE_ALERT_FAIL = `${prefix}/DELETE_ALERT_FAIL`;

export const ADD_ALERT_REQUEST = `${prefix}/ADD_ALERT_REQUEST`;
export const ADD_ALERT_SUCCESS = `${prefix}/ADD_ALERT_SUCCESS`;
export const ADD_ALERT_FAIL = `${prefix}/ADD_ALERT_FAIL`;


/**
 * Reducer
 */

export const PagingRecord = Record({
  currentPage: null,
  totalPages: null,
  itemsPerPage: null
});

export const EntitiesRecord = Record({
    alerts: OrderedSet([]),
    paging: PagingRecord()
});

export const ReducerRecord = Record({
  loading: true,
  deleting: false,
  entities: EntitiesRecord(),
  err: null
});

export default function reducer(state = ReducerRecord(), { type, payload, err }) {
  switch (type) {
    case GET_ALERTS_REQUEST:
      return state
        .set('loading', true)
        .set('err', null)
        .set('entities', EntitiesRecord(payload));

    case GET_ALERTS_SUCCESS:
      return state
        .set('loading', false)
        .set('entities', EntitiesRecord(payload));

    case GET_ALERTS_FAIL:
      return state
        .set('loading', false)
        .set('entities', EntitiesRecord({}))
        .set('err', err);

    case DELETE_ALERT_REQUEST:
      return state.set('deleting', true);

    case DELETE_ALERT_SUCCESS:
      return state
        .set('deleting', false)
        .set('entities', EntitiesRecord(payload));

    case DELETE_ALERT_FAIL:
      return state
        .set('err', err)
        .set('deleting', false);

    case ADD_ALERT_REQUEST:
      return state
        .set('deleting', false)
        .set('entities', EntitiesRecord(payload));

    case ADD_ALERT_SUCCESS:
      return state
        .set('err', err)
        .set('deleting', false);

    default:
      return state;
  }
}

/**
 * Actions
 */

export const fetchAlerts = (page, itemsPerPage) => ({
  type: GET_ALERTS_REQUEST,
  payload: { page, itemsPerPage }
});
export const addAlert = () => ({ type: ADD_ALERT_REQUEST });
export const deleteAlert = (item) => ({
  type: DELETE_ALERT_REQUEST,
  payload: { item }
});

/**
 * Selectors
 */

export const stateSelector = state => state[moduleName];

export const loadingSelector = createSelector(
  stateSelector, state => state.loading
);

export const deletingSelector = createSelector(
  stateSelector, state => state.deleting
);

export const entitiesSelector = createSelector(
  stateSelector, state => state.entities
);

export const errorSelector = createSelector(
  stateSelector, state => state.err
);

/**
 * Sagas
 */

export const fetchAlertsSaga = function* () {
  while(true) {
    const { payload: { page, itemsPerPage } } = yield take(GET_ALERTS_REQUEST);
    try {
      const { data } = yield call(api.getAlerts, page, itemsPerPage);
      yield put({ type: GET_ALERTS_SUCCESS, payload: data })
    } catch(err) {
      if(!err.response) {
        err.messageKey = 'unavailable';
        yield put({ type: GET_ALERTS_FAIL, err });
        return null;
      }

      let status = err.response.status;
      if (status === Http.NotFound) {
        err.messageKey = 'pageNotFound';
        yield put({ type: GET_ALERTS_FAIL, err });
        return null;
      }
      err.messageKey = 'error';
      yield put({ type: GET_ALERTS_FAIL, err });
    }
  }
};

export const deleteAlertSaga = function* ({payload: { item }}) {
  try {
    yield call(api.deleteAlert, item.path);
    const { data } = yield call(api.getAlerts);
    yield put({ type: DELETE_ALERT_SUCCESS, payload: data});
  } catch(err) {
    yield put({ type: DELETE_ALERT_FAIL, err });
  }
};

export const addAlertSaga = function* ({payload: { path } }) {
  try {
    yield call(api.createAlert, path);
    yield put({ type: ADD_ALERT_SUCCESS });
  } catch(err) {
    yield put({ type: ADD_ALERT_FAIL, err });
  }
};

export function* saga() {
  yield spawn(fetchAlertsSaga);
  yield takeEvery(ADD_ALERT_REQUEST, addAlertSaga);
  yield takeEvery(DELETE_ALERT_REQUEST, deleteAlertSaga);
}
