import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { faExclamationTriangle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { FormattedMessage } from 'react-intl';
import { Link } from 'react-router-dom';

// import ErrorModal from '../../common/error-modal';
import m from './messages';
import PageLinkModel from '../../components/common/old-pagination/model';
import pageSizes, { getSizes } from '../../components/common/paging/mock';
import PageView from '../../components/common/page-view';
import Pagination from '../../components/common/old-pagination';
import Progress from '../../components/common/progress';
import QueryModel from '../../ducks/common/query-model';
import SearchBoards from '../../components/searches/boards';
import Section from '../../components/common/old-section';
import { Busy } from '../../components/common/busy';
import { categoryLink } from '../../components/utils/old_link';
import { getBoards, boardsLoading, boardsEntities, boardsError } from '../../ducks/search';
import { highlightMatchesText } from '../../components/utils';
import { trackPageView } from '../../utils/tealium';

function SearchBoardsPage({
  entities: { boards, breadcrumbs },
  err,
  history,
  loading,
  getBoards,
  match: { isExact, params },
  newQuery
}) {
  if (!isExact) return history.push('/');
  const searchQuery = newQuery.params.query || '';
  const dependencies = [searchQuery, newQuery.itemsPerPageName, newQuery.currentPage];
  useEffect(() => {
    getBoards(...dependencies);
  }, dependencies);

  useEffect(() => trackPageView('search: board'), []);

  // if (err) return <ErrorModal />;

  const query = new PageLinkModel({
    ...(boards ? boards.paging : {}),
    params: {
      query: searchQuery
    },
    sizes: getSizes(pageSizes.HUNDRED),
    change: newPage => history.push(newPage.toString())
  });
  query.path = '/search/boards/:query';

  const getBody = () => {
    if (boards) {
      if (!boards.items || !boards.items.length) return getNoticeEmptyResult();
      return (
        <Section>
          <h2 className="search-boards__title"><FormattedMessage {...m.boards} /></h2>
          <ul className="search-boards__list">
            <Busy busy={loading}>
              {boards.items.map((board, index) => getBoardsItem(board, index, params.query))}
            </Busy>
          </ul>
          <Pagination model={query} />
        </Section>
      );
    }
    if (loading) {
      return <Progress />;
    }

    return null;
  };

  return (
    <PageView
      title={<FormattedMessage {...m.messageBoards} />}
      breadcrumbs={breadcrumbs && breadcrumbs.boards}
      description={boards && boards.description}
      classes="search-boards"
    >
      <SearchBoards intialValue={params.query} />
      { getBody() }
    </PageView>
  );
}

function getBoardsItem(board, index, searchQuery) {
  return (
    <li key={index} className="search-boards__item">
      { board.links.map(boardItem => (
        <span className="search-boards__item-wrap" key={boardItem.path}>
          <Link className="search-boards__item-link" to={categoryLink(boardItem.path)}>{
            highlightMatchesText(boardItem.title, searchQuery)
          }
          </Link>
        </span>
      ))
      }
    </li>
  );
}

function getNoticeEmptyResult() {
  return (
    <div className="alert">
      <FormattedMessage {...m.noResultsText} />
    </div>
  );
}

export function mapStateToProps(state, props) {
  const newQuery = new QueryModel(props.location.search);
  newQuery.params.query = props.match.params.query;
  return {
    loading: boardsLoading(state),
    entities: boardsEntities(state),
    err: boardsError(state),
    newQuery
  };
}

export default connect(mapStateToProps, { getBoards })(SearchBoardsPage);
