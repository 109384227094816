import React from 'react';
import PropTypes from 'prop-types';
import { Link, useParams } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';
import { useSelector } from 'react-redux';
// view
import ThreadIndicator from '../indicator';
import unknownUserIcon from '../../../assets/usericon.gif';
// helpers
import m from '../messages';
import url from '../../../url-service';
import { convertToDate } from '../../utils';
import { asAuthenticatedSelector } from '../../../ducks/user';


export default function TableReplies({ replies, institution, isAuthenticated }) {
  const { threadId } = useParams();
  const isLoggedIn = useSelector(asAuthenticatedSelector);
  function renderTableHead() {
    return (
      <div className='table__row table__row--header'>
        <div className='table__col'>
          <FormattedMessage {...m.subject} />
        </div>
        <div className='table__col'>
          <FormattedMessage {...m.author} />
        </div>
        <div className='table__col'>
          <FormattedMessage {...m.datePosted} />
        </div>
      </div>
    )
  }


  function renderTableBody() {
    return replies.map(reply => {
      const showIndicator = isAuthenticated && !institution && !reply.viewed;

      const _getSubjectTitle = ({ id, path, subject }) => {
        const space = 8;
        const spaceTitleSubject = (id.match(/\./ig) || []).length * space;
        return (
          <div className='table__col-subject' style={{ paddingLeft: `${spaceTitleSubject}px` }}>
            { showIndicator && (
              <span className='table__col-subject-indicator'>
                <ThreadIndicator />
              </span>)
            }
            <Link to={`/${path}/${id}`}>{subject}</Link>
          </div>
        )
      };

      const _getReplyAuthor = ({ user: { userId, displayName, imageId } }) => {
        const imgSrc = (imageId && url.getImageIdToLink(imageId)) || unknownUserIcon;
        if (!userId || !isLoggedIn) return (
          <span className='table__reply-autor-link'>
            <span className='table__reply-autor-img'>
              <img src={unknownUserIcon} alt='' />
            </span>
            <span className='table__reply-autor-name'>{displayName}</span>
          </span>
        );
        return (
          <a className='table__reply-autor-link' href={url.createPath('profile', userId)} target='_blank'>
            <span className='table__reply-autor-img'>
              <img src={imgSrc} alt='' />
            </span>
            <span className='table__reply-autor-name'>{displayName}</span>
          </a>
        )
      };

      return (
        <div className={`table__row${threadId === reply.id ? ' active' : ''}`} key={reply.id}>
          <div className='table__col'>
            { _getSubjectTitle(reply) }
          </div>
          <div className='table__col'>
            { _getReplyAuthor(reply) }
          </div>
          <div className='table__col'>
            {reply.lastPost && convertToDate(reply.posted) }
          </div>
        </div>
      )
    })
  }


  return (
    <div className='table table--reply'>
       <div className='table__body'>
        { renderTableHead() }
        { renderTableBody() }
       </div>
    </div>
  )
}


TableReplies.propTypes = {
  replies: PropTypes.arrayOf(PropTypes.object),
};
