import React, { useEffect } from 'react';
import { RemoveBoard } from '../../board/delete/index';
import m from './messages';
import { FormattedMessage, injectIntl } from 'react-intl';
import { trackPageView } from '../../../../utils/tealium';

export function DeleteCategory({ intl, match }) {
  useEffect(() => trackPageView(`delete-category : ${match.params.path}`), []);
  return (
    <RemoveBoard
      title={<FormattedMessage {...m.title} />}
      messages={m}
      successMessage={intl.formatMessage(m.success)}
    />
  );
}

export default injectIntl(DeleteCategory);
