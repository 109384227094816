import React from 'react';
import { useSelector } from 'react-redux';

import Progress from '../../../common/progress';
import { uploadLoadingSelector, attachemntsSelector } from '../../../../ducks/board-info-edit';


export default function UploadLayout({ children }) {
  const loading = useSelector(uploadLoadingSelector);
  const attachments = useSelector(attachemntsSelector);

  const getClassValue = () => {
    let classValue = 'attachments__overlay';
    const hasAttachments = attachments && attachments.length;
    if (loading && hasAttachments) classValue = `${classValue} active`;
    return classValue;
  }

  return (
    <div className={ getClassValue() }>
      { children }
      { loading && <Progress width='20px' height='20px'/> }
    </div>
  )
}