import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';

// data
import { 
  // action
  fetchBoardInfo,
  // selectors
  loadingSelector, errorSelector, entitiesSelector
} from '../../ducks/board-info-edit';
// templates
import PageLayout from '../../components/hoc/page-layout';
import BoardInfoEditForm from '../../components/forms/board-info-edit';
import m from './messages';


export default function BoardInfoEditPage() {
  /**
   * Redux 
   * Routing
   */
  const dispatch = useDispatch();
  // data
  const { path } = useParams();
  const loading = useSelector(loadingSelector);
  const entities = useSelector(entitiesSelector);
  const error = useSelector(errorSelector);

  useEffect(() => {
    dispatch(fetchBoardInfo(path))
  }, [path])

  const pageTitle = <FormattedMessage {...m.title} />

  return (
    <PageLayout
      title={pageTitle}
      loading={loading}
      error={error}
    >
      { entities && <BoardInfoEditForm /> }
    </PageLayout>
  )
}