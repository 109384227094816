import React from 'react';
import { FormattedMessage } from 'react-intl';

import m from '../../pages/request-boards/messages';

export default function MessagesList() {
  let messages = [m.topic1, m.topic2, m.topic3];

  return (
    <ul className="request__list">
      {messages.map((message, index) => (
        <li className="request__list-item" key={index}>
          <FormattedMessage {...message} />
        </li>
      ))}
    </ul>
  );
}
