// from config get -> basename
import { put, select } from 'redux-saga/effects';
import { createSelector } from 'reselect';
import { basename } from '../config'

const routerSelector = state => state.router;
const pathnameSelector = createSelector(
  routerSelector, router => router.location.pathname
);

export function* errorHandlerSaga(type, err) {
  if (err && err.response && err.response.status === 500) {
    let pathname = yield select(pathnameSelector);
        pathname = pathname.replace(basename, '');
    // return window.location.replace(`/error?error-path=${pathname}`);
    return window.location.replace(`/issue/500?errorpath=${pathname}`);
  }
  yield put({ type, err})
}
