import React from 'react';
import { Redirect, useLocation } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';

import TitleCategory from './title-category';
import TableCategory from './tables/category';
import TableBoards from './tables/boards';
import TableThread from './tables/thread';
import NoticeEmpty from './notice-empty';
import LinkNewThread from '../common/link-new-thread';
import { CATEGORY_TYPES } from '../../constants';
import m from './messages';
import config from '../../config';
import { mapObjToQueryString } from '../utils';
import useQuery from '../../hooks/use-query-params';


export default function Categories({ title, categories, boards, threads, children, nodeType, gatewayAddress, options, deleted }) {
  let isReadOnly;
  if(options) isReadOnly = !options.posts;
  const parsedQueryInObj = useQuery();
  const { pathname } = useLocation();

  const renderCategoriesTable = () => (
    <div className='categories__table'>
      {categories.map((category, index) => <TableCategory key={index} {...category} />)}
    </div>
  )
  const renderBoardsTable = () => (
    <div className='categories__table'>
      <TableBoards {...boards} />
    </div>
  )


  const renderThreadTable = () => (
    <div className='categories__table'>
      <TableThread {...threads} />
    </div>
  )


  const renderBodyTables = () => {
    const hasCategory = categories && Array.isArray(categories) && categories.length;
    const hasBoards =  boards && Array.isArray(boards.items) && boards.items.length;
    const hasThread =  threads && Array.isArray(threads.items) && threads.items.length;
    const isBoardCategory = nodeType === CATEGORY_TYPES.board;

    if (!hasThread && isBoardCategory) {
      if(parsedQueryInObj.page > threads.paging.totalPages) {
        const queryUrl = mapObjToQueryString(parsedQueryInObj, { page: threads.paging.totalPages });
        const fullUrl= threads.paging.totalPages === 1 ? pathname : `${pathname}${queryUrl}`;
        return <Redirect to={fullUrl} />
      }
    }

    if (isBoardCategory && !hasCategory && !hasBoards && !hasThread) return <NoticeEmpty board={isBoardCategory} />;
    return (
      <>
        { hasCategory ? renderCategoriesTable() : null }
        { hasBoards ? renderBoardsTable() : null }
        { hasThread ? renderThreadTable() : null }
      </>
    )

  }

  const renderGatewayUsername = () => {
    const isBoardCategory = nodeType === CATEGORY_TYPES.board;
    const gatewayUsername = gatewayAddress? gatewayAddress.split('@')[0] : null;

    if (isBoardCategory && gatewayUsername) return (
      <p className='categories__footer'>
        <FormattedMessage {...m.gateway} values={{ name: gatewayUsername }} />
      </p>)
    return null;
  }

  return (
    <section className='categories'>
      <div className='categories__header'>
        <TitleCategory title={title} />
        {threads && !isReadOnly && !deleted && !config.institution && <LinkNewThread /> }
      </div>
      { children && <div className='categories__nav'>{children}</div> }
      { renderBodyTables() }
      { renderGatewayUsername() }
    </section>
  )
}


