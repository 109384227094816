import React, { useState } from 'react'; 
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';

import m from './messages';


export default function FiledSubject({ name, handleChange, value = '', readOnly = false, placeholder, showEmptyField, handleResetEmptyMessage }) {
  const [ fieldBlured, setFieldBlured ] = useState(false);

  const fieldProps = { 
    type: 'text',
    name,
    className: `form-filed__inp${ readOnly ? ' readonly' : '' }`,
    value, 
    readOnly 
  }
  showEmptyField = showEmptyField.includes(name); // check if empty field is current
  const emptyFiled = !readOnly && !value && fieldBlured;

  const handleBlur = () => !fieldBlured && setFieldBlured(true)

  const renderRequireLabel = () => {
    if (!readOnly && value) return <span className='form-filed__label-succes' />
    if (emptyFiled || showEmptyField) return (
      <span className='form-filed__title-error icon iconWarning' > 
        <FormattedMessage {...m.required}/>
      </span>
    )
    return <span className='form-field__title-required'/>
  }

  if (!readOnly && handleChange) {
    fieldProps.onChange = handleChange;
    fieldProps.onFocus = handleResetEmptyMessage;
    fieldProps.onBlur = handleBlur;
  }

  if (placeholder) fieldProps.placeholder = placeholder;


  return (
    <div className={`form-filed${emptyFiled || showEmptyField ? ' error': ''}`}>
      <p className='form-filed__title'>
        <FormattedMessage {...m.subject} />
        { renderRequireLabel() }
      </p>
      <input { ...fieldProps } />
    </div>
  )
}
FiledSubject.defaultProps = {
  showEmptyField: '',
  handleResetEmptyMessage: () => {}
}

FiledSubject.propTypes = {
  handleChange: PropTypes.func,
  handleResetEmptyMessage: PropTypes.func,
  value: PropTypes.string,
  readOnly: PropTypes.bool,
  showEmptyField: PropTypes.string
}