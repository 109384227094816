import { addLocaleData } from 'react-intl';
import de from 'react-intl/locale-data/de';
import en from 'react-intl/locale-data/en';
import es from 'react-intl/locale-data/es';
import fr from 'react-intl/locale-data/fr';
import it from 'react-intl/locale-data/it';
import ja from 'react-intl/locale-data/ja';
import ko from 'react-intl/locale-data/ko';
import nl from 'react-intl/locale-data/nl';
import pl from 'react-intl/locale-data/pl';
import pt from 'react-intl/locale-data/pt';
import ru from 'react-intl/locale-data/ru';
import sv from 'react-intl/locale-data/sv';
import tr from 'react-intl/locale-data/tr';
import uk from 'react-intl/locale-data/uk';
import zh from 'react-intl/locale-data/zh';

const localeData = () => addLocaleData([
  ...de,
  ...en,
  ...es,
  ...fr,
  ...it,
  ...ja,
  ...ko,
  ...nl,
  ...pl,
  ...pt,
  ...ru,
  ...sv,
  ...tr,
  ...uk,
  ...zh
]);

export default localeData;
