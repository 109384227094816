import React from 'react';
import { useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import { injectIntl } from 'react-intl';

import { deleteThread } from '../../../ducks/thread-page';
import url from '../../../url-service';
import m from '../messages';


export const ReplyLink = injectIntl(({ path, threadId, intl }) => path
  ? (<li className='thread-tool__item'>
      <Link
        to={url.createPath('thread', path, threadId, 'reply')}
        className='thread-tool-reply icon iconArrowBack'
        title={intl.formatMessage(m.replyTitle)}
      />
    </li>)
  : null)


export const AbuseLink = injectIntl(({ path, threadId, intl }) => path
  ? (<li className='thread-tool__item'>
      <Link
        to={url.createPath('thread', path, threadId, 'abuse')}
        className='thread-tool-reply icon iconFlag'
        title={intl.formatMessage(m.abuseTitle)}
      />
    </li>)
  : null)


export const PrintLink = injectIntl(({ path, threadId, intl }) => path
  ? (<li className='thread-tool__item'>
      <Link
        to={url.createPath('thread', path, threadId, 'print')}
        className='thread-tool-reply icon iconPrint'
        title={intl.formatMessage(m.printTitle)}
      />
    </li>)
  : null)

export const EditLink = injectIntl(({ path, threadId, intl }) => path
  ? (<li className='thread-tool__item'>
      <Link
        to={url.createPath('thread', path, threadId, 'edit')}
        className='thread-tool-reply icon iconEdit'
        title={intl.formatMessage(m.editTitle)}
      />
    </li>)
  : null)


export const MoveLink = injectIntl(({ path, threadId, intl }) => path
  ? (<li className='thread-tool__item'>
      <Link
        to={url.createPath('thread', path, threadId, 'move')}
        className='thread-tool-reply icon iconMove'
        title={intl.formatMessage(m.moveTitle)}
      />
    </li>)
  : null)


export const BtnDeletePost = injectIntl(({ path, threadId, intl }) => {
  const dispatch = useDispatch();
  const msgConfirm = intl.formatMessage(m.confirmDeletePost);

  const handleClick = () => {
    const confirmed = confirm(msgConfirm);
    if (confirmed) dispatch(deleteThread(path, threadId, false));
  }

  return (
    <li className='thread-tool__item'>
      <button className='thread-tool__item-btn icon iconTrash' onClick={handleClick} title={intl.formatMessage(m.deleteTitle)} />
    </li>
  )
})


export const BtnDeletePostsAndReplies = injectIntl(({ path, threadId, intl }) => {
  const dispatch = useDispatch();
  const msgConfirm = intl.formatMessage(m.confirmDeletePost);

  const handleClick = () => {
    const confirmed = confirm(msgConfirm);
    if (confirmed) dispatch(deleteThread(path, threadId, true));
  }

  return (
    <li className='thread-tool__item'>
      <button className='thread-tool__item-btn icon iconTrash threadDeleteAll' onClick={handleClick} title={intl.formatMessage(m.deleteWithRepliesTitle)} />
    </li>
  )
})
