import React from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';

import { deleteFavorites } from '../../ducks/favorites';



export default function AlertConfirmDelete({ itemForDelete, setItemForDelete, listByType }) {
  const dispatch = useDispatch();
  const handleClose = () => setItemForDelete(null);

  const handleDelete = () => {
    dispatch(deleteFavorites({...itemForDelete, listByType}));
    handleClose();
  }

  return (
    <Dialog
      open={!!itemForDelete}
      onClose={handleClose}
      className='favorites__modal'
    >
      <DialogContent>
        Are you sure you want to delete this favorite?
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} size='small' color="secondary">
          Cancel
        </Button>
        <Button onClick={handleDelete} size='small' color="primary">
          OK
        </Button>
      </DialogActions>
    </Dialog>
  )
}


AlertConfirmDelete.propTypes = {
  itemForDelete: PropTypes.object.isRequired, // object can be null or object type
  setItemForDelete: PropTypes.func.isRequired,
  fullList: PropTypes.bool
}
