import React from 'react';
import NodeOptions from './options';
import { connect } from 'react-redux';
import { push } from 'connected-react-router';
import { requestFetchOptions, requestUpdateOptions, updatePrefix } from '../../../../ducks/options';
import { categoryLink } from '../../../utils/old_link';
import { isBusy } from '../../../../ducks/common/busy';
import { requestCachedCategory } from '../../../../ducks/category';

export function NodeOptionsContainer(
  {
    busySave,
    fetch,
    save
  }) {
  return (<NodeOptions tracking="editOptions" save={save} fetch={fetch} busySave={busySave} />);
}



const mapStateToProps = state => ({
  busySave: isBusy(state, `${updatePrefix}/`)
});

const mapDispatchToProps = dispatch => ({
  save(path) {
    dispatch(requestUpdateOptions(path, push(categoryLink(path))));
  },

  fetch(path) {
    dispatch(requestFetchOptions(path));
    dispatch(requestCachedCategory(path));
  }
});

export default connect(mapStateToProps, mapDispatchToProps)(NodeOptionsContainer);
