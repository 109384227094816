import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Link } from 'react-router-dom';
import FieldView from '../../common/field-view';
import m from './messages';
import { categoryLink, profileLink, threadLink } from '../../utils/old_link';
import { spaceless } from '../../../utils/strings';
import { convertToDate } from '../../utils';

const profileAnchor = (id, name) => (id && name ? <a href={profileLink(id)}>{name}</a> : '');

export default function Fieldset(
  {
    name: block = '',
    threadId = '',
    fields = {},
    messages = {},
    names = []
  }
) {
  const { isLocked, lockerName } = fields;
  const items = [<span key="number" className="field-number">{fields.number}</span>]
    .concat(names.map(name => {
      const translation = messages[`${block}_${name}`] || messages[name] || m[name];
      if (!translation) {
        return null;
      }
      const raw = fields[name];
      let value;
      switch (name) {
        case 'status':
          if (isLocked) {
            value = lockerName
              ? <FormattedMessage {...m.lockedBy} values={{ lockerName }} />
              : <FormattedMessage {...m.locked} />;
          } else {
            value = <FormattedMessage {...m.unlocked} />;
          }
          break;
        case 'authorName':
          value = profileAnchor(fields.authorId, raw);
          break;
        case 'creatorName':
          value = profileAnchor(fields.creatorId, raw);
          break;
        case 'email':
          value = raw ? <a href={`mailto:${raw}`}>{raw}</a> : '';
          break;
        case 'path':
          value = raw ? <Link to={categoryLink(raw)}>{raw}</Link> : '';
          break;
        case 'requestDate':
        case 'posted':
          value = convertToDate(raw);
          break;
        case 'boardPath':
          value = fields.path ? <Link to={categoryLink(fields.path)}>{raw}</Link> : value;
          break;
        case 'subject':
          value = threadId && fields.path
            ? <Link to={threadLink(fields.path, threadId)}>{raw}</Link>
            : value;
          break;
        default:
          value = raw;
          break;
      }
      if (!value) {
        return null;
      }
      return (
        <FieldView
          key={name}
          label={<FormattedMessage {...translation} />}
          name={name}
        >
          {value}
        </FieldView>
      );
    }));

  return (
    <div className={spaceless(`fieldset fieldset-${block}`)}>
      {items}
    </div>
  );
}
