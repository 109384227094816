import React from 'react';
import classNames from 'classnames';
import { bool, string } from 'prop-types';
import { connect } from 'react-redux';
import { ChildrenType } from '../utils/prop-types';
import { isBusy } from '../../ducks/common/busy';
import { spaceless } from '../../utils/strings';

export function Busy({ busy, children, classes }) {
  const className = classNames(spaceless(`widget busy-container ${classes}`), {
    busy,
    empty: !children
  });
  let content;
  if (children) {
    content = (
      <div className="busy-content">
        {children}
      </div>
    );
  }
  return (
    <div className={className}>
      {content}
      <div className="busy-animation-layer">
        <div className="loading" />
      </div>
    </div>
  );
}

Busy.propTypes = {
  busy: bool.isRequired,
  children: ChildrenType.isRequired,
  classes: string
};

Busy.defaultProps = {
  classes: ''
};

function mapStateToProps(state) {
  return {
    busy: isBusy(state)
  };
}

export default connect(mapStateToProps)(Busy);
