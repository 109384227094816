import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';


// data
import { asAdminAuthSelector, userEntitiesSelector } from '../../../ducks/user';
import { isActiveToolbar } from '../../../ducks/admin-panel';
// templates
import AuthenticatedList from './auth-list';
// settings
import config from '../../../config';
import GuestList from './guest-list';


import { threadUserRoleSelector } from '../../../ducks/thread-page';

import { ROLE_TYPES } from '../../../constants';



export default function ThreadTool({ authorThreadId, userMarkedDeleted, replieId, repliePath, isReadOnly }) {
  const { path, threadId } = useParams();
  // from redux
  const activeToolbar = useSelector(isActiveToolbar);
  const authUser = useSelector(userEntitiesSelector);


  let userRole = useSelector(threadUserRoleSelector);
  userRole = userRole || ROLE_TYPES.guest;

  const asAdminAuth = [ROLE_TYPES.superAdmin, ROLE_TYPES.admin].includes(userRole)


  const isUserAuthorThread = authUser.userId === authorThreadId;

  const props = {
    activeToolbar,
    userMarkedDeleted,
    isUserAuthorThread,
    asAdminAuth,
    path: repliePath || path,
    threadId: replieId || threadId,
    isReadOnly: isReadOnly
  }

  return ((asAdminAuth || isUserAuthorThread) && !config.institution) ? <AuthenticatedList {...props} /> : <GuestList {...props} institution={config.institution} />
}

ThreadTool.propTypes = {
  userMarkedDeleted: PropTypes.bool,
  authorThread: PropTypes.object,
  replieId: PropTypes.string, // coming only from ListReplies component
  repliePath:PropTypes.string, // coming only from ListReplies component
  isReadOnly: PropTypes.bool
}
