import React, { useEffect, useState } from 'react';
import classNames from 'classnames';
import { connect, useSelector, useDispatch } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import { Link, useParams } from 'react-router-dom';
import { push } from 'connected-react-router';
import AdminView from '../../view';
import Field from '../../../common/field';
import FieldView from '../../../common/field-view';
import m from './messages';
import ProfileLink from '../../../old_thread/thread-item/profile-link';
import Progress from '../../../common/progress';
import Section from '../../../common/old-section';
import SelectCategory from '../../../choose-category';
import { categoryLink, threadLink } from '../../../../../utils/link';
import { MoveBoardsPathFormat } from '../../../../utils/strings';
import { Form } from '../../../common/form';
import { isBusy } from '../../../../ducks/common/busy';
import { showSuccess } from '../../../../ducks/common/alert';
import {
  movePrefix,
  requestMoveThread,
} from '../../../../ducks/old_thread';
import { trackPageView } from "../../../../utils/tealium";

import {
  getThreadById,
  loadingSelector,
  entitiesSelector,
  threadSelector
} from '../../../../ducks/thread-page';
import { convertToDate } from '../../../utils';




export function MoveThread({
  busyMove,
  getThreadById,
  move,
}) {
  const [newPath, setNewPath] = useState('');
  const { path, id} = useParams();
  const dispatch = useDispatch();
  const busyFetch = useSelector(loadingSelector);
  const thread = useSelector(threadSelector);
  const entities = useSelector(entitiesSelector);

  useEffect(() => {
    dispatch(getThreadById(path, id));
  }, [path, id]);
  useEffect(() => trackPageView(`move-message : ${path}`), []);

  const isValid = !!newPath;

  let breadcrumbs = [];
  const hasDefaultBreadcrumbs = entities && !breadcrumbs.entities;
  if (hasDefaultBreadcrumbs) {
    breadcrumbs = [
      { path: '' },
      { title: <FormattedMessage {...m.title} /> }
    ];
  }

  function getContent() {
    if (busyFetch) return <Progress />;

    const namePath = thread.namePath ?
      thread.namePath.replace(/^\|+/g, '').replace(/\|/g, ' > ')
      : '';

  return (
    <Section>
      <FieldView label={<FormattedMessage {...m.subject} />} name="subject">{thread.subject}</FieldView>
      <FieldView label={<FormattedMessage {...m.author} />} name="author">
        <ProfileLink user={thread.user} />
      </FieldView>
      <FieldView label={<FormattedMessage {...m.posted} />} name="posted">
        {convertToDate(thread.posted)}
      </FieldView>
      <FieldView label={<FormattedMessage {...m.board} />} name="path">
        <Link to={categoryLink(thread.path)}>{namePath}</Link>
      </FieldView>
      <Form classes="conSection" submit={() => move(id, path, newPath)} canSubmit={isValid}>
        <SelectCategory choose={setNewPath} />
        <Field
          format={MoveBoardsPathFormat}
          label={<FormattedMessage {...m.moveToPath} />}
          name="path"
          setValue={setNewPath}
          validationClasses="required"
          value={newPath}
        />
        <div className="actions">
          <button className={classNames('ancBtn orange', { loading: busyMove })} disabled={!isValid || busyMove}>
            <FormattedMessage {...m.move} />
          </button>
          <Link className="link" to={threadLink(path, id)}>
            <FormattedMessage {...m.cancel} />
          </Link>
        </div>
      </Form>
    </Section>
  );
  }

  return (
    <AdminView
      breadcrumbs={breadcrumbs}
      classes="move-thread"
      title={<FormattedMessage {...m.title} />}
    >
      {thread && getContent()}
    </AdminView>
  );
}

function mapStateToProps(state) {
  return {
    busyMove: isBusy(state, `${movePrefix}/`),
  };
}

function mapDispatchToProps(dispatch) {
  return {
    getThreadById,
    move(id, path, newPath) {
      dispatch(requestMoveThread(id, path, newPath, [
        showSuccess('Thread moved'),
        push(categoryLink(newPath))
      ]));
    }
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(MoveThread);
