import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { FormattedMessage, injectIntl } from 'react-intl';
import { withRouter } from 'react-router-dom';
import m from '../../category/create/messages';
import NodeEditFieldset from './fieldset';
import NodeOptions from '../options/options';
import { categoryPathFormat, linkIdFormat, nameFormat } from '../../../../utils/strings';
import { getCategory } from '../../../../ducks/category';
import { isBusy } from '../../../../ducks/common/busy';
import { plainObjectOf } from '../../../utils/redux';
import {
  probePrefix,
  requestPathProbe,
  selectNode,
  selectNodeConflict,
  setNodeField,
  setOption
} from '../../../../ducks/options';


export function NodeEdit(
  {
    tracking,
    busy,
    classes,
    conflict,
    intl,
    linkId,
    messages,
    name,
    path,
    probe,
    save,
    set,
    setNodeOption,
    nodeType,
    breadcrumbs
  }) {
  const errors = {};
  const newPath = `${path}.${linkId}`;
  const [ nameFocus, setNameFocus ] = useState(false)
  useEffect(() => {
    if (linkId && (!conflict.path || conflict.path !== newPath)) {
      probe(newPath);
    }
  }, [newPath]);

  if (conflict.name && conflict.name === name) {
    errors.name = intl.formatMessage(m.exists);
  }
  if (conflict.path && conflict.path === newPath) {
    errors.linkId = intl.formatMessage(m.exists);
  }
  const isValid = Object.keys(errors).length === 0
    && [path, name, linkId].filter(value => (value ? value.trim().length > 0 : false)).length === 3;

  const getValueForFieldName = () => {
    if (!nameFocus) return '';
    return name;
  }

  return (
    <NodeOptions
      tracking={tracking}
      canSubmit={isValid && !busy}
      classes={classes}
      fetch={() => {}}
      save={save}
      set={setNodeOption}
      title={<FormattedMessage {...messages.title} />}
      nodeType={nodeType}
      breadcrumbs={breadcrumbs}
    >
      <NodeEditFieldset
        focus={() => {
          setNameFocus(true)
        }}
        errors={errors}
        messages={messages}
        fields={{
          path: {
            value: path,
            format: categoryPathFormat
          },
          name: {
            value: getValueForFieldName(name),
            format: nameFormat
          },
          linkId: {
            value: linkId,
            format: linkIdFormat
          }
        }}
        set={set}
      />
    </NodeOptions>
  );
}

export function mapStateToProps(state) {
  return {
    ...plainObjectOf(selectNode(state)),
    busy: isBusy(state, `${probePrefix}/`),
    conflict: selectNodeConflict(state).toJSON()
  };
}

export function mapDispatchToProps(dispatch) {
  return {
    setNodeOption(name, value) {
      dispatch(setOption(name, value));
    },
    set: (name, value) => dispatch(setNodeField(name, value)),
    fetch(path) {
      dispatch(getCategory(path));
    },
    probe(path) {
      dispatch(requestPathProbe(path));
    }
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(injectIntl(NodeEdit)));
