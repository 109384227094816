import React, { useEffect, memo } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import { Link } from 'react-router-dom';
import m from './messages';
import Progress from './../common/progress';

import {
  // actions
  fetchAllFavorites,
  clearFavoritesState,
  // selectors
  loadingSelector,
  deletingSelector,
  entitiesSelector,
  errorSelector
} from '../../ducks/favorites';
import url from '../../url-service';
import TableFavorites from './table';
import ProcessLayout from '../hoc/process-layout';


export default function FavoritesOverview(_) {

  const dispatch = useDispatch()
  // data from redux
  const loading = useSelector(loadingSelector);
  const errorFetch = useSelector(errorSelector);
  const delitingProcess = useSelector(deletingSelector);

  const { categories, boards, authors, threads, totalCount } = useSelector(entitiesSelector);
  const tableLists = { categories, boards, authors, threads };
  const hasContent = Object.values(tableLists).find(value => value); // check if exists value

  useEffect(() => {
    dispatch(fetchAllFavorites());
  }, []);

  useEffect(()=> () => dispatch(clearFavoritesState()), [])

  const getLinkSeeAll = () => (
    <Link className="favorites__link-all link" to={url.createPath('favorites', 'boards')}>
      <FormattedMessage {...m.seeAll} />
    </Link>
  );

  if (loading) return <Progress />;
  
  if (!totalCount || !Object.values(totalCount).reduce((a, b) => a + b, 0)) return null;

  return (
    <ProcessLayout loading={loading || delitingProcess} hideContent={false} error={errorFetch}>
      { hasContent &&
          <section className='favorites'>
            <header className='favorites__header'>
              <h2 className='favorites__title'><FormattedMessage {...m.title} /></h2>
              {hasContent.length !== 0 ? getLinkSeeAll() : null }
            </header>
            <div className='favorites__tables'>
              { Object.keys(totalCount).map(tableName => (tableLists[tableName]
                ? <TableFavorites
                      key={tableName}
                      type={tableName}
                      data={tableLists[tableName]}
                      countItems={totalCount[tableName]}
                  />
                : null
                ))
              }
            </div>
        </section>
      }
    </ProcessLayout>
  )
}
