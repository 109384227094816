import React from 'react';
import PropTypes from 'prop-types';
import { useParams } from 'react-router-dom';

// views
import HomeLists from './lists/home';
import CategoryList from './lists/category';
import CategoryBoardList from './lists/category-board';
import ThreadList from './lists/thread';
import ToolsTypes from './types';
import config from '../../../config';
import AdvertAnchor from '../../common/advert-anchor';



export { ToolsTypes } 
export default function AdminTools({ type }) {
  // data from redux
  const { path } = useParams();
  if (config.institution) return null;

  const isVerticalView = (type === ToolsTypes['category-board'] || type === ToolsTypes['thread'])
  const classes = `nav-menu--tools-admin tools${ isVerticalView ? ' vertical' : ''}`;

  function renderMenuItems() {
    if (type === ToolsTypes.home) return <HomeLists path={path} classes={classes} />
    if (type === ToolsTypes['category-root'] || type === ToolsTypes['category-nested']) {
      const isNested = (type === ToolsTypes['category-nested']);
      return <CategoryList path={path} classes={classes} isNested={isNested} />;
    }
    if (type === ToolsTypes['category-board']) {
      return (
        <>
          <CategoryBoardList path={path} classes={classes} />
          <div className='page__section-advertising'>
            <AdvertAnchor advertPosition='aside' />
          </div>
        </>
      );
    }
    if (type === ToolsTypes['thread']) return (
      <>
        <ThreadList path={path} classes={classes} isThread />
        <div className='page__section-advertising'>
          <AdvertAnchor advertPosition='aside' />
        </div>
      </>
    )
    return null;
  } 
  


  return renderMenuItems()
}

AdminTools.propTypes = {
  type: PropTypes.string.isRequired,
}



