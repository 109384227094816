/* eslint-disable import/prefer-default-export */

export function parentPath(path) {
  const parts = path.split('.');
  return parts.length > 0 ? parts.slice(0, -1).join('.') : '';
}

export function linkIdOf(path) {
  const parts = path.split('.');
  return parts.length > 0 ? parts[parts.length - 1] : '';
}
