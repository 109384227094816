import React from 'react';
import PropTypes from 'prop-types';

const Button = (props) => {
  const { title,  inpValue, handleButtonClick } = props;
  const disabledBtn = !inpValue ? true : false;

  return (
    <button
      type='button'
      className={`ancBtn orange btn--search${ disabledBtn ?  ` btn--disabled` : ''}`}
      disabled={disabledBtn}
      onClick={handleButtonClick}
    >
    { title }
    </button>
  )
};


Button.propTypes = {
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.object]).isRequired,
  inpValue: PropTypes.string.isRequired,
  handleButtonClick: PropTypes.func.isRequired
};

export default Button;
