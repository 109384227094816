import React from 'react';
import { profileLink } from '../../utils/old_link';
import { shape } from 'prop-types';
import { UserType } from '../../utils/prop-types';
import { userImageIdToLink } from '../../../ducks/old-media';
import usericon from '../../../assets/usericon.gif';
import config from '../../../config';

export default function ProfileLink({ user }) {
  const { institution } = config;
  if (!user) {
    return null;
  }
  if (user.userId === null || institution) {
    return <span className="name">{user.displayName}</span>;
  }
  return (
    <a className="profile-link" href={profileLink(user.userId)}>
      <div className="avatar-container">
        <img src={user.imageId === null ? usericon : userImageIdToLink(user.imageId, 20, 15)} alt="" />
      </div>
      <span className="name">{user.displayName}</span>
    </a>
  );
}

ProfileLink.propTypes = {
  user: shape(UserType)
};
