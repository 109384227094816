import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';

import m from './messages';
import url from '../../url-service';
import { convertToDate } from '../utils';
import unknownUserIcon from '../../assets/usericon.gif';

export default function AbuseContent({ subject, user: { userId, displayName, imageId }, posted, classification, updated, surnames, body }) {
  const imgSrc = (imageId && url.getImageIdToLink(imageId)) || unknownUserIcon;

  return (
    <div className="abuse-content">
      <div className="abuse__header-wrap">
        <header className="abuse__header">
          <h3 className="abuse__title">
            {subject}
          </h3>
          {displayName && (
            <a className="abuse__user-link" href={url.createPath('profile', userId)} target="_blank">
              <img className="abuse__user-img" src={imgSrc} alt="" />
              <span className="abuse__user-title">{displayName}</span>
            </a>
          )}
        </header>
        <ul className="abuse__list">
          {posted && (
            <li className="abuse__list-item">
              <FormattedMessage {...m.listItem1} />: {convertToDate(posted)}
            </li>
          )}
          {classification && (
            <li className="abuse__list-item">
              <FormattedMessage {...m.listItem2} />: <span>{classification}</span>
            </li>
          )}
          {updated && (
            <li className="abuse__list-item">
              <FormattedMessage {...m.listItem3} />: {convertToDate(updated)}
            </li>
          )}
          {surnames && (
            <li className="abuse__list-item">
              <FormattedMessage {...m.listItem4} />: {surnames}
            </li>
          )}
        </ul>
      </div>
      <div className="abuse__body-wrap">
        <p className="abuse__title">{body}</p>
      </div>
    </div>
  );
}

AbuseContent.propTypes = {
  subject: PropTypes.string,
  user: PropTypes.object,
  posted: PropTypes.number,
  classification: PropTypes.string,
  body: PropTypes.string
};
