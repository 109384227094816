import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { FormattedMessage } from 'react-intl';

import { asAuthenticatedSelector } from '../../ducks/user';
import { isFavoriteUserSelector } from '../../ducks/thread-page';
import {loadingAuthorSelector, addRemoveAuthorFavorite} from '../../ducks/author-favorite';
import url from '../../url-service';
import m from './messages';
import Progress from '../common/progress';
import config from '../../config';
import unknowUserIcon from '../../assets/usericon.gif';

export default function AuthorThread({ isGuest, userId, displayName, imageId }) {
  const { institution } = config;
  const dispatch =  useDispatch();
  // data from redux
  const isFavorite = useSelector(isFavoriteUserSelector);
  const favoriteLoading = useSelector(loadingAuthorSelector);
  const isLoggedIn = useSelector(asAuthenticatedSelector);

  const handleBtnClick = () => dispatch(addRemoveAuthorFavorite(userId));

  const renderAuthorIcon = () => {
    if (institution) return null;

    let imageSrc = unknowUserIcon
    if (imageId) imageSrc = url.getImageIdToLink(imageId)

    return (
      <div className='thread-author__img'>
        <img src={imageSrc} />
      </div>
    )
  }

  const renderAuthorLink = () => (institution || !userId || !isLoggedIn)
      ? <span className='thread-author__link disabled'>{displayName}</span>
      : (<>
          <a href={url.createPath('profile', userId)}>{displayName}</a>
          {!isGuest && <button
            className={`thread-author__star icon${ isFavorite ? ' added' : '' }`}
            onClick={handleBtnClick}
          />}
        </>
      )

  const renderAuthorsPostsLink = () => {
    if (!userId || !isLoggedIn) return null;
    return (
      <>
        (<a href={`/boards/author-posts/${userId}`} target='_blank'>
          <FormattedMessage {...m.viewPosts} />
        </a>)
      </>
    )
  }


  return (
    <div className={`thread-author${favoriteLoading ? ' active': ''}`}>
      { renderAuthorIcon() }
      { renderAuthorLink() }
      { renderAuthorsPostsLink() }
      { favoriteLoading && <Progress small />}
    </div>
  )
}
