import React from 'react';
import { FormattedMessage } from 'react-intl';

import m from './messages';


export default function ThreadIndicator({ withMsg }) {

  return (
    <div className='indicator'>
      <i className='indicator__img'>
        <img
          className="new-thread-indicator"
          src="https://www.ancestrycdn.com/boards/i/new-b832be8e.gif"
          width="8"
          height="8"
          alt="New"
        />
      </i>
      { withMsg && <span className="indicator__text"><FormattedMessage {...m.indicatesUnread} /></span>}
    </div>
  )
}