import React from 'react';
import { FormattedMessage } from 'react-intl';

import m from './messages'


const BtnToogleEditPreview = ({ handleBtnClick, showPreview }) => (
  <button
    type='button'
    className='btn btn--link'
    onClick={handleBtnClick}
  >
    { showPreview
      ? <FormattedMessage {...m.edit} />
      : <FormattedMessage {...m.preview} />
    }
  </button>
)

export default BtnToogleEditPreview