import { defineMessages } from 'react-intl';

export default defineMessages({
  next: 'Next Thread',
  previous: 'Prev Thread',

  addAuthorToFavorites: 'This Message Board Author has been added to your favorites.',
  removedAuthorFromFavorites: 'This Message Board Author has been removed from your favorites.',
  errorAddAuthorToFavorites: 'This Message Board Author was not saved to your favorites.',
  errorRemovedAuthorFavorites: 'This Message Board Author was not removed from your favorites.',

  viewPosts: 'View Posts',
  replies: 'Replies',
  posted: 'Posted',
  classification: 'Classification',
  edited: 'Edited',
  surnames: 'Surnames',

  floatView:'Change to Flat View',
  threadView: 'Change to Thread View',
  attachments: 'Attachments',

  subject: 'Subject',
  author: 'Author',
  datePosted: 'Date Posted',
  admin: 'Admin:',

  newestFirst: 'Newest First',
  oldestFirst: 'Oldest First',

  deleteMessage: 'This post was deleted by {deletedBy} on {deletedOn}',
  deletedByAuthor: 'the author',
  deletedByAdmin: 'a board administrator',
  confirmDeletePost: 'Are you sure to delete this post?',
  indicatesUnread: 'indicates an unread post',

  replyTitle: 'Reply',
  abuseTitle: 'Report Abuse',
  printTitle: 'Print',
  editTitle: 'Edit Post',
  moveTitle: 'Move Post',
  deleteTitle: 'Delete Post',
  deleteWithRepliesTitle: 'Delete Post and Replies'
});
