import React, { useState } from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import Field from '../../../common/field';
import m from './messages';
import ChangeBoard from '../change';
import { singleSpace } from '../../../../utils/strings';

export function RenameBoard({ messages = m, intl, tracking }) {
  const [name, setName] = useState('');

  return (
    <ChangeBoard
      tracking={tracking || 'rename-board'}
      canSubmit={!!name.trim()}
      data={{ name }}
      messages={messages}
      operation="rename"
      title={<FormattedMessage {...messages.title} />}
      successMessage={intl.formatMessage(messages.success)}
    >
      <Field
        format={singleSpace}
        label={<FormattedMessage {...messages.newName} />}
        name="name"
        setValue={value => setName(value)}
        validationClasses="required"
        value={name}
      />
    </ChangeBoard>
  );
}

export default injectIntl(RenameBoard);
