// https://en.wikipedia.org/wiki/Binary_prefix#kibi
export const KibiSize = 1024;
export const MebiSize = KibiSize ** 2;
export const GibiSize = KibiSize ** 3;
export const TebiSize = KibiSize ** 4;

export const old_defaults = {
  input: {
    name: {
      subject: {
        maxLength: 128
      },
      title: {
        maxLength: 128
      },
      path: {
        maxLength: 128
      }
    },
    type: {
      text: {
        maxLength: 128
      },
      textarea: {
        maxLength: 15000
      }
    }
  }
};

export const Classification = {
  BIBLE: 'Bible',
  BIOGRAPHY: 'Biography',
  BIRTH: 'Birth',
  CEMETERY: 'Cemetery',
  CENSUS: 'Census',
  DEATH: 'Death',
  DEED: 'Deed',
  IMMIGRATION: 'Immigration',
  LOOKUP: 'Lookup',
  MARRIAGE: 'Marriage',
  MILITARY: 'Military',
  OBITUARY: 'Obituary',
  PENSION: 'Pension',
  QUERIES: 'Query',
  WILL: 'Will'
};


export const PostedWithin = {
  ANYTIME: 'ANYTIME',
  ONE_WEEK: '1 Week',
  ONE_MONTH: '1 Month',
  SIX_MONTH: '6 Months',
  ONE_YEAR: '1 Year'
};


export const Http = {
  Forbidden: 403,
  NotFound: 404,
  Conflict: 409,
  InternalServerError: 500
};

export const ERROR_TYPES = {
  not_found: 'not_found',
  server_error: 'server_error',
  type_error: 'type_error'
}

export const ROLE_TYPES = {
  superAdmin: 'ROLE_SUPER_ADMIN',
  admin: 'ROLE_ADMIN',
  authenticated: 'ROLE_AUTHENTICATED',
  guest: 'ROLE_GUEST'
};

export const CATEGORY_TYPES = {
  category: 'CATEGORY',
  board: 'BOARD',
  thread: 'THREAD'
}


// FILES VALUES
export const ACCEPT_FILE_TYPES = '.bmp, .gif, .jpg, .jpeg, .png, .tif, .tiff';
export const MAX_FILE_SIZE = 20 * MebiSize;


export const Second = 1000;
export const Minute = 60 * Second;
export const Hour = 60 * Minute;
export const Day = 24 * Hour;
