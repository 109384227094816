import React from 'react';
import { Link, withRouter } from 'react-router-dom';
import classNames from 'classnames';
import PageModel from './model';
import { spaceless } from "../../../utils/strings";

export function PageLink({ children, classes = '', history, ...props }) {
  const { model } = PageModel.initProps(props);

  const url = model.toString();
  const className = classNames(spaceless(`pagination-link ${classes}`), {
    disabled: !model.isValid,
    single: model.isSingle
  });

  function onClick(e) {
    const change = model.change(model);
    if (!model.isLink && change) {
      history.push(url);
    } else if (model.isLink && !change) {
      e.preventDefault();
    }
  }

  if (model.isLink) {
    return (
      <Link to={url} className={className} onClick={onClick}>
        {children}
      </Link>
    );
  }
  return (
    <button className={className} type="button" onClick={onClick}>
      {children}
    </button>
  );
}

export default withRouter(PageLink);
