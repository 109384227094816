import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import { useParams } from 'react-router-dom';

import {
  checkBoardPathAndSubmitted,
  //selectors
  loadingSelector,
  pathExistsSelector,
  pathSubmittedSelector,
} from '../../ducks/volunteer';
import m from './messages';
import { trackPageView } from '../../utils/tealium';

// templates
import PageLayout from '../../components/hoc/page-layout';
import SubmittedContent from '../../components/volunteer/submitted-content';
import DefaultContent from '../../components/volunteer/default-content';
import ErrorContent from '../../components/volunteer/error-content';

export default function BecomeVolunteerPage(_) {
  // routing
  const { path } = useParams();

  // redux
  const dispatch = useDispatch();

  // data from redux
  const loading = useSelector(loadingSelector);
  const pathExists = useSelector(pathExistsSelector);
  const pathSubmitted = useSelector(pathSubmittedSelector);

  useEffect(() => {
    dispatch(checkBoardPathAndSubmitted(path));
  }, [path]);

  useEffect(() => trackPageView(`volunteer-admin: ${path}`), [path]);

  return (
    <PageLayout
      classModificator="volunteer"
      title={<FormattedMessage {...m.title} />}
      loading={loading}
    >
      {!pathExists && <ErrorContent />}
      {pathExists && !pathSubmitted && <DefaultContent />}
      {pathSubmitted && <SubmittedContent />}
    </PageLayout>
  );
}
