import React from 'react';
import classNames from 'classnames';
import { FormattedMessage } from 'react-intl';
import { bool, func, number, string } from 'prop-types';
import { connect } from 'react-redux';
import Actions from '../actions';
import Judge from '../judge';
import m from './messages';
import Progress from '../../../common/progress';
import { isBusy } from '../../../../ducks/common/busy';
import { spaceless } from '../../../../utils/strings';
import Fieldset from '../fieldset';
import {
  fetchSinglePrefix, JudgeOperations,
  requestSingleSuggestion,
  selectSingleSuggestion
} from '../../../../ducks/suggestion';
import { plainObjectOf } from '../../../utils/redux';

export function ManageObjection(props) {
  const { threadId, classes, id, fetch, path, isLocked, uid, objection, loadingMessage } = props;
  const className = classNames(spaceless(`manage-item manage-objection ${classes}`), {
    loaded: !!path
  });

  let objectionMessage;
  if (loadingMessage || !objection) {
    objectionMessage = <Progress />;
  } else {
    objectionMessage = (
      <div className="objection-message">
        <h3><FormattedMessage {...m.message} /></h3>
        <Fieldset
          name="message"
          fields={objection}
          threadId={threadId}
          messages={m}
          names={['subject', 'authorName', 'posted', 'boardPath', 'message']}
        />
      </div>
    );
  }

  return (
    <div className={className}>
      <Fieldset
        name="objection"
        fields={props}
        messages={m}
        names={['creatorName', 'requestDate', 'email', 'path', 'comment', 'status']}
      />
      <Judge
        classes="judge-objection"
        id={uid}
        title={<FormattedMessage {...m.judge} />}
        onOpen={() => fetch(id, path)}
      >
        <Fieldset
          name="modal"
          fields={props}
          messages={m}
          names={['creatorName', 'email', 'requestDate', 'path', 'comment']}
        />
        <Actions
          id={id}
          path={path}
          names={[
            JudgeOperations.Remove,
            JudgeOperations.Reject,
            isLocked ? JudgeOperations.Unlock : JudgeOperations.Lock
          ]}
          data={{ type: 'objections' }}
          messages={m}
        />
        {objectionMessage}
      </Judge>
    </div>
  );
}

ManageObjection.propTypes = {
  classes: string,
  fetch: func.isRequired,
  id: number.isRequired,
  isLocked: bool.isRequired,
  loadingMessage: bool.isRequired,
  path: string.isRequired,
  uid: string.isRequired
};

ManageObjection.defaultProps = {
  classes: ''
};

export function mapStateToProps(state) {
  return {
    objection: plainObjectOf(selectSingleSuggestion(state)),
    loadingMessage: isBusy(state, `${fetchSinglePrefix}/`)
  };
}

export function mapDispatchToProps(dispatch) {
  return {
    fetch(id, path) {
      dispatch(requestSingleSuggestion(id, path));
    }
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(ManageObjection);
