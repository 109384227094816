import { defineMessages } from 'react-intl';

export default defineMessages({
  author: 'Author',
  createDate: 'Create Date',
  date: 'Date',
  deleteDate: 'Delete Date',
  empty: 'The Recycle Bin is empty.',
  name: 'Name',
  recycleBin: 'Recycle Bin',
  restore: 'Restore'
});
