import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';

import m from './messages';


export default function FiledMessage({ name, handleChange, value = '', readOnly = false, showEmptyField, handleResetEmptyMessage, sameUser }) {
  const [ fieldBlured, setFieldBlured ] = useState(false)
  const fieldProps = {
    name,
    value,
    className: `form-filed__inp-textarea${ readOnly ? ' readonly' : '' }`,
    readOnly
  }
  showEmptyField = showEmptyField.includes(name); // check if empty field is current
  const emptyFiled = !readOnly && !value && fieldBlured;

  const handleBlur = () => !fieldBlured && setFieldBlured(true)

  const renderRequireLabel = () => {
    if (!readOnly && value) return <span className='form-filed__label-succes' />
    if (emptyFiled || showEmptyField) return (
      <span className='form-filed__title-error icon iconWarning' >
        <FormattedMessage {...m.required}/>
      </span>
    )
    return <span className='form-field__title-required'/>
  }

  if (!readOnly && handleChange) {
    fieldProps.onChange = handleChange;
    fieldProps.onFocus= handleResetEmptyMessage;
    fieldProps.onBlur= handleBlur;
  }

  const showMessage = () => {
    if(sameUser === false) return (<p className='form-filed__description'>
      <FormattedMessage {...m.messageDesc} />
    </p>)
  };

  return (
    <div className={`form-filed form-filed form-filed--textarea${emptyFiled || showEmptyField ? ' error': ''}`}>
      <p className='form-filed__title'>
        <FormattedMessage {...m.message} />
        { renderRequireLabel() }
      </p>
      <textarea { ...fieldProps } />
      {showMessage()}
    </div>
  )
}

FiledMessage.defaultProps = {
  showEmptyField: '',
  handleResetEmptyMessage: () => {}
}

FiledMessage.propTypes = {
  handleChange: PropTypes.func,
  handleResetEmptyMessage: PropTypes.func,
  value: PropTypes.string,
  readOnly: PropTypes.bool,
  showEmptyField: PropTypes.string
}
