export const MAX_SEARCH_TEXT_SIZE = 200; // maximum length
export const SEARCH_MATCH_OFFSET = 12; // number of chars before keyword
export const ELLIPSIS = '\u2026';

export const Classification = {
  BIBLE: 'Bible',
  BIOGRAPHY: 'Biography',
  BIRTH: 'Birth',
  CEMETERY: 'Cemetery',
  CENSUS: 'Census',
  DEATH: 'Death',
  DEED: 'Deed',
  IMMIGRATION: 'Immigration',
  LOOKUP: 'Lookup',
  MARRIAGE: 'Marriage',
  MILITARY: 'Military',
  OBITUARY: 'Obituary',
  PENSION: 'Pension',
  QUERIES: 'Query',
  WILL: 'Will'
};

export const AllClassification = {
  ALL: 'All',
  ...Classification
};

export const PostedWithin = {
  ONE_WEEK: '1 Week',
  ONE_MONTH: '1 Month',
  SIX_MONTH: '6 Months',
  ONE_YEAR: '1 Year'
};

export const AllPostedWithin = {
  ALL: 'Anytime',
  ...PostedWithin
};
