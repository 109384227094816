import { prefix, predicates } from '../../components/utils/redux';
import { appName } from '../../config';

export const moduleName = 'busy';
export const origin = `${appName}/${moduleName}`;
export const BUSY_PUT = `${origin}/PUT`;
export const BUSY_REMOVE = `${origin}/REMOVE`;

export const putBusy = payload => ({ type: BUSY_PUT, payload });
export const removeBusy = payload => ({ type: BUSY_REMOVE, payload });

export default function reducer(state = [], { type, payload }) {
  const put = string => (
    state.indexOf(string) < 0
      ? state.concat(`${string}/`)
      : state
  );
  const remove = string => state.filter(s => string.indexOf(s) < 0);

  switch (type) {
    case BUSY_PUT:
      return put(payload);
    case BUSY_REMOVE:
      return remove(`${payload}/`);
    case 'RESET_ALL_BUSY':
      return []
    default:
      if (predicates.request(type)) {
        const actionTypePrefix = type.split('/').slice(0, -1).join('/');
        return put(actionTypePrefix);
      }
      if (predicates.done(type)) {
        return remove(type);
      }
      return  state;
  }
}

export function selectBusy(state) {
  return state[moduleName];
}

/**
 * @param {object} state
 * @param {function|string} predicate
 * @returns {*}
 */
export function isBusy(state, predicate = null) {
  const busy = selectBusy(state);
  if (busy.length > 0) {
    if (!predicate) {
      return true;
    }
    if (typeof predicate === 'string') {
      predicate = prefix(predicate);
    }
    return busy.some(predicate);
  }
  return false;
}
