import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import { Link } from 'react-router-dom';

import { authorPostsLink, threadLink } from '../../../../../utils/link';
import { convertToDate } from '../../../utils';
import FieldView from '../../../common/field-view';
import Progress from '../../../common/progress';
import Modal from '../../../common/modal';
import m from './messages';

import { threadSelector, getThreadById, loadingSelector } from '../../../../ducks/thread-page';

export default function ThreadRow({ remove, ...props }) {
  const dispatch = useDispatch();

  const fetchedThread = useSelector(threadSelector);
  const loading = useSelector(loadingSelector);

  const [modal, setModal] = useState(null);

  useEffect(() => {
    if (modal) dispatch(getThreadById(modal.path, modal.threadId));
  }, modal ? [modal.threadId, modal.path] : [null, null]);

  const showModal = (item) => (e) => {
    e.preventDefault();
    setModal(item);
  }

  const threadTitle = () => {
    if(loading) return '';
    if(fetchedThread) return fetchedThread.subject;
    return props.subject;
  }

  const modalContent = () => {
    if (!fetchedThread || loading) return <Progress />;
    return(
      <>
        <FieldView name="date" label={<FormattedMessage {...m.date} />}>
          {convertToDate(fetchedThread.posted)}
        </FieldView>
        <div className="body">{fetchedThread.body}</div>
      </>
    )
  };

  return (
    <tr>
      <td className="subject">
        <Link
          onClick={showModal(props)}
          to={threadLink(props.path, props.threadId)}
        >
          {props.subject}
        </Link>
      </td>
      <td className="author">
        <Link to={authorPostsLink(props.authorId)}>{props.username}</Link>
      </td>
      <td className="deleted">
        {convertToDate(props.deleteDate)}
      </td>
      <td className="actions">
        <button type="button" className="link" onClick={() => remove(props)}>
          <FormattedMessage {...m.restore} />
        </button>
      </td>
      <Modal
        classes="thread-preview"
        close={() => setModal(null)}
        title={threadTitle()}
        visible={!!modal}
      >
        {modalContent()}
      </Modal>
    </tr>
  );
}
