import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { FormattedMessage, injectIntl } from 'react-intl';
import { useParams } from 'react-router-dom';
import AdminView from '../view';
import Alert from '../../common/alert';
import m from './messages';
import Progress from '../../common/progress';
import Section from '../../common/old-section';
import { Busy } from '../../common/busy';
import { confirmation } from '../../utils';
import { getCategory, selectCategory } from '../../../ducks/category';
import { isBusy } from '../../../ducks/common/busy';
import { or, prefix } from '../../utils/redux';
import UnknownErrorAlert from '../../common/unknown-error-alert';
import { Roles } from '../../../ducks/_user';
import {
  adminListPrefix,
  removeAdminPrefix,
  requestAdminList,
  requestRemoveAdmin,
  selectAdminList
} from '../../../ducks/old_admin';
import { trackPageView } from '../../../utils/tealium';

export function AdminListPage(
  {
    busy,
    category,
    fetch,
    intl,
    items,
    remove
  }) {
  const { path } = useParams();
  useEffect(() => fetch(path), [path]);
  useEffect(() => trackPageView(`admins-list : ${(path || 'home')}`), []);

  let content;
  if (items) {
    const rows = items.map(item => (
      <tr key={item.userId}>
        <td className="icon iconSettings">
          <strong>{item.username}</strong>
        </td>
        <td>{item.email}</td>
        <td>{item.namePath}</td>
        <td>
          {item.rights === Roles.Admin
            ? <FormattedMessage {...m.superAdmin} />
            : (
              <button
                type="button"
                className="link icon iconTrash"
                onClick={confirmation(
                  intl.formatMessage(m.confirm),
                  () => remove(path, item.userId)
                )}
              />
            )}
        </td>
      </tr>
    ));
    content = (
      <Section>
        <Busy busy={busy}>
          <table className="table">
            <tbody>{rows}</tbody>
          </table>
        </Busy>
      </Section>
    );
  } else if (busy) {
    content = <Progress />;
  } else {
    content = <UnknownErrorAlert />;
  }

  const title = category.title
    ? <FormattedMessage {...m.titleName} values={{ name: category.title }} />
    : <FormattedMessage {...m.title} />;

  return (
    <AdminView classes="admin-list" title={title}>
      <Alert />
      {content}
    </AdminView>
  );
}

function mapStateToProps(state) {
  return {
    items: selectAdminList(state),
    busy: isBusy(state, or(
      prefix(`${adminListPrefix}/`),
      prefix(`${removeAdminPrefix}/`)
    )),
    category: selectCategory(state)
  };
}

function mapDispatchToProps(dispatch) {
  return {
    fetch(path) {
      dispatch(getCategory(path));
      dispatch(requestAdminList(path));
    },

    remove(path, userId) {
      dispatch(requestRemoveAdmin(path, userId, requestAdminList(path)));
    }
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(AdminListPage));
