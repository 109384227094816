import React, { useEffect } from 'react';
import classNames from 'classnames';
import { bool, func, string } from 'prop-types';
import { connect } from 'react-redux';
import { Redirect, withRouter } from 'react-router-dom';
import AdminView from '../view';
import Alert from '../../common/alert';
import PageNumber from '../../common/old-pagination/number';
import Progress from '../../common/progress';
import QueryModel, { parseQueryString } from '../../../ducks/common/query-model';
import { Busy } from '../../common/busy';
import {
  fetchPrefix,
  requestSuggestionFetch,
  selectModalId,
  selectSuggestionsQuery
} from '../../../ducks/suggestion';
import { isBusy } from '../../../ducks/common/busy';
import { ChildrenType, QueryType } from '../../utils/prop-types';
import PageLinkModel from '../../common/old-pagination/model';
import pageSizes, { sizeNameOf } from '../../common/paging/mock';
import { trackPageView } from '../../../utils/tealium';
import { mapObjToQueryString } from '../../utils';
import useQuery from '../../../hooks/use-query-params';

/**
 * Component for objections, rights and boards reviews
 * @param {boolean} busy
 * @param {array} items
 * @param {QueryModel} newQuery
 * @param {QueryModel} query
 * @param {string} classes
 * @param {function} fetch
 * @param {string} name
 * @param {object} props
 * @returns {Element}
 * @constructor
 */
export function Manage(
  {
    busy,
    children,
    classes,
    fetch,
    match: { params: { name } },
    modalId,
    newQuery,
    query,
    history,
    ...props
  }) {
  const parsedQueryInObj = useQuery();
  newQuery.path = '/suggestions/:type';
  newQuery.params.type = name;
  useEffect(() => fetch(newQuery), newQuery.toDependencies());
  query.change = changedQuery => { history.push(changedQuery.toString()); };
  query.params.type = name;
  query.path = '/admin/manage/:type';
  query.busy = busy;
  useEffect(() => trackPageView(`review : ${newQuery.params.type}`), []);

  if(query.totalPages !== 0 && newQuery.totalPages === 0 && newQuery.currentPage > query.totalPages){
    const queryUrl = mapObjToQueryString(parsedQueryInObj, { itemsPerPage: sizeNameOf(query.itemsPerPage), page: query.totalPages });
    const path = query.path.split(':');
    const pathWithType = `${path[0]}${query.params.type}`;
    const fullUrl = `${pathWithType}${queryUrl}`;

    return <Redirect to={fullUrl} />
  }

  const actual = query.equals(newQuery);
  const className = classNames(`manage-list manage-${name} ${classes}`, {
    'has-items': children && children.length > 0,
    'items-loaded': children,
    actual,
    empty: children && children.length === 0,
    outdated: !actual
  });

  let container;
  if (children && children.length !== 0) {
    container = (
      <div className="list-wrapper">
        <PageNumber editable model={query} />
        <Busy busy={busy}>
          <ul>{children}</ul>
        </Busy>
        <PageNumber editable model={query} />
      </div>
    );
  } else {
    container = <Progress />;
  }

  return (
    <AdminView classes={className} {...props}>
      {modalId ? null : <Alert />}
      {children.length !== 0 ? container : null}
    </AdminView>
  );
}

Manage.propTypes = {
  busy: bool,
  children: ChildrenType,
  classes: string,
  fetch: func,
  modalId: string,
  newQuery: QueryType,
  query: QueryType
};

Manage.defaultProps = {
  busy: false,
  children: '',
  classes: '',
  fetch: null,
  modalId: '',
  newQuery: new QueryModel(),
  query: new PageLinkModel()
};

export function mapStateToProps(state) {
  const queryOptions = parseQueryString(state.router.location.search);
  if (!queryOptions.itemsPerPage) {
    queryOptions.itemsPerPage = pageSizes.TWENTY_FIVE;
  }
  return {
    busy: isBusy(state, `${fetchPrefix}/`),
    modalId: selectModalId(state),
    newQuery: new QueryModel(queryOptions),
    query: new PageLinkModel(selectSuggestionsQuery(state))
  };
}

export function mapDispatchToProps(dispatch) {
  return {
    fetch(query) {
      dispatch(requestSuggestionFetch(query));
    }
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Manage));
