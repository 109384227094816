import pageSizes, { defaultPageSize, sizeNameOf } from '../../components/common/paging/mock';
import { parseQueryUrl, stringifyQueryUrl } from '../../components/utils';
import { Record } from 'immutable';

export function parseItemsPerPage(itemsPerPage) {
  if (!itemsPerPage) {
    return  -1;
  }
  if (+itemsPerPage > 0) {
    return +itemsPerPage;
  }
  if (typeof itemsPerPage === 'string') {
    const name = itemsPerPage.toUpperCase();
    const size = pageSizes[name];
    if (size > 0) {
      return size;
    }
  }
  return  -1;
}

export function parseQueryString(string, keys) {
  const params = parseQueryUrl(string);
  const query = {};
  if (params.page) {
    query.currentPage = +params.page;
  }
  const itemsPerPage = parseItemsPerPage(params.itemsPerPage);
  if (itemsPerPage > 0) {
    query.itemsPerPage = itemsPerPage;
  }
  if (!keys) {
    keys = Object.keys(params).filter(key => ['page', 'itemsPerPage'].indexOf(key) < 0);
  }
  query.params = {};
  for (const key of keys) {
    query.params[key] = params[key];
  }
  return query;
}

const QueryRecord = Record({
  currentPage: 1,
  itemsPerPage: 10,
  params: null,
  path: '',
  totalPages: 0
});

/**
 * @property {boolean} isLink Detects if anchor may be parsed by search engines
 * @property {boolean} busy When client is loading a request
 */
export default class QueryModel {
  constructor(options = {}) {
    this.assign(typeof options === 'string' ? parseQueryString(options) : options);
  }

  assign(options) {
    this.assignPaging(options);
    this.params = options.params || {};
    this.path = typeof options.path === 'string' && options.path.length > 0 ? options.path : '';
  }

  assignPaging(options) {
    this.currentPage = isFinite(options.currentPage) ? +options.currentPage : 1;
    const itemsPerPage = parseItemsPerPage(options.itemsPerPage);
    this.itemsPerPage = itemsPerPage > 0 ? itemsPerPage : defaultPageSize.value;
    this.totalPages = options.totalPages >= 0 ? +options.totalPages : 0;
  }

  create(options) {
    return new (this.constructor)(options);
  }

  /**
   * Number of previous page
   * @returns {number}
   */
  get previous() {
    return this.currentPage - 1;
  }

  /**
   * Number of next page
   * @returns {number}
   */
  get next() {
    return this.currentPage + 1;
  }

  /**
   * Detects if previous page available
   * @returns {boolean}
   */
  get hasPrevious() {
    return this.previous > 1;
  }

  /**
   * Detects if next page available
   * @returns {boolean}
   */
  get hasNext() {
    return this.next <= this.totalPages;
  }

  /**
   * Method for previous and next page creation based on current page parameters
   * @param {int} currentPage
   * @param {int} itemsPerPage
   * @returns {QueryModel}
   */
  createSibling(currentPage, itemsPerPage = this.itemsPerPage) {
    return this.create({
      ...this,
      currentPage,
      itemsPerPage
    });
  }

  /**
   * Create page model with new URL params
   * @param {object} params
   */
  deriveParams(params) {
    const base = Object.assign({}, this.params);
    return this.create({
      ...this,
      params: Object.assign(base, params)
    });
  }

  derive(options) {
    return this.create({
      ...this,
      ...options
    });
  }

  /**
   * Returns URL representation of page size
   * @returns {string}
   */
  get itemsPerPageName() {
    return sizeNameOf(this.itemsPerPage);
  }

  /**
   * @returns {boolean}
   */
  get isValid() {
    return this.totalPages > 0
      && this.currentPage > 0
      && this.currentPage <= this.totalPages;
  }

  /**
   * Shows if the selected page size is default
   * @returns {boolean}
   */
  get isSizeDefault() {
    return this.itemsPerPage === defaultPageSize.value;
  }

  /**
   * Returns query object that can be serialized query string
   * @returns {{page: string, itemsPerPage: string}}
   */
  toJSON() {
    const params = this.getParamKeys().reduce((acc, key) => {
      acc[key] = this.params[key];
      return acc;
    }, {});
    return {
      page: this.currentPage,
      itemsPerPage: this.itemsPerPageName,
      ...params
    };
  }

  get paging() {
    return {
      itemsPerPage: this.itemsPerPageName,
      page: this.currentPage
    };
  }

  get pagingString() {
    return stringifyQueryUrl(this.paging);
  }

  get isSingle() {
    return this.totalPages === 1;
  }

  /**
   * Returns query string
   * @returns {string}
   */
  toString() {
    const params = this.toJSON();
    let string = '';
    if (this.path) {
      string = this.path.replace(/(:[\w_]+)/g, name => {
        name = name.slice(1); // remove ":"
        const value = params[name];
        if (value) {
          delete params[name];
          return value;
        }
        return '';
      });
    }
    string += `?${stringifyQueryUrl(params)}`;
    return string;
  }

  toRecord() {
    return QueryRecord({
      currentPage: this.currentPage,
      itemsPerPage: this.itemsPerPage,
      params: this.params,
      path: this.path || '',
      totalPages: this.totalPages
    });
  }

  getParamKeys() {
    return Object.keys(this.params).sort();
  }

  toDependencies(keys = this.getParamKeys()) {
    return [
      this.path,
      this.currentPage,
      this.itemsPerPage,
      ...keys,
      ...keys.map(key => this.params[key])
    ];
  }

  get hasParams() {
    return Object.keys(this.params).length > 0;
  }

  equals(query) {
    if (!(this.page === query.page && this.itemsPerPage === query.itemsPerPage)) {
      return false;
    }
    const keys = this.getParamKeys();
    const objectKeys = query.getParamKeys();
    if (keys.length !== objectKeys.length) {
      return false;
    }
    for (let i = 0; i < keys.length; i++) {
      const key = keys[i];
      if (key !== objectKeys[i]) {
        return false;
      }
      if (this.params[key] !== query.params[key]) {
        return false;
      }
    }
    return true;
  }
}

QueryModel.createRecord = function createRecord(options = {}) {
  return (new QueryModel(options)).toRecord();
};
