import React from 'react';
import { FormattedMessage } from 'react-intl';

import m from './messages';


export default function NotFoundView(props) {
  const handlerLinkBack = () => window.history.go(-1);

  const link = (id, url) => (
    <li>
      <a href={url}><FormattedMessage {...m[id]} /></a>
    </li>
  );

  return (
    <div className='error-page'>
      <a id='test-link' className='link-back' onClick={handlerLinkBack}>
        <FormattedMessage {...m.back} />
      </a>
      <div className='error-content'>
        <div className='error-content__info'>
          <div className='unknown'><FormattedMessage {...m.unknown} /></div>
          <ul>
            {link('usefulLinkCreateTree', 'https://www.ancestry.com/family-tree/')}
            {link('usefulLinkSearch', 'https://search.ancestry.com/')}
            {link('usefulLinkDna', 'https://www.ancestry.com/dna')}
            {link('usefulLinkGetStarted', 'https://support.ancestry.com/s/gettingstarted')}
            {link('usefulLinkReady', 'https://mycanvas.ancestry.com/')}
            {link('usefulLinkLooking', 'https://www.familytreemaker.com/')}
          </ul>
        </div>
        <div className='error-content__img'>
          <img
            src='https://www.ancestrycdn.com/ui-static/i/templates/1/template-error.jpg'
            alt='Not found image'
          />
        </div>
      </div>
    </div>
  )
}
