import React from 'react';
import MoveBoard from '../../board/move';
import m from './messages';
import { FormattedMessage } from 'react-intl';

export default function MoveCategory() {
  return (
    <MoveBoard
      tracking="move-category"
      title={<FormattedMessage {...m.title} />}
      messages={m}
    />
  );
}
