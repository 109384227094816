import React, { useState, useEffect } from 'react';
import { BannerConfig, getInitialVisibility, updateVisibility } from './banner-config';
import { TrackEvent } from './tracking';

import Illustration from '../../assets/Illustration.png';

const Banner = () => {

    const [isVisible, setIsVisible] = useState(getInitialVisibility());

    useEffect(() => {
        if (isVisible) {
            updateVisibility(true);
        }
    }, [isVisible]);

    const handleClose = () => {
        updateVisibility(false);
        setIsVisible(false);
    };

    if (!isVisible) {
        return null;
    }

    const handleClick = () => {
        window.open(BannerConfig.betaUrl, '_blank');
        TrackEvent();
    };

    return (
        (
            <div className='banner' id='banner'>
                <div className='grid' id='grid'>
                    <div className='illustration' id='illustration'>
                        <img src={Illustration} alt="" />
                    </div>
                    <div className='text-button' id='text-button'>
                        <div className="text" id='text'>
                            <span className='heading' id='heading'>{BannerConfig.headerText}</span>
                            <span className='intro' id='intro'>{BannerConfig.introText}</span>
                        </div>
                        <button className="ancBtn Blue" id='button' onClick={handleClick} >{BannerConfig.buttonText}</button>
                    </div>
                </div>
                <button className="icon iconRemove link" id='close-icon' title="Remove banner" type="button" onClick={handleClose}></button>
            </div>
        )
    )
}

export default Banner;