import React from 'react';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';

import { deleteFile } from '../../../../ducks/board-info-edit';
import { useContent } from '../context-content';
import url from '../../../../url-service';
import m from '../messages';
import { escapeSpecialSymbols } from '../../../utils';

export default function UploadedList({ attachments }) {
  const { path } = useParams();
  const { content, setContent } = useContent();
  const dispatch = useDispatch();

  if (!attachments.length) return null;

  const handleButtonClick = (type = 'removeFile', { attachmentId, name, id }) => () => {
    if (!attachmentId || !name) return;
    let imgTag = `<img src="DSI:${attachmentId},${name}:EndDSI">`;
    let newContent = '';

    if (type === 'addFile') newContent = `${content}\n${imgTag}`;
    else {
      imgTag = escapeSpecialSymbols(imgTag);
      const regexp = new RegExp(`\n${imgTag}`, 'ig'); // \n<img src="DSI:attachment-1,image-name.jpg:EndDSI">
      newContent = content.replace(regexp, '');
      dispatch(deleteFile(path, attachmentId));
    }

    setContent(newContent);
  }


  return (
    <ul className='attachments__list'>
      { attachments.map((file) => (
        <li className='attachments__item' key={file.id + file.attachmentId}>
          <div className='attachments__item-wrap'>
            <img className='attachments__item-img' src={url.getAttachmentLinkFromId(file.id)} alt={file.name}/>
          </div>
          <span className='attachments__file-name'>{ file.name }</span>
          <div className='attachments__item-btns'>
            <button className='attachments__item-btn' onClick={handleButtonClick('addFile', file)}>
              <FormattedMessage {...m.btnInsertHtmlTitle}/>
            </button>
            <button className='attachments__item-btn' onClick={handleButtonClick('removeFile', file)}>
              <FormattedMessage {...m.btnRemoveTitle} />
            </button>
          </div>
        </li>
      )) }
    </ul>
  )
}
