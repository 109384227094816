import React from 'react';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faComments } from '@fortawesome/free-solid-svg-icons';



export default function ParentSubject({ parentSubject }) {
  if (!parentSubject) return null;

  return (
    <h2 className='thread__title'>
      <span>
        <FontAwesomeIcon icon={faComments} />
      </span>
      {parentSubject}
    </h2>
  )
}


ParentSubject.propTypes = {
  parentSubject: PropTypes.string
}