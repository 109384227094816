import { createSelector } from 'reselect';
import { LOCATION_CHANGE } from 'connected-react-router';
import { Record } from 'immutable';
import settings from '../../local-settings';
import { appName } from '../../config';
import { Day, Second } from '../../constants';
import { prefix, predicates, action } from '../../components/utils/redux';

export const moduleName = 'old_alert';

export const HIDDEN_ALERT = 'hidden';
export const LIFETIME_FOREVER = -1;
export const LIFETIME_NEXT_PAGE_ONLY = 1;
export const LIFETIME_DEFAULT = LIFETIME_NEXT_PAGE_ONLY;
export const TIMEOUT_SUCCESS_DEFAULT = 15 * Second;
export const TIMEOUT_DEFAULT = 365 * Day;
export const AlertRecord = Record({
  type: HIDDEN_ALERT,
  text: '',
  // Number of @@router/LOCATION_CHANGE till alert will closed
  // if lifetime = -1 then alert never close
  lifetime: LIFETIME_FOREVER,
  expires: 0
});

export const moduleOrigin = `${appName}/${moduleName}`;
const prefixSlash = `${moduleOrigin}/`;
const isAlertActionType = prefix(prefixSlash);

export const ALERT_SUCCESS = `${moduleOrigin}/SUCCESS`;
export const ALERT_ERROR = `${moduleOrigin}/ERROR`;
export const ALERT_HIDDEN = `${moduleOrigin}/HIDDEN`;
export const showAlert = (type, text) => action(`${moduleOrigin}/${type.toUpperCase()}`, text);
export const showSuccess = text => action(ALERT_SUCCESS, text);
export const closeAlert = () => action(ALERT_HIDDEN);
export function showError(text) {
  const act = action(ALERT_ERROR);
  if (typeof text === 'string') {
    act.payload = text;
  } else if (typeof text === 'object' && text) {
    if (text.response && text.response.data){
		if(text.response.data.message){
			act.payload = text.response.data.message;
		}else if(text.response.data.error){
			act.payload = text.response.data.error;
		}else{
			act.payload = text;
		}			
	}else{
		act.payload = text;
	}
  }
  return act;
}

export default function reducer(state = new AlertRecord({}), { type, payload }) {
  const alert = (type = HIDDEN_ALERT, text = '') => state
    .set('type', type.toLowerCase())
    .set('text', text)
    .set('lifetime', LIFETIME_DEFAULT);

  if (type === ALERT_HIDDEN) {
    return alert(HIDDEN_ALERT, '');
  }
  // Do not hide alert if his type is not "error"
  if (predicates.request(type) && state.get('type') === 'error') {
    return alert();
  }
  if (isAlertActionType(type)) {
    const alertType = type.slice(prefixSlash.length).toLowerCase();
    let defaultTimeout = settings.getNumber('alert.timeout');
    if (!(defaultTimeout > 0)) {
      defaultTimeout = type === ALERT_SUCCESS ? TIMEOUT_SUCCESS_DEFAULT : TIMEOUT_DEFAULT;
    }
    return state
      .set('type', alertType)
      .set('text', payload.message || payload.text || payload)
      .set('lifetime', payload.lifetime >= 0 ? payload.lifetime : LIFETIME_DEFAULT)
      .set('expires', Date.now() + (payload.timeout > 0 ? payload.timeout : defaultTimeout));
  }
  // Can hide alert if user navigate next page
  if (type === LOCATION_CHANGE) {
    const lifetime = state.get('lifetime') - 1;
    let newState = state.set('lifetime', lifetime);
    if (!(lifetime > 0)) {
      newState = newState
        .set('type', HIDDEN_ALERT)
        .set('text', '');
    }
    return newState;
  }
  return  state;
}

export function selectAlert(state) {
  return state[moduleName];
}

export const selectAlertText = createSelector(
  selectAlert,
  state => state.get('text')
);

export const hasAlert = createSelector(
  selectAlertText,
  text => !!text
);

export const hasErrorAlert = createSelector(
  selectAlert,
  state => state.get('type') === 'error'
);
