import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { Link, withRouter, Redirect } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';

import PageNumber from '../../components/common/old-pagination/number';
import PageLink from '../../components/common/old-pagination/link';
import { requestAuthorPosts, selectAuthorPosts, clearAuthorPostsState } from '../../ducks/old_author-posts';
import { mapObjToQueryString, parseQueryUrl } from '../../components/utils';
import PageModel from '../../components/common/old-pagination/model';
import PageView from '../../components/common/page-view';
import Section from '../../components/common/old-section';
import { categoryLink, threadLink } from '../../components/utils/old_link';
import { plainObjectOf } from '../../components/utils/redux';
import { isUUID } from '../../../utils';
import { trackPageView } from '../../utils/tealium';
import m from './messages';
import { convertToDate } from '../../components/utils'
import { isBusy } from '../../ducks/common/busy';
import Progress from '../../components/common/progress';
import useQuery from '../../hooks/use-query-params';

export function renderThread(thread) {
  const url = threadLink(thread.path, thread.id);
  return (
    <li key={url}>
      <Link to={url} className="subject">{thread.subject}</Link>
      <span className='widget time last-post'>{convertToDate(thread.lastPost, 'M/DD/YYYY')}</span>
    </li>
  );
}

export function renderBoard(board) {
  const id = `board-${board.path}`;
  return (
    <li key={id} id={id} className="conSection">
      <Link to={categoryLink(board.path)} className="subject bold">{board.name}</Link>
      <ul className="board-threads">{ board.threads.map(renderThread) }</ul>
    </li>
  );
}

export function renderItems(items) {
  const list = items.map(item => (item.threads ? renderBoard(item) : renderThread(item)));
  return <ul className="list">{list}</ul>;
}

export function AuthorPostsPage(
  {
    busy,
    dispatch,
    history,
    items,
    match,
    message,
    paging,
    params,
    title,
    isBusy
  }) {
  const parsedQueryInObj = useQuery();

  useEffect(() => () => dispatch(clearAuthorPostsState()), [])

  if (!isUUID(match.params.userId)) return <Redirect to='/' />

  const dependencies = [match.params.userId, params.orderBy, params.page];
  useEffect(() => {
    dispatch(requestAuthorPosts(...dependencies));
  }, dependencies);
  const linkOptions = {
    busy,
    change: newQuery => history.push(newQuery.toString()),
    isLink: true
  };
  const pageModel = new PageModel({
    ...paging,
    ...linkOptions,
    params: {
      orderBy: params.orderBy || 'BOARD'
    }
  });
  const sortLink = (name, orderBy = name.toUpperCase()) => {
    const sortPageModel = new PageModel({
      ...linkOptions,
      params: { orderBy }
    });
    const className = `sort-by sort-by-${name.toLowerCase()} ${orderBy === params.orderBy ? 'active' : ''}`.trim();
    return (
      <li className={className}>
        <PageLink model={sortPageModel}>{name}</PageLink>
      </li>
    );
  };

  const isBoards = items && items.every(item => item.threads);

  useEffect(() => trackPageView('author-posts'), []);

  if(parsedQueryInObj.page > paging.totalPages) {
    const queryUrl = mapObjToQueryString(parsedQueryInObj, {
      itemsPerPage: parsedQueryInObj.itemsPerPage,
      orderBy: parsedQueryInObj.orderBy,
      page: paging.totalPages
    });
    const fullUrl = paging.totalPages === 1 ? `/author-posts/${match.params.userId}` : `/author-posts/${match.params.userId}${queryUrl}`;
    if (paging.currentPage !== 1 && paging.totalPages !== 1) return <Redirect to={fullUrl} />
  }

  return (
    <PageView classes="author-posts" title={title || 'Author Posts'}>
      <div className="ancGrid ancGridPadded">
        <div className="ancCol w100">
          {message ? <div className="alert alertError">{message}</div> : null}
          <Section>
            <div className="author-posts-links-top">
              <div className="sort">
                <span className="text"><FormattedMessage {...m.listBy} /></span>
                <ul>
                  {sortLink('Board')}
                  {sortLink('Date', 'CREATE_DATE')}
                </ul>
              </div>
              <PageNumber editable model={pageModel} />
            </div>
            <div className={`author-posts-container ${isBoards ? '' : 'conSection'}`}>
              { isBusy
                ? <Progress />
                : (<>
                  { items ? renderItems(items) : <FormattedMessage {...m.noPosts} /> }
                  { busy ? <div className="busy"><div className="loading" /></div> : null }
                </>)
              }
            </div>
            <div className="author-posts-links-bottom">
              <PageNumber editable model={pageModel} />
            </div>
          </Section>
        </div>
      </div>
    </PageView>
  );
}

function mapStateTopProps(state) {
  const params = parseQueryUrl(state.router.location.search) || {};
  return {
    ...plainObjectOf(selectAuthorPosts(state)),
    params: {
      page: params.page > 0 ? +params.page : 1,
      orderBy: params.orderBy || 'BOARD'
    },
    isBusy: isBusy(state)
  };
}

export default connect(mapStateTopProps)(withRouter(AuthorPostsPage));
