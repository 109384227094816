import React, { useEffect } from 'react';
import classNames from 'classnames';
import { bool, func, string } from 'prop-types';
import { connect } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import { withRouter, useParams } from 'react-router-dom';
import AdminView from '../../view';
import Alert from '../../../common/alert';
import BoardActions from '../../board/actions';
import Field from '../../../common/field';
import Form from '../../../common/form';
import m from './message';
import Progress from '../../../common/progress';
import Section from '../../../common/old-section';
import { Busy } from '../../../common/busy';
import { ChildrenType } from '../../../utils/prop-types';
import { isBusy } from '../../../../ducks/common/busy';
import { plainObjectOf } from '../../../utils/redux';
import { selectCategoryBreadcrumbs } from '../../../../ducks/category';
import {
  fetchPrefix,
  selectNode, setNodeField,
  setOption,
  requestFetchOptions,
  errSelector
} from '../../../../ducks/options';
import { spaceless } from '../../../../utils/strings';
import { trackPageView } from '../../../../utils/tealium';
import ErrorView from '../../../error'


export function NodeOptions(props) {
  const {
    tracking,
    breadcrumbs,
    busyFetch,
    busySave,
    canSubmit,
    children,
    classes,
    err,
    gatewayAddress,
    name,
    options,
    save,
    set,
    setNodeField,
    requestFetchOptions,
    nodeType,
    title = name
      ? <FormattedMessage {...m.titleName} values={{ name }} />
      : <FormattedMessage {...m.title} />
  } = props

  const { path } = useParams()
  useEffect(() => {
    requestFetchOptions(path, nodeType)
  }, [path, nodeType]);


  useEffect(() => trackPageView(`${(tracking || 'unknownTrackingInfo')} : ${(path || 'home')}`), []);
  if (err) return <ErrorView />

  if (!options) {
    return <Progress />;
  }

  const items = Object.keys(options)
    .map(name => {
      if (!m[name]) {
        return null;
      }
      let additional;
      if (name === 'mailListGateway' && options[name]) {
        additional = (
          <Field
            label={<FormattedMessage {...m.gatewayAddress} />}
            name="gatewayAddress"
            setValue={value => setNodeField('gatewayAddress', value)}
            value={gatewayAddress}
          />
        );
      }
      return (
        <li key={name}>
          <Field
            inputClasses="checkbox"
            label={<FormattedMessage {...m[name]} />}
            name={name}
            setValue={value => set(name, value)}
            type="checkbox"
            value={options[name]}
          />
          {additional}
        </li>
      );
    });

  const submitClassName = classNames('ancBtn orange', {
    loading: busySave
  });

  const submittable = canSubmit && !busySave;


  return (
    <AdminView classes={spaceless(`node-options ${classes}`)} title={title} breadcrumbs={breadcrumbs.links}>
      <Alert />
      <Section>
        <Form submit={() => save(path)} canSubmit={submittable}>
          <Busy busy={busyFetch}>
            {children}
            <ul>{items}</ul>
            <BoardActions
              busy={!submittable}
              path={path}
              submitClassName={submitClassName}
            />
          </Busy>
        </Form>
      </Section>
    </AdminView>
  );
}

NodeOptions.propTypes = {
  busyFetch: bool,
  busySave: bool,
  canSubmit: bool,
  children: ChildrenType,
  fetch: func,
  name: string,
  classes: string,
  save: func.isRequired
};

NodeOptions.defaultProps = {
  busyFetch: false,
  busySave: false,
  canSubmit: true,
  children: '',
  classes: '',
  name: '',
  fetch: () => {}
};

export function mapStateToProps(state) {
  return {
    ...plainObjectOf(selectNode(state)),
    busyFetch: isBusy(state, `${fetchPrefix}/`),
    err: errSelector(state)
  };
}

export function mapDispatchToProps(dispatch) {
  return {
    requestFetchOptions: (path, nodeType) => dispatch(requestFetchOptions(path, nodeType)),
    setNodeField(name, value) {
      dispatch(setNodeField(name, value));
    },
    set(name, value) {
      dispatch(setOption(name, value));
    }
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(NodeOptions));
