/* eslint-disable max-len */
import { defineMessages } from 'react-intl';

export default defineMessages({
  authors: 'Authors',
  boards: 'Boards',
  categories: 'Categories',
  confirmDelete: 'Are you sure you want to delete this favorite?',
  deleteText: 'Delete this entry',
  lastPost: 'Last Post',
  manage: 'Here you can manage all your favorites in one place. Add favorites for quick access later whenever you see this {icon} icon.',
  messageBoards: 'Message Boards',
  myFavorites: 'My Favorites',
  name: 'Name',
  no: 'No',
  noFavorites: 'You have no favorites. {link} for topics that interest you or for specific ancestor information.',
  posts: 'Posts',
  replies: 'Replies',
  returnToHomepage: 'Return to Homepage',
  searchLink: 'Search the message boards',
  threads: 'Threads',
  tools: 'Tools',
  viewPosts: 'view posts',
  yes: 'Yes'
});
