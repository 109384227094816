import { call, put, takeEvery, select } from 'redux-saga/effects';
import { createSelector } from 'reselect';
import { OrderedSet, Record } from 'immutable';
import { replace } from 'connected-react-router';

import api from '../services/contents-service';
import { action, freeze } from '../components/utils/redux';
import { appName } from '../config';
import { Http } from '../constants';

/**
 * Constants
 */
export const moduleName = 'category';
export const prefix = `${appName}/${moduleName}`;

export const fetchPrefix = `${prefix}/fetch`;
export const CATEGORY_GET_CACHE = `${fetchPrefix}/CACHE`;
export const GET_CATEGORY_BY_PATH = `${fetchPrefix}/REQUEST`;
export const GET_CATEGORY_BY_PATH_SUCCESS = `${fetchPrefix}/RECEIVE`;
export const GET_CATEGORY_BY_PATH_ERROR = `${fetchPrefix}/FAIL`;
export const CATEGORY_SET = `${prefix}/SET`;

export const NodeType = {
  Board: 'BOARD',
  Category: 'CATEGORY'
};
Object.freeze(NodeType);

export const setCategory = (name, value) => action(CATEGORY_SET, { name, value });
export const requestCachedCategory = path => action(CATEGORY_GET_CACHE, path);

/**
 * Reducer
 */
export const BoardRecord = Record({
  description: null,
  items: OrderedSet([]),
  paging: null
});

export const EntitiesRecord = Record({
  boards: BoardRecord(),
  breadcrumbs: { links: [{ path: '' }] },
  categories: OrderedSet([]),
  deleted: false,
  gatewayAddress: '',
  isAlert: null,
  isFavourite: null,
  nodeType: '',
  path: null,
  readOnly: null,
  surnames: null,
  threads: null,
  title: null
});

export const ReducerRecord = Record({
  entities: EntitiesRecord({}),
  err: false,
  loading: false
});

export default function reducer(state = ReducerRecord({}), action) {
  const { type, payload, err } = action;

  switch (type) {
    case GET_CATEGORY_BY_PATH:
      return state.set('loading', true);

    case GET_CATEGORY_BY_PATH_SUCCESS:
      return state
        .set('loading', false)
        .set('entities', EntitiesRecord(freeze(payload)));

    case GET_CATEGORY_BY_PATH_ERROR:
      return state
        .set('loading', false)
        .set('entities', EntitiesRecord({}))
        .set('err', err);

    case CATEGORY_SET:
      return state
        .setIn(['entities', payload.name], payload.value);

    default:
      return state;
  }
}

/**
 * Action
 */

export const getCategory = (path = '', count = 'TEN', page = 1) => ({
  type: GET_CATEGORY_BY_PATH,
  payload: { path, count, page }
});

/**
 * Selector
 */
export const stateSelector = state => state[moduleName];
export const loadingSelector = createSelector(
  stateSelector,
  state => state.loading
);

export const selectCategory = createSelector(
  stateSelector,
  state => state.entities
);

export const selectCategoryPath = createSelector(
  selectCategory,
  state => state.path
);

export const selectCategoryTitle = createSelector(
  selectCategory,
  state => state.title
);

export const selectCategoryBreadcrumbs = createSelector(
  selectCategory,
  state => {
    const breadcrumbs = state.breadcrumbs;
    return breadcrumbs ? breadcrumbs.links : [];
  }
);

export const selectIsFavourite = createSelector(
  selectCategory,
  state => state.isFavourite
);

export const selectIsCategory = createSelector(
  selectCategory,
  state => NodeType.Category === state.nodeType
);

export const selectIsBoard = createSelector(
  selectCategory,
  state => NodeType.Board === state.nodeType
);

export const selectIsAlert = createSelector(
  selectCategory,
  state => state.isAlert
);

/**
 * Saga
 */
export function* fetchCategory({ payload: { path, count = 'ten', page = 1 } }) {
  try {
    const { data } = yield call(api.getContent, path, count.toUpperCase(), page);
    yield put({
      type: GET_CATEGORY_BY_PATH_SUCCESS,
      payload: {
        ...data,
        path
      }
    });
  } catch (err) {
    yield put({ type: GET_CATEGORY_BY_PATH_ERROR, err });
    if (err && err.response && err.response.status === Http.NotFound) yield put(replace('/404'));
  }
}

export function* getCachedCategory({ payload: path }) {
  if (selectCategoryPath(yield select()) !== path) {
    yield put(getCategory(path));
  }
}

export function* saga() {
  yield takeEvery(GET_CATEGORY_BY_PATH, fetchCategory);
  yield takeEvery(CATEGORY_GET_CACHE, getCachedCategory);
}
