import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

import { faFolderOpen, faChevronRight } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { FormattedMessage } from 'react-intl';
import m from '../messages';

export default function TableCategory({ description, items, links }) {
  const renderTableTitle = () => description
    ? <h3 className='table__title'>{<FormattedMessage {...m.categoriesTableTitle} values={{ description }} />}</h3>
    : null;

  const renderTableHead = () => (
    <thead>
      <tr className='table__row table__row--header'>
        <th className='table__col'><FormattedMessage {...m.categoryTableHeader} /></th>
        <th className='table__col'><FormattedMessage {...m.subCategoriesTableHeader} /></th>
        <th className='table__col'><FormattedMessage {...m.boardsTableHeader} /></th>
      </tr>
    </thead>
  )

  const renderTableBody = () => items.map(rowItem => (
    <tr className='table__row' key={rowItem.path}>
      <td className='table__col'>
        <Link className='table__link' to={rowItem.path}>
          <span className='table__image'>
            <FontAwesomeIcon icon={faFolderOpen} />
          </span>
          {rowItem.title}
        </Link>
      </td>
      <td className='table__col'>{rowItem.subCategories}</td>
      <td className='table__col'>{rowItem.boards}</td>
    </tr>
  ))


  const renderLinksMore = ({ title, path }) => (
    <div className='table__more'>
      <Link className='table__more-link' to={path}>
        {<FormattedMessage {...m.moreCategoryLink} values={{ title }} />}
      </Link>
    </div>
  )


  return (
    <>
      {renderTableTitle()}
      <table className='table table--category'>
        {renderTableHead()}
        <tbody className='table__body'>
          {renderTableBody()}
        </tbody>
      </table>
      {links && renderLinksMore(links[0])}
    </>
  )
}


TableCategory.propTypes = {
  description: PropTypes.string,
  items: PropTypes.array,
  links: PropTypes.arrayOf(PropTypes.object), // array of one element
}