import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';

// it's common type, and can be getting from on of [alert, author, category, thread]-favorite duck
import { FAVORITE_RESET_TO_DEFAULT } from '../../ducks/alert-favorite';

import CategoryNotice from './category';
import ThreadNotice from './thread';
import AuthorNotice from './author';
import AlertNotice from './alert';


export default function NoticeAddOrRemove() {
  const dispatch = useDispatch();
  
  useEffect(() => () => {
    dispatch({ type: FAVORITE_RESET_TO_DEFAULT })
  }, [])

  return (
    <>
      <CategoryNotice />
      <ThreadNotice />
      <AuthorNotice />
      <AlertNotice />
    </>
  )
}