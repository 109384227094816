/* eslint-disable max-len */
import { defineMessages } from 'react-intl';

export default defineMessages({
  hint: 'All messages and threads will be moved to this location.',
  newPath: 'New Board Path',
  success: 'Board merged',
  takeTime: 'Merging large boards may take several minutes. The current transfer rate is approx. 100 messages per minute.',
  title: 'Merge Board'
});
