import React from 'react';
import { useLocation, Link } from 'react-router-dom';
import { FormattedMessage, injectIntl } from 'react-intl';

import m from './messages';
import url from '../../url-service';

export default injectIntl(({ listItems, activeLink }) => {
  const { search: searchQuery } = useLocation();

  const getItemClassValue = itemName => `favorites-full__menu-item${itemName === activeLink ? ' active' : ''}`;

  const getLinkPath = item => `${url.createPath('favorites', item)}${searchQuery || ''}`;

  const renderListItems = () => listItems.map(item => (
    <li className={getItemClassValue(item)} key={item}>
      <Link className="favorites-full__menu-link" to={getLinkPath(item)}>
        <FormattedMessage {...m[item]} />
      </Link>
    </li>
  ));

  return (
    <nav className="favorites-full__menu">
      <div className="favorites-full__menu-title">
        <FormattedMessage {...m.messageBoards} />
      </div>
      <ul className="favorites-full__menu-list">
        { renderListItems() }
      </ul>
    </nav>
  );
});
