import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';
import { push } from 'connected-react-router';
import { withRouter } from 'react-router-dom';
import m from './messages';
import NodeEdit from '../../node/edit';
import { categoryLink } from '../../../../../utils/link';
import { plainObjectOf } from '../../../utils/redux';
import { isBusy } from '../../../../ducks/common/busy';
import { showSuccess } from '../../../../ducks/common/alert';
import {
  createPrefix,
  DefaultNode,
  requestCreateOptions,
  selectNode,
  setNodeField
} from '../../../../ducks/options';


export function CreateCategory(
  {
    intl,
    match,
    node,
    reset,
    set,
    ...props
  }) {
  useEffect(() => {
    reset();
    set('path', match.params.path);
  }, [match.params.path]);

  return (
    <NodeEdit
      tracking="create-category"
      classes="create category"
      messages={m}
      {...props}
    />
  );
}

export function mapStateToProps(state) {
  return {
    busySave: isBusy(state, `${createPrefix}/`),
    node: plainObjectOf(selectNode(state)),
  };
}

export function mapDispatchToProps(dispatch) {
  return {
    save(path) {
      dispatch(requestCreateOptions(path, 'CATEGORY', [
        push(categoryLink(path)),
        showSuccess('Category created successfully'),
        DefaultNode.Category
      ]));
    },
    set: (name, value) => dispatch(setNodeField(name, value)),
    reset() {
      dispatch(DefaultNode.Category);
    }
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(injectIntl(CreateCategory)));
