import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Link } from 'react-router-dom';
import classNames from 'classnames';
import m from './message';
import { bool, string } from 'prop-types';
import { ChildrenType } from '../../../utils/prop-types';
import { categoryLink } from '../../../utils/old_link';

export default function BoardActions(
  {
    busy,
    children,
    path,
    submitClassName,
    type,
    handleClick
  }) {
  const otherProps = {};
  if (handleClick) otherProps.onClick = handleClick;

  return (
    <div className="actions">
      <button
        className={submitClassName || classNames('ancBtn orange', { loading: busy })}
        disabled={busy}
        type={type}
        {...otherProps}
      >
        <FormattedMessage {...m.submit} />
      </button>
      <Link className="cancel" to={categoryLink(path)}><FormattedMessage {...m.cancel} /></Link>
      {children}
    </div>
  );
}

BoardActions.propTypes = {
  busy: bool,
  children: ChildrenType,
  path: string,
  submitClassName: string
};

BoardActions.defaultProps = {
  busy: false,
  children: null,
  submitClassName: '',
  type: "submit"
};
