import React from 'react';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';

import { showPopup } from '../../../ducks/popup';
import m from '../messages';
import { Classification as dictionary } from '../../../constants';


export default injectIntl(function ClassificationSelect({ value, handleChange, helpLink, intl }) {
  ClassificationSelect.propTypes = {
    handleChange: PropTypes.func,
    value: PropTypes.string
  }

  value = value.toUpperCase();
  const renderHelpLink = () => {
    const dispatch = useDispatch()
    return (
      <button
        type="button"
        className="link btnInline"
        onClick={() => dispatch(showPopup('FAQ'))}
      >
      {intl.formatMessage(m.classificationHelp)}
    </button>
    )
  }

  return (
    <div className='form-filed form-filed--select'>
      <p className='form-filed__title'>
        {intl.formatMessage(m.classification)}
      </p>
      <div className='form-filed__select-wrap'>
        <select value={value} onChange={handleChange} aria-label={intl.formatMessage(m.classification)}>
          { Object.keys(dictionary).map(key => (
              <option value={key} key={key}>
                {intl.formatMessage(m[key])}
              </option>
            ))
          }
        </select>
        { helpLink && renderHelpLink() }
      </div>
    </div>
  )
})


