import React, { useState, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { injectIntl } from 'react-intl';

import { uploadFileBoardInfo, attachemntsSelector } from '../../../../ducks/board-info-edit';
import UploadedList from './uploaded-list';
import UploadLayout from './upload-layout';
import m from '../messages';
import { ACCEPT_FILE_TYPES, MAX_FILE_SIZE } from '../../../../constants';


export default injectIntl(function Attachmnets({ intl }) {
  const attachments = useSelector(attachemntsSelector);
  const [ errSize, setErrorSize ] = useState(false);
  const { path } = useParams();
  const inpFile = useRef(null);

  const dispatch = useDispatch();

  const handleChange = ({ target: { files } }) => {
    if (errSize) setErrorSize(false); // clearing for new upload

    const file = files[0];

    if (file.size > MAX_FILE_SIZE) {
      setErrorSize(true);
      inpFile.current.value = '';  // clearing cache file
      return; 
    }

    dispatch(uploadFileBoardInfo(file, path))
    inpFile.current.value = '';  // clearing cache file
  }

  return (
    <div className='attachments attachments--board-edit'>
      <UploadLayout>
        <div className='attachments__add-image'>
          <button className='attachments__btn' onClick={() => inpFile.current.click()}>
            { intl.formatMessage(m.btnAddAttachmnetTitle) }
          </button>
          <input type='file' ref={inpFile} style={{ dispay: 'none' }} onChange={handleChange} accept={ACCEPT_FILE_TYPES} />
          { errSize && <span className='attachments__size-error'>{ intl.formatMessage(m.errMaxFileSize) }</span> }
        </div>
        <UploadedList attachments={attachments} />
      </UploadLayout>
    </div>
  )
})
