import React from 'react';
import { useSelector } from 'react-redux';
import { FormattedMessage } from 'react-intl';

// data
import {
  errorSelector,
  loadingSelector
} from '../../ducks/thread-page';
import m from './messages';

// templates
import PageLayout from '../../components/hoc/page-layout';
import RequestContent from '../../components/request-boards/content';
import RequestForm from '../../components/request-boards/form';

export default function RequestBoardsPage(_) {
  // data from redux
  const loadingContent = useSelector(loadingSelector);
  const errorContent = useSelector(errorSelector);

  return (
    <PageLayout
      classModificator="request-boards"
      title={<FormattedMessage {...m.title} />}
      loading={loadingContent}
      error={errorContent}
    >
      <div className="request-boards">
        <div className="request__content-wrap">
          <RequestContent />
        </div>
        <div className="request__form-wrap">
          <RequestForm />
        </div>
      </div>
    </PageLayout>
  );
}
