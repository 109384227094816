/* eslint-disable camelcase */
import { defineMessages } from 'react-intl';

export default defineMessages({
  authorName: 'Author:',
  boardPath: 'Board:',
  comment: 'Comment',
  judge: 'Judge Objection',
  message: 'Message',
  modal_path: 'Path:',
  objection_requestDate: 'Objection Date:',
  remove: 'Delete post and all replies',
  title: 'Review Objections'
});
