import React from 'react';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';

import m from '../messages';


export default injectIntl(function SelectField({ value, name, handleChange, labelKey, intl, dictionary }) {
  SelectField.propTypes = {
    handleChange: PropTypes.func,
    value: PropTypes.string,
    dictionary: PropTypes.object
  }

  value = value.toUpperCase();

  return (
    <div className='form-filed form-filed--select'>
      <p className='form-filed__title'>
        {intl.formatMessage(m[labelKey])}
      </p>
      <div className='form-filed__select-wrap'>
        <select name={name} value={value} onChange={handleChange} aria-label={intl.formatMessage(m[labelKey])}>
          { Object.keys(dictionary).map(key => (
              <option value={key} key={key}>
                {intl.formatMessage(m[key])}
              </option>
            ))
          }
        </select>
      </div>
    </div>
  )
})


