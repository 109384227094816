import React from 'react';
import { bool, func, shape } from 'prop-types';
import classNames from 'classnames';
import { useDispatch, useSelector } from 'react-redux';
import { Redirect, withRouter } from 'react-router-dom';
import PageView from '../../common/page-view';
import Progress from '../../common/progress';
import { categoryLink } from '../../utils/old_link';
import { isActiveToolbar } from '../../../ducks/admin-panel';
import { showError } from '../../../ducks/common/alert';
import { spaceless } from '../../../utils/strings';

import {
  loadingSelector,
  userEntitiesSelector,
  isSuperAdminRoleSelector, asAdminAuthSelector
} from '../../../ducks/user';


export function AdminView(
  {    
    children,
    classes,
    allowModerator,
    match: { params: { path } },
    ...props
  }) {
  const dispatch = useDispatch()
  const lodaingUserData = useSelector(loadingSelector);
  const isEnabled = useSelector(isActiveToolbar);
  const currentUser = useSelector(userEntitiesSelector);
  const isSuperAdmin = useSelector(isSuperAdminRoleSelector);
  const authAsAdmin = useSelector(asAdminAuthSelector); // super admin or moderator

  
  const alertError = (message) => dispatch(showError(message));


  const getClassName = () => classNames(spaceless(`admin-view ${classes}`), {
    enabled: isEnabled,
    disabled: !isEnabled
  });

  const accessPermission = allowModerator ? authAsAdmin : isSuperAdmin

  if (lodaingUserData) {
    return (
      <div className={`loading-current-user ${getClassName()}`}>
        <span>Loading permissions...</span>
        <Progress />
      </div>
    )
  }
  
  if (!accessPermission) return <Redirect to={categoryLink(path)} />;

  return (
    <PageView classes={getClassName()} currentUser={currentUser} alertError={alertError} {...props}>
      {children}
    </PageView>
  );


}

AdminView.propTypes = {
  ...PageView.propTypes,
  allowModerator: bool,
  isEnabled: bool,
};

AdminView.defaultProps = {
  ...PageView.defaultProps,
  allowModerator: false,
  currentUser: null,
};


export default withRouter(AdminView);
