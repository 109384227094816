import { take, call, put, spawn } from 'redux-saga/effects';
import { createSelector } from 'reselect';
import { Record } from 'immutable';

import { appName } from '../config';
import api from '../services/static-pages-service';




/**
 * Constants
 */

export const moduleName = 'popup';
export const prefix = `${appName}/${moduleName}`;

export const GET_POPUP_CONTENT_REQUEST = `${prefix}/GET_POPUP_CONTENT_REQUEST`;
export const GET_POPUP_CONTENT_SUCCESS = `${prefix}/GET_POPUP_CONTENT_SUCCESS`;
export const GET_POPUP_CONTENT_FAIL = `${prefix}/GET_POPUP_CONTENT_FAIL`;

export const SET_POPUP_CONTENT = `${prefix}/SET_POPUP_CONTENT`;


export const POPUP_CLOSE = `${prefix}/POPUP_CLOSE`;

/**
 * Reducer
 */

  export const ReducerRecord  = Record({
    opened: false,
    loading: true,
    content: null,
    err: null,
    classValue: ''
  })



export default function reducer(state = ReducerRecord(), { type, payload, err }) {
  switch(type) {
    case GET_POPUP_CONTENT_REQUEST:
        return state.set('opened', true);

    case GET_POPUP_CONTENT_SUCCESS:
      return state
                .set('loading', false)
                .set('content', payload);

    case SET_POPUP_CONTENT:
      return state
              .set('opened', true)
              .set('loading', false)
              .set('classValue', 'full-width')
              .set('content', payload);

    case GET_POPUP_CONTENT_FAIL:
      return state
                .set('loading', false)
                .set('err', err)
                .set('content', null)

    case POPUP_CLOSE:
      return state.clear();
    
    default: return state;
  }
}


/**
 * Action
 */

 export const showPopup = (page) => ({ type: GET_POPUP_CONTENT_REQUEST, payload: page });
 export const closePopup = () => ({ type: POPUP_CLOSE });
 export const setContent = (content) => ({ type: SET_POPUP_CONTENT, payload: content });

/**
 * Selectors
 */

 export const stateSelector = state => state[moduleName];

 export const isOpenedSelector = createSelector(
  stateSelector,
  state => state.opened
 );

 export const loadingSelector = createSelector(
  stateSelector,
  state => state.loading
 )
 export const classValueSelector = createSelector(
  stateSelector,
  state => state.classValue
 )

  export const contentSelector = createSelector(
    stateSelector,
    state => state.content
  )
  
  export const errorSelector = createSelector(
    stateSelector,
    state => state.err
  )


/**
 * Sagas
 */

export const getPopupContentSaga = function* () {
  while(true) {
    const { payload: page } = yield take(GET_POPUP_CONTENT_REQUEST);
      try {
        const { data: htmlContent } = yield call(api.getPopupContent, page);
        yield put({ type: GET_POPUP_CONTENT_SUCCESS, payload: htmlContent });
      } catch(err) {
        yield put({ type: GET_POPUP_CONTENT_FAIL, err })
      }
  }
}

 export const saga = function* () {
    yield spawn(getPopupContentSaga);
 }