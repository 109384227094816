import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
// links view
import {
  ReplyLink,
  AbuseLink,
  PrintLink,
  EditLink,
  MoveLink,
  BtnDeletePost,
  BtnDeletePostsAndReplies
} from './links';
import m from '../messages';


export default function AuthenticatedList({ path, threadId, userMarkedDeleted, isUserAuthorThread, activeToolbar, asAdminAuth, isReadOnly }) {
  function renderAuthorItems() {

    if (!asAdminAuth && !userMarkedDeleted) return (
      <nav className='thread-tool'>
          <ul className='thread-tool__list'>
            {!isReadOnly && <ReplyLink path={path} threadId={threadId} />}
            <AbuseLink path={path} threadId={threadId} />
            <PrintLink path={path} threadId={threadId} />
            {!isReadOnly && <EditLink path={path} threadId={threadId} />}
            <BtnDeletePost path={path} threadId={threadId} />
          </ul>
        </nav>
      )

      if (!asAdminAuth && userMarkedDeleted) return (
        <nav className='thread-tool'>
          <div className='thread-tool__admin'>
          <FormattedMessage {...m.admin}/>
            <ul className='thread-tool__list thread-tool__list--admin'>
              <BtnDeletePostsAndReplies path={path} threadId={threadId} />
            </ul>
          </div>
        </nav>
      )




    if (activeToolbar && !userMarkedDeleted) return (
      <nav className='thread-tool'>
        <ul className='thread-tool__list'>
          {!isReadOnly && <ReplyLink path={path} threadId={threadId} />}
          <AbuseLink path={path} threadId={threadId} />
          <PrintLink path={path} threadId={threadId} />
          {!isReadOnly && <EditLink path={path} threadId={threadId} />}
          <BtnDeletePost path={path} threadId={threadId} />
        </ul>
        <div className='thread-tool__admin'>
          <FormattedMessage {...m.admin}/>
          <ul className='thread-tool__list thread-tool__list--admin'>
            <MoveLink path={path} threadId={threadId} />
            <BtnDeletePostsAndReplies path={path} threadId={threadId} />
          </ul>
        </div>
      </nav>
    )

    if (activeToolbar && userMarkedDeleted) return (
      <nav className='thread-tool'>
        <div className='thread-tool__admin'>
          <FormattedMessage {...m.admin}/>
          <ul className='thread-tool__list thread-tool__list--admin'>
            <BtnDeletePostsAndReplies path={path} threadId={threadId} />
          </ul>
        </div>
      </nav>
    )

    // disabled admin center
    if (!activeToolbar && !userMarkedDeleted) {
      return (
        <nav className='thread-tool'>
          <ul className='thread-tool__list'>
            {!isReadOnly && <ReplyLink path={path} threadId={threadId} />}
            <AbuseLink path={path} threadId={threadId} />
            <PrintLink path={path} threadId={threadId} />
            {!isReadOnly && <EditLink path={path} threadId={threadId} />}
            <BtnDeletePost path={path} threadId={threadId} />
          </ul>
        </nav>
      )
    }

    return null; // By default and for case if !activeToolbar && userMarkedDeleted
  }



  function renderAlienItems() {
    if (activeToolbar && !userMarkedDeleted) return (
      <nav className='thread-tool'>
        <ul className='thread-tool__list'>
          {!isReadOnly && <ReplyLink path={path} threadId={threadId} />}
          <AbuseLink path={path} threadId={threadId} />
          <PrintLink path={path} threadId={threadId} />
        </ul>
        <div className='thread-tool__admin'>
          <FormattedMessage {...m.admin}/>
          <ul className='thread-tool__list thread-tool__list--admin'>
            {!isReadOnly && <EditLink path={path} threadId={threadId} />}
            <MoveLink path={path} threadId={threadId} />
            <BtnDeletePost path={path} threadId={threadId} />
            <BtnDeletePostsAndReplies path={path} threadId={threadId} />
          </ul>
        </div>
      </nav>
    )

    if (activeToolbar && userMarkedDeleted) return (
      <nav className='thread-tool'>
        <div className='thread-tool__admin'>
          <FormattedMessage {...m.admin}/>
          <ul className='thread-tool__list thread-tool__list--admin'>
            <BtnDeletePostsAndReplies path={path} threadId={threadId} />
          </ul>
        </div>
      </nav>
    )

    // disabled admin center
    if (!activeToolbar && !userMarkedDeleted) return (
      <nav className='thread-tool'>
        <ul className='thread-tool__list'>
          {!isReadOnly && <ReplyLink path={path} threadId={threadId} />}
          <AbuseLink path={path} threadId={threadId} />
          <PrintLink path={path} threadId={threadId} />
        </ul>
      </nav>
    )

    return null; // By default and for case if !activeToolbar && userMarkedDeleted
  }


  return (
    <>
      { isUserAuthorThread && renderAuthorItems() }
      { !isUserAuthorThread && renderAlienItems() }
    </>
  )
}


AuthenticatedList.propTypes = {
  path: PropTypes.string,
  threadId: PropTypes.string,
  userMarkedDeleted: PropTypes.bool,
  isUserAuthorThread: PropTypes.bool,
  activeToolbar: PropTypes.bool
}

