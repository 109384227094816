import React, { useRef } from 'react'; 
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';

import m from './messages';


export default function FiledSurnames({ name, handleChange, value = '', readOnly = false, placeholder }) {
  const fieldProps = { 
    type: 'text',
    name,
    className: `form-filed__inp${ readOnly ? ' readonly' : '' }`,
    value, 
    readOnly 
  }

  if (!readOnly && handleChange) fieldProps.onChange = handleChange;
  if (placeholder) fieldProps.placeholder = placeholder;

  return (
    <div className='form-filed'>
      <p className='form-filed__title'>
        <FormattedMessage {...m.surnames} />
      </p>
      <input { ...fieldProps } />
    </div>
  )
}

FiledSurnames.propTypes = {
  handleChange: PropTypes.func,
  value: PropTypes.string,
  readOnly: PropTypes.bool,
}