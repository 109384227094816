import React from 'react';
import classNames from 'classnames';
import PageModel from './model';
import { string } from 'prop-types';
import { FormattedMessage } from 'react-intl';
import m from './messages';

export default function PageSize({ classes, ...props }) {
  const { model } = PageModel.initProps(props);
  const className = classNames(`page-size ${classes}`.trim(), {
    single: model.isSingle
  });

  const options = model.sizes.map(
    ({ name, value }) => (
      <option key={name} value={value}>
        {value}
      </option>
    )
  );

  return (
    <div className={className}>
      <select
        aria-labelledby="itemperpage"
        disabled={model.busy}
        value={model.itemsPerPage}
        onChange={e => model.change(model.createSibling(1, +e.target.value))}
      >
        {options}
      </select>
      <span id="itemperpage" className="per-page-text"><FormattedMessage {...m.resultsPerPage} /></span>
    </div>
  );
}

PageSize.propTypes = {
  classes: string
};

PageSize.defaultProps = {
  classes: ''
};
