import { call, put, select } from 'redux-saga/effects';
import { appName } from '../../config';
import { isObject, pick } from '../../../utils';
import { removeBusy } from '../../ducks/common/busy';
import { showError } from '../../ducks/common/alert';

export function prefix(string) {
  return function hasPrefix(text) {
    return text.indexOf(string) === 0;
  };
}

export function suffix(string) {
  return function hasSuffix(text) {
    return text.lastIndexOf(string) === text.length - string.length;
  };
}

export function and(...predicates) {
  return function every(...args) {
    return predicates.every(predicate => predicate(...args));
  };
}

export function or(...predicates) {
  return function some(...args) {
    return predicates.some(predicate => predicate(...args));
  };
}

const project = prefix(`${appName}/`);

const suffixes = (...args) => and(project, or(...args.map(suffix)));

export const predicates = {
  project,
  request: suffixes('/REQUEST'),
  receive: suffixes('/RECEIVE', '/SUCCESS'),
  fail: suffixes('/FAIL', '/ERROR')
};

predicates.done = or(
  predicates.receive,
  predicates.fail
);

export function action(type, payload, next) {
  return {
    type,
    payload,
    next
  };
}

export const setterAction = type => (name, value) => action(type, { name, value });

export function actionPrefixOf(actionType) {
  return actionType.split('/').slice(0, -1).join('/');
}

export function* clearBusy(actionType) {
  yield put(removeBusy(actionPrefixOf(actionType)));
}

export function* catchError(actionType, err) {
  yield clearBusy(actionType);
  yield put(showError(err));
}

/**
 * @param {object|array} object
 * @returns {object|array}
 */
export function freeze(object) {
  (Array.isArray(object) ? object : Object.values(object))
    .filter(object => typeof object === 'object' && object !== null)
    .forEach(freeze);
  return Object.freeze(object);
}

export function fetching(serve, success, convert = v => v) {
  return function* ({ payload }) {
    try {
      const { data } = yield call(...serve(payload));
      yield put({ type: success, payload: convert(data, payload) });
    } catch (err) {
      if (isObject(payload)) {
        if (payload.ignoreError) {
          yield clearBusy(success);
          return;
        } else if (payload.errorActionType) {
          yield clearBusy(success);
          yield put({
            type: payload.errorActionType,
            payload: err
          });
          return;
        }
      }
      yield catchError(success, err);
    }
  };
}

export function queryFetching(selectQuery, serve, success, convert) {
  return function* (action) {
    const query = action.payload || selectQuery(yield select());
    yield fetching(
      () => serve(query),
      success,
      data => convert(data, query)
    )(action);
  };
}

export function assignQueryPaging(itemsName) {
  return function (data, query) {
    query.assignPaging(data.paging);
    return {
      items: data[itemsName],
      query
    };
  };
}

export function* chain(...actions) {
  for(const action of actions) {
    yield put(action);
  }
}

export function modification(serve, success, error = exception => catchError(success, exception)) {
  return function* ({ payload, next }) {
    try {
      const { headers, status } = yield call(...serve(payload));
      const successAction = {
        type: success,
        status,
        headers: pick(headers, 'location'),
        payload
      };
      yield put(successAction);
      if (next) {
        if (Array.isArray(next)) {
          yield chain(...next);
        } else {
          yield put(next);
        }
      }
    } catch (exception) {
      yield error(exception);
    }
  };
}

export function plainObjectOf(record, method = 'toJSON') {
  if (isObject(record) && typeof record[method] === 'function') {
    const object = record[method]();
    for (const key in object) {
      const value = object[key];
      const converted = plainObjectOf(value, method);
      if (value !== converted) {
        object[key] = converted;
      }
    }
    return object;
  }
  return record;
}
