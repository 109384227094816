import { defineMessages } from 'react-intl';

export default defineMessages({
  alertInfo: 'Receive notifications of recent posts made to boards you specify.',
  confirm: 'Are you sure you want to remove {name} from the list of Alerts?',
  lastPost: 'Last Post',
  messageBoardAlerts: 'Message Board Alerts',
  name: 'Name',
  tools: 'Tools',
  cancel: 'Cancel',
  yes: 'Yes'
});
