import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';

import m from '../messages';
import Pagination from '../../common/pagination';
import UserLink from '../../common/user-link';
import { convertToDate } from '../../utils';
import NotiveEmpty from '../notice-empty';


export default function TableThread({ threads, messages, items, paging }) {
  const hasItems = !!items.length;

  const renderTableHead = () => (
    <thead>
      <tr className='table__row table__row--header'>
        <th className='table__col'><FormattedMessage {...m.threads} /></th>
        <th className='table__col'><FormattedMessage {...m.author} /></th>
        <th className='table__col'><FormattedMessage {...m.replies} /></th>
        <th className='table__col'><FormattedMessage {...m.lastPost} /></th>
      </tr>
    </thead>
  )


  const renderTableBody = () => items.map(rowItem => (
    <tr className='table__row' key={rowItem.id}>
      <td className='table__col'>
        <Link className='table__link' to={`/${rowItem.path}/${rowItem.id}`}>
          {rowItem.subject}
        </Link>
      </td>
      <td className='table__col'>
        <UserLink userData={rowItem.user} />
      </td>
      <td className='table__col'>{rowItem.repliesCount}</td>
      <td className='table__col'>{rowItem.lastPost && convertToDate(rowItem.lastPost)}</td>
    </tr>
  ))


  const renderBodyTitle = () => (
    <div className='table__meta-info'>
      <span className='table__meta-data'>
        <FormattedMessage {...m.threads} />: {threads}
      </span>
      {' - '}
      <span className='table__meta-data'>
        <FormattedMessage {...m.messages} />: {messages}
      </span>
    </div>
  )

  const renderBodyContent = () => (
    <>
      {renderTableHead()}
      <tbody className='table__body'>
        {renderTableBody()}
      </tbody>
    </>
  )

  return (
    <>
      {(Array.isArray(items) && items.length)
        ? (
          <>
            {renderBodyTitle()}
            <table className='table table--thread'>
              {renderBodyContent()}
            </table>
            {paging && <Pagination hasItems={hasItems} paging={paging} countType='hundred' />}
          </>
        )
        : <NotiveEmpty board />
      }
    </>
  )
}

TableThread.propTypes = {
  threads: PropTypes.number,
  messages: PropTypes.number,
  tools: PropTypes.object,
  items: PropTypes.array,
  paging: PropTypes.object
}

