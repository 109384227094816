import React from 'react';
import PropTypes from 'prop-types';
import { faFolderOpen } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { FormattedMessage } from 'react-intl';

import m from './messages';



export default function TitleCategorie({ title }) {
  
  return (
    <h2 className='categories__title'>
    { title
      ? (<>
          <FontAwesomeIcon icon={faFolderOpen} />
          {title}
        </>)
      : <FormattedMessage {...m.category} />
      }
    </h2>
  )
}

TitleCategorie.defaultProps = {
  title: ''
}

TitleCategorie.propTypes = {
  title: PropTypes.string
}