/**
 * Client is the entry point for the client side application.
 * @module client
 */
import 'es6-shim';
import React from 'react';
import Loadable from 'react-loadable';
import ReactDOM from 'react-dom';
import { ABTestProvider } from '@ancestry/utils-components-ancestry';
import { ConnectedRouter } from 'connected-react-router';
import { Provider } from 'react-redux';

import './sass/style.scss';
import config from './config';
import history from './configureHistory';
import initDevelopmentTools from './devtools';
import localeData from '../utils/locale';
import Root from './Root';
import settings from './local-settings';
import store from './redux/store';
import { IntlProvider } from 'react-intl';
import { loadMessages, Locales } from './utils/load-messages';

localeData();

// For debug purposes
let localeCode = settings.get('locale');
if (localeCode && !Locales[localeCode]) {
  localeCode = '';
}
if (!localeCode) {
  localeCode = config.locale;
}
const locale = loadMessages(localeCode);
const abTests = config.abTests;
// is SSR use hydrate, otherwise render
const render = config.isClientSide ? ReactDOM.render : ReactDOM.hydrate;

initDevelopmentTools(store);

/**
 * Entry point for the application which renders the app and connects it to the
 * Redux store and react-intl IntlProvider.
 * @type {Object}
 */
Loadable.preloadReady()
  .then(() => {
    render(
      <Provider store={store}>
        <IntlProvider locale={locale.locale} messages={locale.messages}>
          <ABTestProvider value={abTests}>
            <ConnectedRouter history={history}>
              <Root />
            </ConnectedRouter>
          </ABTestProvider>
        </IntlProvider>
      </Provider>,
      document.getElementById('main')
    );
  });
