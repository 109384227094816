import React, { useEffect, useState } from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import m from './messages';
import { trackPageView } from '../../utils/tealium';
import Pagination from '../../components/common/pagination';
import { convertToDate } from '../../components/utils';
import PageLayout from '../../components/hoc/page-layout';
import { deletingSelector, entitiesSelector, errorSelector, fetchAlerts, loadingSelector } from '../../ducks/alerts';
import useQuery from '../../hooks/use-query-params';
import ProcessLayout from '../../components/hoc/process-layout';
import AlertConfirmDelete from './alert-confirm-delete';


export default injectIntl(_ => {
  const [itemForDelete, setItemForDelete] = useState(null);

  const dispatch = useDispatch();
  const loading = useSelector(loadingSelector);
  const deletingProcess = useSelector(deletingSelector);
  const { alerts, paging } = useSelector(entitiesSelector) || [];
  const error = useSelector(errorSelector);

  const { itemsPerPage, page } = useQuery();
  const hasItems = !!alerts.length;

  useEffect(() => {
    if(!page && !itemsPerPage){
      dispatch(fetchAlerts(1, 'TEN'));
    }
    dispatch(fetchAlerts(page, itemsPerPage));
  }, [page, itemsPerPage]);

  useEffect(() => trackPageView('alert'), []);

  // if (error) {
  //   return <ErrorView message={'Something wrong!'} />
  // }

  function getLastPost(lastPost) {
    if (lastPost) {
      return convertToDate(lastPost);
    }
    return null;
  }

  let rows = <div className="table__row"/>;
  if (alerts && alerts.length > 0) {
    rows = alerts.map(item => (
      <div key={item.path} className="table__row">
        <div className="table__col">
          <Link to={item.path}>{item.title}</Link>
        </div>
        <div className="table__col">
          {getLastPost(item.lastPost)}
        </div>
        <div className="table__col">
          <button type="button" className="icon iconTrash link" onClick={() => setItemForDelete(item)} />
        </div>
      </div>
    ));
  }

  return (
    <PageLayout
      title={<FormattedMessage {...m.messageBoardAlerts} />}
      description={<FormattedMessage {...m.alertInfo} />}
    >
      <ProcessLayout loading={loading || deletingProcess} error={error}>
        <div className="table table--alerts">
          <div className="table__row table__row--header">
            <div className="table__col"><FormattedMessage {...m.name} /></div>
            <div className="table__col"><FormattedMessage {...m.lastPost} /></div>
            <div className="table__col"><FormattedMessage {...m.tools} /></div>
          </div>
          {rows}
        </div>
        <Pagination hasItems={hasItems} paging={paging} />
        {itemForDelete && <AlertConfirmDelete itemForDelete={itemForDelete} setItemForDelete={setItemForDelete} />}
      </ProcessLayout>
    </PageLayout>
  );
});
