import { call, put, spawn, take } from 'redux-saga/effects';
import { Record } from 'immutable';
import { createSelector } from 'reselect';

import { appName } from '../config';
import api from '../services/searches-service';

/**
 * Constants
 */

export const moduleName = 'search-adv-result';
export const prefix = `${appName}/${moduleName}`;

export const GET_RESULT_REQUEST = `${prefix}/GET_RESULT_REQUEST`;
export const GET_RESULT_SUCCESS = `${prefix}/GET_RESULT_SUCCESS`;
export const GET_RESULT_FAIL = `${prefix}/GET_RESULT_FAIL`;

export const RESET_RESULT_SEARCH = `${prefix}/RESET_RESULT_SEARCH`;



/**
 * Reducer
 */


  export const EntitiesBoardsRecord = Record({
    description: null,
    items: [],
    paging: null,
  })

  export const ReducerRecord = Record({
    loading: false,
    entitiesBoards: EntitiesBoardsRecord(),
    nodeName: null,
    err: null
  })

 export default function reducer(state = ReducerRecord(), { type, payload, err }) {
  switch(type) {
    case GET_RESULT_REQUEST:
      return state.set('loading', true)

    case GET_RESULT_SUCCESS:
      return state
                .set('loading', false)
                .set('entitiesBoards', EntitiesBoardsRecord(payload.boards))
                .set('nodeName', payload.nodeName)
                .set('err', null)

    case GET_RESULT_FAIL:
      return state
              .set('loading', false)
              .set('err', err)

    case RESET_RESULT_SEARCH:
      return state.clear()

    default:
      return state;
  }
 }

/**
 * Actions
 */
 export const fetchSearchResult = (data) => ({ type: GET_RESULT_REQUEST, payload: data });
 export const resetSearchResult = () => ({ type: RESET_RESULT_SEARCH });

 /**
 * Selectors
 */
export const stateSelector = state => state[moduleName];
export const loadingSelector = createSelector(
  stateSelector, state => state.loading
);

export const nodeNameSelector = createSelector(
  stateSelector, state => state.nodeName
);

export const entitiesBoardsSelector = createSelector(
  stateSelector, state => state.entitiesBoards
);

export const errorSelector = createSelector(
  stateSelector, state => state.err
);



 /**
 * Sagas
 */

 export const fetchSearchResultSaga = function* () {
  while(true) {
    const { payload } = yield take(GET_RESULT_REQUEST);

    try {
      const { data } = yield call(api.getAdvancedSearch, payload);
      yield put({ type: GET_RESULT_SUCCESS, payload: data })
    } catch(err) {
      yield put({ type: GET_RESULT_FAIL, err })
    }
  }
 }

 export const saga = function* () {
   yield spawn(fetchSearchResultSaga)
 }