import React from 'react';
import { Link } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';

import m from './messages';

export default function EmptyMessages(_) {
  const link = <Link to="/"><FormattedMessage {...m.searchLink} /></Link>;

  return (
    <div className="favorites-full__messages">
      <div className="favorites-full__messages-text">
        <FormattedMessage {...m.noFavorites} values={{ link }} />
      </div>
    </div>
  );
}
