import { useLocation } from 'react-router-dom';

export default function useQuery() {
  const urlQueries = new URLSearchParams(useLocation().search);
  let obj = {}
  for ( let entries of urlQueries.entries()) {
    obj[entries[0]] = entries[1];
  }
  return obj;
}
