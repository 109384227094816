import React from 'react';
import PropTypes from 'prop-types';

import PrevNextLinks from './prev-next'
import ThreadHeader from './header';
import ThreadBody from './body';
import { ROLE_TYPES } from '../../constants';
import { FormattedMessage } from 'react-intl';
import m from '../../pages/category/messages';
import { useSelector } from 'react-redux';
import { isAdminRoleSelector, userEntitiesSelector } from '../../ducks/user';
import { isActiveToolbar } from '../../ducks/admin-panel';
import { useParams } from 'react-router-dom';



export default function ThreadView(props) {
  // routing
  const { path } = useParams();

  const isGuest = props && props.userRole === ROLE_TYPES.guest;

  // user data
  const activeToolbar = useSelector(isActiveToolbar);
  const isAdmin = useSelector(isAdminRoleSelector);
  const { adminNodes } = useSelector(userEntitiesSelector);

  const getAdminMessage = () => {
    const isAdminOfCurrentPath = adminNodes.find(node => node.path === path);
    const messageKey = isAdminOfCurrentPath ? 'areAdmin' : 'notAdmin';
    return <div className={`thread__admin-board ${isAdminOfCurrentPath ? 'icon iconSettings': ''}`}><FormattedMessage {...m[messageKey]} /></div>
  }

  return (
    <section className='thread'>
      {adminNodes && activeToolbar && isAdmin && getAdminMessage()}
      <PrevNextLinks {...props} classModificator='top' />
      {props.thread && <ThreadHeader
        optionsModeration={props.options.moderation}
        isGuest={isGuest}
        isReadOnly={!props.options.posts}
        parentSubject={props.parentSubject}
        rootRepliesCount={props.propsrepliesCount}
        {...props.thread}/> }
      <ThreadBody {...props} />
      <PrevNextLinks {...props} classModificator='bottom' />
    </section>
  )
}


ThreadView.propTypes = {
  prevThreadId: PropTypes.string,
  nextThreadId: PropTypes.string,
  parentSubject: PropTypes.string,
  repliesCount: PropTypes.number,
  thread: PropTypes.object,
  replies: PropTypes.arrayOf(PropTypes.object),
  repliesPaging: PropTypes.object
}
