import React from 'react';
import PropTypes from 'prop-types';
import { Link, useParams } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCommentMedical } from '@fortawesome/free-solid-svg-icons';

import m from './messages';
import url from '../../../url-service';
import config from '../../../config';


export default function LinkNewThread({ id, type }) {
  const { path } = useParams();
  const { institution } = config;

if (institution) { //US188005:remove add thread from institutional accounts - Analysis
  return null;
}
  return (
    <Link className="thread-link" to={url.createPath('thread', path, id, type)}>
      <span className="thread-link__icon">
        <FontAwesomeIcon icon={faCommentMedical} />
      </span>
      <span className="thread__new-text">
        <FormattedMessage {...m.beginNewThread} />
      </span>
    </Link>
  );
} 


LinkNewThread.defaultProps = {
  type: 'create'
}
LinkNewThread.propTypes = {
  id: PropTypes.number,
  type: PropTypes.string
}