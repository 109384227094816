import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useHistory } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';
import m from '../messages';

import { layoutSelector, LAYOUT_TREE_VIEW, LAYOUT_VIEW_FLAT, changeThreadLayout } from '../../../ducks/thread-page';
import { addOrReplaceQueryUrl } from '../../utils';


export default function ToggleBtnView(_) {
  const dispatch = useDispatch();
  const history = useHistory();
  const { pathname, search } = useLocation()

  const layoutView = useSelector(layoutSelector);

  const handleDispatch = (viewType) => () => {
    dispatch(changeThreadLayout(viewType))
    const searchQuery = addOrReplaceQueryUrl(search, { viewType });
    history.push(`${pathname}?${searchQuery}`)
  }
  
  const viewType = layoutView === LAYOUT_TREE_VIEW ? LAYOUT_VIEW_FLAT : LAYOUT_TREE_VIEW;
  return (
    <button className='thread__btn-toggle' onClick={handleDispatch(viewType)}>   
    { layoutView === LAYOUT_TREE_VIEW
      ? <FormattedMessage {...m.floatView} /> 
      : <FormattedMessage {...m.threadView} /> 
    }
    </button>    
  )
}