import HttpServices from './index';

class MediasService extends HttpServices {
  uploadFiles = filesStream => {
    const data = new FormData();
    filesStream.forEach(file => data.append('files', file, file.name));
    return this.http.post('/medias/multiple', data);
  }

  uploadFile = (filesStream) => {
    const data = new FormData();
    data.append('file', filesStream, filesStream.name);
    return this.http.post('/medias', data);
  }

}


export default new MediasService();
