import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { useSelector } from 'react-redux';
import url from '../../../url-service';
// image by default
import unknowUserIcon from '../../../assets/usericon.gif';
import { getTypeOf } from '../../utils';
import config from '../../../config';
import m from './messages';
import { asAuthenticatedSelector } from '../../../ducks/user';


export default function UserLink({ userData }) {
  const isLoggedIn = useSelector(asAuthenticatedSelector);
  
  if (!userData || getTypeOf(userData) !== "Object") return (
    <span className='user-link user-link--anonymous'>
      <img className='user-link__img' src={unknowUserIcon} alt=''/>
      <span className='user-link__name'>
        <FormattedMessage {...m.anonymous} />
      </span>
    </span>
  )

  const { userId, imageId, displayName } = userData;
  const { institution } = config
  const imgSrc = (imageId && url.getImageIdToLink(imageId)) || unknowUserIcon;

  if (institution) return  <span className='user-link__name'>{displayName}</span>
  if (!userId || !isLoggedIn) return (
    <span className='user-link'>
       <img className='user-link__img' src={unknowUserIcon} alt=''/>
      <span className='user-link__name'>{displayName}</span>
    </span>
  )
  return (
    <a className='user-link' href={url.createPath('profile', userId)} target='_blank'>
      <img className='user-link__img' src={imgSrc} alt=''/>
      <span className='user-link__name'>{displayName}</span>
    </a>
  )
}


UserLink.propTypes = {
  userData: PropTypes.object
}