/* eslint-disable react/destructuring-assignment,react/forbid-prop-types */
import React from 'react';
import Field from '../../../common/field';
import { FormattedMessage } from 'react-intl';
import m from './messages';
import { func, object, shape, string } from 'prop-types';

export default class NodeEditFieldset extends React.PureComponent {
  constructor(props) {
    super(props);
    // Mark unmodified fields
    this.state = Object.keys(props.fields).reduce((state, name) => {
      state[name] = false;
      return state;
    }, {});
  }

  setValue = (name, value) => {
    this.props.focus()
    this.props.set(name, value);
    this.setState({ [name]: true });
  };

  render() {
    let { fields, messages, errors } = this.props;
    const items = Object.keys(fields).map(name => {
      const translationMessage = messages[name];
      if (translationMessage) {
        const { value, ...props } = fields[name];
        const errorMessage = value.trim() || !this.state[name] ? '' : <FormattedMessage {...m.required} />;
        return (
          <Field
            key={name}
            label={<FormattedMessage {...translationMessage} />}
            message={(errors && errors[name]) || errorMessage}
            name={name}
            setValue={value => this.setValue(name, value)}
            validationClasses="required"
            value={value}
            {...props}
          />
        );
      }
      return null;
    });
    return (
      <>
        {items}
      </>
    );
  }
}

NodeEditFieldset.propTypes = {
  fields: object,
  messages: object,
  set: func.isRequired,
  errors: shape({
    name: string,
    path: string
  })
};

NodeEditFieldset.defaultProps = {
  errors: {}
};
