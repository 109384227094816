import React from 'react';
import { injectIntl } from 'react-intl';
import { useLocation, useHistory } from 'react-router-dom';


import { ASCENDING, DESCENDING } from '../../ducks/thread-page';
import useQuery from '../../hooks/use-query-params';
import m from './messages';
import { addOrReplaceQueryUrl } from '../utils';

export default injectIntl(function SortRepliesView({ intl }) {
  const { sortDirection } = useQuery();
  const history = useHistory();
  const { pathname, search } = useLocation()

  const handleChange = ({ target: { value } }) => {
    const searchQuery = addOrReplaceQueryUrl(search, { sortDirection: value });
    history.push(`${pathname}?${searchQuery}`)
  }

  const directionValue = sortDirection || ASCENDING;

  return (
    <div className='thread__select'>
      <select value={directionValue} onChange={handleChange}>
        <option value={ASCENDING}>
          {intl.formatMessage(m.oldestFirst)}
        </option>
        <option value={DESCENDING}>
            {intl.formatMessage(m.newestFirst)}
        </option>
      </select>
    </div>
  )
})
