import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams, Link } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';

import { submitBoardInfo, attachemntsSelector } from '../../../ducks/board-info-edit';
import { setContent } from '../../../ducks/popup';
import { useContent } from './context-content';
import url from '../../../url-service';
import m from './messages';
import { transformAttachmentIdToImgPath } from '../../utils';


export default function ButtonsControl() {
  const dispatch = useDispatch();
  const { path } = useParams();
  const { content } = useContent();
  const attachments = useSelector(attachemntsSelector);

  const handleButtonSubmit = () => dispatch(submitBoardInfo(path, content));
  const handleButtonPreview = () => {
    const newContent = transformAttachmentIdToImgPath(attachments, content);
    dispatch(setContent(newContent));
  }
  const cancelPath = url.createPath('category', path);
  
  return (
    <>
      <div className='board-edit__btn-submit'>
        <button className='btn btn--submit' type='button' onClick={handleButtonSubmit}>
          <FormattedMessage {...m.btnSubmit} />
        </button>
      </div>
      <div className='board-edit__btn-cancel'>
        <Link className='link link--cancel' to={cancelPath}>
          <FormattedMessage {...m.btnCancel} />
        </Link>
      </div>
      <div className='board-edit__btn-preview'>
        <button className='btn btn--link' type='button' onClick={handleButtonPreview}>
          <FormattedMessage {...m.btnPreview} />
        </button>
      </div>
    </>
  )
}