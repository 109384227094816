import React, { useEffect } from 'react';
import classNames from 'classnames';
import { connect } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import { withRouter } from 'react-router-dom';
import AdminView from '../../view';
import Alert from '../../../common/alert';
import Busy from '../../../common/busy';
import CategoryRow from './category-row';
import m from './messages';
import PageLinkModel from '../../../common/old-pagination/model';
import PageNumber from '../../../common/old-pagination/number';
import pageSizes, { getSizes } from '../../../common/paging/mock';
import Progress from '../../../common/progress';
import Section from '../../../common/old-section';
import ThreadRow from './thread-row';
import { isBusy } from '../../../../ducks/common/busy';
import { requestCachedCategory, selectCategoryBreadcrumbs, selectIsCategory } from '../../../../ducks/category';
import {
  fetchPrefix,
  requestRecycleBin,
  requestRestoreNode,
  selectRecycleBin
} from '../../../../ducks/recycle-bin';
import { trackPageView } from '../../../../utils/tealium';

const Empty = () => (
  <Alert type="info" text={<FormattedMessage {...m.empty} />} />
);

export function RecycleBin(
  {
    breadcrumbs,
    busy,
    fetch,
    fetchCategory,
    isCategory,
    items,
    match,
    page,
    restore,
  }) {
  useEffect(() => { fetch(1, match.params.path); }, [match.params.path]);
  useEffect(() => { fetchCategory(match.params.path); }, [match.params.path]);
  useEffect(() => trackPageView(`recycle-bin : ${match.params.path}`), []);

  let content;
  let isEmpty = false;
  if (items) {
    if (items.length > 0) {
      const rows = items.map(item => {
        const Row = item.type === 'thread' ? ThreadRow : CategoryRow;
        return (
          <Row
            key={`${item.path}/${item.threadId || ''}`}
            remove={() => restore(item.path, item.threadId, requestRecycleBin(1, match.params.path))}
            {...item}
          />
        );
      });

      const pageModel = new PageLinkModel({
        currentPage: page,
        busy,
        sizes: getSizes(pageSizes.HUNDRED)
      });

      content = (
        <div className="ancGrid ancGridPadded">
          <div className="ancCol w100">
            <Alert />
            <Section>
              <PageNumber classes="top" model={pageModel} />
              <Busy>
                <table className="table">
                  <thead>
                    <tr>
                      <th className="name"><FormattedMessage {...m.name} /></th>
                      <th className={isCategory ? 'created' : 'author'}>
                        <FormattedMessage {...(isCategory ? m.createDate : m.author)} />
                      </th>
                      <th className="deleted"><FormattedMessage {...m.deleteDate} /></th>
                      <th className="actions" />
                    </tr>
                  </thead>
                  <tbody>
                    {rows}
                  </tbody>
                </table>
              </Busy>
              <PageNumber classes="bottom" model={pageModel} />
            </Section>
          </div>
        </div>
      );
    } else {
      content = (
        <div className="con">
          <div className="conBody">
            <p
              className="alert alertInfo"
              role="alertdialog"
              aria-label={<FormattedMessage {...m.empty} />}
              tabIndex="-1"
            >
              <FormattedMessage {...m.empty} />
            </p>
          </div>
        </div>);
      isEmpty = true;
    }
  } else if (busy) {
    content = <Progress />;
  } else {
    content = <Empty />;
  }

  const className = classNames('recycle-bin', {
    'type-category': isCategory,
    'type-board': !isCategory,
    empty: isEmpty
  });

  return (
    <AdminView
      breadcrumbs={breadcrumbs}
      classes={className}
      title={<FormattedMessage {...m.recycleBin} />}
      allowModerator
    >
      {content}
    </AdminView>
  );
}

function mapStateTopProps(state) {
  return {
    ...selectRecycleBin(state).toJSON(),
    breadcrumbs: selectCategoryBreadcrumbs(state),
    busy: isBusy(state, `${fetchPrefix}/`),
    isCategory: selectIsCategory(state)
  };
}

const mapDispatchToProps = {
  fetch: requestRecycleBin,
  fetchCategory: requestCachedCategory,
  restore: requestRestoreNode
};

export default connect(mapStateTopProps, mapDispatchToProps)(withRouter(RecycleBin));
