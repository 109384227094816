import { defineMessages } from 'react-intl';

export default defineMessages({
  adminInfo: 'Admin Info',
  administerNodes: 'You are registered to administer the following boards ({shown} of {total} shown):',
  backToBoards: 'Exit Admin Center',
  boardAdminNews: 'Board Administrator News',
  collapseList: 'Collapse List',
  expandList: 'Expand List',
  listBoards: 'List Boards',
  loading: 'Loading...',
  manageSpam: 'Manage Spam',
  myAlerts: 'My Alerts',
  new: 'New',
  objections: '({number} objections)',
  reviewBoards: 'Review Boards',
  reviewObjections: 'Review Objections',
  reviewRights: 'Review Rights',
  title: 'Message Boards Admin Center',
  viewAllNews: 'View All News'
});
