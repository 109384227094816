import { defineMessages } from 'react-intl';

export default defineMessages({
  textFieldLabel: 'Edit Board Information',
  btnResetTitle: 'Reset to default Board Information',
  btnAddAttachmnetTitle: 'Add an Attachment',
  btnInsertHtmlTitle: 'Insert Image in Html',
  btnRemoveTitle: 'Remove Attachment',
  errMaxFileSize: 'File size is too large',
  btnCancel: 'Cancel',
  btnSubmit: 'Submit',
  btnPreview: 'Preview',
});
