import HttpServices from './index';

class AlertsServices extends HttpServices {
  getAlerts = (page = 1, count = 'TEN') => this.http.get(`/alerts?page=${page}&itemsPerPage=${count}`);

  createAlert = boardPath => this.http.post('/alerts', null, this._setHeaderBoardsPath(boardPath));

  deleteAlert = boardPath => this.http.delete('/alerts', this._setHeaderBoardsPath(boardPath));
}

export default new AlertsServices();
