import React from 'react';
import { useSelector } from 'react-redux';

import {
  userEntitiesSelector,
  isSuperAdminRoleSelector,
  isAdminRoleSelector
} from '../../ducks/user';
import config from '../../config';



export function adminSuperAdminPrivate(Component) {
  return function WrappedComponent(props) {
    const userData = useSelector(userEntitiesSelector);
    const encodeUri = encodeURIComponent(window.location.href);
    const redirectUrl = `/account/signin?returnurl=${encodeUri}`;

    const isSuperAdmin = useSelector(isSuperAdminRoleSelector);
    const isAdmin = useSelector(isAdminRoleSelector);

    return ((isAdmin || isSuperAdmin) && !config.institution)
      ? <Component {...props} user={userData} />
      : location.replace(redirectUrl)
  }
}
