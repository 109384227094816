import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useHistory, useRouteMatch } from 'react-router-dom';
import { FormattedMessage, injectIntl } from 'react-intl';

// temaplates - fields
import InputField from './fields/input';
import RadioFieldLocation from './fields/radio-location';
import SelectField from './fields/select';
import CheckboxField from './fields/checkbox';
import BtnSearch from './btn-search';
import BtnToggleView from './btn-toogle-view';
import { 
  Classification as dictionaryClassification, 
  PostedWithin as dictionaryAllPostedWithin
} from '../../../constants'
import m from './messages';
import { mapObjToQueryString } from '../../utils';



export default injectIntl(function SearchResultsForm({ intl, allQueryProperties, fullForm, radioBtnValueAll, locPath }) {
  // detail prop types
  SearchResultsForm.propTypes = {
    allQueryProperties: PropTypes.object,
    fullForm: PropTypes.string,
    radioBtnValueAll: PropTypes.string,
  }

  delete allQueryProperties.page
  delete allQueryProperties.itemsPerPage
  
  // local state
  const [ fields, setFieldsValue ] = useState({ ...allQueryProperties });
  const [ fullFormView, setFullFormView ] = useState(!!fullForm);
  // routing
  const { push } = useHistory();
  const { url: routeUrl } = useRouteMatch();
  // handlers
  const handleFieldChange = ({ target: { name, value } }) => setFieldsValue(prevState => ({ ...prevState, [name]: value }))

  const handleSubmit = (e) =>  {
    e.preventDefault();
    const objParams = {...fields };
    if (fullFormView) objParams.fullForm = true;

    if (objParams.useSoundex) objParams.useSoundex = true;
    else delete objParams.useSoundex;
    
    if (locPath) { // set path values if an user came from a category page (except home page)
      objParams.path = locPath;
      objParams.selectedPath = fields.path;
    }
    const queryString = mapObjToQueryString(objParams)
    const url = `${routeUrl}${queryString}`;
    push(url);
  }

  const renderKeywordsSection = () => {
    return (
      <div className='s-result-form__header'>
        <InputField 
          name='keyword'
          value={fields.keyword}
          labelValue={intl.formatMessage(m.keywordLabel)}  
          handleFieldChange={handleFieldChange} 
        />
        {!fullFormView && (
          <div className='s-result-form__header-btns'>
            <BtnSearch type='suibmit' />
            <BtnToggleView fullFormView={!!fullFormView} setFullFormView={setFullFormView} setFieldsValue={setFieldsValue} />
          </div>
        )}
      </div>
    )
  }
  const renderFullForm = () => {
    if (!fullFormView) return null;

    const classificationWithAll = {ALL: 'All', ...dictionaryClassification}
    return (
      <div className='s-result-form__full'>
        <div className='s-result-form__field s-result-form__field--subject'>
          <InputField 
            name='subject'
            value={fields.subject}
            labelValue={intl.formatMessage(m.subjectLabel)}  
            handleFieldChange={handleFieldChange} 
          />
        </div>
        <div className='s-result-form__field s-result-form__field--author'>
          <InputField 
            name='author'
            value={fields.author}
            labelValue={intl.formatMessage(m.authorLabel)}  
            handleFieldChange={handleFieldChange} 
          />
        </div>
        {/* <div className='s-result-form__field s-result-form__field--select-posted'>
          <SelectField 
            dictionary={dictionaryAllPostedWithin}
            value={fields.postedWithin}
            name='postedWithin'
            labelKey='postedWithinLabel'
            handleChange={handleFieldChange}
          />
        </div> */}
        <div className='s-result-form__field s-result-form__field--select'>
          <SelectField 
            dictionary={classificationWithAll}
            value={fields.classification}
            name='classification'
            labelKey='classificationLabel'
            handleChange={handleFieldChange}
          />
        </div>
        <div className='s-result-form__field s-result-form__field--surnames'>
          <InputField 
            name='surnames'
            value={fields.surnames}
            labelValue={intl.formatMessage(m.surnamesLabel)}  
            handleFieldChange={handleFieldChange} 
          />
        </div>
        <div className='s-result-form__field s-result-form__field--checkbox'>
          <CheckboxField 
            name='useSoundex'
            value={fields.useSoundex}
            labelValue={intl.formatMessage(m.soundexLabel)}  
            handleChange={handleFieldChange}
          />
        </div>

        <div className='s-result-form__forms-btns'>
            <BtnSearch type='suibmit' />
            <BtnToggleView 
              fullFormView={!!fullFormView} 
              setFullFormView={setFullFormView} 
              setFieldsValue={setFieldsValue} 
            />
          </div>
      </div>
    )
  }


  return (
    <section className='s-result-form'>
      <h2 className='s-result-form__title'>
        <FormattedMessage {...m.title} />
      </h2>
      <form className='s-result-form__fields' onSubmit={handleSubmit}>
      { renderKeywordsSection() }
      { fields.path && (
        <div className='s-result-form__radio-btns'>
          <RadioFieldLocation 
            locPathValue={locPath} 
            checkedValue={fields.path} 
            name='path' 
            handleChange={handleFieldChange} 
            radioBtnValueAll={radioBtnValueAll}
          />
        </div>
      )}
      { renderFullForm() }
      </form>

    </section>
  )
})

