import { call, put, select, takeEvery } from 'redux-saga/effects';
import { createSelector } from 'reselect';
import { push, replace } from 'connected-react-router';
import { Record } from 'immutable';

import api from '../../services/threads-service';
// import PageModel from '../../components/common/pagination/model';
import QueryModel from '../common/query-model';
import settings from '../../local-settings';
import { action, fetching, modification } from '../../components/utils/redux';
import { appName } from '../../config';
import { clearUploads } from '../old-media';
import { Http } from '../../constants';
import { showError, showSuccess } from '../common/alert';
import { threadLink } from '../../components/utils/old_link';

/**
 * Constants
 */

export const moduleName = 'old_thread';
export const prefix = `${appName}/${moduleName}`;

export const TREE_LAYOUT = 'TREE_VIEW';
export const FLAT_LAYOUT = 'FLAT_VIEW';
export const DEFAULT_LAYOUT = TREE_LAYOUT;
export const Layouts = [TREE_LAYOUT, FLAT_LAYOUT];
export const ThreadLayoutKey = 'thread.layout';

export const ASCENDING = 'ASCENDING';
export const DESCENDING = 'DESCENDING';
export const DEFAULT_SORT_DIRECTION = DESCENDING;
export const SortDirections = [ASCENDING, DESCENDING];

export const getPrefix = `${prefix}/get`;
export const GET_THREAD_REQUEST = `${getPrefix}/REQUEST`;
export const GET_THREAD_SUCCESS = `${getPrefix}/RECEIVE`;
export const GET_THREAD_FAIL = `${getPrefix}/GET_THREAD_FAIL`;
export const deletePrefix = `${prefix}/delete`;
export const DELETE_THREAD_REQUEST = `${deletePrefix}/REQUEST`;
export const DELETE_THREAD_SUCCESS = `${deletePrefix}/SUCCESS`;
export const DELETE_THREAD_FAIL = `${deletePrefix}/FAIL`;
export const postThreadOrigin = `${prefix}/post`;
export const POST_THREAD_REQUEST = `${postThreadOrigin}/REQUEST`;
export const POST_THREAD_SUCCESS = `${postThreadOrigin}/SUCCESS`;
export const THREAD_SET = `${prefix}/SET`;
export const THREAD_REMOVE_ATTACHMENT = `${prefix}/attachment/REMOVE`;
export const movePrefix = `${prefix}/move`;
export const THREAD_MOVE_REQUEST = `${movePrefix}/REQUEST`;
export const THREAD_MOVE_SUCCESS = `${movePrefix}/SUCCESS`;
export const getParentPrefix = `${prefix}/parent/fetch`;
export const THREAD_FETCH_PARENT_REQUEST = `${getParentPrefix}/REQUEST`;
export const THREAD_FETCH_PARENT_RECEIVE = `${getParentPrefix}/RECEIVE`;

export const THREAD_RESET_DATA = `${getPrefix}/THREAD_RESET_DATA`;

export const setThread = (name, value) => action(THREAD_SET, { name, value });
export const removeAttachment = id => action(THREAD_REMOVE_ATTACHMENT, id);
export const requestMoveThread = (id, path, newPath, next) => action(THREAD_MOVE_REQUEST, { id, path, newPath }, next);

/**
 * Reducer
 */

export const ThreadRecord = Record({
  active: false,
  attachments: [],
  body: '',
  classification: null,
  id: null,
  isFavorite: null,
  deleted: false,
  userMarkedDeleted:false,
  markedDeletedBy: null,
  isFavoriteAuthor: false,
  lastPost: 0,
  namePath: null,
  path: null,
  repliesCount: 0,
  sendAlert: false,
  subject: '',
  surnames: '',
  user: {},
  viewed: false
});

export const PaginationRecord = Record({
  currentPage: 1,
  itemsPerPage: 10,
  totalPages: 0
});

export const EntitiesRecord = Record({
  nextThreadId: null,
  prevThreadId: null,
  replies: [ThreadRecord({})],
  repliesPaging: PaginationRecord({}),
  breadcrumbs: { links: [{ path: '' }] },
  thread: ThreadRecord({})
});

export const ReducerRecord = Record({
  entities: EntitiesRecord({}),
  parent: null,
  loaded: false,
  deleteLoading: false
});

export default function reducer(state = ReducerRecord({}), action) {
  const { type, payload } = action;
  switch (type) {
    case THREAD_SET:
      return state
        .setIn(['entities', 'thread', payload.name], payload.value);

    case GET_THREAD_SUCCESS:
      return state
        .set('loaded', true)
        .set('entities', EntitiesRecord(payload));

    case GET_THREAD_FAIL:
      return state.set('loaded', true);

    case THREAD_RESET_DATA:
      return state.clear();

    case DELETE_THREAD_REQUEST:
      return state.set('deleteLoading', true)

    case DELETE_THREAD_SUCCESS:
    case DELETE_THREAD_FAIL:
      return state.set('deleteLoading', false)

    case THREAD_FETCH_PARENT_RECEIVE:
      return state.set('parent', ThreadRecord(payload.thread));

    case THREAD_REMOVE_ATTACHMENT: {
      const attachments = state.getIn(['entities', 'thread', 'attachments'])
        .filter(item => item.id !== payload);
      return state.setIn(['entities', 'thread', 'attachments'], attachments);
    }

    default:
      return state;
  }
}

const createThreadQueryModel = id => new QueryModel({ path: '/threads/:id', params: { id } });

/**
 * Action
 */

export const getThread = (query, path) => action(GET_THREAD_REQUEST, {
  query: typeof query === 'string'
    ? createThreadQueryModel(query)
    : query,
  path
});

export const requestParentThread = (id, path) => action(THREAD_FETCH_PARENT_REQUEST, {
  query: createThreadQueryModel(id),
  path
});
export const requestDeleteThread = (id, path, next) => action(DELETE_THREAD_REQUEST, { id, path }, next);
export const postThread = (id, data) => action(POST_THREAD_REQUEST, { id, data });
export const resetThreadData = () => action(THREAD_RESET_DATA);

/**
 * Selector
 */

export const stateSelector = state => state[moduleName];

export const entitiesSelector = createSelector(
  stateSelector,
  state => state.get('entities')
);


export const deleteLoadingSelector = createSelector(
  stateSelector, state => state.deleteLoading
)

export const selectParentThread = createSelector(
  stateSelector,
  state => state.get('parent')
);

export const selectThreadBreadcrumbs = createSelector(
  entitiesSelector,
  state => {
    const breadcrumbs = state.get('breadcrumbs');
    return (breadcrumbs ? breadcrumbs.links : null) || [];
  }
);

export const selectCurrentThread = createSelector(
  entitiesSelector,
  state => state.thread
);

export const selectThreadIsFavorite = createSelector(
  selectCurrentThread,
  state => state.isFavorite
);

export const selectAuthorIsFavorite = createSelector(
  selectCurrentThread,
  state => state.isFavoriteAuthor
);

export const selectSendAlert = createSelector(
  selectCurrentThread,
  state => state.get('sendAlert')
);

export const loadedSelector = createSelector(
  stateSelector,
  state => state.loaded
);

/**
 * Saga
 */

export function* fetchThread({ payload: { query, path } }) {
  try {
    const { entities } = stateSelector(yield select());
    if (entities.thread.id) {
      if (!query) {
        query = new QueryModel({
          path: '/threads/:id',
          params: { id: entities.thread.id },
          ...entities.repliesPaging
        });
      }
      if (!path) {
        path = entities.thread.path;
      }
    }
    const { data } = yield call(api.getThreadById, query, path);
    yield put({
      type: GET_THREAD_SUCCESS,
      payload: data
    });
  } catch (err) {
    if (err && err.response && err.response.status === Http.NotFound) {
      return yield put(replace('/404'));
    }
    yield put(showError(err));
    yield put({ type: GET_THREAD_FAIL });
  }
}

export const fetchParentThread = fetching(
  ({ query, path }) => [api.getThreadById, query, path],
  THREAD_FETCH_PARENT_RECEIVE
);


export const deleteThreadSaga = function* ({ payload: { id, path } }) {
  try {
    yield call(api.deleteThread, id, path);
    yield put({ type: DELETE_THREAD_SUCCESS })
    yield put(push(threadLink(path)));
  } catch(err) {
    yield put({ type: DELETE_THREAD_FAIL })
  }
}


export function* saveThread({ payload: { id, data } }) {
  try {
    const { headers, data: responseData } = id ? yield call(api.updateThread, id, data, data.path) : yield call(api.createThread, data, data.path);
    yield put(clearUploads());
    yield put({ type: POST_THREAD_SUCCESS });
    if (headers.location) {
      const location = headers.location.split('/');
      const threadId = location[location.length - 1];
      if (threadId) {
        yield put(push(threadLink(data.path, threadId)));
      }
    }
    if (responseData && responseData.id) {
      yield put(push(threadLink(responseData.path, responseData.id)));
    }
  } catch (err) {
    yield put(showError(err.response && err.response.status === Http.Forbidden ? 'You cannot edit topic' : err));
  }
}

const move = modification(
  ({ id, path, newPath }) => [api.moveThread, id, path, newPath],
  THREAD_MOVE_SUCCESS
);

export function* saga() {
  yield takeEvery(GET_THREAD_REQUEST, fetchThread);
  yield takeEvery(DELETE_THREAD_REQUEST, deleteThreadSaga);
  yield takeEvery(POST_THREAD_REQUEST, saveThread);
  yield takeEvery(THREAD_MOVE_REQUEST, move);
  yield takeEvery(THREAD_FETCH_PARENT_REQUEST, fetchParentThread);
}

/**
 * @param {Location} location
 * @returns {PageModel}
 */
export function parseQuery(location) {
  const query = new PageModel(location.search);
  query.path = '/threads/:id';
  if (!query.params.sortDirection || !SortDirections.includes(query.params.sortDirection)) {
    query.params.sortDirection = DEFAULT_SORT_DIRECTION;
  }
  if (!query.params.viewType || !Layouts.includes(query.params.viewType)) {
    const layout = settings.get(ThreadLayoutKey);
    query.params.viewType = Layouts.includes(layout) ? layout : DEFAULT_LAYOUT;
  }
  return query;
}
