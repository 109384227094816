import { select, takeEvery, put, call } from 'redux-saga/effects';
import { Record } from 'immutable';
import api from '../services/nodes-service';
import { action, catchError, clearBusy, fetching, modification, setterAction } from '../components/utils/redux';
import { appName } from '../config';
import { createSelector } from 'reselect';
import { Http } from '../constants';
import { isBusy } from './common/busy';
import { showError } from './common/alert';

export const FetchOptionsDelay = 300;
export const moduleName = 'options';
export const prefix = `${appName}/${moduleName}`;

export const fetchPrefix = `${prefix}/fetch`;
export const OPTIONS_FETCH_REQUEST = `${fetchPrefix}/REQUEST`;
export const OPTIONS_FETCH_RECEIVE = `${fetchPrefix}/RECEIVE`;
export const OPTIONS_FETCH_RECEIVE_FAIL = `${fetchPrefix}/FAIL`;
export const createPrefix = `${prefix}/create`;
export const OPTIONS_CREATE_REQUEST = `${createPrefix}/REQUEST`;
export const OPTIONS_CREATE_SUCCESS = `${createPrefix}/SUCCESS`;
export const updatePrefix = `${prefix}/update`;
export const OPTIONS_UPDATE_REQUEST = `${updatePrefix}/REQUEST`;
export const OPTIONS_UPDATE_SUCCESS = `${updatePrefix}/SUCCESS`;
export const OPTIONS_SET_NODE_FIELD = `${prefix}/SET`;
export const OPTIONS_SET_OPTION = `${prefix}/SET_OPTION`;
export const OPTIONS_SET_DEFAULT = `${prefix}/DEFAULT`;
export const OPTIONS_SET_CONFLICT = `${prefix}/CONFLICT`;
export const probePrefix = `${prefix}/probe`;
export const OPTIONS_PATH_PROBE_REQUEST = `${probePrefix}/REQUEST`;
export const OPTIONS_PATH_PROBE_RECEIVE = `${probePrefix}/RECEIVE`;

export const requestFetchOptions = (path = '', nodeType, ignoreError = false) => ({
  type: OPTIONS_FETCH_REQUEST,
  payload: { path, nodeType, ignoreError }
});


export const setDefaultOptions = type => action(OPTIONS_SET_DEFAULT, type);
export const requestCreateOptions = (path, type, next) => action(OPTIONS_CREATE_REQUEST, { path, type }, next);
export const requestUpdateOptions = (path, next) => action(OPTIONS_UPDATE_REQUEST, path, next);
export const setOption = setterAction(OPTIONS_SET_OPTION);
export const setNodeField = setterAction(OPTIONS_SET_NODE_FIELD);
export const setConflict = setterAction(OPTIONS_SET_CONFLICT);
export const requestPathProbe = path => ({ type: OPTIONS_PATH_PROBE_REQUEST, payload: path });

export const DefaultNode = {
  Board: setDefaultOptions('BOARD'),
  Category: setDefaultOptions('CATEGORY')
};

// Order of properties is important!
export const BoardDefaultOptions = {
  posts: true,
  banners: true,
  soundex: false,
  moderation: false,
  employeePosts: false,
  mailListGateway: false,
};

export const CategoryDefaultOptions = {
  virtualList: false
};

export const BoardOptionsRecord = Record(BoardDefaultOptions);
export const CategoryOptionsRecord = Record(CategoryDefaultOptions);

export const NodeRecord = Record({
  gatewayAddress: '',
  linkId: '',
  name: '',
  options: null,
  path: '',
  type: '',
  breadcrumbs: {
    links: []
  }
});

export const NodeConflictRecord = Record({
  path: '',
  name: ''
});

export const NodeStateRecord = Record({
  node: NodeRecord(),
  conflict: NodeConflictRecord(),
  err: null
});

export const OptionNames = {
  BOARD: Object.keys(BoardDefaultOptions),
  CATEGORY: Object.keys(CategoryDefaultOptions)
};

export const fetch = () => {
  return fetching(
    ({ path }) => [api.getNodes, path],
    OPTIONS_FETCH_RECEIVE
  );
}

/**
 * Selectors
 */
export function selectNodeState(state) {
  return state[moduleName];
}

export const errSelector = createSelector(
  selectNodeState,
  state => state.err
);

export const selectNode = createSelector(
  selectNodeState,
  state => state.node
);

export const selectNodeConflict = createSelector(
  selectNodeState,
  state => state.get('conflict')
);




export function saving(success, serve) {
  return function* save(action) {
    const data = selectNode(yield select());
    yield modification(
      payload => serve(payload, data),
      success,
      function* (err) {
        if (err && err.response && err.response.status === Http.Conflict) {
          yield put(setConflict('name', data.name));
        } else {
			yield put(setConflict('name', data.name));
          yield catchError(success, err);
        }
      }
    )(action);
  };
}

const create = saving(
  OPTIONS_CREATE_SUCCESS,
  ({ path, type }, data) => [api.createOptions, path, type, data]
);

const update = saving(
  OPTIONS_UPDATE_SUCCESS,
  (path, data) => [api.updateOptions, path, data]
);

function* pathProbeSaga({ payload: path }) {
  try {
    yield call(api.getNodes, path);
    yield put(action(OPTIONS_PATH_PROBE_RECEIVE, path));
  } catch (err) {
    yield clearBusy(OPTIONS_PATH_PROBE_RECEIVE);
    if (!err.response || err.response.status !== Http.NotFound) {
      yield put(showError(err));
    }
  }
}

export default function reducer(state = NodeStateRecord(), { type, payload, err }) {
  switch (type) {
    case OPTIONS_FETCH_REQUEST:
      return state
        .setIn(['node', 'type'], '')
        .setIn(['node', 'options'], null);
    case OPTIONS_PATH_PROBE_REQUEST:
      return state
        .setIn(['conflict', 'path'], '');
    case OPTIONS_FETCH_RECEIVE: {
      const { data, nodeType } = payload;
      // in priority check if this data for create category in msg board - options by default
      if (nodeType === 'msgBoardCategory') {
        data.options = BoardOptionsRecord({})
      } else {
        let OptionsRecord = data.type === 'CATEGORY' ? CategoryOptionsRecord : BoardOptionsRecord;
        data.options = OptionsRecord(data.options)
      }
      data.name = '';
      return state.set('node', NodeRecord(data))

    }

    case OPTIONS_PATH_PROBE_RECEIVE:
      return state
        .setIn(['conflict', 'path'], payload);
    case OPTIONS_SET_NODE_FIELD:
      return state.setIn(['node', payload.name], payload.value);
    case OPTIONS_SET_OPTION:
      return state.setIn(['node', 'options', payload.name], payload.value);
    case OPTIONS_SET_DEFAULT:
      return state
        .set('node', NodeRecord({
          type: payload,
          options: payload === 'CATEGORY' ? CategoryOptionsRecord() : BoardOptionsRecord()
        }))
        .set('conflict', NodeConflictRecord());
    case OPTIONS_SET_CONFLICT:
      return state.setIn(['conflict', payload.name], payload.value);

    case OPTIONS_FETCH_RECEIVE_FAIL:
        return state.set('err', err)

    default:
      return state;
  }
}

export const getOptionsSaga = function* ({ payload: { path, nodeType } }) {
  try {
    const { data } = yield call(api.getNodes, path);
    yield put({ type: OPTIONS_FETCH_RECEIVE, payload: { data, nodeType } })
  } catch(err) {
    yield put({ type: OPTIONS_FETCH_RECEIVE_FAIL, err })
    yield put({ type: 'RESET_ALL_BUSY' })
  }
}

export function* saga() {
  yield takeEvery(OPTIONS_FETCH_REQUEST, getOptionsSaga);
  yield takeEvery(OPTIONS_CREATE_REQUEST, create);
  yield takeEvery(OPTIONS_UPDATE_REQUEST, update);
  yield takeEvery(OPTIONS_PATH_PROBE_REQUEST, pathProbeSaga);
}

export function createOptionsFetch(busy, path, requestFetchOptions, timeout) {
  function fetch() {
    if (path && path[path.length - 1] !== '.') {
      if (busy) {
        if (!(timeout.current > 0)) {
          timeout.current = setTimeout(fetch, FetchOptionsDelay);
        }
      } else {
        if (timeout.current > 0) {
          clearTimeout(timeout.current);
          timeout.current = 0;
        }
        requestFetchOptions(path, true);
      }
    }
  }
  return fetch;
}

export function optionsMapStateToProps(state) {
  const node = selectNode(state);
  return {
    busy: isBusy(state, `${fetchPrefix}/`),
    hasNode: !!node,
    isBoard: node && node.type === 'BOARD',
    isCategory: node && node.type === 'CATEGORY'
  };
}
