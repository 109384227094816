import React from 'react';
import { useSelector } from 'react-redux';

import ParentSubject from './parent-subject';
import ThreadSubject from './thread-subject';
import AuthorThread from '../author';
import NoticeAddRemove from '../../notice-add-remove';
import LinkNewThread from '../../common/link-new-thread';
import ThreadMetaDetail from '../thread-meta-detail';
import NoticeReadOnly from '../../notice-read-only';
import { ROLE_TYPES } from '../../../constants';
import NoticeModerate from '../../notice-moderate';


export default function ThreadHeader({ isGuest, repliesCount, parentSubject, posted, subject, updated, user, classification, surnames, isReadOnly, moderate, optionsModeration }) {
  return (
    <header className='thread__header'>

      <div className='thread__header-top'>
        <ParentSubject parentSubject={parentSubject}/>
        {!isReadOnly && <div className='thread__link-new'>
          <LinkNewThread />
        </div>}
      </div>

      <NoticeAddRemove />
      {isReadOnly && <NoticeReadOnly />}
      {optionsModeration && moderate && <NoticeModerate />}

      <div className='thread__header-bottom'>
        <div className='thread__header-meta'>
          <ThreadSubject subject={subject} />
          {user && (
            <div className='thread__header-author'>
              <AuthorThread isGuest={isGuest} {...user} />
            </div>
          ) }
        </div>
        <div className='thread__header-detail'>
          <ThreadMetaDetail repliesCount={repliesCount} posted={posted} updated={updated} classification={classification} surnames={surnames} />
        </div>
      </div>
    </header>
  )
}
