import React from 'react';
import { injectIntl } from 'react-intl'

import m from './messages';

export default injectIntl(function NoMatchesResult({ intl }) {
  const emptyMsg = intl.formatMessage(m.noMatchesNotice)
  return (
    <div className="conBody">
      <div id="noresultsPane" className="alert" role={emptyMsg}>
        {emptyMsg}
      </div>
    </div>
  )
})