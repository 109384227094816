import React from 'react';
import { FormattedMessage } from 'react-intl';

import m from '../../pages/volunteer/messages';

export default function ErrorContent(_) {
  return (
    <div className='volunteer-content--error'>
      <p className='volunteer__title'>
        <FormattedMessage {...m.pathError} />
      </p>
    </div>
  );
};
