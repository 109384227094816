import React from 'react'; 
import { useParams, Link } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';

import m from './messages';
import url from '../../url-service';


export default function CancelLink() {
  const { path, threadId } = useParams();
  const anchor = url.createPath('thread', path, threadId);

  return (
    <Link className='link link--cancel' to={anchor}>
      <FormattedMessage {...m.cancel} />
    </Link>
  )
}