import React from 'react';
import { arrayOf, bool, func, number, object, oneOfType, string } from 'prop-types';
import { connect } from 'react-redux';
import { FormattedMessage } from 'react-intl';

import m from './messages';
import Progress from '../../common/progress';
import { isBusy } from '../../../ducks/common/busy';
import {
  DataJudgeOperations,
  judgePrefix,
  requestJudgeSuggestion,
  requestSuggestionFetch
} from '../../../ducks/suggestion';

export function Actions({
  busy,
  data,
  disabled,
  id,
  names,
  path,
  requestJudge,
  messages = {}
}) {
  if (busy) {
    return <Progress />;
  }
  const items = names
    .map(actionName => {
      const name = actionName.toLowerCase();
      const translation = messages[name] || m[name];
      if (!translation) {
        return null;
      }
      return (
        <button
          disabled={DataJudgeOperations.includes(actionName) ? disabled : false}
          key={name}
          type="button"
          className={`ancBtn ${name}`}
          onClick={() => requestJudge(id, path, name.toUpperCase(), data)}
        >
          <FormattedMessage {...translation} />
        </button>
      );
    });

  return <div className="manage-actions">{items}</div>;
}

Actions.propTypes = {
  busy: bool.isRequired,
  data: object.isRequired,
  disabled: bool,
  id: oneOfType([string, number]).isRequired,
  names: arrayOf(string).isRequired,
  path: string.isRequired,
  requestJudge: func.isRequired
};

Actions.defaultProps = {
  disabled: false
};

function mapStateToProps(state) {
  return {
    busy: isBusy(state, `${judgePrefix}/`)
  };
}

export function mapDispatchToProps(dispatch) {
  return {
    requestJudge(id, path, operation, data) {
      for (const key in data) {
        let value = data[key];
        if ('string' === typeof value) {
          value = value.trim();
          if (!value) {
            delete data[key];
          }
        }
      }
      dispatch(requestJudgeSuggestion({ id, path, operation, data }, requestSuggestionFetch()));
    }
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(Actions);
