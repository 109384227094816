import { call, put, take, spawn } from 'redux-saga/effects';
import { Record } from 'immutable';
import { createSelector } from 'reselect';

import api from '../services/nodes-service';
import { appName } from '../config';


/**
 * Constants
 */

export const moduleName = 'node-path';
export const prefix = `${appName}/${moduleName}`;
export const GET_NODE_PATH_REQUEST = `${prefix}/GET_NODE_PATH_REQUEST`;
export const GET_NODE_PATH_SUCCESS = `${prefix}/GET_NODE_PATH_SUCCESS`;
export const GET_NODE_PATH_FAIL = `${prefix}/GET_NODE_PATH_FAIL`;


export const RESET_NODE_PATH_STATE = `${prefix}/RESET_NODE_PATH_STATE`;


/**
 * Reducer
 */

 const EntitiesRecord = Record({
  name: null,
  path: null,
  type: null,
  deleted: null
 })

 const ReducerRecord = Record({
   loading: false,
   err: null,
   entities: null
 })


 export default function reducer(state = new ReducerRecord(), { type, payload, err }) {
  switch(type) {
    case GET_NODE_PATH_REQUEST:
        return state.set('loading', true);

    case GET_NODE_PATH_SUCCESS:
        return state
                .set('loading', false)
                .set('entities', new EntitiesRecord(payload))

    case GET_NODE_PATH_FAIL:
        return state
                .set('loading', false)
                .clear('entities')
                .set('err', err)

    case RESET_NODE_PATH_STATE:
      return state.clear()

    default:
        return state;
  }
}


 /**
  * Action
  */

 export const getNodePathData = (path) => ({ type: GET_NODE_PATH_REQUEST, payload: path });
 export const resetNodePathState = () => ({ type: RESET_NODE_PATH_STATE });


  /**
   * Selectors
   */

  export const stateSelector = state => state[moduleName];
  export const loadingSelector = createSelector(
    stateSelector,
    state => state.loading
  )
  export const entitiesSelector = createSelector(
  stateSelector,
  state => state.entities
  )

  export const errorSelector = createSelector(
    stateSelector, state => state.err
  );




   /**
    * Sagas
    */

  export const getNodePathSaga = function* () {
    while(true) {
      const { payload: path } = yield take(GET_NODE_PATH_REQUEST);
      try {
        const { data } = yield call(api.getNodes, path);
        yield put({ type: GET_NODE_PATH_SUCCESS, payload: data });
      } catch (err) {
        yield put({ type: GET_NODE_PATH_FAIL, err });
      }   
    }
  }

  export const saga = function* () {
    yield spawn(getNodePathSaga)
  }