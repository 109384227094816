/* eslint-disable max-len */
import { defineMessages } from 'react-intl';

export default defineMessages({
  title: 'Become An Admin',
  pathError: 'The current board path does not exist!',
  thanks: 'Thank you for your interest in volunteer to be a board administrator!',
  responsibilitiesList: 'The basic responsibilities of a board administrator are as follows:',
  responsibilityItem1: 'Board admins must review new messages posted to the board for violations of the Community Guidelines and remove them if necessary.',
  responsibilityItem2: 'A board admin must maintain a valid, working e-mail address.',
  responsibilityItem3: 'An admin must be willing to receive and respond to e-mail questions and requests made by board members.',
  responsibilityItem4: 'Board admins allow and support discussion and interaction on their board.',
  information1: 'Activity on each board can vary greatly so it is impossible to estimate the amount of time necessary to administer a board. No special tools beyond a computer, e-mail and an internet connection are required to become an admin.',
  information2: 'If your request to volunteer as a board administrator is approved, you will be notified via email and given access to the necessary online tools.',
  request: 'I would like to become the admin for the following message board:',
  submit: 'Submit',
  cancel: 'Cancel',
  submitted1: 'Your request has been submitted to the Message Boards administrator.',
  submitted2: 'You will receive an email message after your request has been reviewed.',
  back: 'Return to the {name}'
});
