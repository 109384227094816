import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Link, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import m from '../../pages/volunteer/messages';
import { pathNameSelector, submitVolunteering } from '../../ducks/volunteer';
import MessagesList from './messages-list';

export default function DefaultContent(_) {
  // routing
  const { path } = useParams();

  // redux
  const dispatch = useDispatch();

  // data from redux
  const pathName = useSelector(pathNameSelector);

  const submit = () => dispatch(submitVolunteering(path));

  return (
    <div className="volunteer-content">
      <p className="volunteer__title">
        <FormattedMessage {...m.thanks} />
      </p>
      <p className="volunteer__title">
        <FormattedMessage {...m.responsibilitiesList} />
      </p>

      <MessagesList />

      <p className="volunteer__title">
        <FormattedMessage {...m.information1} />
      </p>
      <p className="volunteer__title">
        <FormattedMessage {...m.information2} />
      </p>
      <p className="volunteer__title">
        <FormattedMessage {...m.request} /> <span className="volunteer__title--bold">{pathName}</span>
      </p>

      <div className="volunteer__btns-wrap">
        <button className="volunteer__submit" onClick={submit}>
          <FormattedMessage {...m.submit} />
        </button>
        <Link className="volunteer__link" to={`/${path}`}>
          <FormattedMessage {...m.cancel} />
        </Link>
      </div>
    </div>
  );
}
