import React from 'react';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';

import { resetBoardInfo } from '../../../ducks/board-info-edit'
import m from './messages';


export default function ButtonReset() {
  const dispatch = useDispatch()
  const { path } = useParams();

  const handleClick = () => dispatch(resetBoardInfo(path));

  return (
    <button className='btn btn--link' onClick={handleClick}>
      <FormattedMessage {...m.btnResetTitle} />
    </button>
  )
}