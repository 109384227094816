import { defineMessages } from 'react-intl';

export default defineMessages({
  advancedSearch: 'Advanced Search',
  tipText: 'Visit the {link} for tips on how to make your searches more effective.',
  all: 'All',
  allBoards: 'All Boards',
  anytime: 'Anytime',
  author: 'Author',
  authorOfMessage: 'Author of Message',
  bible: 'Bible',
  biography: 'Biography',
  birth: 'Birth',
  boardFAQ: 'Board FAQ',
  by: 'by',
  cemetery: 'Cemetery',
  census: 'Census',
  chooseCategorySearch: '{name} - Family History & Genealogy Message Board',
  death: 'Death',
  deed: 'Deed',
  go: 'Go',
  hideAdvancedSearch: 'Hide Advanced Search',
  immigration: 'Immigration',
  lastName: 'Last Name (surnames)',
  lookup: 'Lookup',
  marriage: 'Marriage',
  messageClassification: 'Message Classification',
  military: 'Military',
  nameOrKeyword: 'Name or Keyword',
  namesOrKeyboard: 'Names or keywords',
  noMatches: 'No matches found',
  noMatchesNotice: 'Your search did not return any results.  Please try again.',
  obituary: 'Obituary',
  pension: 'Pension',
  postedOn: 'Posted on:',
  postedWithin: 'Posted Within',
  queries: 'Query',
  search: 'Search',
  searchBoards: 'Search the Boards',
  searchForContent: 'Search for content in message board',
  searchHint: 'e.g. John Smith or Civil War',
  searchLabel: 'Names or keywords',
  searchPlaceholder: 'e.g. John Smith or Civil War',
  searchResults: 'Search Results',
  simpleSearchTitle: 'Find a board about a specific topic',
  simpleSearchWarning: 'Enter at least 2 characters then click \'Go\'.',
  subject: 'Subject',
  subjectOfMessage: 'Subject of Message',
  surnames: 'Surnames',
  surnamesOrTopics: 'Surnames or topics',
  tip: 'Tip',
  searchFor: 'for "{name}"',
  useSoundex: 'Use Soundex',
  week1: '1 Week',
  will: 'Will',
  year1: '1 Year',
  messages: 'Messages',
  messageBoards: 'Message Boards'
});
