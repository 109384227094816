import { defineMessages } from 'react-intl';

export default defineMessages({
  enterAdminCenter: 'Admin Center',
  exitAdminCenter: 'Exit Admin Center',
  faq: 'Board FAQ',
  feedback: 'Send Feedback',
  guidelines: 'Community Rules',
  help: 'Board Help',
  requestNewBoard: 'Request New Board'
});
