import React, { useEffect, useState, useRef } from 'react';
import { Link, useParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { FormattedMessage, injectIntl } from 'react-intl';
import { Redirect } from 'react-router';

import m from '../../pages/request-boards/messages';
import { createRequest } from '../../ducks/request-boards';
import useQuery from '../../hooks/use-query-params';
import config from '../../config';
import { trackPageView } from '../../utils/tealium';

export default injectIntl(props => {
  // routing
  const { path, id } = useParams();
  const { returnUrl } = useQuery();
  // redux
  const dispatch = useDispatch();

  const inputEl = useRef(null);

  const minBoardNameLength = 2;
  const [boardName, setBoardName] = useState('');
  const [comment, setComment] = useState('');
  const [checkbox, setCheckbox] = useState(false);
  const [backUrl, setBackUrl] = useState('');

  const states = ['required', 'error', 'success'];
  const [state, setState] = useState(states[0]);
  const [disabled, setDisabled] = useState(false);

  useEffect(() => {
    let page = 'request-board';
    if (path) {
      page = `${page} : ${path}`;
    }
    trackPageView(page);
  }, []);

  useEffect(() => {
    if (path && id) return setBackUrl(`/${path}/${id}`);
    if (path) return setBackUrl(`/${path}`);
    setBackUrl('/');
  }, []);

  const handleBoardNameChange = e => setBoardName(e.target.value);

  const handleBoardNameBlur = _ => setState(boardName.length >= minBoardNameLength ? states[2] : states[1]);

  const handleCommentChange = e => setComment(e.target.value);

  const handleCheckbox = _ => {
    if (!checkbox) return setCheckbox(true);
    setCheckbox(false);
  };

  function handleSubmit() {
    if (!boardName) {
      setState(states[1]);
      inputEl.current.focus();
      return;
    }
    const formattedComment = `*** ${props.intl.formatMessage(m.checkboxTitle)} *** ${comment}`;
    const commentRequest = checkbox ? formattedComment : comment;
    const body = {
      comment: commentRequest,
      boardName
    };
    setDisabled(true);
    dispatch(createRequest(path, decodeURIComponent(returnUrl), body));
  }

  const objClassValue = () => {
    if (state === states[2]) return states[2];
    if (state === states[1]) return states[1];
    return states[0];
  };

  if (config.institution) return <Redirect to="/" />;
  return (
    <div className="request-form">
      <div className="request__field-wrap">
        <p className={`request__field-title ${objClassValue()?`request__field-title--${objClassValue()}`:''}`}>
          <FormattedMessage {...m.boardName} />
          {state === states[1] ? (
            <span className="request__title--error-message icon"><FormattedMessage {...m.boardNameRequired} /></span>
          ) : null}
        </p>
        <div className="request__field">
          <input
            ref={inputEl}
            type="text"
            className={`request__input--${state}`}
            onChange={handleBoardNameChange}
            onBlur={handleBoardNameBlur}
          />
        </div>
      </div>
      <div className="request__field-wrap">
        <p className="request__field-title"><FormattedMessage {...m.comments} /></p>
        <div className="request__field">
          <textarea onChange={handleCommentChange} />
        </div>
      </div>
      <div className="request__field-wrap">
        <input type="checkbox" className="request__checkbox" checked={checkbox} readOnly />
        <label onClick={handleCheckbox} className="request__label"><FormattedMessage {...m.checkboxTitle} /></label>
      </div>
      <div className="request__btns-wrap">
        <button
          disabled={disabled}
          type="button"
          className="ancBtn orange"
          onClick={handleSubmit}
        >
          <FormattedMessage {...m.submit} />
        </button>
        <Link className="form__link" to={backUrl}>
          <FormattedMessage {...m.cancel} />
        </Link>
      </div>
    </div>
  );
});
