import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { FormattedMessage } from 'react-intl';

import { newAddedFilesSelector, deleteFile } from '../../../ducks/attachments';
import m from '../messages';


export default function AddedList({}) {
  const files = useSelector(newAddedFilesSelector);
  const dispatch = useDispatch();
  if (!files.length) return null;

  return files.map((file, index) => (
    <div className='attachments__item' key={file.name + index}>
      <div className='attachments__item-wrap'>
        <img className='attachments__item-img' src={URL.createObjectURL(file)} alt={file.name}/>
      </div>
      
      <button 
        type='button'
        className='attachment__item-delete'  
        onClick={() => dispatch(deleteFile('newAdded', file))}
      >
        <FormattedMessage {...m.dltBtnTitle} />
      </button>
    </div>
  ))
}