import HttpServices from './index';

class BoardInfoService extends HttpServices {
  defaultPayload = {
    content: '',
    attachments: []
  }

  getBoardInfo = path => this.http.get('/boards-info', this._setHeaderBoardsPath(path));

  updateBoardInfo = (path, body) => this.http.put('/boards-info', body, this._setHeaderBoardsPath(path));

  getPreviewContent = body => this.http.post('/contents/sanitize', body);

  getBoardInfoEmail = email => this.http.get(`/boards-info/${email}`);

  saveBoardInfo = (path, data = this.defaultPayload) => this.http.put('/boards-info', data, this._setHeaderBoardsPath(path));

  uploadFile = (path, filesStream) => {
    const data = new FormData();
    data.append('file', filesStream, filesStream.name);
    return this.http.post('/boards-info/attachments', data, this._setHeaderBoardsPath(path));
  }

  deleteFile = (path, attachmentId) => this.http.delete(`/boards-info/attachments/${attachmentId}`, this._setHeaderBoardsPath(path));
}

export default new BoardInfoService();
