import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';

// data
import {
  // action
  checkExistsPath,
  // selectors
  checkPathLoadingSelector, pathLocationNameSelector, checkPathErrorSelector
} from '../../ducks/thread-create';
import { showPopup } from '../../ducks/popup';
// templates
import PageLayout from '../../components/hoc/page-layout';
import CreateForm from '../../components/forms/create';
import m from './messages';
import { trackPageView } from '../../utils/tealium';

export default function ThreadEditPage({ user }) {
  const dispatch = useDispatch();
  // routing data
  const { path } = useParams();

  // redux data
  const loading = useSelector(checkPathLoadingSelector);
  const locationName = useSelector(pathLocationNameSelector);
  const error = useSelector(checkPathErrorSelector);

  useEffect(() => {
    dispatch(checkExistsPath(path));
  }, [path]);

  useEffect(() => trackPageView(`create-message : ${path}`), [path]);

  const breadcrumbs = {
    links: [{
      path,
      title: <FormattedMessage {...m.returnLink} values={{ item: locationName }} />
    }]
  };

  const pageTitle = <FormattedMessage { ...m.postNewThread } values={{ name: locationName  }} />

  const communityGuidelines = (
    <button
      type="button"
      className="link"
      onClick={() => dispatch(showPopup('HELP'))}
    >
      <FormattedMessage {...m.communityGuidelines} />
    </button>
  );
  const faq = <button type="button" className="link" onClick={() => dispatch(showPopup('FAQ'))}><FormattedMessage {...m.faq} /></button>;
  const tipTitle = <strong><FormattedMessage {...m.tipTitle} /></strong>;
  const pageDescription = <FormattedMessage {...m.tip} values={{ tipTitle, communityGuidelines, faq }} />;

  return (
    <PageLayout
      loading={loading}
      error={error}
      title={pageTitle}
      description={pageDescription}
      breadcrumbs={breadcrumbs}
      aloneLink={true}
    >
      <CreateForm user={user} />
    </PageLayout>
  );
}

ThreadEditPage.propTypes = {
  user: PropTypes.object.isRequired // The current visitor
};
