import React from 'react';
import PageModel from './model';
import PageSize from './size';
import PageNumber from './number';
import { ChildrenType } from '../../utils/prop-types';

export default function Pagination({ children, ...props }) {
  const newProps = PageModel.initProps(props);
  return (
    <div className="pagination">
      <PageSize {...newProps} />
      {children}
      <PageNumber {...newProps} />
    </div>
  );
}

Pagination.propTypes = {
  children: ChildrenType
};

Pagination.defaultProps = {
  children: ''
};
