import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { useParams } from 'react-router-dom';
import { injectIntl } from 'react-intl';

//data
import { createThread, loadingSubmitSelector, errSubmitSelector } from '../../ducks/thread-create';
// templates
import UserInfo from './user-info'
import FiledSubject from './field-subject';
import FieldMessage from './field-message';
import FieldCheckbox from './field-checkbox';
import FiledSurnames from './field-surnames';
import ClassificationSelect from './classification-select';
import FieldAttachments from './field-attachments';
import BtnSubmit from './btn-submit';
import CancelLink from './cancel-link.js.js';
import Progress from '../common/progress';
import PreviewForm from './preview';
import BtnToogleEditPreview from './btn-edit-preview';
// helpers
import m from './messages';


export default injectIntl(function CreateForm({ user, intl }) {
  CreateForm.propTypes = {
    user: PropTypes.object.isRequired
  }

  const [ fields, setFileds ] = useState(() => ({
    subject: '',
    surnames: '',
    classification: 'QUERIES',
    body: '',
    sendAlert: true,
  }))

  const [ showPreview, setShowPreview ] = useState(false);
  const [ showEmptyField, setShowEmptyField ] = useState(''); // field name

  const { path } = useParams();
  const dispatch = useDispatch();
  const submitLoading = useSelector(loadingSubmitSelector);
  const errSubmit = useSelector(errSubmitSelector);
  
  const emptyFields = (() => {
    let fieldsEmpty = ''
    
    if (!fields.subject) fieldsEmpty = 'subject';
    if (!fields.body) fieldsEmpty = 'body';
    if (!fields.subject && !fields.body) fieldsEmpty ='subject body';

    return fieldsEmpty;
  })()

  const handleChangeField = ({ target: { value, name } }) => setFileds(prevState => ({ ...prevState, [name]: value }))
  const handleChangeSelect = ({ target: { value } }) => setFileds(prevState => ({ ...prevState, classification: value }))
  const handleChangeCheckbox = ({ target: { name } }) => setFileds(prevState => ({ ...prevState, [name]: !prevState.sendAlert }))
  const handleSubmit = () => emptyFields ? setShowEmptyField(emptyFields) : dispatch(createThread(path, fields))
  const handleResetEmptyMessage = () => setShowEmptyField('');
  const toggleBtnPreview = () => emptyFields ? setShowEmptyField(emptyFields) : setShowPreview(!showPreview)

  return (
    <div className='thread-form thread-form--create'>
        { showPreview  
          ? <PreviewForm user={user} fields={fields} hideUserLink />
          : ( <>
                <div className='thread-form__user-info'>
                  <UserInfo user={user} />
                </div>
                <div className='thread-form__field thread-form__field--subject'>
                  <FiledSubject 
                    name='subject' 
                    value={fields.subject} 
                    handleChange={handleChangeField} 
                    placeholder={intl.formatMessage(m.subjectPalceholder)}
                    showEmptyField={showEmptyField}
                    handleResetEmptyMessage={handleResetEmptyMessage}
                  />
                </div>
                <div className='thread-form__field thread-form__field--textarea'>
                  <FieldMessage 
                    name='body' 
                    value={fields.body} 
                    handleChange={handleChangeField} 
                    showEmptyField={showEmptyField}
                    handleResetEmptyMessage={handleResetEmptyMessage}
                  />
                </div>
                <div className='thread-form__field thread-form__field--checkbox'>
                  <FieldCheckbox name='sendAlert' value={fields.sendAlert}  handleChange={handleChangeCheckbox}/>
                </div>
                <div className='thread-form__field thread-form__field--surnames'>
                  <FiledSurnames 
                    name='surnames' 
                    value={fields.surnames} 
                    placeholder={intl.formatMessage(m.surnamePalceholder)}
                    handleChange={handleChangeField} 
                  />
                </div>
                <div className='thread-form__field thread-form__field--classification'>
                  <ClassificationSelect 
                    name='classification' 
                    value={fields.classification} 
                    handleChange={handleChangeSelect} 
                    helpLink
                  />
                </div>
                <div className='thread-form__field thread-form__field--attachemnt'>
                  <FieldAttachments canDownload={true} />
                </div>
              </>
        )}
          <div className='thread-form__btns'>
            <div className='thread-form__btn-submit'>
              <BtnSubmit handleSubmit={handleSubmit} title={intl.formatMessage(m.post)}/>
            </div>
            <div className='thread-form__btn-preview'>
              <BtnToogleEditPreview showPreview={showPreview} handleBtnClick={toggleBtnPreview} />
            </div> 
            <div className='thread-form__cancel-link'>
              <CancelLink />
            </div>
        </div>


      { submitLoading && (
          <div className='thread-form__progress'>
            <Progress small />
          </div>  
      )}
    </div>
  )
})



