import config, { basename } from '../config';


class URLService {
  constructor() {
    this._slash = string => string ? `/${string}` : '';
    this._lowercase = string => 'string' === typeof string ? string.toLowerCase() : '';

    this._rssLink = path => `${this.getOrigin()}${basename}/rss/${path}`;
    this._profileLink = id => `${this.getOrigin()}/connect/Profile/${id}`;
    this._feedbackLink = () => `${this.getOrigin()}/community/feedback.aspx?cat=message+boards&kurl=${this.getPath()}`;
    this._favoritesLink = path => `/favorites/${path}`;
    this._categoryLink = path => (path ? `/${this._lowercase(path)}` : '/');
    this._threadLink = (path, id = '', type = '') => `/${this._lowercase(path)}${this._slash(id)}${this._slash(type)}`;
    this._authorPostsLink = userId => `/author-posts/${userId}`;
    this._createImgLink = (namespace, id, height, width) => {
      let env;
      switch (config.env) {
        case 'local':
        case 'dev':
          env = 'dev';
          break;
        case 'stage':
          env = 'stage';
          break;
        default:
          env = '';
      }
      return `https://mediasvc.ancestry${env}.com/v2/image/namespaces/${namespace}/media/${id}?Client=Ancestry.Boards${height ? `&maxHeight=${height}` : ''}${width ? `&maxWidth=${width}` : ''}`;
    };
    this._reportAbuseLink = path => (path ? `/${this._lowercase(path)}` : '/');
    this._requestBoardLink = (path, id='') => (`/request-board/${this._lowercase(path)}${this._slash(id)}?returnUrl=${encodeURIComponent(this.getPath())}`);
  }

  getPath = () => window.location.href;

  getOrigin = () => window.location.origin;

  createPath = (type, ...args) => {
    switch(type) {
      case 'feedback': return this._feedbackLink(...args);
      case 'favorites': return this._favoritesLink(...args);
      case 'category': return this._categoryLink(...args);
      case 'thread': return this._threadLink(...args);
      case 'rss': return this._rssLink(...args);
      case 'profile': return this._profileLink(...args);
      case 'author-post': return this._authorPostsLink(...args);
      case 'report-abuse': return this._reportAbuseLink(...args);
      case 'request-board': return this._requestBoardLink(...args);
      default: {
        console.error('Invalid type argument');
        return '/';
      }
    }
  }
  getImageIdToLink = (imageId, height='80', width='60') => this._createImgLink('60564', imageId, height, width);

  getAttachmentLinkFromId = (id) => this._createImgLink('60623', id);
}

export default new URLService()
