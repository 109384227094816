import { call, put, takeEvery } from 'redux-saga/effects';
import { appName } from '../config';
import api from '../services/threads-service';
import { Record } from 'immutable';
import { showError } from './common/alert';
import { createSelector } from 'reselect';
import PageLinkModel from '../components/common/old-pagination/model';
import pageSizes, { getSizes } from '../components/common/paging/mock';

export const moduleName = 'manage-spam';
export const prefix = `${appName}/${moduleName}`;
export const fetchPrefix = `${prefix}/fetch`;
export const selectPrefix = `${prefix}/select`;
export const deletePrefix = `${prefix}/delete`;

function messageOf(e) {
  return e.response && e.response.data && e.response.data.message ? e.response.data.message : e.message;
}

const action = type => (payload = null) => ({
  type,
  payload
});

export const MANAGE_SPAM_REQUEST = `${fetchPrefix}/REQUEST`;
export const MANAGE_SPAM_SUCCESS = `${fetchPrefix}/SUCCESS`;
export const MANAGE_SPAM_RESET = `${fetchPrefix}/RESET`;
export const MANAGE_SPAM_SELECT = `${selectPrefix}/ONE`;
export const MANAGE_SPAM_SELECT_ALL = `${selectPrefix}/ALL`;
export const MANAGE_SPAM_DELETE_REQUEST = `${deletePrefix}/REQUEST`;
export const MANAGE_SPAM_DELETE_SUCCESS = `${deletePrefix}/SUCCESS`;

export const requestManageSpam = action(MANAGE_SPAM_REQUEST);
export const resetManageSpam = action(MANAGE_SPAM_RESET);
export const receiveManageSpam = (items, query) => action(MANAGE_SPAM_SUCCESS)({
  items,
  query
});
export const selectThread = (id, path) => action(MANAGE_SPAM_SELECT)({ id, path });
export const selectAllThreads = action(MANAGE_SPAM_SELECT_ALL);
export const requestDeleteSpam = (data) => action(MANAGE_SPAM_DELETE_REQUEST)({ data });
export const requestDeleteSpamSuccess = action(MANAGE_SPAM_DELETE_SUCCESS);

export const Parameters = ['authorEmail', 'authorUserId', 'authorUsername', 'endDate', 'startDate', 'subject'];

export const initialState = {
  items: null,
  query: null
};

export function* fetchManageSpam({ payload: query }) {
  try {
    const { data: { threads, paging } } = yield call(api.searchThreads, query);
    threads.forEach(item => {
      item.selected = false;
    });
    query.assignPaging(paging);
    threads.forEach(thread => { thread.selected = false; });
    yield put(receiveManageSpam(threads, query));
  } catch (e) {
    yield put(showError(messageOf(e)));
  }
}

export function isSelected(items) {
  return items.some(item => item.selected);
}

export function selectState(state) {
  return state[moduleName];
}

export const selectSelectedIds = createSelector(
  selectState,
  state => state.get('items')
    .filter(item => item.selected)
    .map(item => ({
      path: item.path,
      threadId: item.id
    }))
);

export function* deleteSpam({ payload: items }) {
  let body = items.data.map(({path, id}) => ({path: path, threadId: id}));
  try {
    if(!body) return;
    yield call(api.deleteWithBody, body);
    yield put(requestDeleteSpamSuccess());
  } catch (e) {
    yield put(showError(messageOf(e)));
  }
}

export const ReducerRecord = new Record(initialState);

export default function reducer(state = ReducerRecord({}), { type, payload }) {
  switch (type) {
    case MANAGE_SPAM_SUCCESS:
      return state
        .set('items', payload.items)
        .set('query', payload.query);
    case MANAGE_SPAM_SELECT:
      return state
        .set('items', state.get('items')
          .map(item => (
            payload.id === item.id && payload.path === item.path
              ? { ...item, selected: !item.selected }
              : item
          ))
        );
    case MANAGE_SPAM_RESET:
      return state
        .set('items', null);
    case MANAGE_SPAM_SELECT_ALL:
      return state
        .set('items', state.get('items')
          .map(item => ({
            ...item,
            selected: payload
          }))
        );
    case MANAGE_SPAM_DELETE_SUCCESS:
      return state
        .set('items', state.get('items')
          .filter(item => !item.selected)
        );
    default:
      return state;
  }
}

export const selectItems = createSelector(
  selectState,
  state => state.get('items')
);

export const selectQuery = createSelector(
  selectState,
  state => {
    const pageModel = new PageLinkModel(state.get('query') || {});
    pageModel.sizes = getSizes(pageSizes.HUNDRED);
    return pageModel;
  }
);

export function* saga() {
  yield takeEvery(MANAGE_SPAM_REQUEST, fetchManageSpam);
  yield takeEvery(MANAGE_SPAM_DELETE_REQUEST, deleteSpam);
}
