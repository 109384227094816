/* eslint-disable max-len */
import { defineMessages } from 'react-intl';
import React from 'react';

export default defineMessages({
  title: 'Request New Board',
  topicTitle: 'Requests for a new board to be created may be submitted for the following topics:',
  topic1: 'Surname (Last Name) - Requests must be for a single surname only.',
  topic2: 'Localities - Suggested localities are countries, states, provinces, departments, shires, counties/parishes or similar.',
  topic3: 'Genealogical Research Topics - Requested topics must be specific subjects that would be of interest to a number of researchers.',
  topicFooter: 'Before requesting a new board, please use the "Find a Board" search at the bottom of most pages to determine if the board already exists.',
  boardName: 'Board Name',
  boardNameRequired: 'Board Name is required.',
  comments: 'Comments',
  checkboxTitle: 'I would like to administer this board.',
  submit: 'Submit',
  cancel: 'Cancel',
});
