import { call, put, spawn, take, takeEvery } from 'redux-saga/effects';
import { OrderedSet, Record } from 'immutable';
import { createSelector } from 'reselect';

import { appName } from '../config';
import api from '../services/contents-service';
import { Http } from '../constants';

/**
 * Constants
 */
export const moduleName = 'home-category-page';
export const prefix = `${appName}/${moduleName}`;

export const GET_CONTENT_REQUEST = `${prefix}/GET_CONTENT_REQUEST`;
export const GET_CONTENT_SUCCESS = `${prefix}/GET_CONTENT_SUCCESS`;
export const GET_CONTENT_FAIL = `${prefix}/GET_CONTENT_FAIL`;

export const SET_CATEGORY_TO_FAVORITE = `${prefix}/SET_CATEGORY_TO_FAVORITE`;
export const DELETE_CATEGORY_FROM_FAVORITE = `${prefix}/DELETE_CATEGORY_FROM_FAVORITE`;

export const SET_ALERT_TO_FAVORITE = `${prefix}/SET_ALERT_TO_FAVORITE`;
export const DELETE_ALERT_FROM_FAVORITE = `${prefix}/DELETE_ALERT_FROM_FAVORITE`;



/**
 * Reducer
 */

export const SurnameRecord = Record({
  active: [],
  letters: []
});

export const OptionsRecord = Record({
  posts: null,
});

export const EntitiesRecord = Record({
  title: null,
  breadcrumbs: null,
  userRole: null,
  nodeType: null,
  deleted: null,
  readOnly: null,
  isFavourite: null,
  isAlert: false,
  surnames: SurnameRecord({}),
  categories: OrderedSet([]),
  boards: null,
  threads: null,
  gatewayAddress: null,
  options: OptionsRecord([])
});

export const ReducerRecord = Record({
  loading: true,
  entities: EntitiesRecord({}),
  err: null
});




export default function reducer(state = ReducerRecord({}), { type, payload, err }) {
  switch (type) {
    case GET_CONTENT_REQUEST:
        return state
          .set('loading', true)
          .set('err', null);

    case GET_CONTENT_SUCCESS:
      return state
              .set('loading', false)
              .set('entities', EntitiesRecord(payload));


    case GET_CONTENT_FAIL:
        return state
                .set('loading', false)
                .set('entities', EntitiesRecord())
                .set('err', err);

    // cases for favorites
    case SET_CATEGORY_TO_FAVORITE:
      return state.setIn(['entities', 'isFavourite'], true);
    case DELETE_CATEGORY_FROM_FAVORITE:
      return state.setIn(['entities', 'isFavourite'], false);

    case SET_ALERT_TO_FAVORITE:
      return state.setIn(['entities', 'isAlert'], true)
    case DELETE_ALERT_FROM_FAVORITE:
      return state.setIn(['entities', 'isAlert'], false)


    default:
      return state;
  }
}

/**
 * Action
 */

export const getContent = (path, itemsPerPage, page) => ({
  type: GET_CONTENT_REQUEST,
  payload: [path, itemsPerPage, page]
});

/**
 * Selector
 */
export const stateSelector = state => state[moduleName];
export const loadingSelector = createSelector(stateSelector, state => state.loading);
export const entitiesSelector = createSelector(stateSelector, state => state.entities.toJS());
export const errorSelector = createSelector(stateSelector, state => state.err);
export const isFavoriteSelector = createSelector(entitiesSelector, entities => entities.isFavourite);
export const isAlertSelector = createSelector(entitiesSelector, entities => entities.isAlert);
export const nodeTypeSelector = createSelector(entitiesSelector, entities => entities.nodeType);
export const threadsSelector = createSelector(entitiesSelector, entities => entities.threads);
export const categoryUserRoleSelector = createSelector(entitiesSelector, entities => entities.userRole);
// links admin tool
export const volunteerLinkSelector = createSelector(
  threadsSelector, threads => threads && threads.tools && threads.tools.volunteerLink
)
export const infoLinkSelector = createSelector(
  threadsSelector, threads => threads && threads.tools && threads.tools.infoLink
)



/**
 * Saga
 */

export const getContentSaga = function* ({ payload }) {
  try {
    const { data } = yield call(api.getContent, ...payload);
    yield put({
      type: GET_CONTENT_SUCCESS,
      payload: data
    });
  } catch (err) {
    if(!err.response) {
      err.messageKey = 'unavailable';
      yield put({ type: GET_CONTENT_FAIL, err });
      return null;
    }

    let status = err.response.status;
    if (status === Http.NotFound) {
      err.messageKey = 'pageNotFound';
      yield put({ type: GET_CONTENT_FAIL, err });
      return null;
    }
    err.messageKey = 'error';
    yield put({ type: GET_CONTENT_FAIL, err });
  }
};





export const saga = function* () {
  yield takeEvery(GET_CONTENT_REQUEST, getContentSaga);
};
