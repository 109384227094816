import React from 'react';
import RenameBoard from '../../board/rename';
import m from './messages';
import { injectIntl } from 'react-intl';

export function RenameCategory() {
  return <RenameBoard messages={m} tracking="rename-category" />;
}

export default injectIntl(RenameCategory);
