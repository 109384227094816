import React from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';

// data
import { layoutSelector, LAYOUT_VIEW_FLAT } from '../../../ducks/thread-page';
import { asAdminAuthSelector } from '../../../ducks/user';
// views
import DeletedMessage from './deleted-message';
import ToggleBtnView from './toggle-btn-view';
import SortRepliesView from '../select-replies-view';
import Attachments from '../attachments';
import Pagination from '../../common/pagination';
import ListReplies from './list-replies'
import TableReplies from './table-replies';
import ThreadTool from '../tools';
import ThreadIndicator from '../indicator';
// settings
import config from '../../../config';
import { ROLE_TYPES } from '../../../constants';


export default function ThreadBody({ userRole, thread, replies, repliesPaging, options: { posts, moderation } }) {
  if (!thread) return null;
  // from redux
  const viewType = useSelector(layoutSelector);
  const asAdminAuth = useSelector(asAdminAuthSelector);
  const isFlatView = viewType === LAYOUT_VIEW_FLAT;
  const { body, userMarkedDeleted, markedDeletedBy, updated, user: authorThread, viewed } = thread;

  const showIndicator = !config.institution;
  const isReadOnly = !posts;
  const isGuest = userRole && userRole === ROLE_TYPES.guest;
  const hasItems = !!replies.length;

  function renderReplies() {
    if (!Array.isArray(replies) || !replies.length) return null;
    return isFlatView
      ? <ListReplies isGuest={isGuest} replies={replies} showIndicator={showIndicator} isAuthenticated={asAdminAuth} institution={config.institution} />
      : <TableReplies replies={replies} showIndicator={showIndicator} isAuthenticated={asAdminAuth} institution={config.institution} />
  }

  const getBodyMessages = () => (
    <p className='thread__body-msg'>
    { userMarkedDeleted
      ? <DeletedMessage markedDeletedBy={markedDeletedBy} updated={updated} />
      : body }
    </p>
  )

  return (
    <div className='thread__body'>
      <div className='thread__body-meta'>
        { body && getBodyMessages() }
        { thread.attachments &&
          (<div className='thread__body-attachments'>
              <Attachments attachments={thread.attachments} />
            </div>)
          }
        { (!isFlatView || !replies.length) &&
            (<div className='thread__body-tools'>
              <ThreadTool isReadOnly={isReadOnly} userMarkedDeleted={!!userMarkedDeleted} authorThreadId={authorThread.userId} />
            </div>)
        }
      </div>
      <div className='thread__content'>
        <div className='thread__content-header'>
          {(Array.isArray(replies) && replies.length)
            ? (<div className='thread__content-settings'>
                <ToggleBtnView />
               { replies && isFlatView && <SortRepliesView replies={replies} />}
              </div>)
            : null
          }
          { repliesPaging && (
            <div className='thread__content-paging'>
              <Pagination hasItems={hasItems} paging={repliesPaging} showItemsPerPage={false} />
            </div>)
          }
          <div className='thread__content-table'>
            { renderReplies() }
          </div>
          { repliesPaging && (
            <div className='thread__content-paging bottom'>
              <Pagination paging={repliesPaging} />
            </div>)
          }
          { showIndicator && !isGuest && (
            <div className='thread__content-indicator'>
              <ThreadIndicator withMsg />
            </div>)
          }
        </div>
      </div>
    </div>
  )
}


ThreadBody.propTypes = {
  thread: PropTypes.object,
  replies: PropTypes.array,
  repliesPaging: PropTypes.object,
}


