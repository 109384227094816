/* eslint-disable max-len */
import HttpServices from './index';

class FavoritesServices extends HttpServices {
    getAllFavorites = () => this.http.get('/favorites');
    
    getByTypeFavorites = (type, itemsPerPage = 'TEN', page = 1) => this.http.get(`/favorites/${type.toUpperCase()}?itemsPerPage=${itemsPerPage}&page=${page}`);

    addFavoritesAuthor = userId => this.http.post(`/favorites/authors/${userId}`);

    deleteFavoritesAuthor = userId => this.http.delete(`/favorites/authors/${userId}`);

    addFavoritesThread = (path, threadId) => this.http.post(`/favorites/threads/${threadId}`, null, this._setHeaderBoardsPath(path));

    deleteFavoritesThread = (path, threadId) => this.http.delete(`/favorites/threads/${threadId}`, this._setHeaderBoardsPath(path));


    removeFromFavorites = (path) => this.http.delete('/favorites/nodes', this._setHeaderBoardsPath(path));

    addToFavorite = path => this.http.post('/favorites/nodes', null, this._setHeaderBoardsPath(path));
}

export default new FavoritesServices();