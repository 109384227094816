import React, { isValidElement } from 'react';
import moment from 'moment';
import url from '../../url-service';

export const DateFormat = 'MM/DD/YYYY';
export const dateTimeFormat = 'DD MMM YYYY h:mm A';
export const Timezone = { UTC: 'UTC', Local: '' };
export const toTimezone = t => t.utc();
export const ZeroTime = toTimezone(moment(0));
Object.freeze(Timezone);

const incorrectTimeMessage = time => `Time value "${time}" of type ${typeof time} is not correct!`;

export function convertUTCDateTime(time, format = dateTimeFormat) {
  if (time) {
    const date = moment(time);
    if (date.isValid()) {
      return toTimezone(date).format(format);
    }
  }
   if (0 === time) {
    return ZeroTime.format(format);
  }
  console.error(incorrectTimeMessage(time));
  return '';
}





export function parseQueryUrl(search) {
  if (!search || typeof search !== 'string' || !search.trim().length) {
    return false;
  }
  const obj = {};
  const searchParams = new URLSearchParams(search);

  for (const arrEntries of searchParams.entries()) {
    obj[arrEntries[0]] = arrEntries[1];
  }

  return obj;
}

export function stringifyQueryUrl(params = {}, sep = '&', eq = '=') {
  const parts = [];
  Object.keys(params).sort().forEach(key => {
    const encodedKey = encodeURIComponent(key);
    const encodedValue = encodeURIComponent(params[key]);
    parts.push(`${encodedKey}${eq}${encodedValue}`);
  });
  return parts.join(sep);
}

export function getSiteSettingsClientSide() {
  try {
    return JSON.parse(document.getElementById('initialState').innerHTML).siteSettingsClientSide;
  } catch (e) {
    return {};
  }
}

export function sortByDateDesc(currentItem, nextItem) {
  if (!isValidElement(currentItem.lastPost) || !isValidElement(nextItem.lastPost)) return -1;

  const { time: currentTimestamp } = currentItem.lastPost.props;
  const { time: nextTimestamp } = nextItem.lastPost.props;
  return currentTimestamp > nextTimestamp ? -1 : 1;
}

export const confirmation = (message, yes, no = () => {}) => (...args) => {
  if (window.confirm(message)) {
    yes(...args);
  } else {
    no(...args);
  }
};

export const highlightMatchesText = (str, matches) => {
  if (!str || !matches) return str;
  const inStr = str.toLowerCase();
  const matchesStr = matches.toLowerCase();

  const indexStart = inStr.indexOf(matchesStr);
  if (indexStart === -1) return str;

  const indexEnd = indexStart + matchesStr.length;

  const firstPart = str.slice(0, indexStart);
  const matchesPart = str.slice(indexStart, indexEnd);
  const endPart = str.slice(indexEnd);

  return (
    <span className="text">
      {firstPart}
      <span className="text-matches">{matchesPart}</span>
      {endPart}
    </span>
  );
};

export function parseDate(string) {
  if (!string) {
    return '';
  }
  string = string.trim();
  if (string.includes('/')) {
    return moment.utc(string, 'MM/DD/YYYY')
      .toDate();
  }
  if (string.includes('.')) {
    return moment.utc(string, 'DD.MM.YYYY')
      .toDate();
  }
  const m = moment.utc(string);
  if (m.isValid()) {
    return m.toDate();
  }
  return '';
}



export function convertToDate(time, format = dateTimeFormat) {
  if (!time) {
    // console.error(`Argument time is missing!`);
    return '';
  }
  const t = moment(time);
  if (t.isValid()) return t.format(format);

  console.error(incorrectTimeMessage(time));
  return '';
}



export const getUniqueId = () => 'id-' + Math.random().toString(36).substr(2, 16);

export const getTypeOf = (item) => Object.prototype.toString.call(item).slice(8, -1);


export const mapObjToQueryString = (rootObj, addObjParams = {}) => {
  const entries = Object.entries({...rootObj, ...addObjParams});
  const lastIndex = entries.length - 1;
  return entries.reduce((acc, item, index) => {
    if (index < lastIndex) return acc + `${item[0]}=${item[1]}&`;
    return acc + `${item[0]}=${item[1]}`;
  }, '?');
}




export const addOrReplaceQueryUrl = (curQueryUrl, queryParams) =>  { // queryParams must be object  { queryName: queryValue }
if (!queryParams || getTypeOf(queryParams) !== 'Object') {
    console.error(`Invalid query argument!`);
    return curQueryUrl;
  }

  const curParsedQuery = new URLSearchParams(curQueryUrl);

  for (const key in queryParams) {
    if (curParsedQuery.has(key)) {
      curParsedQuery.delete(key);
    }
    curParsedQuery.append(key, queryParams[key]);
  }
  return curParsedQuery.toString();
}

export const replaceBarSymbolToGreaterThan = (string) => {
  if (!string) {
    console.error('Argument string is required!')
    return '';
  }

  const regexBarChar = /\|/g;
  return string.replace(regexBarChar, ' > ').trim();
}


export function escapeSpecialSymbols(string) {
  if (!string || typeof string !== 'string')  return '';
  
  return string.replace(/[.*+?^${}()|[\]\\]/g, '\\$&'); 
} 

export function transformAttachmentIdToImgPath(attachments, content) {
  if (!Array.isArray(attachments) || typeof content !== 'string') {
    console.error('One of the arguments is of the wrong type!');
    return;
  }

  if (!attachments.length) return content;

  /**
   * replace <img src="DSI:attachment-3,Meerkat-shutterstock_410213.jpg:EndDSI"> 
   * to <img src="http://path...">
   */

  attachments.forEach(({ attachmentId, name, id }) => {
    name = escapeSpecialSymbols(name);
    const searchTemplateRegex = new RegExp(`DSI:${attachmentId},${name}:EndDSI`, 'ig');
    const replaceTemplate = url.getAttachmentLinkFromId(id);
    content = content.replace(searchTemplateRegex, replaceTemplate);
  })
  
  return content;
}