import React, { useRef, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { FormattedMessage, injectIntl } from 'react-intl';

import m from './messages';
import { createAbuse } from '../../ducks/report-abuse';

export default injectIntl(props => {
  // redux
  const dispatch = useDispatch();
  // routing
  const { path, id } = useParams();

  const minCommentLength = 3;
  const optionKeys = ['SELECT', 'SPAM', 'OBSCENITY', 'IMPERSONAL', 'PERSONAL ATTACKS', 'OTHER'];
  const defaultOption = props.intl.formatMessage(m[optionKeys[0]]);
  const [option, setOption] = useState(defaultOption);
  const [comment, setComment] = useState('');

  const states = ['required', 'error', 'success'];
  const [state, setState] = useState(states[0]);
  const [commentState, setCommentState] = useState(states[0]);
  const [disabled, setDisabled] = useState(false);

  const selectEl = useRef(null);
  const textareaEl = useRef(null);

  const handleSelectChange = e => {
    const { value } = e.target;
    setOption(value);
    setState(value !== defaultOption ? states[2] : states[1]);
  };

  const handleCommentChange = e => setComment(e.target.value);

  const handleCommentBlur = e => {
    const { value } = e.target;
    setCommentState(value.length <= minCommentLength ? states[1] : states[2]);
  };

  const handleSubmit = () => {
    if (option === defaultOption && comment.length <= minCommentLength) {
      setState(states[1]);
      setCommentState(states[1]);
      selectEl.current.focus();
      return;
    }
    if (option === defaultOption) {
      setState(states[1]);
      selectEl.current.focus();
      return;
    }
    if (comment.length <= minCommentLength) {
      setCommentState(states[1]);
      textareaEl.current.focus();
      return;
    }

    setDisabled(true);

    const body = {
      comment,
      objectionType: option,
      threadId: id
    };
    dispatch(createAbuse(path, id, body));
  };

  const objClassValue = () => {
    if (state === states[0]) {
      return 'required';
    }
    if (state === states[1]) {
      return 'error';
    }
    return 'success';
  };

  return (
    <div className="abuse-form">
      <div className="abuse__field-wrap">
        <p className={`abuse__title abuse__title--${objClassValue()}`}>
          <FormattedMessage {...m.title1} />
          {state === states[1] ? (
            <span className="abuse__title--error-message icon"><FormattedMessage {...m.titleErrorMessage} /></span>
          ) : null}
        </p>
        <div className="abuse__field">
          <select ref={selectEl} className={`abuse__select--${state}`} onChange={handleSelectChange}>
            {optionKeys.map(nameKey => (
              <option className="abuse__select-option" key={nameKey}>
                {props.intl.formatMessage(m[nameKey])}
              </option>
            ))}
          </select>
        </div>
      </div>
      <div className="abuse__field-wrap">
        <p className={`abuse__title abuse__title--${commentState}`}><FormattedMessage {...m.title2} /></p>
        <div className="abuse__field">
          <textarea
            ref={textareaEl}
            className={`abuse__textarea--${commentState}`}
            onChange={handleCommentChange}
            onBlur={handleCommentBlur}
          />
        </div>
      </div>
      <div className="abuse__btns-wrap">
        <button disabled={disabled} type="button" className="ancBtn orange" onClick={handleSubmit}>
          <FormattedMessage {...m.formSubmit} />
        </button>
        <Link className="form__link" to={`/${path}/${id}`}>
          <FormattedMessage {...m.formLink} />
        </Link>
      </div>
    </div>
  );
});
