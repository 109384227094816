const pageSizes = {
  TEN: 10,
  TWENTY_FIVE: 25,
  FIFTY: 50,
  HUNDRED: 100,
  THOUSAND: 1000
};

export const getMinPageSize = () => Math.min(...Object.values(pageSizes));

export default pageSizes;

export function getSizes(max = pageSizes.FIFTY) {
  const items = [];
  for (const name in pageSizes) {
    const value = pageSizes[name];
    if (value > max) {
      break;
    }
    items.push({ name, value });
  }
  return items;
}

export function sizeNameOf(value) {
  for (const name in pageSizes) {
    if (pageSizes[name] === value) {
      return name;
    }
  }
  return '';
}

export const countsItems = getSizes().map(item => ({
  name: item.name.toLowerCase(),
  value: item.value
}));

export const hundredSizes = getSizes(pageSizes.HUNDRED);

export const defaultPageSize = countsItems[0];
