import { defineMessages } from 'react-intl';

  export default defineMessages({
    success_add: 'This Message Board {name} has been saved to your favorites.',
    success_remove: 'This Message Board {name} has been removed from your favorites.',
    fail_add: 'This Message Board {name} was not saved to your favorites.',
    fail_remove: 'This Message Board {name} was not saved to your favorites.',


    success_add_alert: 'This Message Board has been saved to your alerts.',
    success_remove_alert: 'This Message Board has been removed from your alerts.',
    fail_add_alert: 'This Message Board was not saved to your alerts.',
    fail_remove_alert: 'This Message Board was not saved to your alerts.',
  });
  
