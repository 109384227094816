import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useParams } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';
import Progress from '../../../common/progress';

// category detail
import { isFavoriteSelector as isFavoriteCategorySelector, nodeTypeSelector} from '../../../../ducks/home-category-page';
import { addRemoveCategoryFavorite, loadingCategorySelector } from '../../../../ducks/category-favorite';

// thread detail
import { isFavoriteSelector as isFavoriteThreadSelector } from '../../../../ducks/thread-page';
import { addRemoveThreadFavorite, loadingThreadSelector } from '../../../../ducks/thread-favorite';


import m from '../messages';
import { CATEGORY_TYPES } from '../../../../constants';



export default function AddRemoveFavorites() {
  let nodeType = useSelector(nodeTypeSelector);
  const { path, threadId } = useParams();

  if (!nodeType || threadId) nodeType = CATEGORY_TYPES.thread;
  nodeType = nodeType.toUpperCase();

  const dispatch = useDispatch();


  // get needed (category or thread page) selector
  const isThreadPage = nodeType === CATEGORY_TYPES.thread;
  const isFavoriteSelector = isThreadPage ? isFavoriteThreadSelector : isFavoriteCategorySelector;
  const loadingSelector = isThreadPage ? loadingThreadSelector : loadingCategorySelector;

  // data from redux
  const isFavorite = useSelector(isFavoriteSelector);
  const loading = useSelector(loadingSelector);


  const handleButtonClick = () => {
    if (loading) return false;
    if (isThreadPage) return dispatch(addRemoveThreadFavorite(path, threadId))
    return dispatch(addRemoveCategoryFavorite(path))
  }


  function getAddTitle() {
    if (nodeType === CATEGORY_TYPES.category) return <FormattedMessage {...m.addFavorite} />;
    if (nodeType === CATEGORY_TYPES.board) return <FormattedMessage {...m.addFavoriteBoard} />;
    return <FormattedMessage {...m.addFavoriteThread} />
  }

  function getRemoveTitle() {
    if (nodeType === CATEGORY_TYPES.category) return <FormattedMessage {...m.removeFavorite} />;
    if (nodeType === CATEGORY_TYPES.board) return <FormattedMessage {...m.removeFavoriteBoard} />;
    return <FormattedMessage {...m.removeFavoriteThread} />
  }

  function renderButtonTitle() {
    if (isFavorite) return getRemoveTitle();
    return getAddTitle();
  }

  return (
    <li className={`menu-nav__item${ loading ? ' active' : '' }`}> 
      <button className={`menu-nav__add-remove-btn icon${ isFavorite ? ' active': ''}`} onClick={handleButtonClick}>
        { renderButtonTitle() }
      </button>
      {' '}
      (<Link to={`/favorites/${nodeType.toLowerCase()}s`}>
        <FormattedMessage {...m.seeAll} />
      </Link>)
      { loading && <Progress small /> }
    </li>
  )
}

