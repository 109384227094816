import React from 'react';
import { useDispatch } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import { MenuItem } from '../../../menu';
import m from '../messages';
import {basename} from '../../../../config';

import { fetchBoardInfo } from '../../../../ducks/board-info';


export const ListAdminLink = ({ path }) => (
  <MenuItem classModificator="icon iconList" to={path ? `/admin/list/${path}` : '/admin/list/'} >
    <FormattedMessage {...m.listAdmins} />
  </MenuItem>
)

export const EditOptionsLink = ({ path }) => (
  <MenuItem classModificator="icon iconEdit" to={path ? `/admin/options/${path}`: '/admin/options'}>
    <FormattedMessage {...m.editOptions} />
  </MenuItem>)

export const CreateNewCategoryLink = ({ path }) => (
  <MenuItem classModificator="icon iconSettings" to={path ? `/admin/category/create/${path}`: '/admin/category/create'}>
    <FormattedMessage {...m.createCategory} />
  </MenuItem>)


export const CreateNewBoardLink = ({ path }) => (
  <MenuItem classModificator="icon iconSettings" to={path ? `/admin/board/create/${path}` : '/admin/board/create'}>
    <FormattedMessage {...m.createBoard} />
  </MenuItem>
)



export const RenameCategoryLink = ({ path }) => path //
  ? (<MenuItem classModificator="icon iconSettings" to={`/admin/category/rename/${path}`}>
      <FormattedMessage {...m.renameCategory} />
    </MenuItem>)
  : null

export const RecycleBinLink = ({ path }) => path
  ? (<MenuItem classModificator="icon iconSettings" to={`/admin/recycle-bin/${path}`}>
    <FormattedMessage {...m.recycleBin} />
  </MenuItem>)
  : null

export const MoveCategoryLink = ({ path }) => path
  ? (<MenuItem classModificator="icon iconMove" to={`/admin/category/move/${path}`}>
      <FormattedMessage {...m.moveCategory} />
    </MenuItem>)
  : null

export const DeleteCategoryLink = ({ path }) => path
  ? (<MenuItem classModificator="icon iconTrash" to={`/admin/category/delete/${path}`}>
      <FormattedMessage {...m.deleteCategory} />
    </MenuItem>)
  : null

// Board Links
export const RenameBoardLink = ({ path }) => path //
  ? (<MenuItem classModificator="icon iconSettings" to={`/admin/board/rename/${path}`}>
      <FormattedMessage {...m.renameBoard} />
    </MenuItem>)
  : null

export const MoveBoardLink = ({ path }) => path
  ? (<MenuItem classModificator="icon iconMove" to={`/admin/board/move/${path}`}>
      <FormattedMessage {...m.moveBoard} />
    </MenuItem>)
  : null

export const MergeBoardLink = ({ path }) => path
  ? (<MenuItem classModificator="icon iconMerge" to={`/admin/board/merge/${path}`}>
      <FormattedMessage {...m.mergeBoard} />
    </MenuItem>)
  : null

export const AddBoardPathLink = ({ path }) => path
  ? (<MenuItem classModificator="icon iconAdd" to={`/admin/board/additional/${path}`}>
      <FormattedMessage {...m.addPath} />
    </MenuItem>)
  : null

export const DeleteBoardLink = ({ path }) => path
  ? (<MenuItem classModificator="icon iconTrash" to={`/admin/board/delete/${path}`}>
      <FormattedMessage {...m.deleteBoard} />
    </MenuItem>)
  : null

export const EditBoardLink = ({ path }) => path
  ? (<MenuItem classModificator="icon iconEdit" to={`/admin/board/info/edit/${path}`}>
      <FormattedMessage {...m.editBoard} />
    </MenuItem>)
  : null

export const EmailBoardLink = () => (
  <MenuItem classModificator="icon iconEmail">
    <a href="mailto:messageboards@rootsweb.com"><FormattedMessage {...m.emailStaff} /></a>
  </MenuItem>)


export const RSSLink = ({ path }) => path
 ? (<MenuItem classModificator="icon iconRss">
      <button
        className='menu-nav__item-btn'
        type='button'
        onClick={() => location.href = `${basename}/rss/${path}`}
      >
        <FormattedMessage {...m.subscribe} />
      </button>
    </MenuItem>)
  : null


export const BoardInformationLink = ({ path }) => {
  if (!path) return null;

  const dispatch = useDispatch();

  return (
    <MenuItem classModificator="icon iconInfo">
      <button className='menu-nav__item-btn' type='button' onClick={() => dispatch(fetchBoardInfo(path, true))}>
        <FormattedMessage {...m.boardInformation} />
      </button>
    </MenuItem>
  )
}

export const VolunteerLink = ({ path }) => {
  if (!path) return null;

  return (
    <MenuItem classModificator="icon iconInfo" to={`/volunteer-admin/${path}`}>
      <FormattedMessage {...m.volunteer} />
    </MenuItem>
  );
}
