import React from 'react';
import { FormattedMessage } from 'react-intl';
import m from './messages';

export default function CategoryHint() {
  const category = <strong><FormattedMessage {...m.category} /></strong>;
  return (
    <div className="category-hint">
      <div className="message">
        {'* '}
        <FormattedMessage {...m.hint} values={{ category }} />
      </div>
    </div>
  );
}
