import axios from 'axios';
import { serviceOrigin, BoardsPathHeader } from '../config';
import DOMPurify from 'dompurify';
class HttpServices {
  constructor() {
    this.http = axios.create({
      baseURL: serviceOrigin,
      withCredentials: true,
      headers: {
        [BoardsPathHeader]: ''
      }
    });
    this._setInterceptors();
  }

  _setInterceptors = () => {
    this.http.interceptors.request.use(
      request =>{
      if(request.url.includes('query'))
        {
          request.url=DOMPurify.sanitize(request.url);
        }
        return request;
    }
    );
    
  };

  static createQueryForPaging(itemsPerPage, page) {
    let queryParams = '';
    if (itemsPerPage) { 
      queryParams = `?itemsPerPage=${itemsPerPage}`;
    }
    if (page) {
      const symbol = !itemsPerPage ? '?' : '&';
      queryParams = `${queryParams}${symbol}page=${page}`;
    }
    return queryParams;
  }


  _setHeaderBoardsPath = (path = '') => ({ headers: { [BoardsPathHeader]: path } });

}

export default HttpServices;
