import { spawn } from 'redux-saga/effects';
import { saga as alertsSaga } from '../ducks/alerts';
import { saga as authorOldPostsSaga } from '../ducks/old_author-posts';
import { saga as boardInfoSaga } from '../ducks/board-info';
import { saga as boardInfoEditSaga } from '../ducks/board-info-edit';
import { saga as chooseCategorySaga } from '../ducks/choose-category';
import { saga as favoritesSaga } from '../ducks/favorites';
import { saga as categoryFavoriteSaga } from '../ducks/category-favorite';
import { saga as threadFavoriteSaga } from '../ducks/thread-favorite';
import { saga as threadEditSaga } from '../ducks/thread-edit';
import { saga as threadCreateSaga } from '../ducks/thread-create';
import { saga as threadReplySaga } from '../ducks/thread-reply';
import { saga as authorFavoriteSaga } from '../ducks/author-favorite';
import { saga as alertFavoriteSaga } from '../ducks/alert-favorite';
import { saga as attachmentsSaga } from '../ducks/attachments';
import { saga as manageSpamSaga } from '../ducks/manage-spam';
import { saga as nodeOldSaga } from '../ducks/node';
import { saga as mediaOldSaga } from '../ducks/old-media';
import { saga as threadOldSaga } from '../ducks/old_thread';
import { saga as objectionOldSaga } from '../ducks/old_objection';
import { saga as optionsOldSaga } from '../ducks/options';
import { saga as popupSaga } from '../ducks/popup';
import { saga as recycleBinSaga } from '../ducks/recycle-bin';
import { saga as rightsSaga } from '../ducks/rights';
import { saga as sagaContent } from '../ducks/home-category-page';
import { saga as sagaOldCategory } from '../ducks/category';
import { saga as searchAdvancedSaga } from '../ducks/advanced-search';
import { saga as searchAdvResultSaga } from '../ducks/search-adv-result';
import { saga as nodePathSaga } from '../ducks/node-path';
import { saga as searchSaga } from '../ducks/search';
import { saga as suggestionSaga } from '../ducks/suggestion';
import { saga as threadSaga } from '../ducks/thread-page';
import { saga as userSaga } from '../ducks/user';
import { saga as userOldSaga } from '../ducks/_user';
import { saga as adminOldSaga } from '../ducks/old_admin';
import { saga as adminPanelSaga } from '../ducks/admin-panel';
import { saga as reportAbuseSaga } from '../ducks/report-abuse';
import { saga as requestBoardsSaga } from '../ducks/request-boards';
import { saga as becomeVolunteerSaga } from '../ducks/volunteer';

export default function* rootSaga() {
  yield spawn(adminPanelSaga);
  yield spawn(adminOldSaga);
  yield spawn(alertsSaga);
  yield spawn(authorOldPostsSaga);
  yield spawn(boardInfoSaga);
  yield spawn(boardInfoEditSaga);
  yield spawn(chooseCategorySaga);
  yield spawn(favoritesSaga);
  yield spawn(categoryFavoriteSaga);
  yield spawn(threadFavoriteSaga);
  yield spawn(threadEditSaga);
  yield spawn(threadCreateSaga);
  yield spawn(threadReplySaga);
  yield spawn(authorFavoriteSaga);
  yield spawn(alertFavoriteSaga);
  yield spawn(attachmentsSaga);
  yield spawn(sagaOldCategory);
  yield spawn(manageSpamSaga);
  yield spawn(mediaOldSaga);
  yield spawn(threadOldSaga);
  yield spawn(objectionOldSaga);
  yield spawn(optionsOldSaga);
  yield spawn(popupSaga);
  yield spawn(recycleBinSaga);
  yield spawn(rightsSaga);
  yield spawn(sagaContent);
  yield spawn(searchAdvancedSaga);
  yield spawn(searchAdvResultSaga);
  yield spawn(nodePathSaga);
  yield spawn(searchSaga);
  yield spawn(suggestionSaga);
  yield spawn(threadSaga);
  yield spawn(nodeOldSaga);
  yield spawn(userSaga);
  yield spawn(userOldSaga);
  yield spawn(reportAbuseSaga);
  yield spawn(requestBoardsSaga);
  yield spawn(becomeVolunteerSaga);
}
