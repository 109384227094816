import React from 'react';
import classNames from 'classnames';
import { ChildrenType } from '../utils/prop-types';
import { element, oneOfType, string } from 'prop-types';
import { spaceless } from '../../utils/strings';

export default function FieldView({ name, label, children, classes }) {
  const className = classNames(spaceless(`widget field-view ${name} ${classes}`), {
    empty: !children || children.length === 0
  });

  return (
    <span className={className}>
      <span className="label">{label}</span>
      <span className="value">{children}</span>
    </span>
  );
}

FieldView.propTypes = {
  children: ChildrenType,
  classes: string,
  label: oneOfType([string, element]).isRequired,
  name: string.isRequired

};

FieldView.defaultProps = {
  classes: '',
  children: ''
};
