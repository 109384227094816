import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { FormattedMessage, injectIntl } from 'react-intl';
import Banner from '../banner/banner-ui';

import m from './messages';
// Data
import { getContent, loadingSelector, entitiesSelector, errorSelector } from '../../ducks/home-category-page';
import { ROLE_TYPES } from '../../constants';
import { userEntitiesSelector } from '../../ducks/user';
import config from '../../config';
import { trackPageView } from '../../utils/tealium';

// Views
// import Alert from '../../common/alert';
import FavoritesOverview from '../../components/favorites/overview';
import KeywordsSearch from '../../components/searches/advanced/keywords-search';
import AdminTool, { ToolsTypes } from '../../components/admin/tools';
import PageLayout from '../../components/hoc/page-layout';
import SearchBoards from '../../components/searches/boards';
import Categories from '../../components/categories';

export function HomePage() {
  const { institution, bannerDisplay } = config;
  // data from redux
  const entities = useSelector(entitiesSelector);
  const loadingContent = useSelector(loadingSelector);
  const { role: userRole } = useSelector(userEntitiesSelector);
  const errorContent = useSelector(errorSelector);
  // redux
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getContent());
    trackPageView('home');
  }, []);

  const sectionTitle = <FormattedMessage {...m.messageBoards} />;
  const description = <FormattedMessage {...m.boardsDesc} />;

  const getFavorites = () => {
    const accessRoles = [ROLE_TYPES.superAdmin, ROLE_TYPES.admin, ROLE_TYPES.authenticated];
    if (accessRoles.includes(userRole) && !institution) return <FavoritesOverview />;
    return null;
  };

  const getBanner = () => {
    const accessRoles = [ROLE_TYPES.superAdmin, ROLE_TYPES.admin, ROLE_TYPES.authenticated];
    if (accessRoles.includes(userRole) && !institution && bannerDisplay) return <Banner />;
    return null;
  };

  return (
    <PageLayout
      title={sectionTitle}
      description={description}
      loading={loadingContent}
      error={errorContent}
      advertOpt={{
        show: true,
        position: 'top'
      }}
    >
      { getBanner() }
      { getFavorites() }
      {entities && <KeywordsSearch {...entities} /> }
      {entities && entities.surnames && <SearchBoards alphabet={entities.surnames.letters} /> }
      <AdminTool type={ToolsTypes.home} />
      <Categories {...entities} />
    </PageLayout>
  );
}

export default injectIntl(HomePage);
