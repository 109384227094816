/*
  eslint-disable import/prefer-default-export
*/
import React from 'react';

/**
 * @param {object} dictionary
 * @returns {node[]}
 */
export function options(dictionary) {
  return Object.keys(dictionary).map(id => <option key={id} value={id}>{dictionary[id]}</option>);
}

export function list(items) {
  return <ul className="list">{items}</ul>;
}
