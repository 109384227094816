import { call, put, takeEvery } from 'redux-saga/effects';
import { appName } from '../config';
import api from '../services/threads-service';
import { Record } from 'immutable';

export const moduleName = 'author-posts';
export const prefix = `${appName}/${moduleName}`;

// ---- fetch ----

export const AUTHOR_POSTS_REQUEST = `${prefix}/REQUEST`;
export const AUTHOR_POSTS_SUCCESS = `${prefix}/SUCCESS`;
export const AUTHOR_POSTS_ERROR = `${prefix}/ERROR`;

export const CLEAR_STATE = `${prefix}/CLEAR_STATE`;

export const initialState = {
  busy: false,
  message: '',
  items: null,
  title: '',
  paging: {
    currentPage: 1,
    totalPages: 1,
    itemsPerPage: 10
  }
};

function messageOf(e) {
  return e.response && e.response.data && e.response.data.message ? e.response.data.message : e.message;
}

const action = type => (payload = null) => ({
  type,
  payload
});

export const requestAuthorPosts = (userId, orderBy, page = 1) => action(AUTHOR_POSTS_REQUEST)({
  userId,
  orderBy,
  page
});

export const receiveAuthorPosts = (title, items = [], paging = initialState.paging) => action(AUTHOR_POSTS_SUCCESS)({
  title,
  items,
  paging
});

export const clearAuthorPostsState = () => ({ type: CLEAR_STATE })

export const requestAuthorPostsError = action(AUTHOR_POSTS_ERROR);

export function* fetchAuthorPosts({ payload: { userId, orderBy, page } }) {
  try {
    const { data: { title, boards: { items, paging } } } = yield call(api.getAuthorPosts, userId, orderBy, page);
    yield put(receiveAuthorPosts(title, items, paging));
  } catch (e) {
    yield put(requestAuthorPostsError(messageOf(e)));
  }
}

export const ReducerRecord = new Record(initialState);

export default function reducer(state = ReducerRecord({}), { type, payload }) {
  switch (type) {
    case AUTHOR_POSTS_REQUEST:
      return state
        .set('busy', true)
        .set('message', '');
    case AUTHOR_POSTS_SUCCESS:
      return state
        .set('paging', payload.paging)
        .set('items', payload.items)
        .set('title', payload.title)
        .set('busy', false)
        .set('message', '');
    case AUTHOR_POSTS_ERROR:
      return state
        .set('busy', false)
        .set('message', payload);

    case CLEAR_STATE:
      return state.clear()
    default:
      return state
        .set('busy', false)
        .set('message', '');
  }
}

export function* saga() {
  yield takeEvery(AUTHOR_POSTS_REQUEST, fetchAuthorPosts);
}

export function selectAuthorPosts(state) {
  return state[moduleName];
}
