import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { FormattedMessage } from 'react-intl';

// data for listening
import {
  // selector
  addedThreadSuccessSelector, adddedThreadFailSelector,
  removedThreadSuccessSelector, removedThreadFailSelector,
  // action
  resetThreadFavoriteState
} from '../../ducks/thread-favorite';
import m from './message';


export default function ThreadNotice() {
  const dispatch = useDispatch();
  // data from redux
  const addSuccess = useSelector(addedThreadSuccessSelector);
  const addFail = useSelector(adddedThreadFailSelector);
  const removeSuccess = useSelector(removedThreadSuccessSelector);
  const removeFail = useSelector(removedThreadFailSelector);

  const active = [addSuccess, addFail, removeSuccess, removeFail].find(state => state);
  if (!active) return null;


  const isSuccess = addSuccess || removeSuccess;
  const isError = addFail || removeFail;
   
  
  function getTitleMessage() {
    if (isSuccess) {
      return addSuccess 
        ? <FormattedMessage {...m.success_add} values={{ name: 'Thread' }} />
        : <FormattedMessage {...m.success_remove} values={{ name: 'Thread' }} />
    } 

    if (isError) {
      return addFail
        ? <FormattedMessage {...m.fail_add} values={{ name: 'Thread' }} />
        : <FormattedMessage {...m.fail_remove} values={{ name: 'Thread' }} />
    }

    return null;
  }

  return (
    <div className={`notice-favorite alert${ isError ? ' notice-favorite--error' : ''}`}>
      { getTitleMessage() }
      <button className='notice-favorite__btn-hide' onClick={() => dispatch(resetThreadFavoriteState())}/>
    </div>
  )
}