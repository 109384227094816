import React, { useState, useEffect, } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import { getSuggestion, suggestionsLoading, suggestionsEntities, suggestionsError } from '../../../ducks/search';
import InputField from './input-field';
import LettersSurname from './letters-surname';
import Section from '../../common/old-section';
import { FormattedMessage } from 'react-intl';
import m from './messages';
import { debounce } from 'debounce';

function SearchBoards(props) {
  const [inpValue, setInpValue] = useState(() => props.intialValue || '');
  const [errInpValue, setErrInpValue] = useState(null);
  const [selectedSuggest, setSelectedSuggest] = useState(false);
  const [activeDropdown, setActiveDropdown] = useState(false);
  const minCharsLength = 3;

  useEffect(() => () => setActiveDropdown(false), []);

  const asyncHandleChangeInput = debounce(value => {
    props.getSuggestion(value);
  }, 200);

  const handleInputChange = e => {
    const { value } = e.currentTarget;
    setInpValue(value);
    setSelectedSuggest(false);
    if (!activeDropdown) setActiveDropdown(true);
    if (value.length === 0) return setErrInpValue(null);
    if (value.length < minCharsLength) return setErrInpValue(true);
    setErrInpValue(false);
    return asyncHandleChangeInput(value);
  };

  const surnameItemClick = surnameItem => {
    setInpValue(surnameItem);
    setSelectedSuggest(true);
  };

  const handleButtonClick = () => props.history.push(`/search/boards/${inpValue}`);
 
  return (
    <Section title={<FormattedMessage {...m.title} />} classes="search search--board">
      <div className="search__wrap">
        <InputField
          type="search"
          label={<FormattedMessage {...m.surnamesOrTopics} />}
          errInpValue={errInpValue}
          inpValue={inpValue}
          handleInputChange={handleInputChange}
          surnameItemClick={surnameItemClick}
          handleButtonClick={handleButtonClick}
          selectedSuggest={selectedSuggest}
          activeDropdown={activeDropdown}
          minCharsLength={minCharsLength}
          {...props}
        />
      </div>
      { props.alphabet && <LettersSurname alphabet={props.alphabet} /> }
    </Section>
  );
}

export default withRouter(
  connect(
    state => ({
      loading: suggestionsLoading(state),
      entities: suggestionsEntities(state),
      err: suggestionsError(state)
    }),
    { getSuggestion }
  )(SearchBoards));