import React, { createContext, useContext, useState, useEffect } from 'react';
import { useSelector } from 'react-redux';

import { contentSelector } from '../../../ducks/board-info-edit';

const ContentContext = createContext();

export default function ContentProvider({ children }) {
  const reduxContent = useSelector(contentSelector); 
  const [ content, setContent ] = useState(reduxContent);
  useEffect(() => {
    setContent(reduxContent);
  }, [reduxContent]);

  return (
    <ContentContext.Provider value={{ content, setContent }}>
      { children }
    </ContentContext.Provider>
  )
}

export function useContent() {
  const context = useContext(ContentContext)
  if (context === undefined) {
    throw new Error('useContent must be used within a ContentProvider')
  }
  return context
}
