import React from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { FormattedMessage } from 'react-intl';

import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';

import { deleteAlert } from '../../ducks/alerts';
import m from './messages';

export default function AlertConfirmDelete({ itemForDelete, setItemForDelete }) {
  const dispatch = useDispatch();
  const handleClose = () => setItemForDelete(null);

  const handleDelete = () => {
    dispatch(deleteAlert(itemForDelete));
    handleClose();
  };

  return (
    <Dialog
      open={!!itemForDelete}
      onClose={handleClose}
      className="favorites__modal"
    >
      <DialogContent>
        <FormattedMessage {...m.confirm} values={{ name: itemForDelete.title }} />
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} size="small" color="secondary">
          <FormattedMessage {...m.cancel} />
        </Button>
        <Button onClick={handleDelete} size="small" color="primary">
          <FormattedMessage {...m.yes} />
        </Button>
      </DialogActions>
    </Dialog>
  );
}

AlertConfirmDelete.propTypes = {
  itemForDelete: PropTypes.object.isRequired, // object can be null or object type
  setItemForDelete: PropTypes.func.isRequired,
};
