import { spawn, take, call, put, select, takeEvery } from 'redux-saga/effects';
import { Record } from 'immutable';
import { createSelector } from 'reselect';

import { appName } from '../config';
import suggestionApi from '../services/suggestions-service';
import nodeApi from '../services/nodes-service';
import { userEntitiesSelector } from './user';
import { Http } from '../constants';

/**
 * Constants
 */

export const moduleName = 'volunteer';
export const prefix = `${appName}/${moduleName}`;

export const CHECK_EXISTS_PATH_REQUEST = `${prefix}/CHECK_EXISTS_PATH_REQUEST`;
export const CHECK_EXISTS_PATH_SUCCESS = `${prefix}/CHECK_EXISTS_PATH_SUCCESS`;
export const CHECK_EXISTS_PATH_FAIL = `${prefix}/CHECK_EXISTS_PATH_FAIL`;
export const CHECK_EXISTS_PATH_ERROR = `${prefix}/CHECK_EXISTS_PATH_ERROR`;

export const CHECK_SUBMIT_PATH_SUCCESS = `${prefix}/CHECK_SUBMIT_PATH_SUCCESS`;
export const CHECK_SUBMIT_PATH_FAIL = `${prefix}/CHECK_SUBMIT_PATH_FAIL`;
export const CHECK_SUBMIT_PATH_ERROR = `${prefix}/CHECK_SUBMIT_PATH_ERROR`;

export const SUBMIT_VOLUNTEERING_REQUEST = `${prefix}/SUBMIT_VOLUNTEERING_REQUEST`;
export const SUBMIT_VOLUNTEERING_SUCCESS = `${prefix}/SUBMIT_VOLUNTEERING_SUCCESS`;
export const SUBMIT_VOLUNTEERING_ERROR = `${prefix}/SUBMIT_VOLUNTEERING_ERROR`;

/**
 * Reducer
 */

export const ReducerRecord = Record({
  loading: false,
  err: null,
  pathExists: null,
  pathSubmitted: null,
  pathName: null
});

export default function reducer(state = new ReducerRecord(), { type, payload, err }) {
  switch (type) {
    case CHECK_EXISTS_PATH_REQUEST:
      return state
        .set('loading', true)
        .set('pathExists', null)
        .set('pathSubmitted', null);

    case CHECK_EXISTS_PATH_SUCCESS:
      return state
        .set('loading', true)
        .set('pathExists', true)
        .set('pathSubmitted', null)
        .set('pathName', payload);

    case CHECK_EXISTS_PATH_FAIL:
      return state
        .set('loading', false)
        .set('pathExists', false)
        .set('pathSubmitted', null);

    case CHECK_EXISTS_PATH_ERROR:
    case CHECK_SUBMIT_PATH_ERROR:
      return state
        .set('loading', false)
        .set('err', err);

    case CHECK_SUBMIT_PATH_SUCCESS:
      return state
        .set('loading', false)
        .set('pathExists', true)
        .set('pathSubmitted', true);

    case CHECK_SUBMIT_PATH_FAIL:
      return state
        .set('loading', false)
        .set('pathExists', true)
        .set('pathSubmitted', false);

    case SUBMIT_VOLUNTEERING_SUCCESS:
      return state
        .set('pathSubmitted', true);

    case SUBMIT_VOLUNTEERING_ERROR:
      return state
        .set('pathSubmitted', false)
        .set('err', err);
      
    default:
      return state;
  }
}

/**
 * Actions
 */

export const checkBoardPathAndSubmitted = path => ({
  type: CHECK_EXISTS_PATH_REQUEST,
  payload: path
});

export const submitVolunteering = path => ({
  type: SUBMIT_VOLUNTEERING_REQUEST,
  payload: path
});

/**
 * Selectors
 */

export const stateSelector = state => state[moduleName];
export const loadingSelector = createSelector(stateSelector, state => state.loading);
export const errorSelector = createSelector(stateSelector, state => state.err);
export const pathExistsSelector = createSelector(stateSelector, state => state.pathExists);
export const pathSubmittedSelector = createSelector(stateSelector, state => state.pathSubmitted);
export const pathNameSelector = createSelector(stateSelector, state => state.pathName);



/**
 * Sagas
 */

export const checkBoardPathAndSubmittedSaga = function*({ payload: path }) {
  const statusCodeOK = 200;
    try {
      const response = yield call(nodeApi.getNodes, path);
      if (response.status === statusCodeOK && response.data.type === 'BOARD') {
        yield put({ type: CHECK_EXISTS_PATH_SUCCESS, payload: response.data.name });
        const user = yield select(userEntitiesSelector);
        if(user){
          try {
            const response = yield call(suggestionApi.checkVolunteerSubmit, user.userId, path);
            if (response.status === statusCodeOK) {
              yield put({ type: CHECK_SUBMIT_PATH_SUCCESS });
              return;
            }
              yield put({ type: CHECK_SUBMIT_PATH_FAIL });
          } catch (err) {
            yield put({ type: CHECK_SUBMIT_PATH_ERROR, err: err });
          }
        }
      } else {
        yield put({ type: CHECK_EXISTS_PATH_FAIL });
      }
    } catch (err) {
      yield put({ type: CHECK_EXISTS_PATH_ERROR, err: err });
    }
};

export const submitVolunteeringSaga = function*() {
  while(true) {
    const { payload: path } = yield take(SUBMIT_VOLUNTEERING_REQUEST);
    try {
      yield call(suggestionApi.createRights, path);
      yield put({ type: SUBMIT_VOLUNTEERING_SUCCESS });
    } catch (err) {
      if (!err.response) {
        err.messageKey = 'unavailable';
        yield put({
          type: SUBMIT_VOLUNTEERING_ERROR,
          err
        });
        return null;
      }

      let status = err.response.status;
      if (status === Http.NotFound) {
        err.messageKey = 'pageNotFound';
        yield put({
          type: SUBMIT_VOLUNTEERING_ERROR,
          err
        });
        return null;
      }
      err.messageKey = 'error';
      yield put({
        type: SUBMIT_VOLUNTEERING_ERROR,
        err
      });
    }
  }
};

export function* saga() {
  yield takeEvery(CHECK_EXISTS_PATH_REQUEST, checkBoardPathAndSubmittedSaga);
  yield spawn(submitVolunteeringSaga);
}
