import React from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { FormattedMessage } from 'react-intl';

import { showPopup } from '../../../ducks/popup';
import { useContent } from './context-content';
import m from './messages';



export default function TextField() {
  const dispatch = useDispatch();
  const { content, setContent } = useContent();

  const handleButtonClick = () => dispatch(showPopup('EDIT_BOARD_INFO'))
  const handleFieldChange = ({ target: { value } }) => setContent(value);

  return (
    <div className='board-edit__field-wrap'>
      <p className='board-edit__field-label'>
        <FormattedMessage { ...m.textFieldLabel } />
        <button onClick={handleButtonClick} className='board-edit__field-label-icon link icon iconHelp'/>
      </p>
      <textarea 
        className='board-edit__field'
        value={content}
        onChange={handleFieldChange}
      />
    </div>
  )
}

TextField.propTypes = {
  content: PropTypes.string,
}