import React, { useState } from 'react';
import classNames from 'classnames';
import { bool } from 'prop-types';
import { connect } from 'react-redux';
import { FormattedMessage, injectIntl } from 'react-intl';
import { Link, useParams, useLocation, useHistory } from 'react-router-dom';
import Field from '../../common/field';
import Form from '../../common/form';
import m from './messages';
import QueryModel from '../../../ducks/common/query-model';
import Section from '../../common/old-section';
import { fetchPrefix } from '../../../ducks/category';
import { isBusy } from '../../../ducks/common/busy';
import { singleSpace } from '../../../utils/strings';
import {
  entitiesSelector as entitiesCategorySelector
} from '../../../ducks/home-category-page';
import {
  threadSelector
} from '../../../ducks/thread-page';


function IntlStatelessKeywordsSearch(
  {
    busy = false,
    children = null,
    intl,
    isBoard = false,
    keyword,
    setKeyword,
    submit,
    getQueryUrlParams,
    history
  }) {
  const title = isBoard
    ? <FormattedMessage {...m.searchBoards} />
    : <FormattedMessage {...m.searchBoards} />;

  const isValid = !!keyword.trim();
  const submitButtonClasses = classNames('search-link orange ancBtn', {
    loading: busy
  });



  const clickBtnToAdvSearch = () => {
    const query = new QueryModel({
      path: '/search/advanced',
      params: getQueryUrlParams()
    });
    history.push(query.toString());
  }
  return (
    <Section title={title} classes="keywords-search">
      <Form submit={submit} canSubmit={isValid}>
        <div className="ancGrid full320">
          <Field
            classes={isValid ? 'ancCol w50 filled' : 'ancCol w50 empty'}
            format={singleSpace}
            label={<FormattedMessage {...m.searchLabel} />}
            name="keyword"
            placeholder={intl.formatMessage(m.searchHint)}
            setValue={setKeyword}
            type="search"
            value={keyword}
          />
          <div className="ancCol w50 navigation noLabel">
            <button
              className={submitButtonClasses}
              disabled={!keyword || busy} 
            >{intl.formatMessage(m.search)}</button>
            <button 
              type='button'
              onClick={clickBtnToAdvSearch} 
              className="btn btn--link advanced-search-link"
            >{intl.formatMessage(m.advancedSearch)}</button>
          </div>
        </div>
        <div className="keyword-search-options">
          {children}
        </div>
      </Form>
    </Section>
  );
}

export const StatelessKeywordsSearch = injectIntl(IntlStatelessKeywordsSearch);

export function KeywordsSearch(
  {
    busy = false,
    busyFetch,
    categoryEntities,
    threadEntities,
    isBoard = false,
  }) {

  let { path } = useParams();
  const { pathname } = useLocation();
  const history = useHistory();
  const surnamePagePath = '/search/surname';
  const surnamesTitleRadioBtn = 'Surnames'
  const isSurnamePage = pathname.toLowerCase() === surnamePagePath;
  
  if (!path && isSurnamePage) path = surnamesTitleRadioBtn;



  const [keyword, setKeyword] = useState('');
  const [inCurrentBoard, setInCurrentBoard] = useState(false);

  const getQueryUrlParams = () => {
    const params = {
      keyword: keyword.trim().replace(/\s+/g, ' '),
    }
    if (path && inCurrentBoard) {
      params.path = path
      params.selectedPath = path
    }
    if (path && !inCurrentBoard) {
      params.path = path
      params.selectedPath = 'All'
    }

    return params;
  }

  function submit() {
    const query = new QueryModel({
      path: '/search/results',
      params: getQueryUrlParams()
    });
    history.push(query.toString());
  }

  const getCategoryTitle = () => {
    if (categoryEntities && categoryEntities.title) return categoryEntities.title;
    if (threadEntities && threadEntities.namePath) return threadEntities.namePath.split('|').slice(-1)[0];
    if (isSurnamePage) return surnamesTitleRadioBtn;
    return '';
  }

  let searchPath;
  if (path) {
    let currentBoard;
    if (!busyFetch) {
      currentBoard = (
        <div>
          <input
            type="radio"
            className="radio"
            id="current-board"
            checked={inCurrentBoard}
            onChange={e => setInCurrentBoard(e.target.checked)}
          />
          <label htmlFor="current-board">
            <FormattedMessage {...m.chooseCategorySearch} values={{ name: getCategoryTitle() }} />
          </label>
        </div>
      );
    }
    searchPath = (
      <div className="search-path">
        <div>
          <input
            type="radio"
            className="radio"
            id="all-boards"
            checked={!inCurrentBoard}
            onChange={e => setInCurrentBoard(!e.target.checked)}
          />
          <label htmlFor="all-boards">
            <FormattedMessage {...m.allBoards} />
          </label>
        </div>
        {currentBoard}
      </div>
    );
  }

  return (
    <StatelessKeywordsSearch
      busy={busy}
      isBoard={isBoard}
      keyword={keyword}
      path={path}
      setKeyword={setKeyword}
      submit={submit}
      getQueryUrlParams={getQueryUrlParams}
      history={history}
    >
      {searchPath}
    </StatelessKeywordsSearch>
  );
}

KeywordsSearch.propTypes = {
  busy: bool,
  busyFetch: bool,
  isBoard: bool
};

KeywordsSearch.defaultProps = {
  busy: false,
  busyFetch: false,

  isBoard: false
};

function mapStateToProps(state) {
  return {
    busyFetch: isBusy(state, `${fetchPrefix}/`),
    categoryEntities: entitiesCategorySelector(state),
    threadEntities: threadSelector(state),
    
  };
}

export default connect(mapStateToProps)(KeywordsSearch);
