import { call, put, takeEvery } from 'redux-saga/effects';
import { appName } from '../config';
import recycleBinService from '../services/nodes-service';
import threadService from '../services/threads-service';
import { Record } from 'immutable';
import { showError } from './common/alert';
import { modification, action } from '../components/utils/redux';

export const moduleName = 'recycle-bin';
export const prefix = `${appName}/${moduleName}`;

function messageOf(e) {
  return e.response && e.response.data && e.response.data.message ? e.response.data.message : e.message;
}

// ---- fetch ----

export const fetchPrefix = `${prefix}/fetch`;
export const RECYCLE_BIN_REQUEST = `${fetchPrefix}/REQUEST`;
export const RECYCLE_BIN_RECEIVE = `${fetchPrefix}/RECEIVE`;

export const requestRecycleBin = (page, path) => action(RECYCLE_BIN_REQUEST, { page, path });
export const receiveRecycleBin = payload => action(RECYCLE_BIN_RECEIVE, payload);

export const initialState = {
  items: null,
  page: 1
};

export function* fetchRecycleBin({ payload: { path, page } }) {
  try {
    const { data: { boards, categories, threads } } = yield call(recycleBinService.getRecycleBin, page, path);
    const timeOf = node => new Date(node.deletedDate).getTime();
    const sortByTime = (first, second) => timeOf(first) - timeOf(second);
    const items = [
      ...categories.map(item => ({ ...item, type: 'category' })).reverse(),
      ...boards.map(item => ({ ...item, type: 'board' })).reverse(),
      ...threads.map(item => ({ ...item, type: 'thread' })).reverse()
    ]
      .sort(sortByTime);
    yield put(receiveRecycleBin({ items, page }));
  } catch (e) {
    yield put(showError(messageOf(e)));
  }
}

export const restorePrefix = `${prefix}/restore`;
export const RECYCLE_BIN_RESTORE_REQUEST = `${restorePrefix}/REQUEST`;
export const RECYCLE_BIN_RESTORE_SUCCESS = `${restorePrefix}/SUCCESS`;

// --- delete ---

export const requestRestoreNode = (path, id, next) => action(RECYCLE_BIN_RESTORE_REQUEST, { path, id }, next);

export const restoreNode = modification(
  ({ path, id }) => (id
    ? [threadService.restoreThread, id, path]
    : [recycleBinService.restoreNode, path]
  ),
  RECYCLE_BIN_RESTORE_SUCCESS
);

export const ReducerRecord = new Record(initialState);

export default function reducer(state = ReducerRecord({}), { type, payload }) {
  if (RECYCLE_BIN_REQUEST === type) {
    return state
      .set('items', null);
  }
  if (RECYCLE_BIN_RECEIVE === type) {
    return state
      .set('items', payload.items);
  }
  return state;
}

export function selectRecycleBin(state) {
  return state[moduleName];
}

export function* saga() {
  yield takeEvery(RECYCLE_BIN_RESTORE_REQUEST, restoreNode);
  yield takeEvery(RECYCLE_BIN_REQUEST, fetchRecycleBin);
}
