import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage, injectIntl } from 'react-intl';

import m from './messages';
import cm from '../forms/messages';
import { convertToDate } from '../utils';

export default injectIntl(function ThreadMetaDetail({ repliesCount, posted, updated, classification, surnames, intl }) {
  ThreadMetaDetail.propTyps = {
    rootRepliesCount: PropTypes.number,
    posted: PropTypes.number, // value is timestamp
    classification: PropTypes.string
  }

  if (!repliesCount && !posted && !classification) return null;

  return (
    <ul className='thread-detail'>
      {repliesCount >= 0 ? <li className='thread-detail__item'><FormattedMessage {...m.replies}/>: {repliesCount}</li> : null}
      { posted ? <li className='thread-detail__item'><FormattedMessage {...m.posted}/>: {convertToDate(posted)}</li> : null }
      { classification ? <li className='thread-detail__item'><FormattedMessage {...m.classification}/>: {intl.formatMessage(cm[classification])}</li> : null }
      { updated ? <li className='thread-detail__item'><FormattedMessage {...m.edited}/>: {convertToDate(updated)}</li> : null }
      { surnames ? <li className='thread-detail__item'><FormattedMessage {...m.surnames}/>: {surnames}</li> : null }
    </ul>
  )
})
