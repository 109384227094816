import React, { useEffect, useRef } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { injectIntl } from 'react-intl';
import Field from '../../common/field';
import { categoryPathFormat } from '../../../utils/strings';
import m from './move/messages';
import CategoryHint from './category-hint';
import {
  createOptionsFetch,
  optionsMapStateToProps,
  requestFetchOptions
} from '../../../ducks/options';

export function PathField(
  {
    busy,
    intl,
    isCategory,
    label,
    message,
    path,
    requestFetchOptions,
    setPath,
  }) {
  useEffect(createOptionsFetch(busy, path, requestFetchOptions, useRef(0)), [path]);
  if (busy || !path) {
    message = '';
  } else if (!message && !isCategory) {
    message = intl.formatMessage(m.notCategory);
  }
  return (
    <Field
      about={<CategoryHint />}
      format={categoryPathFormat}
      label={label}
      message={message}
      name="path"
      setValue={value => setPath(value)}
      validationClasses="required"
      value={path}
    />
  );
}

export default connect(optionsMapStateToProps, { requestFetchOptions })(withRouter(injectIntl(PathField)));
