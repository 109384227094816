import React from 'react';
import PropTypes from 'prop-types';


export default function FiledCheckbox({ name, labelValue, handleChange, value }) {
  const fieldProps = {
    type: 'checkbox',
    name,
    className: `checkbox`,
    onChange: () => handleChange({ target: { name, value: !value } }),
    checked: value,
    id: "field-use-soundex"
  }

  return (
    <div className='form-filed form-filed--checkbox'>
      <input {...fieldProps} />
      <label for="field-use-soundex">{labelValue && <span>{labelValue}</span>}</label>
    </div>
  )
}

FiledCheckbox.propTypes = {
  handleChange: PropTypes.func,
  value: PropTypes.bool,
  readOnly: PropTypes.bool
}