import { call, put, take, spawn } from 'redux-saga/effects';
import { Record } from 'immutable';
import { createSelector } from 'reselect';

import api from '../services/auth-service';
import { appName } from '../config';
import { ROLE_TYPES } from '../constants';


/**
 * Constants
 */

export const moduleName = 'user-data';
export const prefix = `${appName}/${moduleName}`;
export const GET_USER_DATA_REQUEST = `${prefix}/GET_USER_DATA_REQUEST`;
export const GET_USER_DATA_SUCCESS = `${prefix}/GET_USER_DATA_SUCCESS`;
export const GET_USER_DATA_FAIL = `${prefix}/GET_USER_DATA_FAIL`;


/**
 * Reducer
 */

 const UserEntitiesRecord = Record({
  adminNodes: null,
  email: '',
  role: '',
  userId: '',
  username: ''
 })

 const ReducerRecord = Record({
   loading: true,
   err: null,
   userEntities: new UserEntitiesRecord({})
 })


 export default function reducer(state = new ReducerRecord(), action) {
  const { type, payload, err } = action;

  switch(type) {
    case GET_USER_DATA_SUCCESS:
        return state
                .set('loading', false)
                .set('userEntities', new UserEntitiesRecord(payload))

    case GET_USER_DATA_FAIL:
        return state
                .clear('userEntities')
                .set('loading', false)
                .set('err', err)

    default:
        return state;
  }

 }


 /**
  * Action
  */

 export const getUserData = () => ({ type: GET_USER_DATA_REQUEST })


  /**
   * Selectors
   */

  export const stateSelector = state => state[moduleName];
  export const loadingSelector = createSelector(
    stateSelector,
    state => state.loading
  )
  export const userEntitiesSelector = createSelector(
  stateSelector,
  state => state.userEntities.toJS()
  )

  // users roles
  export const isSuperAdminRoleSelector = createSelector(
    userEntitiesSelector,
    ({ role }) => ROLE_TYPES.superAdmin === role
  )

  export const isAdminRoleSelector = createSelector(
    userEntitiesSelector,
    ({ role }) => ROLE_TYPES.admin === role
  )

  export const isAuthenticatedRoleSelector = createSelector(
    userEntitiesSelector,
    ({ role }) => ROLE_TYPES.authenticated === role
  )

  // auth as next user permission
  export const asAdminAuthSelector = createSelector(
    [isSuperAdminRoleSelector, isAdminRoleSelector],
    (superAdmin, admin) => superAdmin || admin
  )


  export const asAuthenticatedSelector = createSelector(
    [asAdminAuthSelector, isAuthenticatedRoleSelector],
    (admin, authenticated) => admin || authenticated
  )


  export const errorSelector = createSelector(
    stateSelector, state => state.err
  );




   /**
    * Sagas
    */

  export const getUserDataSaga = function* () {
    while(true) {
      yield take(GET_USER_DATA_REQUEST);
      try {
        const { data } = yield call(api.getUserData);
        yield put({ type: GET_USER_DATA_SUCCESS, payload: data });
      } catch (err) {
        yield put({ type: GET_USER_DATA_FAIL, err });
      }   
    }
  }

  export const saga = function* () {
    yield spawn(getUserDataSaga)
  }