import React from 'react';
import PropTypes from 'prop-types';

// links view
import {
  ReplyLink,
  AbuseLink,
  PrintLink
} from './links';



export default function GuestList({ path, threadId, userMarkedDeleted, institution, isReadOnly }) {
  if (userMarkedDeleted) return null;

  if (institution) return (
    <nav className='thread-tool'>
      <ul className='thread-tool__list'>
        <PrintLink path={path} threadId={threadId} />
      </ul>
    </nav>
  )

  return (
    <nav className='thread-tool'>
      <ul className='thread-tool__list'>
        {!isReadOnly && <ReplyLink path={path} threadId={threadId} />}
        <AbuseLink path={path} threadId={threadId} />
        <PrintLink path={path} threadId={threadId} />
      </ul>
    </nav>
  )
}

GuestList.propTypes = {
  path: PropTypes.string,
  threadId: PropTypes.string,
  userMarkedDeleted: PropTypes.bool,
  institution: PropTypes.bool,
  isReadOnly: PropTypes.bool
}
