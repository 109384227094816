import { defineMessages } from 'react-intl';

export default defineMessages({
  copied: 'Posts to this board are copied to the "{email}" RootsWeb.com mailing list.',
  deleted: 'This category is deleted.',
  empty: 'There are no messages in this board.',
  messageBoards: 'Message Boards',
  notModerator: 'You are not an Administrator on this board.',
  readOnly: 'This board is read-only and closed to new posts.',
  moderate: 'This board requires administrator approval before messages can be viewed publicly. Until approved, this message will be visible to only the author.',
  areAdmin: 'You are a Board Administrator on this board.',
  notAdmin: 'You are not an Administrator on this board.'
});
