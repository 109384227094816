import { defineMessages } from 'react-intl';

export default defineMessages({
  title: 'Search the Boards',
  hideAdvanceSearch: 'Hide Advanced Search',
  showAdvanceSearch: 'Advanced Search',
  keywordLabel: 'Names or keywords',
  subjectLabel: 'Subject',
  authorLabel: 'Author',
  postedWithinLabel: 'Posted Within',
  surnamesLabel: 'Surnames',
  soundexLabel: 'Use Soundex',
  searchBtn: 'Search',


  // classification
  classificationLabel: 'Message Classification',
  ALL: 'All',
  BIBLE: 'Bible',
  BIOGRAPHY: 'Biography',
  BIRTH: 'Birth',
  CEMETERY: 'Cemetery',
  CENSUS: 'Census',
  DEATH: 'Death',
  DEED: 'Deed',
  IMMIGRATION: 'Immigration',
  LOOKUP: 'Lookup',
  MARRIAGE: 'Marriage',
  MILITARY: 'Military',
  OBITUARY: 'Obituary',
  PENSION: 'Pension',
  QUERIES: 'Query',
  WILL: 'Will',
  // Posted Within
  ANYTIME: 'Anytime',
  ONE_WEEK: '1 Week',
  ONE_MONTH: '1 Month',
  SIX_MONTH: '6 Months',
  ONE_YEAR: '1 Year',

  radioLabelAll: 'All Boards',
  chooseCategorySearch: '{name} - Family History & Genealogy Message Board',
  allBoards: 'All Boards',
});
