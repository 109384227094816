import React, { useEffect } from 'react';
import { withRouter } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import { threadSelector, getThreadById, clearThreadData } from '../../../ducks/thread-page';
import config from '../../../config';
import FieldView from '../../common/field-view';
import m from './messages';
import Progress from '../../common/progress';
import { threadLink } from '../../../../utils/link';
import { trackPageView } from '../../../utils/tealium';
import { convertToDate } from '../../utils';

export function PrintThreadPage({ match: { params: { id, path } } }) {
  const dispatch = useDispatch();
  const thread = useSelector(threadSelector);

  useEffect(() => {
    dispatch(getThreadById(path, id));
  }, [id, path]);

  useEffect(() => () => dispatch(clearThreadData()), []);

  useEffect(() => trackPageView(`print-message : ${path}`), []);

  if (!thread || !thread.id) {
    return <Progress />;
  }
  const namePath = thread.namePath
    .replace('||', '')
    .replace(/\|/g, ' > ');

  return (
    <div className="print-thread">
      <div className="fields">
        <FieldView
          name="path"
          label={<FormattedMessage {...m.board} />}
        >
          {namePath}
        </FieldView>
        <FieldView
          name="url"
          label={<FormattedMessage {...m.url} />}
        >
          {config.origin + threadLink(path, id)}
        </FieldView>
        <FieldView
          name="subject"
          label={<FormattedMessage {...m.subject} />}
        >
          {thread.subject}
        </FieldView>
        <FieldView
          name="author"
          label={<FormattedMessage {...m.author} />}
        >
          {thread.user.displayName}
        </FieldView>
        <FieldView
          name="posted"
          label={<FormattedMessage {...m.date} />}
        >
          { convertToDate(thread.posted) }
        </FieldView>
        <FieldView
          name="classification"
          label={<FormattedMessage {...m.classification} />}
        >
          {thread.classification}
        </FieldView>
        <FieldView
          name="surnames"
          label={<FormattedMessage {...m.surnames} />}
        >
          {thread.surnames}
        </FieldView>
      </div>
      <div className="body">{thread.body}</div>
    </div>
  );
}

export default withRouter(PrintThreadPage);
