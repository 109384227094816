import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { FormattedMessage } from 'react-intl';

import {
  // selector
  addedAlertSuccessSelector, addedAlertFailSelector,
  removedAlertSuccessSelector, removedAlertFailSelector, 
  // action
  resetAlertFavoriteState
} from '../../ducks/alert-favorite';

import m from './message';


export default function AlertNotice() {
  const dispatch = useDispatch();
  // data from redux
  const addSuccess = useSelector(addedAlertSuccessSelector);
  const addFail = useSelector(addedAlertFailSelector);
  const removeSuccess = useSelector(removedAlertSuccessSelector);
  const removeFail = useSelector(removedAlertFailSelector);

  const active = [addSuccess, addFail, removeSuccess, removeFail].find(state => state);
  if (!active) return null;


  const isSuccess = addSuccess || removeSuccess;
  const isError = addFail || removeFail;
   
  
  function getTitleMessage() {
    if (isSuccess) {
      return addSuccess 
        ? <FormattedMessage {...m.success_add_alert} />
        : <FormattedMessage {...m.success_remove_alert} />
    } 

    if (isError) {
      return addFail
        ? <FormattedMessage {...m.fail_add_alert} />
        : <FormattedMessage {...m.fail_remove_alert} />
    }

    return null;
  }

  return (
    <div className={`notice-favorite alert${ isError ? ' notice-favorite--error' : ''}`}>
      { getTitleMessage() }
      <button className='notice-favorite__btn-hide' onClick={() => dispatch(resetAlertFavoriteState())}/>
    </div>
  )
}