/* eslint-disable camelcase,quote-props,import/extensions */
import enTranslationStrings from '../i18n/translations/en';
import deTranslationStrings from '../i18n/translations/de';
import en_AUTranslationStrings from '../i18n/translations/en-AU';
import en_CATranslationStrings from '../i18n/translations/en-CA';
import en_GBTranslationStrings from '../i18n/translations/en-GB';
import esTranslationStrings from '../i18n/translations/es';
import es_ESTranslationStrings from '../i18n/translations/es-ES';
import frTranslationStrings from '../i18n/translations/fr';
import fr_CATranslationStrings from '../i18n/translations/fr-CA';
import itTranslationStrings from '../i18n/translations/it';
import ja_JPTranslationStrings from '../i18n/translations/ja-JP';
import ko_KRTranslationStrings from '../i18n/translations/ko-KR';
import nl_NLTranslationStrings from '../i18n/translations/nl-NL';
import pl_PLTranslationStrings from '../i18n/translations/pl-PL';
import pt_BRTranslationStrings from '../i18n/translations/pt-BR';
import pt_PTTranslationStrings from '../i18n/translations/pt-PT';
import ru_RUTranslationStrings from '../i18n/translations/ru-RU';
import svTranslationStrings from '../i18n/translations/sv';
import tr_TRTranslationStrings from '../i18n/translations/tr-TR';
import uk_UATranslationStrings from '../i18n/translations/uk-UA';
import zh_HansTranslationStrings from '../i18n/translations/zh-Hans';

export const Locales = {
  'en-US': enTranslationStrings,
  'de-DE': deTranslationStrings,
  'en-AU': en_AUTranslationStrings,
  'en-CA': en_CATranslationStrings,
  'en-GB': en_GBTranslationStrings,
  'es-MX': esTranslationStrings,
  'es-ES': es_ESTranslationStrings,
  'fr-FR': frTranslationStrings,
  'fr-CA': fr_CATranslationStrings,
  'it-IT': itTranslationStrings,
  'ja-JP': ja_JPTranslationStrings,
  'ko-KR': ko_KRTranslationStrings,
  'nl-NL': nl_NLTranslationStrings,
  'pl-PL': pl_PLTranslationStrings,
  'pt-BR': pt_BRTranslationStrings,
  'pt-PT': pt_PTTranslationStrings,
  'ru-RU': ru_RUTranslationStrings,
  'sv-SE': svTranslationStrings,
  'tr-TR': tr_TRTranslationStrings,
  'uk-UA': uk_UATranslationStrings,
  'zh-Hans': zh_HansTranslationStrings
};

export const DefaultLocale = 'en-US';
export const DefaultMessages = Locales[DefaultLocale];

export const loadMessages = (locale = DefaultLocale) => ({
  locale,
  messages: Locales[locale] || DefaultMessages
});

export const getLocaleMessages = (locale = DefaultLocale) => Locales[locale] || DefaultMessages;
export const getMessage = (id, locale = DefaultLocale) => getLocaleMessages(locale)[id] || DefaultMessages[id] || '';
