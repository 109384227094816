import { call, put, spawn, take, select } from 'redux-saga/effects';
import { Record } from 'immutable';
import { createSelector } from 'reselect';
import { push } from 'connected-react-router';

import { appName } from '../config';
import api from '../services/board-info-service';
import url from '../url-service';



/**
 * Constants
 */

 export const moduleName = 'board-info-edit';
 export const prefix = `${appName}/${moduleName}`;

 export const GET_BOARD_INFO_REQUEST = `${prefix}/GET_BOARD_INFO_REQUEST`;
 export const GET_BOARD_INFO_SUCCESS = `${prefix}/GET_BOARD_INFO_SUCCESS`;
 export const GET_BOARD_INFO_FAIL = `${prefix}/GET_BOARD_INFO_FAIL`;

 export const SUBMIT_BOARD_INFO_REQUEST = `${prefix}/SUBMIT_BOARD_INFO_REQUEST`;
 export const SUBMIT_BOARD_INFO_SUCCESS = `${prefix}/SUBMIT_BOARD_INFO_SUCCESS`;
 export const SUBMIT_BOARD_INFO_FAIL = `${prefix}/SUBMIT_BOARD_INFO_FAIL`;

 export const RESET_BOARD_INFO_REQUEST = `${prefix}/RESET_BOARD_INFO_REQUEST`;
 export const RESET_BOARD_INFO_SUCCESS = `${prefix}/RESET_BOARD_INFO_SUCCESS`;
 export const RESET_BOARD_INFO_FAIL = `${prefix}/RESET_BOARD_INFO_FAIL`;

 export const UPLOAD_FILE_REQUEST = `${prefix}/UPLOAD_FILE_REQUEST`;
 export const UPLOAD_FILE_SUCCESS = `${prefix}/UPLOAD_FILE_SUCCESS`;
 export const UPLOAD_FILE_FAIL = `${prefix}/UPLOAD_FILE_FAIL`;

 export const DELETE_FILE_REQUEST = `${prefix}/DELETE_FILE_REQUEST`;
 export const DELETE_FILE_SUCCESS = `${prefix}/DELETE_FILE_SUCCESS`;
 export const DELETE_FILE_FAIL = `${prefix}/DELETE_FILE_FAIL`;
/**
 * Reducer
 */


export const EntitiesRecord = Record({
  content: null,
  attachments: []
});

export const ReducerRecord = Record({
  loading: false,
  uploadLoading: false,
  entities: null,
  err: null
});

export default function reducer(state = ReducerRecord(), { type, payload, err }) {
  switch(type) {
    case GET_BOARD_INFO_REQUEST:
    case SUBMIT_BOARD_INFO_REQUEST:
    case RESET_BOARD_INFO_REQUEST:
      return state.set('loading', true);

    case UPLOAD_FILE_REQUEST:
    case DELETE_FILE_REQUEST:
      return state.set('uploadLoading', true);

    case UPLOAD_FILE_SUCCESS: {
      const newAttachments = [...state.entities.attachments, payload];
      return state
              .set('uploadLoading', false)
              .setIn(['entities', 'attachments'], newAttachments)
    }

    case DELETE_FILE_SUCCESS: {
      const newAttachments = state.entities.attachments.filter(file => file.attachmentId !== payload);
      return state
              .set('uploadLoading', false)
              .setIn(['entities', 'attachments'], newAttachments)
    }



    case GET_BOARD_INFO_SUCCESS:
    case RESET_BOARD_INFO_SUCCESS:
      return state
                .set('loading', false)
                .set('uploadLoading', false)
                .set('entities', EntitiesRecord(payload))

    case SUBMIT_BOARD_INFO_SUCCESS:
        return state.clear();


    case RESET_BOARD_INFO_REQUEST:
    case RESET_BOARD_INFO_FAIL:
    case UPLOAD_FILE_FAIL:
    case DELETE_FILE_FAIL:
      return state
                .set('loading', false)
                .set('uploadLoading', false)
                .set('entities', null)
                .set('err', err)
    default:
      return state;
  }
}

 /**
  * Actions
  */
 export const fetchBoardInfo = path => ({ type: GET_BOARD_INFO_REQUEST, payload: path });
 export const submitBoardInfo = (path, content) => ({ type: SUBMIT_BOARD_INFO_REQUEST, payload: { path, content } });
 export const resetBoardInfo = path => ({ type: RESET_BOARD_INFO_REQUEST, payload: path });
 export const uploadFileBoardInfo = (fileStream, path) => ({ type: UPLOAD_FILE_REQUEST, payload: { fileStream, path } });
 export const deleteFile = (path, attachmentId) => ({ type: DELETE_FILE_REQUEST, payload: { path, attachmentId } });

/**
 * Selectors
 */

export const stateSelector = state => state[moduleName];

export const loadingSelector = createSelector(
  stateSelector,
  state => state.loading
)
export const uploadLoadingSelector = createSelector(
  stateSelector,
  state => state.uploadLoading
)
export const entitiesSelector = createSelector(
  stateSelector,
  state => state.entities
);

export const contentSelector = createSelector(
  entitiesSelector,
  entities => entities && entities.toJS().content
);

export const attachemntsSelector = createSelector(
  entitiesSelector,
  entities => entities && entities.attachments
);

export const errorSelector = createSelector(
  stateSelector,
  state => state.err
)


/**
* Sagas
*/

export const fetchBoardInfoSaga = function* () {
  while(true) {
    const { payload: path } = yield take(GET_BOARD_INFO_REQUEST);

    try {
      const { data } = yield call(api.getBoardInfo, path);
      yield put({ type: GET_BOARD_INFO_SUCCESS, payload: data })
    } catch(err) {
      yield put({ type: GET_BOARD_INFO_FAIL, err })
    }
  }
}
export const submitBoardInfoSaga = function* () {
  while(true) {
    const { payload: { path, content } } = yield take(SUBMIT_BOARD_INFO_REQUEST);

    let attachments = yield select(attachemntsSelector);
    attachments = attachments.map(attachment => ({ id: attachment.id, name: attachment.name }))

    try {
      const { data } = yield call(api.updateBoardInfo, path, { content, attachments });
      yield put({ type: SUBMIT_BOARD_INFO_SUCCESS });
      const redirectPath = url.createPath('category', path);
      yield put(push(redirectPath));
    } catch(err) {
      yield put({ type: SUBMIT_BOARD_INFO_FAIL, err });
    }
  }
}


export const resetBoardInfoSaga = function* () {
  while(true) {
    const { payload: path } = yield take(RESET_BOARD_INFO_REQUEST);

    try {
      yield call(api.saveBoardInfo, path);
      const attachments = yield select(attachemntsSelector);
      const data = { content: '', attachments }
      yield put({ type: RESET_BOARD_INFO_SUCCESS, payload: data })
    } catch(err) {
      yield put(RESET_BOARD_INFO_FAIL, err)
    }
  }
}


export const uploadFileBoardInfoSaga = function* () {
  while(true) {
    const { payload: { fileStream, path } } = yield take(UPLOAD_FILE_REQUEST);
    try {
      const { data } = yield call(api.uploadFile, path, fileStream);
      yield put({ type: UPLOAD_FILE_SUCCESS, payload: data });
    } catch(err) {
      yield put({ type: UPLOAD_FILE_FAIL, err });
    }
  }
}

export const deleteFileSaga = function* () {
  while(true) {
    const { payload: { path, attachmentId } } = yield take(DELETE_FILE_REQUEST);

    try {
      yield call(api.deleteFile, path, attachmentId);
      yield put({ type: DELETE_FILE_SUCCESS, payload: attachmentId })
    } catch(err) {
      yield put({ type: DELETE_FILE_FAIL, err })
    }
  }
}



export const saga = function* () {
  yield spawn(fetchBoardInfoSaga);
  yield spawn(submitBoardInfoSaga);
  yield spawn(resetBoardInfoSaga);
  yield spawn(uploadFileBoardInfoSaga);
  yield spawn(deleteFileSaga);
}
