import { defineMessages } from 'react-intl';

export default defineMessages({
  title: 'Find a board about a specific topic',
  surnamesOrTopics: 'Surnames or topics',
  tipSurnames: '<strong>Tip:</strong> To quickly navigate to a surname board, enter the surname in the "Find a Board" search above or use the links below for your surname\'s',
  minimumCharCondition:'Enter at least 3 characters then click \'Go\'.',
  go:'Go',
  surnames:'Surnames',
  noMatches: 'No matches found',
  loading: 'Loading...'
});
