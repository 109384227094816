import { call, put, takeEvery } from 'redux-saga/effects';
import { createSelector } from 'reselect';

import api from '../services/medias-service';
import { action, freeze } from '../components/utils/redux';
import { List, Record } from 'immutable';
import { MebiSize } from '../constants';
import { pick } from '../../utils';
import { showError } from './common/alert';
import config from '../config';

/**
 * Supported file types
 * @type {string[]}
 */
export const FileTypes = [
  'image/bmp',
  'image/gif',
  'image/jpeg',
  'image/png',
  'image/tiff'
];

const MimeFileExtensions = {
  'image/jpeg': ['jpeg', 'jpg'],
  'image/tiff': ['tif', 'tiff']
};

export const MaxFileSize = 10 * MebiSize;

// Assigning file extensions for mime types not listed in MimeFileExtensions
for(const mime of FileTypes) {
  if (!MimeFileExtensions[mime]) {
    MimeFileExtensions[mime] = mime.split('/').slice(1);
  }
}

export const moduleName = 'media';
export const moduleOrigin = `${config.appName}/${moduleName}`;

export const uploadOrigin = `${moduleOrigin}/upload`;
export const MEDIA_UPLOAD_REQUEST = `${uploadOrigin}/REQUEST`;
export const MEDIA_UPLOAD_SUCCESS = `${uploadOrigin}/SUCCESS`;
export const MEDIA_UPLOAD_CLEAR = `${moduleOrigin}/CLEAR`;
export const MEDIA_UPLOAD_REMOVE = `${moduleOrigin}/REMOVE`;

export const requestUpload = file => ({
  type: MEDIA_UPLOAD_REQUEST,
  payload: file
});

export const removeUpload = id => ({
  type: MEDIA_UPLOAD_REMOVE,
  payload: id
});

export const saveMediaData = (data, file) => ({
  type: MEDIA_UPLOAD_SUCCESS,
  payload: {
    data,
    file
  }
});

export const clearUploads = () => action(MEDIA_UPLOAD_CLEAR);

const UploadRecord = Record({
  id: null,
  link: null,
  created: 0,
  response: null,
  file: null
});

const MediaRecord = Record({
  activeUpload: null,
  uploads: new List()
});

// eslint-disable-next-line max-len
export const attachmentIdToLink = id => getMediaServiceLink('60623', id);
// eslint-disable-next-line max-len
export const userImageIdToLink = (id, height, width) => getMediaServiceLink('60564', id, height, width);

const getMediaServiceLink = (namespace, id, height = null, width = null) => {
  let env;
  switch (config.env) {
    case 'local':
    case 'dev':
      env = 'dev';
      break;
    case 'stage':
      env = 'stage';
      break;
    default:
      env = '';
  }
  return `https://mediasvc.ancestry${env}.com/v2/image/namespaces/${namespace}/media/${id}?Client=Ancestry.Boards${height ? `&maxHeight=${height}` : ''}${width ? `&maxWidth=${width}` : ''}`;
};

export default function reducer(state = MediaRecord({}), { type, payload }) {
  switch (type) {
    case MEDIA_UPLOAD_REQUEST:
      return state
        .set('activeUpload', UploadRecord({
          name: payload.name,
          file: Object.freeze(pick(payload, 'name', 'size', 'type', 'lastModified')),
          created: Date.now()
        }));
    case MEDIA_UPLOAD_SUCCESS: {
      if (payload && payload.data) {
        const id = payload.data.externalMediaId;
        const active = state.get('activeUpload')
          .set('id', id)
          .set('link', attachmentIdToLink(id))
          .set('response', freeze(payload.data));
        const uploads = state.get('uploads');
        return state
          .set('activeUpload', null)
          .set('uploads', uploads.push(active));
      }
      return state.set('activeUpload', null);
    }
    case MEDIA_UPLOAD_CLEAR:
      return state.set('uploads', new List());
    case MEDIA_UPLOAD_REMOVE:
      return state.set('uploads', state.get('uploads')
        .filter(item => item.get('response').externalMediaId !== payload)
      );
    default:
      return state;
  }
}

// Selectors

export function selectMedia(state) {
  return state[moduleName];
}

export const selectUploads = createSelector(
  selectMedia,
  state => state.get('uploads')
);

export const selectActiveUpload = createSelector(
  selectMedia,
  state => state.get('activeUpload')
);

export const selectUploadedIds = createSelector(
  selectMedia,
  state => state.get('uploads')
    .filter(item => item.get('response'))
    .map(item => item.get('response').externalMediaId)
);

// saga

export function* upload({ payload: file }) {
  try {
    const { data } = yield call(api.uploadFile, file);
    yield put(saveMediaData(data, file));
  } catch (err) {
    yield put({ type: MEDIA_UPLOAD_SUCCESS });
    yield put(showError(err));
  }
}

export function* saga() {
  yield takeEvery(MEDIA_UPLOAD_REQUEST, upload);
}

export const AcceptString = FileTypes.join(', ');
export const FileExtensions = Object.values(MimeFileExtensions)
  .reduce((acc, extensions) => [...acc, ...extensions], [])
  .sort();
