import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';

// data
import {
  // action
  getThreadById,
  // selectors
  loadingSelector,
  threadSelector,
  errorSelector
} from '../../ducks/thread-page';

import { showPopup } from '../../ducks/popup';
// templates
import PageLayout from '../../components/hoc/page-layout';
import ReplyForm from '../../components/forms/reply';
import m from './messages';
import { trackPageView } from '../../utils/tealium';

export default function ThreadReplyPage({ user }) {
  const dispatch = useDispatch();
  // routing data
  const { path, threadId } = useParams();

  // redux thread data
  const loading = useSelector(loadingSelector);
  const error = useSelector(errorSelector);

  const thread = useSelector(threadSelector);

  useEffect(() => {
    dispatch(getThreadById(path, threadId));
  }, [path, threadId]);

  useEffect(() => trackPageView(`reply-message : ${path}`), [path]);

  const getLocationName = () => {
    if (!thread || !thread.namePath) return '';

    return thread.namePath.split('|').slice(-1)[0]; // get last name from string path
  };

  const breadcrumbs = {
    links: [{
      path,
      title: <FormattedMessage {...m.returnLink} values={{ item: getLocationName() }} />
    }]
  };

  const pageTitle = <FormattedMessage { ...m.postNewReply } values={{ name: getLocationName() }} />

  const communityGuidelines = (
    <button
      type="button"
      className="link"
      onClick={() => dispatch(showPopup('HELP'))}
    >
      <FormattedMessage {...m.communityGuidelines} />
    </button>
  );
  const faq = <button type="button" className="link" onClick={() => dispatch(showPopup('FAQ'))}><FormattedMessage {...m.faq} /></button>;
  const tipTitle = <strong><FormattedMessage {...m.tipTitle} /></strong>;
  const pageDescription = <FormattedMessage {...m.tip} values={{ tipTitle, communityGuidelines, faq }} />;

  return (
    <PageLayout
      loading={loading}
      error={error}
      title={pageTitle}
      description={pageDescription}
      breadcrumbs={breadcrumbs}
      aloneLink={true}
    >
      { thread && <ReplyForm user={user} thread={thread} /> }
    </PageLayout>
  );
}

ThreadReplyPage.propTypes = {
  user: PropTypes.object.isRequired // The current visitor
};
