import { defineMessages } from 'react-intl';

export default defineMessages({
  returnLink: 'Return to {item}',
  postNewThread: 'Post new thread to {name}',
  postNewReply: 'Post new reply to {name}',
  tip: '{tipTitle} Please read the {communityGuidelines} before you make your first post. For tips on creating a great post, visit the {faq}.',
  tipTitle: 'Tip:',
  communityGuidelines: 'community guidelines',
  faq: 'Board FAQ',
});