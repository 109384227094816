import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import m from './messages';
import { createPrefix, requestCreateOptions, setDefaultOptions, setNodeField } from '../../../../ducks/options';
import { isBusy } from '../../../../ducks/common/busy';
import NodeEdit from '../../node/edit';
import { push } from 'connected-react-router';
import { categoryLink } from '../../../utils/old_link';
import { showSuccess } from '../../../../ducks/common/alert';

const resetNode = setDefaultOptions('BOARD');

export function CreateBoard({ match, set, reset, ...props }) {
  useEffect(() => {
    reset();
    set('path', match.params.path);
  }, [match.params.path]);
  return (
    <NodeEdit
      tracking="create-board"
      classes="create board"
      typeCategory='BOARD'
      nodeType='msgBoardCategory'
      messages={m}
      {...props}
    />
  );
}

export function mapStateToProps(state) {
  return {
    busySave: isBusy(state, `${createPrefix}/`)
  };
}

export function mapDispatchToProps(dispatch) {
  return {
    save(path) {
      dispatch(requestCreateOptions(path, 'BOARD', [
        push(categoryLink(path)),
        showSuccess('Board created successfully'),
        resetNode
      ]));
    },
    set: (name, value) => dispatch(setNodeField(name, value)),
    reset() {
      dispatch(resetNode);
    }
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(CreateBoard));
