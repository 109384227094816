import React from 'react';
import { FormattedMessage } from 'react-intl';


export default function InputField({ value, name, labelValue, handleFieldChange}) {
  return (
    <div className='s-result-form__field-wrap'>
      <label className='s-result-form__label'>
        { labelValue && <span className='s-result-form__label-value'>{labelValue}</span>}
        <input type='text' name={name} value={value} onChange={handleFieldChange} />
      </label>
    </div>
  )
}