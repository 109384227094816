/* eslint-disable max-len */
import { defineMessages } from 'react-intl';

export default defineMessages({
  returnLink: 'Return to {item}',
  title: 'Report abuse on {id}',
  title1: 'Objection',
  'SELECT': '-Select-',
  'SPAM': 'Spam',
  'OBSCENITY': 'Obscenity',
  'IMPERSONAL': 'Impersonation',
  'PERSONAL ATTACKS': 'Personal Attacks',
  'OTHER': 'Other',
  titleErrorMessage: 'Required',
  title2: 'Comments',
  formSubmit: 'Submit',
  formLink: 'Cancel',
  listItem1: 'Posted',
  listItem2: 'Classification',
  listItem3: 'Edited',
  listItem4: 'Surnames'
});
