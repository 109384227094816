import { Record } from 'immutable';
import { takeEvery } from 'redux-saga/effects';
import api from '../services/suggestions-service';
import QueryModel from './common/query-model';
import { action, modification } from '../components/utils/redux';
import { appName } from '../config';

export const moduleName = 'objection';
export const prefix = `${appName}/${moduleName}`;
export const createPrefix = `${prefix}/create`;
export const OBJECTION_CREATE_REQUEST = `${createPrefix}/REQUEST`;
export const OBJECTION_CREATE_SUCCESS = `${createPrefix}/SUCCESS`;

export const requestCreateObjection = (payload, next) => action(OBJECTION_CREATE_REQUEST, payload, next);

export const ObjectionTypes = {
  Spam: 'Spam',
  Obscenity: 'Obscenity',
  Impersonation: 'Impersonation',
  'Personal Attacks': 'Personal Attacks',
  Other: 'Other'
};
export const DefaultObjectionType = ObjectionTypes.Spam;

export const createObjection = modification(
  ({ path, comment, threadId, objectionType }) => [api.createObjection, path, { comment, threadId, objectionType }],
  OBJECTION_CREATE_SUCCESS
);

const ObjectionStateRecord = Record({
  query: null
});

export default function reducer(state = ObjectionStateRecord({ query: QueryModel.createRecord() })) {
  return state;
}

export function* saga() {
  yield takeEvery(OBJECTION_CREATE_REQUEST, createObjection);
}
