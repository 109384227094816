import React from 'react';
import { FormattedMessage } from 'react-intl';
import m from './messages';
import { convertToDate } from '../../../utils';

export default function CategoryRow(props) {
  return (
    <tr>
      <td className="name">
        <span className={props.type === 'category' ? 'icon iconFolder' : 'icon iconMessageBoard'}>
          {props.name}
        </span>
      </td>
      <td className="created">
        {convertToDate(props.createdDate)}
      </td>
      <td className="deleted">
        {convertToDate(props.deletedDate)}
      </td>
      <td className="actions">
        <button type="button" className="link" onClick={() => props.remove(props)}>
          <FormattedMessage {...m.restore} />
        </button>
      </td>
    </tr>
  );
}
