import React from 'react';

import ContentProvider from './context-content';
import TextField from './text-field'
import ButtonReset from './btn-reset';
import Attachments from './attachmnets';
import ButtonsControl from './btns-control';


export default function BoardInfoEdit() {
  return (
    <ContentProvider>
      <div className='board-edit'>
        <div className='board-edit__form-field'>
          <TextField />
        </div>
        <div className='board-edit__btn-reset-wrap'>
          <ButtonReset />
        </div>
        <div className='board-edit__attachmnets'>
          <Attachments />
        </div>
        <div className='board-edit__btns-control'>
          <ButtonsControl />
        </div>
      </div>
    </ContentProvider>
  )
}