import config from '../config';

/* eslint-disable */
export function trackPageView(pageName) {
  //alert('inside trackpage view');
  var data;
  if (window.utag && utag.view && utag.data) {
     data = {
      page_name: `${config.omniture} : boards : ${pageName}`,
      page_name_var: pageName,
      feature: 'boards',
      feature_category: 'family history'
    };
    window.utag_cfg_ovrd = { noview: true };
    //alert('banner called');
    utag.view(data);
  } else {
    data = {
      page_name: `${config.omniture} : boards : ${pageName}`,
      page_name_var: pageName,
      feature: 'boards',
      feature_category: 'family history'
    };
    window.utag_cfg_ovrd = { noview: true };
    window.addEventListener('tealiumReady', () => {
      //alert('banner called');
      utag.view(data);
    });
  }
}
/* eslint-enable */
