import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import { useParams } from 'react-router-dom';

// data
import {
  loadingSelector,
  entitiesSelector,
  errorSelector,
  getThreadById
} from '../../ducks/thread-page';
import m from '../../components/abuse/messages';
import { trackPageView } from '../../utils/tealium';

// templates
import PageLayout from '../../components/hoc/page-layout';
import AbuseForm from '../../components/abuse/form';
import AbuseContent from '../../components/abuse/content';

export default function ReportAbusePage(_) {
  // redux
  const dispatch = useDispatch();
  // routing
  const { path, id } = useParams();

  // data from redux
  const loadingContent = useSelector(loadingSelector);
  const entities = useSelector(entitiesSelector);
  const errorContent = useSelector(errorSelector);

  useEffect(() => {
    dispatch(getThreadById(path, id));
  }, [path, id]);

  useEffect(() => trackPageView(`report-abuse : ${path}`), []);

  const dataLoaded = entities && entities.thread;
  const classModification = dataLoaded ? 'abuse' : 'abuse-no-content';
  const titleMessage = dataLoaded
    ? (
      <FormattedMessage
        {...m.title}
        values={{
          id: entities.thread.subject
        }}
      />
    ) : '';

  const title = dataLoaded ? entities.thread.subject : '';
  const breadcrumbs = {
    links: [{
      path: `${path}/${id}`,
      title: <FormattedMessage {...m.returnLink} values={{ item: title }} />
    }]
  };

  return (
    <PageLayout
      breadcrumbs={breadcrumbs}
      aloneLink={true}
      classModificator={classModification}
      title={titleMessage}
      loading={loadingContent}
      error={errorContent}
    >
      <div className="abuse">
        <div className="abuse__form-wrap">
          <AbuseForm />
        </div>
        <div className="abuse__content-wrap">
          {dataLoaded && <AbuseContent {...entities.thread} />}
        </div>
      </div>
    </PageLayout>
  );
}
