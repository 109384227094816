import React, { useState } from 'react';
import { connect } from 'react-redux';
import { FormattedMessage, injectIntl } from 'react-intl';
import { withRouter } from 'react-router-dom';
import ChangeBoard from '../change';
import m from './messages';
import PathField from '../path-field';
import { optionsMapStateToProps } from '../../../../ducks/options';
import { PathRegExp } from '../../../../utils/strings';
import { string, shape } from 'prop-types';

export function AdditionalBoard(
  {
    intl,
    isCategory,
    match: { params: { path } }
  }) {
  const [newPath, setNewPath] = useState('');
  const isValid = newPath.length > 0 && PathRegExp.test(newPath);
  return (
    <ChangeBoard
      tracking="additional-board-path"
      canSubmit={isValid && isCategory}
      classes="additional-path"
      data={{ newPath }}
      operation="additional-path"
      path={path}
      successMessage={intl.formatMessage(m.added)}
      title={<FormattedMessage {...m.title} />}
    >
      <PathField
        label={<FormattedMessage {...m.newPath} />}
        path={newPath}
        setPath={setNewPath}
      />
    </ChangeBoard>
  );
}

AdditionalBoard.propTypes = {
  match: shape({ params: shape({ path: string.isRequired }) }).isRequired
};

export default connect(optionsMapStateToProps)(withRouter(injectIntl(AdditionalBoard)));
