import { call, put, spawn, take, takeEvery } from 'redux-saga/effects';
import { OrderedSet, Record } from 'immutable';
import { createSelector } from 'reselect';

import { appName } from '../config';
import api from '../services/searches-service';

/**
 * Constants
 */

export const moduleName = 'search';
export const prefix = `${appName}/${moduleName}`;

export const fetchSuggestionPrefix = `${prefix}/suggestions/fetch`;
export const GET_SUGGESTION_REQUEST = `${fetchSuggestionPrefix}/REQUEST`;
export const GET_SUGGESTION_SUCCESS = `${fetchSuggestionPrefix}/SUCCESS`;
export const GET_SUGGESTION_ERROR = `${fetchSuggestionPrefix}/ERROR`;

export const fetchBoardsPrefix = `${prefix}/boards/fetch`;
export const GET_BOARDS_REQUEST = `${fetchBoardsPrefix}/REQUEST`;
export const GET_BOARDS_SUCCESS = `${fetchBoardsPrefix}/SUCCESS`;
export const GET_BOARDS_ERROR = `${fetchBoardsPrefix}/ERROR`;

export const fetchSurnamesPrefix = `${prefix}/surnames/fetch`;
export const GET_SURNAMES_REQUEST = `${fetchSurnamesPrefix}/REQUEST`;
export const GET_SURNAMES_SUCCESS = `${fetchSurnamesPrefix}/SUCCESS`;
export const GET_SURNAMES_ERROR = `${fetchSurnamesPrefix}/ERROR`;


export const RESET_STATE = `${fetchSurnamesPrefix}/RESET_STATE`;

/**
 * Reducer
 */


export const SurnameEntities = Record({
  title: null,
  breadcrumbs: null,
  isFavourite: null,
  isAlert: null,
  surnames: {
    active: [],
    letters: [
      ["A", "B", "C", "D", "E", "F", "G", "H", "I", "J", "K", "L", "M", "N", "O", "P", "Q", "R", "S", "T", "U","V", "W", "X", "Y", "Z"]
    ]
  },
  categories: null,
  boards: null,
  threads: null
});

export const BoardsEntities = Record({
  title: null,
  breadcrumbs: {},
  isFavourite: null,
  isAlert: null,
  categories: null,
  boards: null,
  threads: null
});

export const BoardsRecord = Record({
  loading: false,
  entities: BoardsEntities({}),
  err: null
});

export const SuggestionRecord = Record({
  loading: false,
  entities: OrderedSet([]),
  err: false
});

export const SurnamesRecord = Record({
  loading: false,
  entities: SurnameEntities({}),
  err: false
});

export const ReducerRecord = Record({
  boards: BoardsRecord({}),
  suggestions: SuggestionRecord({}),
  surnames: SurnamesRecord({})
});

export default function reducer(state = ReducerRecord(), action) {
  const { type, payload, err } = action;

  switch (type) {
    case GET_SUGGESTION_REQUEST:
      return state.setIn(['suggestions', 'loading'], true);

    case GET_SUGGESTION_SUCCESS:
      return state
        .setIn(['suggestions', 'loading'], false)
        .setIn(['suggestions', 'entities'], OrderedSet(payload));

    case GET_SUGGESTION_ERROR:
      
      return state
        .setIn(['suggestions', 'loading'], false)
        .setIn(['suggestions', 'entities'], OrderedSet([]))
        .setIn(['suggestions', 'err'], err.message);

    case GET_BOARDS_REQUEST:
      return state.setIn(['boards', 'loading'], true);

    case GET_BOARDS_SUCCESS:
      return state
        .setIn(['boards', 'loading'], false)
        .setIn(['boards', 'entities'], BoardsEntities(payload));

    case GET_BOARDS_ERROR:
      return state
        .setIn(['boards', 'loading'], false)
        .setIn(['boards', 'entities'], BoardsEntities({}))
        .setIn(['boards', 'err'], err.message);

    case GET_SURNAMES_REQUEST:
      return state.setIn(['surnames', 'loading'], true);

    case GET_SURNAMES_SUCCESS:
      return state
        .setIn(['surnames', 'loading'], false)
        .setIn(['surnames', 'entities'], SurnameEntities(payload));

    case GET_SURNAMES_ERROR:
      return state
        .setIn(['surnames', 'loading'], false)
        .setIn(['surnames', 'entities'], SurnameEntities({}))
        .setIn(['surnames', 'err'], err.messages);


    case RESET_STATE:
      return state.clear()

    default:
      return state;
  }

}

/**
 * Actions
 */

export const getSuggestion = (query) => ({
  type: GET_SUGGESTION_REQUEST,
  payload: query
});
export const getBoards = (query, count = 'TEN', page = 1) => ({
  type: GET_BOARDS_REQUEST,
  payload: {
    query,
    count,
    page
  }
});
export const getSurnames = (query = '', count = 'TEN', page = 1) => ({
  type: GET_SURNAMES_REQUEST,
  payload: {
    query,
    count,
    page
  }
});

// clearing
export const resetSurnamesState = () => ({ type: RESET_STATE })
/**
 * Selector
 */

export const stateSelector = state => state[moduleName];
// Suggestion
export const suggestionsLoading = createSelector(stateSelector, state => state.suggestions.loading);
export const suggestionsEntities = createSelector(stateSelector, state => state.suggestions.entities.toArray());
export const suggestionsError = createSelector(stateSelector, state => state.suggestions.error);

// Boards
export const boardsLoading = createSelector(stateSelector, state => state.boards.loading);
export const boardsEntities = createSelector(stateSelector, state => state.boards.entities.toJS());
export const boardsError = createSelector(stateSelector, state => state.boards.err);

// Surnames
export const surnamesLoading = createSelector(stateSelector, state => state.surnames.loading);
export const surnamesEntities = createSelector(stateSelector, state => state.surnames.entities.toJS());
export const surnamesError = createSelector(stateSelector, state => state.surnames.err);


/**
 * Saga
 */

export const getSuggestionSaga = function* ({ payload: query }) {
    try {
      const { data } = yield call(api.getSuggestion, query);
      yield put({
        type: GET_SUGGESTION_SUCCESS,
        payload: data
      });
    } catch (err) {
      yield put({
        type: GET_SUGGESTION_ERROR,
         err
      });
    }
};

export const getBoardsSaga = function* () {
  while (true) {
    const { payload: { query, count, page } } = yield take(GET_BOARDS_REQUEST);
    try {
      const { data } = yield call(api.getBoards, query, count.toUpperCase(), page);
      yield put({
        type: GET_BOARDS_SUCCESS,
        payload: data
      });
    } catch (err) {
      yield put({
        type: GET_BOARDS_ERROR,
        err
      });
    }
  }
};

export const getSurnamesSaga = function* () {
  while (true) {
    const { payload: { query, count, page } } = yield take(GET_SURNAMES_REQUEST);
    try {
      const { data } = yield call(api.getSurnames, query, count.toUpperCase(), page);
      yield put({
        type: GET_SURNAMES_SUCCESS,
        payload: data
      });
    } catch (err) {
      yield put({
        type: GET_SURNAMES_ERROR,
        err
      });
    }
  }
};

export const saga = function* () {
  // yield spawn(getSuggestionSaga);
  yield takeEvery(GET_SUGGESTION_REQUEST, getSuggestionSaga);
  yield spawn(getBoardsSaga);
  yield spawn(getSurnamesSaga);
};
