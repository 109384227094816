import React, { useState } from 'react';
import classNames from 'classnames';
import { FormattedMessage } from 'react-intl';
import Judge from '../judge';
import m from './messages';
import { singleSpace, spaceless } from '../../../../utils/strings';
import { bool, number, string } from 'prop-types';
import Field from '../../../common/field';
import Actions from '../actions';
import Fieldset from '../fieldset';
import { JudgeOperations } from '../../../../ducks/suggestion';

export default function ManageRight(props) {
  const { classes, id, path, isLocked, uid } = props;
  const [comments, setComments] = useState('');
  const className = classNames(spaceless(`manage-item manage-right ${classes}`), {
    loaded: !!path
  });
  const isValid = !comments.trim() || comments.length > 4;

  return (
    <div className={className}>
      <Fieldset
        name="right"
        fields={props}
        messages={m}
        names={['creatorName', 'requestDate', 'email', 'path', 'comment', 'status']}
      />
      <Judge classes="judge-right" id={uid} title={<FormattedMessage {...m.judge} />}>
        <Fieldset
          name="modal"
          fields={props}
          messages={m}
          names={['creatorName', 'email', 'requestDate', 'path', 'comment']}
        />
        <Field
          label={<FormattedMessage {...m.rejectionComments} />}
          name="comments"
          setValue={setComments}
          type="textarea"
          value={comments}
          format={singleSpace}
        />
        <Actions
          data={{ comments, type: 'rights' }}
          disabled={!isValid}
          id={id.toString()}
          names={[
            JudgeOperations.Accept,
            JudgeOperations.Reject,
            JudgeOperations.Remove,
            isLocked ? JudgeOperations.Unlock : JudgeOperations.Lock
          ]}
          path={path}
        />
      </Judge>
    </div>
  );
}

ManageRight.propTypes = {
  classes: string,
  id: number.isRequired,
  isLocked: bool.isRequired,
  path: string.isRequired,
  uid: string.isRequired
};

ManageRight.defaultProps = {
  classes: ''
};
