import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import url from '../../../url-service';
import { FormattedMessage } from 'react-intl';

import { uploadedFilesSelector, deleteFile } from '../../../ducks/attachments';
import m from '../messages';


export default function UploadedList() {
  const uploadedFiles = useSelector(uploadedFilesSelector);
  const dispatch = useDispatch();

  if (!uploadedFiles.length) return null;

  return uploadedFiles.map((file) => (
    <div className='attachments__item' key={file.id + file.name}>
      <div className='attachments__item-wrap'>
        <img className='attachments__item-img' src={url.getAttachmentLinkFromId(file.id)} alt={file.name}/>
      </div>
      <button 
        type='button'
        className='attachment__item-delete'  
        onClick={() => dispatch(deleteFile('uploaded', file))}
      >
        <FormattedMessage {...m.dltBtnTitle} />
      </button>
    </div>
  ))
}