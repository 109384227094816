import React from 'react';
import DialogContent from '@material-ui/core/DialogContent';
import Dialog from '@material-ui/core/Dialog';
import classNames from 'classnames';
import { spaceless } from '../../../utils/strings';
import { DialogTitle } from '@material-ui/core';
import { bool, string } from 'prop-types';

export default function Modal({ close, visible, classes, title, children, ...props }) {
  const className = classNames(spaceless(`boards-modal ${classes}`), {
    visible
  });
  let actions;
  if (close) {
    actions = (
      <button type="button" className="close" onClick={close}>
        {'\u2716'}
      </button>
    );
  }
  return (
    <Dialog open={visible} className={className} {...props}>
      <DialogTitle>
        <span className="title">{title}</span>
      </DialogTitle>
      {actions}
      <DialogContent>
        {children}
      </DialogContent>
    </Dialog>
  );
}

Modal.propTypes = {
  classes: string,
  visible: bool
};

Modal.defaultProps = {
  classes: '',
  visible: true
};
