const pkg = require('./package');

const ENVIRONMENT = process.env.NODE_ENV;
const DEVELOPMENT = ENVIRONMENT === 'development';
const STAGE = ENVIRONMENT === 'test';
const DEBUG = DEVELOPMENT || STAGE;
const PRODUCTION = !DEBUG && ENVIRONMENT === 'production';
const PROJECT_NAME = pkg.name;
const PROJECT_VERSION = pkg.version;

module.exports = {
  DEBUG,
  DEVELOPMENT,
  ENVIRONMENT,
  PRODUCTION,
  PROJECT_NAME,
  PROJECT_VERSION,
  STAGE
};
