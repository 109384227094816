import React, { useEffect } from 'react';
import classNames from 'classnames';
import m from './messages';
import { connect } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import { isBusy } from '../../ducks/common/busy';
import { PathRegExp } from '../../utils/strings';
import {
  chooseCategory,
  fetchPrefix,
  requestFetchChooseCategory,
  selectChooseCategoryBoards,
  selectChooseCategoryBreadcrumbs,
  selectChooseCategoryCategories,
  selectChooseCategoryQueryPath
} from '../../ducks/choose-category';

const Columns = 3;
const MinColumnHeight = 8;

const isPathValid = item => item.path && 'string' === typeof item.path && PathRegExp.test(item.path);

const createItem = ({ path, title }, classes, choose) => (
  <li key={path}>
    <button
      type="button"
      onClick={() => choose(path)} className={classNames(`link ${classes}`, { root: !path })}
    >
      {title}
    </button>
  </li>
);

export function ChooseCategory(
  {
    boards,
    busy,
    categories,
    change,
    choose,
    fetch,
    links,
    path
  }) {
  useEffect(() => fetch(path), [path]);
  let content;
  let hasManyColumns = false;
  const breadcrumbs = [{ path: '', title: <FormattedMessage {...m.boards} /> }, ...links]
    .map(link => createItem(link, '', change));
  if (busy) {
    content = <FormattedMessage {...m.loading} />;
  } else if (categories.length === 0 && boards.length === 0) {
    content = <FormattedMessage {...m.empty} />;
  } else {
    const items = [
      ...boards.filter(isPathValid).map(item => createItem(item, 'icon iconMessageBoard', choose)),
      ...categories.filter(isPathValid).map(item => createItem(item, 'icon iconFolder', change))
    ];
    if (items.length < MinColumnHeight) {
      content = <ul className="folders">{items}</ul>;
    } else {
      const columns = [];
      const itemsPerColumn = Math.max(Math.ceil(items.length / Columns), MinColumnHeight);
      let columnItems = [];
      for(let i = 0; i < items.length; i++) {
        if (columnItems.length >= itemsPerColumn) {
          const column = Math.floor(items.length / Columns);
          columns.push(<ul key={column} className="folders">{columnItems}</ul>);
          columnItems = [];
        }
        columnItems.push(items[i]);
      }
      columns.push(<ul key={Columns - 1} className="folders">{columnItems}</ul>);
      hasManyColumns = columns.length > 1;
      content = <>{columns}</>;
    }
  }

  return (
    <div className={classNames('widget choose-category', { busy })}>
      <ul className="breadcrumbs">{breadcrumbs}</ul>
      <div className={classNames('content', { 'multi-column': hasManyColumns })}>{content}</div>
    </div>
  );
}

export function mapStateToProps(state) {
  return {
    busy: isBusy(state, `${fetchPrefix}/`),
    links: selectChooseCategoryBreadcrumbs(state),
    categories: selectChooseCategoryCategories(state),
    boards: selectChooseCategoryBoards(state),
    path: selectChooseCategoryQueryPath(state)
  };
}

export function mapDispatchToProps(dispatch) {
  return {
    fetch(path) {
      dispatch(requestFetchChooseCategory(path));
    },
    change(path) {
      dispatch(chooseCategory(path));
    }
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(ChooseCategory);
