import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';

import { isAlertSelector as alertBoardSelector} from '../../../../ducks/home-category-page';
import { isAlertSelector as alertThreadSelector} from '../../../../ducks/thread-page';
import { addRemoveAlertFavorite, loadingAlertSelector } from '../../../../ducks/alert-favorite';
import m from '../messages';
import Progress from '../../../common/progress';


export default function AddRemoveAlerts({ path, isThread }) {
  const dispatch = useDispatch();

  const alertSelector = isThread ? alertThreadSelector : alertBoardSelector;
  const isFavorite = useSelector(alertSelector);
  const loading = useSelector(loadingAlertSelector);

  const handleButtonClick = () => dispatch(addRemoveAlertFavorite(path, isThread, isFavorite))


  function renderButtonTitle() {
    if (isFavorite) return <FormattedMessage {...m.removeFromAlerts} />
    return <FormattedMessage {...m.addToAlerts} />
  }


  return (
    <li className={`menu-nav__item${ loading ? ' active' : '' }`}> 
      <button className={`menu-nav__add-remove-btn icon${ isFavorite ? ' active': ''}`} onClick={handleButtonClick}>
        { renderButtonTitle() }
      </button>
      {' '}
      <button 
        type='button' 
        className='menu-nav__item-btn-link'
        onClick={() => location.href = '/secure/account/emailsettings' }
      >
        (<FormattedMessage {...m.seeAll} />)
      </button>
      { loading && <Progress small /> }
    </li>
  )
}

AddRemoveAlerts.propTypes = {
  isThread: PropTypes.bool, // check wich page category-board or thread page
  path: PropTypes.string 
}