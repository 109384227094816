import { defineMessages } from 'react-intl';

export default defineMessages({
  beginDate: 'Begin Date',
  beginDatePlaceholder: '(default: 1 Jan 2000)',
  date: 'Date',
  deleteSelected: 'Delete Selected',
  email: 'Email',
  endDate: 'End Date',
  endDatePlaceholder: '(default: today)',
  enterSearchCriteria: 'Enter search criteria. You must supply at least one search field.',
  manageSpam: 'Manage Spam',
  results: 'Results',
  search: 'Search',
  searchMessages: 'Search Messages',
  selectAll: 'Select all',
  subject: 'Subject',
  username: 'Username'
});
