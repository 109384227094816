import React, { useEffect } from 'react';
import classNames from 'classnames';
import { bool, func, shape, string } from 'prop-types';
import { connect } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import { push } from 'connected-react-router';
import { withRouter } from 'react-router-dom';
import AdminView from '../view';
import Alert from '../../common/alert';
import BoardActions from './actions';
import FieldView from '../../common/field-view';
import Form from '../../common/form';
import m from './messages';
import Progress from '../../common/progress';
import Section from '../../common/old-section';
import { changePrefix, requestNodeChange } from '../../../ducks/node';
import { isBusy } from '../../../ducks/common/busy';
import { showSuccess } from '../../../ducks/common/alert';
import { spaceless } from '../../../utils/strings';
import {
  BreadcrumbsType,
  ChildrenType,
  PathMatchType,
  WidgetDefaults,
  WidgetType
} from '../../utils/prop-types';
import {
  fetchPrefix,
  requestCachedCategory,
  selectCategoryBreadcrumbs,
  selectCategoryTitle
} from '../../../ducks/category';
import { categoryLink } from '../../../../utils/link';
import { parentPath } from './utils';
import { trackPageView } from '../../../utils/tealium';

export function ChangeBoard({
  tracking,
  boardName,
  breadcrumbs,
  busyChange,
  busyFetch,
  canSubmit,
  change,
  children,
  classes,
  data,
  fetch,
  footer,
  match: { params: { path } },
  messages = m,
  operation,
  path: propsPath,
  pathLabel,
  successMessage,
  title
}) {
  useEffect(() => { fetch(path); }, [path]);
  useEffect(() => trackPageView(tracking || 'unknown-tracking-name', path), []);

  let content;
  if (busyFetch) {
    content = <Progress />;
  } else {
    const submitClassName = classNames('ancBtn orange', {
      loading: busyChange
    });

    if (data) {
      for (const key of ['name']) {
        if (key in data) {
          data[key] = data[key].trim();
        }
      }
    }

    const submittable = canSubmit && !busyChange;
    content = (
      <Section>
        <FieldView
          name="path"
          label={pathLabel || <FormattedMessage {...(messages.path || m.path)} />}
        >
          {propsPath || path}
        </FieldView>
        <FieldView name="name" label={<FormattedMessage {...(messages.name || m.name)} />}>{boardName}</FieldView>
        <Form submit={() => change(path, operation, data, successMessage)}>
          {children}
          <BoardActions
            busy={!submittable}
            path={path}
            submitClassName={submitClassName}
          />
        </Form>
        {footer}
      </Section>
    );
  }

  return (
    <AdminView
      breadcrumbs={[{ path: '' }, ...breadcrumbs]}
      classes={spaceless(`change-board operation-${operation} ${classes}`)}
      title={title}
    >
      <Alert />
      {content}
    </AdminView>
  );
}

ChangeBoard.propTypes = {
  ...WidgetType,
  boardName: string,
  breadcrumbs: BreadcrumbsType,
  busyChange: bool.isRequired,
  busyFetch: bool.isRequired,
  canSubmit: bool.isRequired,
  change: func.isRequired,
  children: ChildrenType,
  fetch: func.isRequired,
  footer: ChildrenType,
  match: shape(PathMatchType).isRequired,
  operation: string,
  successMessage: string
};

ChangeBoard.defaultProps = {
  ...WidgetDefaults,
  boardName: '',
  canSubmit: true,
  footer: null,
  operation: '',
  successMessage: ''
};

export function mapStateToProps(state) {
  return {
    busyFetch: isBusy(state, `${fetchPrefix}/`),
    boardName: selectCategoryTitle(state),
    breadcrumbs: selectCategoryBreadcrumbs(state)
  };
}

export function mapDispatchToProps(dispatch) {
  return {
    fetch(path) {
      dispatch(requestCachedCategory(path));
    }
  };
}

export const RouterChangeBoard = withRouter(ChangeBoard);

export default connect(state => ({
  busyChange: isBusy(state, `${changePrefix}/`),
  ...mapStateToProps(state)
}),
dispatch => ({
  change(path, operation, data, message) {
    dispatch(requestNodeChange(path, operation, data, message
      ? [push(categoryLink(parentPath(path))), showSuccess(message)]
      : undefined));
  },
  ...mapDispatchToProps(dispatch)
}))(RouterChangeBoard);
