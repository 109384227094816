import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { faComments } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { convertToDate } from '../../../components/utils';
import { FormattedMessage } from 'react-intl';
import m from '../messages';



export default function TableBoards({ description, items }) {
  const renderTableTitle = () => description
    ? <h3 className='table__title'>{<FormattedMessage {...m.boardsTableTitle} values={{ description }}/>}</h3>
    : null;

  const renderTableHead = () => (
    <div className='table__row table__row--header'>
      <div className='table__col'><FormattedMessage {...m.boardsTableHeader} /></div>
      <div className='table__col'><FormattedMessage {...m.threads} /></div>
      <div className='table__col'><FormattedMessage {...m.messages} /></div>
      <div className='table__col'><FormattedMessage {...m.lastPost} /></div>
    </div>
  )

  const renderTableBody = () => items.map(({ title, path, threads, messages, lastPost }) => (
    <div className='table__row' key={path}>
      <div className='table__col'>
        <Link to={path}>
          <FontAwesomeIcon icon={faComments} />
          { title }
        </Link>
      </div>
      <div className='table__col'>{threads}</div>
      <div className='table__col'>{messages}</div>
      <div className='table__col'>{convertToDate(lastPost)}</div>
    </div>
  ))

  return (
    <div className='table table--boards'>
      <div className='table__body'>
        { renderTableTitle() }
        { renderTableHead() }
        { renderTableBody() }
      </div>
    </div>
  )
}



TableBoards.defaultProps = {
  description: '',
  items: []
}

TableBoards.propTypes = {
  description: PropTypes.string, // description property is title
  items: PropTypes.arrayOf(PropTypes.object)
}