/* eslint-disable max-len */
import HttpServices from './index';

class ThreadsService extends HttpServices {

  createThread = (path, body) => this.http.post('/threads', body, this._setHeaderBoardsPath(path));

  createReply = (path, id, body) => this.http.post(`/threads/${id}`, body, this._setHeaderBoardsPath(path));

  deleteWithBody = body => this.http.delete('/threads', { data: body });

  deleteThread = (path, id) => this.http.delete(`/threads/${id}`, this._setHeaderBoardsPath(path));

  deletesThreadAndReplies = (path, id) => this.http.delete(`/threads/${id}/replies`, this._setHeaderBoardsPath(path));

  getAuthorPosts = (userId, orderBy, page) => this.http.get(`/threads/authors/${userId}?itemsPerPage=TWENTY_FIVE&listMessagesBy=${orderBy}&page=${page}`);

  getThreadById = (path, threadId, itemsPerPage, page, viewType, sortDirection) => {
    let url = `/threads/${threadId}`;
    let queryPagingParams = '';
    if (itemsPerPage || page) queryPagingParams = HttpServices.createQueryForPaging(itemsPerPage, page);
    const symbolConcatQuery = queryPagingParams ? '&' : '?';
    url = `${url}${queryPagingParams}${symbolConcatQuery}viewType=${viewType}&sortDirection=${sortDirection}`;
    return this.http.get(url, this._setHeaderBoardsPath(path));
  }

  searchThreads = query => this.http.post(`/searches/threads?${query.pagingString}`, query.params);

  updateThread = (path, threadId, body) => this.http.put(`/threads/${threadId}`, body, this._setHeaderBoardsPath(path));

  moveThread = (id, path, newPath) => this.http.put(`/threads/${id}/move?newPath=${newPath}`, null, this._setHeaderBoardsPath(path));

  restoreThread = (id, path) => this.http.patch(`/threads/${id}/restore`, null, this._setHeaderBoardsPath(path));
}

export default new ThreadsService();

