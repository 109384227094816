import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

// data from redux
import {
  // selectors
  activeModalSelector,
  loadingSelector,
  errorSelector,
  contentSelector,
  // action
  closeBoardInfoModal,
} from '../../ducks/board-info';
import { entitiesSelector } from '../../ducks/home-category-page';

import Progress from '../common/progress';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFolderOpen, faComments } from '@fortawesome/free-solid-svg-icons';
import { FormattedMessage } from 'react-intl';
import m from './messages';
import { CATEGORY_TYPES } from '../../constants';


export default function BoardInfo() {
  const dispatch = useDispatch();

  const activeModal = useSelector(activeModalSelector);
  const entities = useSelector(entitiesSelector);
  const loading = useSelector(loadingSelector);
  const content = useSelector(contentSelector);
  const error = useSelector(errorSelector);
  const errorDefaultMsg = 'Something happened wrong...';


  const handleClose = () => dispatch(closeBoardInfoModal())

  const renderContent = () => {
    const boardName = entities.title;
    if (loading) return <Progress classModificator='boards-info' />;

    if (error) return (
      <>
        <DialogTitle id="alert-dialog-title">Error occured</DialogTitle>
        <DialogContent>
            <DialogContentText id="alert-dialog-description">{errorDefaultMsg}</DialogContentText>
        </DialogContent>
      </>
    );

    if (content) return (
      <>
        <DialogContent>
          <div dangerouslySetInnerHTML={{__html: content}} />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            <FormattedMessage {...m.close} />
          </Button>
        </DialogActions>
      </>
    );

    return (
      <>
        <DialogContent>
          <h3 className='categories__title'>
            { boardName && (
              <>
                <FontAwesomeIcon icon={entities.nodeType === CATEGORY_TYPES.category ? faFolderOpen : faComments} />
                <FormattedMessage {...m.title} values={{ name: boardName }} />
              </>
              )
            }
          </h3>
          <FormattedMessage {...m.contact} />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            <FormattedMessage {...m.close} />
          </Button>
        </DialogActions>
      </>
    )
  }

  return (
    <Dialog
      open={activeModal}
      onClose={handleClose}
      aria-labelledby="board-information-dialog"
      aria-describedby="board-information-content"
      className={`boards-info-modal${ loading ? ' loading': '' }`}
    >
      { renderContent() }
    </Dialog>
  )
}

