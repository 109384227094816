import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Link, useParams } from 'react-router-dom';

import m from '../../pages/volunteer/messages';
import { useSelector } from 'react-redux';
import { pathNameSelector } from '../../ducks/volunteer';

export default function SubmittedContent(_) {
  // routing
  const { path } = useParams();

  // data from redux
  const pathName = useSelector(pathNameSelector);

  return (
    <div className='volunteer-content volunteer-content--submitted'>
      <p className='volunteer__title'>
        <FormattedMessage {...m.submitted1} />
        <FormattedMessage {...m.submitted2} />
      </p>
      <p className='volunteer__title'>
        <FormattedMessage
          {...m.back}
          values={{
            name: (
              <Link className='volunteer__link' to={`/${path}`}>
                {pathName} Board
              </Link>
            )
          }}
        />
      </p>
    </div>
  );
};
