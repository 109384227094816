import React from 'react';
import { arrayOf, shape, string } from 'prop-types';
import { Link } from 'react-router-dom';
import m from './messages';
import { FormattedMessage } from 'react-intl';
import { categoryLink } from '../utils/old_link';

function getLink(link, index, lastIndex) {
  let linkTemplate;

  if (link.path === '') {
    linkTemplate = (
      <Link className="breadcrumbs__link" to="/">{link.title || <FormattedMessage {...m.messageBoards} />}</Link>
    );
  } else if (!link.path || (lastIndex > 1 && index === lastIndex)) {
    linkTemplate = (
      <span className="breadcrumbs__link breadcrumbs__link--active">{link.title}</span>
    );
  } else if (link.path[0] === '/') {
    linkTemplate = <Link className="breadcrumbs__link" to={link.path}>{link.title}</Link>;
  } else {
    linkTemplate = <Link className="breadcrumbs__link" to={categoryLink(link.path)}>{link.title}</Link>;
  }
  return (
    <li className="breadcrumbs__item" key={link.path || index}>
      {linkTemplate}
    </li>
  );
}

function Breadcrumbs({ items }) {
  if (!(items || items.length > 0)) {
    return null;
  }
  const lastIndex = items.length - 1;
  return (
    <nav aria-label='breadcrumb' className="breadcrumbs">
      <ul className="breadcrumbs__list">
        {items.map((link, index) => getLink(link, index, lastIndex))}
      </ul>
    </nav>
  );
}

Breadcrumbs.propsTypes = {
  items: arrayOf(shape({
    path: string,
    title: string.isRequired
  }))
};

Breadcrumbs.defaultProps = {
  items: []
};

export default Breadcrumbs;