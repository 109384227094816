import React from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
// data
import { isSuperAdminRoleSelector } from '../../../../ducks/user';
import { isActiveToolbar } from '../../../../ducks/admin-panel';
// links views
import { ListAdminLink, EditOptionsLink, CreateNewCategoryLink } from '../links/list';
import Menu from '../../../menu';
import m from '../messages';


export default function HomeList(props) {
  const isSuperAdmin = useSelector(isSuperAdminRoleSelector);
  const activeToolbar = useSelector(isActiveToolbar);

  function renderListItems() {
    if (isSuperAdmin && activeToolbar) return (
      <div>
      <h2 className='admin-tool-title'>
        <FormattedMessage {...m.addminTool} />
      </h2>
      <Menu classModificator={props.classes}>
        <ListAdminLink {...props} />
        <EditOptionsLink {...props} />
        <CreateNewCategoryLink {...props} />
      </Menu>
      </div>
    )

    return null;
  }
  
  return renderListItems()
}


HomeList.propTypes = {
  path: PropTypes.string
}