import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';

import m from '../messages';
import { convertToDate } from '../../utils';


export default function DeletedMessage({ markedDeletedBy, updated }) {
  let deletedBy = <FormattedMessage {...m.deletedByAdmin} />;
  if (markedDeletedBy === 'AUTHOR') deletedBy = <FormattedMessage {...m.deletedByAuthor} />;

  const time = convertToDate(updated)
  const values = { deletedBy };
  if (time) values.deletedOn = time;
  
  return <FormattedMessage {...m.deleteMessage} values={{...values}} />
}

DeletedMessage.propTypes = {
  markedDeletedBy: PropTypes.string,
  updated: PropTypes.number,
}