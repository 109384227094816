export function pick(object, ...keys) {
  const result = {};
  for (const key of keys) {
    if (key in object) {
      result[key] = object[key];
    }
  }
  return result;
}

export function omit(object, ...keys) {
  const result = {};
  for (const key of Object.keys(object)) {
    if (keys.indexOf(key) < 0) {
      result[key] = object[key];
    }
  }
  return result;
}

export function isObject(value) {
  return typeof value === 'object' && value !== null;
}

export function applyDefaults(object, defaults) {
  for (const key in defaults) {
    if (!(key in object)) {
      object[key] = defaults[key];
    }
  }
  return object;
}

export function isUUID(value) {
  return /[0-9a-fA-F]{8}\-[0-9a-fA-F]{4}\-[0-9a-fA-F]{4}\-[0-9a-fA-F]{4}\-[0-9a-fA-F]{12}/.test(value);
}
