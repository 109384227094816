import React, { useEffect } from 'react';
import { FormattedMessage } from 'react-intl';
import m from './messages';
import Alert from '../../common/alert';
import ManageSpamForm from './form';
import ManageSpamResults from './results';
import AdminView from '../../admin/view';
import { trackPageView } from '../../../utils/tealium';

export default function ManageSpamPage() {
  useEffect(() => trackPageView('manage-spam'), []);
  return (
    <AdminView
      title={<FormattedMessage {...m.manageSpam} />}
      classes="manage-spam"
    >
      <Alert />
      <ManageSpamForm />
      <ManageSpamResults />
    </AdminView>
  );
}
