import React,{useRef,useEffect} from 'react';
import { Link } from 'react-router-dom';
import { FormattedHTMLMessage, FormattedMessage } from 'react-intl';
import m from './messages';
import { userEntitiesSelector } from '../../ducks/user';

export function SurnameLettersBoard({ active, letters: levelsLetters }) {
  const ref = useRef();
  useEffect(()=>{
    ref.current.children[0].focus();
  },[])
  if (!levelsLetters.length) return null;

  return (
    <div className="surname-letters">
      <h3 className="surname-letters__title"><FormattedMessage {...m.surnames} /></h3>
      <p className="surname-letters__desc">
        <FormattedHTMLMessage {...m.tipSurnames} />
      </p>
      <ul className="surname-letters__list">
        {levelsLetters.map((levelLetters, index) => (
          <li className="surname-letters__item" key={index} style={{ paddingLeft: `${index * 10}px` }} ref={ref}> 
            {getLettersLink(active, levelLetters)}
          </li>
        ))}
      </ul>
    </div>
  );
}

function getLettersLink(activeLetters, levelLetters) {
  const getClassName = letter => {
    const classValue = 'surname-letters__link';
    if (activeLetters.includes(letter)) return `${classValue} ${classValue}--active`;
    return classValue;
  };

  const getAriaLabel = letter => {
    let ariaLabel = null;
    if (activeLetters.includes(letter)) return ariaLabel = `${letter} is active`;
    return ariaLabel;
  };
  return levelLetters.map((letter,index) => (
    <Link
      className={getClassName(letter)}
      key={letter}
      to={`?sname=${letter}`}
      tabIndex={(activeLetters.length < letter.length) ? "0" : null}
      aria-label={getAriaLabel(letter)}
    >
      {letter}
    </Link>
  ));
}

SurnameLettersBoard.defaultProps = {
  active: [],
  levelsLetters: []
};

export default SurnameLettersBoard;
