import { takeEvery } from 'redux-saga/effects';
import { appName } from '../config';
import { action, modification } from '../components/utils/redux';
import api from '../services/suggestions-service';

export const moduleName = 'old_rights';
export const prefix = `${appName}/${moduleName}`;
export const createPrefix = `${prefix}/create`;
export const RIGHTS_CREATE_REQUEST = `${createPrefix}/REQUEST`;
export const RIGHTS_CREATE_SUCCESS = `${createPrefix}/SUCCESS`;

export const requestCreateRights = (path, next) => action(RIGHTS_CREATE_REQUEST, path, next);

export const createRights = modification(
  path => [api.createRights, path],
  RIGHTS_CREATE_SUCCESS
);

export function* saga() {
  yield takeEvery(RIGHTS_CREATE_REQUEST, createRights);
}
