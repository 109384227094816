import React from 'react';
import PropTypes from 'prop-types';

// templates
import AuthorThread from '../author';
import ThreadMetaDetail from '../thread-meta-detail';
import Attachments from '../attachments';
import ThreadTool from '../tools';
import ThreadIndicator from '../indicator';

export default function ListReplies({ isGuest, replies, institution, isAuthenticated }) {

  function renderReplyItem({ id, path, subject, user, repliesCount, posted, updated, classification, body, attachments, userMarkedDeleted, viewed, surnames }) {
    const showIndicator = isAuthenticated && !institution && !viewed;

    const _getHeadMetaInfo = () => (
      <div className='list-replies__item-meta'>
        <p className='list-replies__subject-wrap'>
          { showIndicator && !isGuest && (
            <span className='list-replies__indicator'>
              <ThreadIndicator />
            </span>)
          }
          <span className='list-replies__subject'>{subject}</span>
        </p>
        <div className='list-replies__author'>
          <AuthorThread isGuest={isGuest} {...user} />
        </div>
      </div>
    )


    return (
      <li className='list-replies__item' key={id}>
        <div className='list-replies__item-head'>
          { _getHeadMetaInfo() }
          <ThreadMetaDetail repliesCount={repliesCount} posted={posted} updated={updated} classification={classification} surnames={surnames} />
        </div>
        <div className='list-replies__item-body'>
        { body && <p className='list-replies__msg'>{body}</p> }
        { attachments && (
            <div className='list-replies__attachments'>
              <Attachments attachments={attachments} />
            </div>)
          }
        <div className='list-replies__tools'>
          <ThreadTool
            replieId={id}
            repliePath={path}
            userMarkedDeleted={userMarkedDeleted}
            authorThreadId={user.userId}
          />
        </div>
        </div>
      </li>
    )
  }


  return (
    <ul className='list-replies'>
      { replies.map(renderReplyItem) }
    </ul>
  )
}


ListReplies.propTypes = {
  replies: PropTypes.arrayOf(PropTypes.object),
}

