import config from '../../config';

let env;
    switch (config.env) {
        case 'local':
        case 'dev':
          env = 'dev';
          break;
        case 'stage':
          env = 'stage';
          break;
        default:
          env = '';
      }

export const BannerConfig = {
    headerText: 'Join the Community groups beta',
    introText: 'We’re testing a new collaboration feature to help you connect with others, celebrate your milestones, and discover more together. Interested in joining?',
    buttonText: 'I want to join',
    betaUrl: `https://www.ancestry${env}.com/s134133/t48941/rd.ashx`,
};

export const getInitialVisibility = () => {
    const shouldDisplay = sessionStorage.getItem('shouldDisplayBanner') !== 'false';
    return shouldDisplay;
};

export const updateVisibility = (isVisible) => {
    sessionStorage.setItem('shouldDisplayBanner', isVisible ? 'true' : 'false');
};
