import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { useParams } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';

//data
import { editThread, loadingSelector } from '../../../../ducks/thread-edit';
// templates
import BoardLink from './board-link';
import FiledSubject from '../../field-subject';
import FiledSurnames from '../../field-surnames';
import UserLink from '../../../common/user-link';
import ClassificationSelect from '../../classification-select';
import FieldMessage from '../../field-message';
import FieldCheckbox from '../../field-checkbox';
import FieldAttachments from '../../field-attachments';
import BtnSubmit from '../../btn-submit';
import CancelLink from '../../cancel-link.js';
import Progress from '../../../common/progress';
// helpers
import m from '../../messages';
import { convertToDate } from '../../../utils';
import { Classification as dictionary } from '../../../../constants';


export default function AlienForm({ thread, sameUser }) {
  const { path, threadId } = useParams();
  const dispatch = useDispatch();
  const submitLoading = useSelector(loadingSelector)

  const [ fields, setFileds ] = useState(() => ({
    subject: thread.subject || '',
    surnames: thread.surnames || '',
    classification: thread.classification || dictionary.QUERIES,
    body: thread.body || '',
    sendAlert: !!thread.sendAlert,
  }))

  const handleChangeField = ({ target: { value, name } }) => setFileds(prevState => ({ ...prevState, [name]: value }))
  const handleChangeSelect = ({ target: { value } }) => setFileds(prevState => ({ ...prevState, classification: value }))
  const handleChangeCheckbox = ({ target: { name } }) => setFileds(prevState => ({ ...prevState, [name]: !prevState.sendAlert }))

  const handleSubmit = () => dispatch(editThread(true, fields, path, threadId)) // first arg for checking form alien

  return (
    <div className='thread-form thread-form--alien'>
      { thread.namePath && (
        <div className='thread-form__board-link'>
          <BoardLink namePath={thread.namePath} />
        </div>
      )}
      <div className='thread-form__field thread-form__field--subject'>
        <FiledSubject name='subject' readOnly value={fields.subject} />
      </div>
      <div className='thread-form__user'>
        <p className='thread-form__user-title'>
          <FormattedMessage {...m.author} />
        </p>
        <UserLink userData={thread.user} />
      </div>
      <div className='thread-form__posted'>
        <p className='thread-form__posted-title'>
          <FormattedMessage {...m.posted} />
        </p>
        <p className='thread-form__posted-time'>
          { convertToDate(thread.posted) }
        </p>
      </div>
      <div className='thread-form__field thread-form__field--surnames'>
        <FiledSurnames name='surnames' value={fields.surnames} handleChange={handleChangeField} />
      </div>
      <div className='thread-form__field thread-form__field--classification'>
        <ClassificationSelect name='classification' value={fields.classification} handleChange={handleChangeSelect} />
      </div>
      <div className='thread-form__field thread-form__field--textarea'>
        <FieldMessage sameUser={sameUser} name='body' value={fields.body} readOnly />
      </div>
      <div className='thread-form__field thread-form__field--checkbox'>
        <FieldCheckbox name='sendAlert' value={fields.sendAlert}  handleChange={handleChangeCheckbox}/>
      </div>
      <div className='thread-form__field thread-form__field--attachemnt'>
        <FieldAttachments
          canDownload={false}
          uploadedList={thread.attachments}
        />
      </div>
      <div className='thread-form__btns'>
        <div className='thread-form__btn-submit'>
          <BtnSubmit handleSubmit={handleSubmit} />
        </div>
        <div className='thread-form__cancel-link'>
          <CancelLink />
        </div>
      </div>
      { submitLoading && (
          <div className='thread-form__progress'>
            <Progress small />
          </div>
      )}
    </div>
  )
}



AlienForm.propTypes = {
  thread: PropTypes.object.isRequired
}
