import React from 'react';
import PropTypes from 'prop-types';

import ItemsPerPage from './items-per-page';
import Pages from './pages';


export default function Pagination({ countType, hasItems, paging, showItemsPerPage, showPages, ...otherProps }) {
  return (
    <div className='paging spam-control'>
      { showItemsPerPage && <ItemsPerPage countType={countType} paging={paging}  {...otherProps} /> }
      { showPages && <Pages hasItems={hasItems} paging={paging} {...otherProps} /> }
    </div>
  )
}

Pagination.propTypes = {
  paging: PropTypes.object
}

Pagination.defaultProps = {
  showItemsPerPage: true,
  showPages: true
}
