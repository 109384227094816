import { spawn, take, call, put } from "redux-saga/effects";
import { push } from "connected-react-router";
import { Record } from "immutable";

import api from "../services/report-abuse-services";
import { appName } from "../config";
import url from "../url-service";

/**
 * Constants
 */

export const moduleName = "report-abuse";
export const prefix = `${appName}/${moduleName}`;

export const CREATE_ABUSE_REQUEST = `${prefix}/CREATE_ABUSE_REQUEST`;
export const CREATE_ABUSE_SUCCESS = `${prefix}/CREATE_ABUSE_SUCCESS`;
export const CREATE_ABUSE_FAIL = `${prefix}/CREATE_ABUSE_FAIL`;

/**
 * Reducer
 */

export const ReducerRecord = Record({
  entity: {},
  err: null
});

export default function reducer(state = new ReducerRecord(), { type, payload, err }) {
  switch (type) {
    case CREATE_ABUSE_SUCCESS:
      return state.set("entity", payload);

    case CREATE_ABUSE_FAIL:
      return state.set("err", err);

    default:
      return state;
  }
}

/**
 * Actions
 */

export const createAbuse = (path, id, data) => ({
  type: CREATE_ABUSE_REQUEST,
  payload: { path, id, data }
});

/**
 * Sagas
 */

export const createAbuseSaga = function*() {
  while(true) {
    const {
      payload: { path, id, data }
    } = yield take(CREATE_ABUSE_REQUEST);
    try {
      yield call(api.createReportAbuse, data, path);
      yield put({
        type: CREATE_ABUSE_SUCCESS,
        payload: data
      });
      yield put(push(url.createPath("report-abuse", `${path}/${id}`)));
    } catch (err) {
      yield put({
        type: CREATE_ABUSE_FAIL,
        err
      });
    }
  }
};

export function* saga() {
  yield spawn(createAbuseSaga);
}
