import React from 'react';

import MenuItem from './menu-item';

export { MenuItem };
export default function Menu({ children, classModificator }) {
  return (
    <ul className={`nav-menu${ classModificator ? ` ${classModificator}`: ''}`}>
      { children }
    </ul>
  )
}