import React, { useState, useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';
import { FormattedMessage } from 'react-intl';

// data
import {
  // actions
  addNewFile, initPrevUploaded,
  // selectors
  loadingUploadedListSelector
} from '../../../ducks/attachments';
// components
import UploadedList from './uploaded-list';
import AddedList from './added-list';

import m from '../messages';
import { ACCEPT_FILE_TYPES, MAX_FILE_SIZE } from '../../../constants';



export default function FieldAttachments({ uploadedList, canDownload }) {
  const [ errSize, setErrorSize ] = useState(false);
  const inpFile = useRef(null);
  const dispatch = useDispatch();
  const loadingInit = useSelector(loadingUploadedListSelector);

  useEffect(() => {
    dispatch(initPrevUploaded(uploadedList))
  }, [uploadedList])


  const handleChange = ({ target: { files } }) => {
    if (errSize) setErrorSize(false); // clearing for new upload

    const file = files[0];

    if (file.size > MAX_FILE_SIZE) {
      setErrorSize(true);
      inpFile.current.value = '';  // clearing cache file
      return; 
    }

    dispatch(addNewFile(file))
    inpFile.current.value = '';  // clearing cache file
  }


  if (loadingInit) return null;

  return (
    <div className='attachments'>
      <p className='attachments__title'>
        <FormattedMessage {...m.attachments} />
      </p>
      { canDownload && (
          <div className='attachments__field-wrap'>
            <button className='attachments__field-btn-add' onClick={() => inpFile.current.click()}>
              <FormattedMessage {...m.chooseFile} />
            </button>
            <input type='file' ref={inpFile} style={{ dispay: 'none' }} onChange={handleChange} accept={ACCEPT_FILE_TYPES} />
          </div>
      )}

      <div className='attachments__list'>
        <UploadedList />
        <AddedList />
      </div>

    { errSize && (
      <div className='attachments__error-size'>
        <FormattedMessage {...m} />
        <button type='button' className='attachments__error-btn' onClick={() => setErrorSize(false)} />
      </div>
    )}
    </div>
  )
}

FieldAttachments.defaultProps = {
  uploadedList: []
}

FieldAttachments.propTypes = {
  uploadedList: PropTypes.array,
  canDownload: PropTypes.bool,
}
