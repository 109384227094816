import React from 'react';
import { useDispatch } from 'react-redux';
import { FormattedMessage } from 'react-intl';

import { toggleAdminToolbar } from '../../../ducks/admin-panel';
import m from './messages';


export default function ButtonExit(props) {
  const dispatch = useDispatch();

  return (
    <button type="button" className="link backLink icon iconArrowLeft" onClick={() => dispatch(toggleAdminToolbar())}>
      <FormattedMessage {...m.backToBoards} />
    </button>
  )
}