import { call, put, takeEvery } from 'redux-saga/effects';
import { createSelector } from 'reselect';
import { Record } from 'immutable';
import api from '../services/users-services';
import { action } from '../components/utils/redux';
import { appName } from '../config';
import { Http } from '../constants';
import { showError } from './common/alert';
import { trimed } from '../utils/strings';

export const moduleName = '__user';
export const moduleOrigin = `${appName}/${moduleName}`;

export const currentPrefix = `${moduleOrigin}/current`;
export const USER_CURRENT_REQUEST = `${currentPrefix}/REQUEST`;
export const USER_CURRENT_RECEIVE = `${currentPrefix}/RECEIVE`;
export const Roles = {
  Admin: 'ROLE_SUPER_ADMIN',
  Moderator: 'ROLE_ADMIN',
  Authenticated: 'ROLE_AUTHENTICATED',
  Guest: 'ROLE_GUEST'
};
export const RolesPriorities = [Roles.Guest, Roles.Authenticated, Roles.Moderator, Roles.Admin];
Object.freeze(Roles);
Object.freeze(RolesPriorities);

export const requestCurrentUser = () => action(USER_CURRENT_REQUEST);
export const receiveCurrentUser = user => action(USER_CURRENT_RECEIVE, user);

export function* fetchUser() {
  try {
    const { data } = yield call(api.getCurrent);
    yield put(receiveCurrentUser(data));
  } catch (err) {
    // If current user is not logged in
    if (err.response && err.response.status === Http.Forbidden) {
      yield put(receiveCurrentUser(null));
    } else {
      yield put(showError(err));
    }
  }
}

export const CurrentUser = Record({
  adminNodes: [],
  email: '',
  role: Roles.Guest,
  userId: '',
  username: ''
});

export const UserEntities = Record({
  current: null
});

export default function reducer(state = UserEntities({}), { type, payload }) {
  switch (type) {
    case USER_CURRENT_REQUEST:
      return state
        .set('current', null);
    case USER_CURRENT_RECEIVE: {
      if (payload) {
        return state
          .set('current', CurrentUser(payload));
      }
      return state;
    }
    default:
      return state;
  }
}

export function selectUserEntities(state) {
  return state[moduleName];
}

export const selectCurrentUser = createSelector(
  selectUserEntities,
  state => state.get('current')
);

export const selectCurrentUserId = createSelector(
  selectCurrentUser,
  user => user ? user.get('userId') : ''
);

export const selectPrivilegesIsDefined = createSelector(
  selectCurrentUser,
  user => user && typeof user === 'object' && 'role' in user
);

export const selectAdminNodes = createSelector(
  selectCurrentUser,
  state => (state ? state.get('adminNodes') : [])
);

/**
 * Returns role of user. By default user role is ROLE_GUEST even user privileges is not loaded yet.
 * Use selectPrivilegesIsDefined to detect if privileges is loaded
 * @type {function}
 */
export const selectRole = createSelector(
  selectCurrentUser,
  user => (user && user.role) || Roles.Guest
);

export function atLeastRole(userRole, role) {
  if (Roles.Guest === role) {
    return true;
  }
  const userRoleIndex = RolesPriorities.indexOf(userRole);
  if (userRoleIndex < 0) {
    return false;
  }
  return userRoleIndex >= RolesPriorities.indexOf(role);
}

export const isRole = (role, ...roles) => roles.indexOf(role) >= 0;

export function currentUserCanModeratePath(state, path) {
  const role = selectRole(state);
  if (role === Roles.Admin) {
    return true;
  }
  if (role === Roles.Moderator) {
    return !!selectAdminNodes(state).find(node => node.path === path);
  }
  return false;
}

export function* saga() {
  yield takeEvery(USER_CURRENT_REQUEST, fetchUser);
}

export function userNameOf(user) {
  return trimed(user.displayName) || trimed(user.username) || user.userId;
}
