import React from 'react';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExclamationTriangle, faCheck } from '@fortawesome/free-solid-svg-icons';
import { FormattedMessage } from 'react-intl';
import classnames from 'classnames';
import m from './messages';
import Button from '../button';
import SuggestionList from './suggestions-list';

function InputField(props) {
  const { type, inpValue, label, handleInputChange, errInpValue, entities } = props;
  const errMsg = (
    <p id='errmsg' className="search__err-msg">
      <FontAwesomeIcon icon={faExclamationTriangle} />
      <span><FormattedMessage {...m.minimumCharCondition} /></span>
    </p>
  );

  const checkIsErrorValue = () => {
    if (errInpValue === true) return ' invalid';
    if (errInpValue === false) return ' valid';
    return '';
  };

  const isEmpty = !inpValue.trim();
  const className = classnames('search__filed-label', {
    empty: isEmpty,
    filled: !isEmpty
  });

  return (
    <div className={`search__field-wrap${checkIsErrorValue()}`}>
      <label className={className}>
        <span className="search__label-value">{ label }</span>
        { errInpValue === false && <FontAwesomeIcon icon={faCheck} color="#9cbe30" /> }
        <input
          type={type}
          className="search__inp"
          aria-describedby='errmsg'
          value={inpValue}
          onChange={handleInputChange}
          list="word-suggestions"
        />
      </label>
      { errInpValue && errMsg }
      <Button title={<FormattedMessage {...m.go} />} inpValue={inpValue} handleButtonClick={props.handleButtonClick} />
      <SuggestionList {...props} />
    </div>
  );
}

InputField.propTypes = {
  type: PropTypes.string.isRequired,
  inpValue: PropTypes.string.isRequired,
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.object]).isRequired,
  handleInputChange: PropTypes.func.isRequired,
  errInpValue: PropTypes.oneOfType([
    PropTypes.bool,
    PropTypes.object
  ])
};

export default InputField;

