import * as globals from '../../globals';
import settings from './local-settings';

Object.assign(global, globals);

export default function initDevelopmentTools(store) {
  if (DEBUG) {
    window.store = store;
    console.log(`Project: ${PROJECT_NAME} ${PROJECT_VERSION}\nEnvironment: ${ENVIRONMENT}`);
    window.setSecureATT = function (token) {
      document.cookie = `SecureATT=${token}; path=/; expires=Tue, 01 Jan 2030 00:00:00 GMT`;
    };
    window.localSettings = settings;
  }
}