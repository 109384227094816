import React from 'react';
import { connect } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import m from './messages';
import ManageList from '..';
import { selectSuggestionsItemsWithNumber } from '../../../../ducks/suggestion';
import ManageBoard from './board';

export function ManageBoards({ items }) {
  let boards;
  if (items) {
    boards = items.map(item => <li key={item.uid}><ManageBoard {...item} /></li>);
  }
  return (
    <ManageList title={<FormattedMessage {...m.title} />}>
      {boards}
    </ManageList>
  );
}

export function mapStateToProps(state) {
  return {
    items: selectSuggestionsItemsWithNumber(state)
  };
}

export default connect(mapStateToProps)(ManageBoards);
