import React from 'react';
import { faInfoCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { FormattedMessage } from 'react-intl';

import m from './messages';


export default function NoticeEmpty({ board }) {
  const key = board ? 'noBoard': 'noCategory';
  return (
    <div className='categories__notice'>
      <div className='categories__notice-info'>
        <FontAwesomeIcon icon={faInfoCircle} />
        <span className='categories__notice-msg'>
          <FormattedMessage {...m[key]} />
        </span>
      </div>
    </div>
  )
}
