import { defineMessages } from 'react-intl';

export default defineMessages({
  category: 'Categories',
  threads: 'Threads',
  messages: 'Messages',
  categoryTableHeader:'Category',
  subCategoriesTableHeader:'Sub-Categories',
  boardsTableHeader:'Boards',
  lastPost:'Last Post',
  replies:'Replies',
  author:'Author',
  moreCategoryLink: 'More "{title}" Categories',
  categoriesTableTitle:'"{description}" Categories',
  boardsTableTitle:'"{description}" Boards',
  // notice modal messages
  success_add_boards: 'This Message Board has been saved to your favorites.',
  success_add_categories: 'This Category has been saved to your favorites.',
  success_add_threads: 'This Thread has been saved to your favorites.',
  success_remove_boards: 'This Message Board has been removed from your favorites.',
  success_remove_categories: 'This Category has been removed from your favorites.',
  success_remove_threads: 'This Message Thread has been removed from your favorites.',

  fail_add: 'This Message Board Category was not saved to your favorites.',
  fail_remove: 'This Message Board Category was not removed from your favorites.',
  noCategory: 'The category does not have messages',
  noBoard: 'There are no messages in this board.',
  gateway: 'Posts to this board are copied to the "{name}" RootsWeb.com mailing list.'
});

