import { call, put, spawn, take, select } from 'redux-saga/effects';
import { Record } from 'immutable';
import { createSelector } from 'reselect';
import { appName } from '../config';
import api from '../services/favorites-service';

import { SET_CATEGORY_TO_FAVORITE, DELETE_CATEGORY_FROM_FAVORITE, isFavoriteSelector } from './home-category-page';

/**
 * Constants
 */
export const moduleName = 'category-favorite';
export const prefix = `${appName}/${moduleName}`;

// === ADD/REMOVE AUTHOR TO FAVORITE
export const ADD_REMOVE_FAVORITE_REQUEST = `${prefix}/ADD_REMOVE_FAVORITE_REQUEST`;

export const ADD_TO_FAVORITE_SUCCESS = `${prefix}/ADD_AUTHOR_TO_FAVORITE_SUCCESS`;
export const ADD_TO_FAVORITE_FAIL = `${prefix}/ADD_AUTHOR_TO_FAVORITE_FAIL`;

export const REMOVE_FROM_FAVORITE_SUCCESS = `${prefix}/REMOVE_FROM_FAVORITE_REQUEST`;
export const REMOVE_FROM_FAVORITE_REQUEST = `${prefix}/REMOVE_FROM_FAVORITE_SUCCESS`;
export const REMOVE_FROM_FAVORITE_FAIL = `${prefix}/REMOVE_FROM_FAVORITE_FAIL`;

// constants for all favorite types (author, alert, category, thread)
export const FAVORITE_RESET_TO_DEFAULT = 'FAVORITE_RESET_TO_DEFAULT';
export const FAVORITE_STATE_CLEAN_UP = 'FAVORITE_STATE_CLEAN_UP';  // saved loading state for link spinner

/**
 * Reducer
 */

export const ProcessRecord = Record({
  done: false,
  err: null
})

export const ReducerRecord = Record({
  loading: false,
  added: ProcessRecord(),
  removed: ProcessRecord()
})

export default function reducer(state = ReducerRecord(), { type, _, err }) {
  switch(type) {
    case ADD_REMOVE_FAVORITE_REQUEST:
      return state.set('loading', true);

    case ADD_TO_FAVORITE_SUCCESS:
      return state.set('loading', false)
                  .set('removed', ProcessRecord())
                  .set('added', ProcessRecord({ done: true }))


    case ADD_TO_FAVORITE_FAIL:
      return state.set('loading', false)
                  .set('removed', ProcessRecord())
                  .set('added', ProcessRecord({ err }))

    case REMOVE_FROM_FAVORITE_SUCCESS:
        return state.set('loading', false)
                    .set('removed', ProcessRecord({ done: true }))
                    .set('added', ProcessRecord())
  
    case REMOVE_FROM_FAVORITE_FAIL:
        return state.set('loading', false)
                    .set('removed', ProcessRecord({ err }))
                    .set('added', ProcessRecord())

    case FAVORITE_STATE_CLEAN_UP:
        return state  
                .set('removed', ProcessRecord())
                .set('added', ProcessRecord())

    case FAVORITE_RESET_TO_DEFAULT:
      return state.clear()
            
    default:
      return state;
  }
}
 
 /**
  * Actions
  */

 export const addRemoveCategoryFavorite = (path) => ({ type: ADD_REMOVE_FAVORITE_REQUEST, payload: path })
 export const resetCategryFavoriteState = () => ({ type: FAVORITE_RESET_TO_DEFAULT })




/**
 * Selectors
 */

export const stateSelector = state => state[moduleName];
export const loadingCategorySelector = createSelector(
  stateSelector, state => state.loading
);

export const addedCategorySuccessSelector = createSelector(
  stateSelector, state => state.added.done
)

export const adddedCategoryFailSelector = createSelector(
  stateSelector, state => state.added.err
)


export const removedCategorySuccessSelector = createSelector(
  stateSelector, state => state.removed.done
)

export const removedCategoryFailSelector = createSelector(
  stateSelector, state => state.removed.err
)



/**
* Sagas
*/


const addCategoryToFavoriteSaga = function* (path) {
  try { 
    yield call(api.addToFavorite, path);
    yield put({ type: ADD_TO_FAVORITE_SUCCESS });
    yield put({ type: SET_CATEGORY_TO_FAVORITE });
  } catch(err) {
    yield put({ type: ADD_TO_FAVORITE_FAIL, err })
  }
}

const removeCategoryFromFavoriteSaga = function* (path) {
  try { 
    yield call(api.removeFromFavorites, path);
    yield put({ type: REMOVE_FROM_FAVORITE_SUCCESS });
    yield put({ type: DELETE_CATEGORY_FROM_FAVORITE });
  } catch(err) {
    yield put({ type: REMOVE_FROM_FAVORITE_FAIL, err })
  }
}



export const addRemoveCategorySaga = function* () {
  while(true) {
    const { payload: path } = yield take(ADD_REMOVE_FAVORITE_REQUEST);
    yield put({ type: FAVORITE_STATE_CLEAN_UP }); // close-clean all notice favorites

    const isFavorite = yield select(isFavoriteSelector);
    
    if (isFavorite) yield call(removeCategoryFromFavoriteSaga, path)
    else yield call(addCategoryToFavoriteSaga, path)
  }
}


export const saga = function* () {
  yield spawn(addRemoveCategorySaga)
}