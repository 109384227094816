import React from 'react';
import { useSelector } from 'react-redux';
import {  } from 'react-router-dom';

import { isActiveToolbar } from '../../ducks/admin-panel';
import config from '../../config';



export default function AdvertAnchor({ advertPosition }) {
  const activeToolbar = useSelector(isActiveToolbar);
  if (config.institution || activeToolbar) return null;

  return advertPosition === 'top' ? <div id='dartContentTop' /> : <div id='dartContentSide'/>
}