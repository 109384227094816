import React from 'react';
import { FormattedMessage } from 'react-intl';

import m from './messages';


export default function UserInfo({ user }) {
  return (
    <div className='user-info'>
      <p className='user-info__title'>
        <FormattedMessage {...m.from} />:
      </p>
      <p className='user-info__value'>
        <span className='user-info__name'>
          {user.displayName || user.username}
        </span>
        <a className='user-info__link' href="/secure/logout">
          <FormattedMessage {...m.notYou} />
        </a>
      </p>
  </div>
  )
}