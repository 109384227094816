import { defineMessages } from 'react-intl';

export default defineMessages({
  author: 'Author',
  board: 'Board',
  boards: 'Boards',
  cancel: 'Cancel',
  move: 'Move',
  moveToPath: 'Move To Path',
  posted: 'Posted',
  subject: 'Subject',
  title: 'Move Message'
});
