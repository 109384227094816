import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useHistory, useLocation } from 'react-router-dom';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import useQuery from '../../../hooks/use-query-params';
import mockLists from './items-mock';
import { mapObjToQueryString } from '../../utils';
import { FormattedMessage, injectIntl } from 'react-intl';
import m from './messages';

export function ItemsPerPage({ countType, intl }) {
  // routes data
  const parsedQueryInObj = useQuery();
  const history = useHistory();
  const { pathname } = useLocation();

  // state
  const [listItems, setListItems] = useState(mockLists[countType]);
  const [selected, setSelected] = useState(parsedQueryInObj.itemsPerPage || listItems[0].value);


  useEffect(
    () => {
      setListItems(mockLists[countType]);
    }, [countType]
  )

  const handleChange = ({ target: { value } }) => {
    setSelected(value);

    delete parsedQueryInObj.page; // remove page property
    const url = `${pathname}${mapObjToQueryString(parsedQueryInObj, { itemsPerPage: value })}`;
    history.push(url)
  }

  const arialabel = intl.formatMessage(m.resultPerPageWithCnt, { value: listItems.find(({ value }) => value == selected).name });

  return (
    <div className='paging__count-list'>
      <Select
        inputProps={{ 'aria-label': arialabel }}
        value={selected}
        onChange={handleChange}
      >
        {listItems.map(({ value, name }) => (
          <MenuItem key={name} className='paging__count-item' value={value}>{name}</MenuItem>))
        }
      </Select>
      <span className='paging__count-text'>{<FormattedMessage {...m.resultsPerPage} />}</span>
    </div>
  )
}


ItemsPerPage.propTypes = {
  countType: PropTypes.string
}

ItemsPerPage.defaultProps = {
  countType: 'fifty'
}

export default injectIntl(ItemsPerPage)