import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { FormattedMessage } from 'react-intl';

import { asAdminAuthSelector, userEntitiesSelector } from '../../ducks/user';
import { toggleAdminToolbar, isActiveToolbar } from '../../ducks/admin-panel';
import { showPopup } from '../../ducks/popup';
import Menu, { MenuItem } from '../menu';
import { ROLE_TYPES } from '../../constants';
import url from '../../url-service';
import m from './messages';

export default function FooterMenu(_) {
  const { role: userRole } = useSelector(userEntitiesSelector);
  const asAdminAuth = useSelector(asAdminAuthSelector);
  const activeToolbar = useSelector(isActiveToolbar);
  const dispatch = useDispatch();

  //data from redux
  const { pathname } = useSelector(state => state.router.location);
  const path = pathname.split('/')[1];
  const id = pathname.split('/')[2];

  function getAdminCenterLink() {
    if (!asAdminAuth) return null;

    const adminCenterText = activeToolbar ? m.exitAdminCenter : m.enterAdminCenter;
    const toggleHandler = () => dispatch(toggleAdminToolbar());
    return (
      <MenuItem classModificator='link' onClick={toggleHandler}>
        <FormattedMessage {...adminCenterText} />
      </MenuItem>
    )
  }

  function getRequestBoardLink() {
    if (userRole === ROLE_TYPES.guest) return null;
    return <MenuItem to={url.createPath('request-board', path, id)}><FormattedMessage {...m.requestNewBoard} /></MenuItem>;
  }

  const handlerShowPopup = pageType => () => dispatch(showPopup(pageType))
  return (
    <footer>
      <Menu classModificator='nav-menu--footer'>
         { getAdminCenterLink() }
         { getRequestBoardLink() }
         <MenuItem>
          <a href='/cs/legal/CommunityGuidelines' target='_blank'>
             <FormattedMessage {...m.guidelines} />
          </a>
         </MenuItem>
        <MenuItem classModificator='link' onClick={handlerShowPopup('HELP')}>
          <FormattedMessage {...m.help} />
        </MenuItem>
        <MenuItem classModificator='link' onClick={handlerShowPopup('FAQ')}>
          <FormattedMessage {...m.faq} />
        </MenuItem>
        <MenuItem classModificator='link'>
          <a href={url.createPath('feedback')}>
            <FormattedMessage {...m.feedback} />
          </a>
        </MenuItem>
      </Menu>
    </footer>
  )
}
