import { takeEvery } from 'redux-saga/effects';
import api from '../services/nodes-service';
import { appName } from '../config';
import { action, modification } from '../components/utils/redux';


export const moduleName = 'node';
export const prefix = `${appName}/${moduleName}`;

export const changePrefix = `${prefix}/change`;
export const NODE_CHANGE_REQUEST = `${changePrefix}/REQUEST`;
export const NODE_CHANGE_SUCCESS = `${changePrefix}/SUCCESS`;
export const deletePrefix = `${prefix}/remove`;
export const NODE_REMOVE_REQUEST = `${deletePrefix}/REQUEST`;
export const NODE_REMOVE_SUCCESS = `${deletePrefix}/SUCCESS`;

export const requestNodeChange = (path, operation, params, next) => action(
  NODE_CHANGE_REQUEST,
  { operation, params, path },
  next
);

export const requestNodeRemove = (path, next) => action(NODE_REMOVE_REQUEST, path, next);

export const save = modification(
  ({ operation, params, path }) => [api.changeNode, path, operation, params],
  NODE_CHANGE_SUCCESS
);

export const remove = modification(
  path => [api.removeNode, path],
  NODE_REMOVE_SUCCESS
);

export function* saga() {
  yield takeEvery(NODE_CHANGE_REQUEST, save);
  yield takeEvery(NODE_REMOVE_REQUEST, remove);
}
