import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import m from './messages';


function SuggestionList(props) {
  const { entities: suggestList, inpValue, loading, surnameItemClick, typing, selectedSuggest, activeDropdown, minCharsLength } = props;

  if (!activeDropdown) return null;
  if (typing) return null;
  if (inpValue.length < minCharsLength) return null;
  if (suggestList.length && selectedSuggest) return null;
  return (
    <div className="suggestion">
      { getBody() }
    </div>
  );

  function getListItems() {
    // const heightList = suggestList.length < 5 ? ItemHeight * suggestList.length : MaxListHeight;
    return (
      <ul className="suggestion__list">
        {
          suggestList.map((suggestion, index) => (
            <li
              key={index}
              className="suggestion__item"
              onClick={() => surnameItemClick(suggestion)}
            >
              <strong>{suggestion.slice(0, inpValue.length)}</strong>
              <span>{suggestion.slice(inpValue.length, suggestion.length)}</span>
            </li>
          ))
        }
      </ul>
    );
  }

  function getBody() {
    if (loading) return <span className="suggestion__notice"><FormattedMessage {...m.loading} /></span>;
    if (!suggestList.length) return <div className="suggestion__notice"><FormattedMessage {...m.noMatches} /></div>;
    return getListItems();
  }
}

SuggestionList.propTypes = {
  suggestList: PropTypes.array
};

SuggestionList.defaultProps = {
  suggestList: []
};

export default SuggestionList;
