import React from 'react';
import PropTypes from 'prop-types';

export default function ThreadSubject({ subject }) {
  if (!subject) return null;
  return <p className='thread__subject-thread'>{subject}</p>
}


ThreadSubject.propTypes = {
  subject: PropTypes.string
}

