import { call, put, spawn, take, select } from 'redux-saga/effects';
import { Record, OrderedSet } from 'immutable';
import { createSelector } from 'reselect';
import { appName } from '../config';
import api from '../services/board-info-service';
import { transformAttachmentIdToImgPath } from '../components/utils'

/**
 * Constants
 */

 export const moduleName = 'board-info';
 export const prefix = `${appName}/${moduleName}`;

 export const GET_BOARD_INFO_REQUEST = `${prefix}/GET_BOARD_INFO_REQUEST`;
 export const GET_BOARD_INFO_SUCCESS = `${prefix}/GET_BOARD_INFO_SUCCESS`;
 export const GET_BOARD_INFO_FAIL = `${prefix}/GET_BOARD_INFO_FAIL`;

 export const GET_BOARD_INFO_PREVIEW_REQUEST = `${prefix}/GET_BOARD_INFO_PREVIEW_REQUEST`;
 export const GET_BOARD_INFO_PREVIEW_SUCCESS = `${prefix}/GET_BOARD_INFO_PREVIEW_SUCCESS`;
 export const GET_BOARD_INFO_PREVIEW_FAIL = `${prefix}/GET_BOARD_INFO_PREVIEW_FAIL`;

 export const BOARD_INFO_CLOSE_MODAL = `${prefix}/BOARD_INFO_CLOSE_MODAL`;
 export const BOARD_INFO_RESET = `${prefix}/BOARD_INFO_RESET`;


/**
 * Reducer
 */


export const BoardInfoRecord = Record({
  content: null,
  attachments: OrderedSet([])
});

export const ReducerRecord = Record({
  entities: BoardInfoRecord(),
  activeModal: false,
  loading: false,
  err: null
});

export default function reducer(state = ReducerRecord(), { type, payload, err }) {
  switch(type) {
    case GET_BOARD_INFO_PREVIEW_REQUEST:
    case GET_BOARD_INFO_REQUEST:
        return state  
                .set('activeModal', true)
                .set('loading', true)
  
      case GET_BOARD_INFO_SUCCESS:
        return state  
          .set('loading', false)
          .set('entities', BoardInfoRecord(payload))

      case GET_BOARD_INFO_PREVIEW_SUCCESS:
          return state  
          .set('loading', false)
          .setIn(['entities', 'content'], payload.html)

      
      case BOARD_INFO_CLOSE_MODAL:
        return state.set('activeModal', false)

      case GET_BOARD_INFO_PREVIEW_FAIL:
      case GET_BOARD_INFO_FAIL:
        return state
          .set('loading', false)
          .set('activeModal', false)
          .set('entities', BoardInfoRecord())
          .set('err', err)

      case BOARD_INFO_RESET:
          return state.clear()
            
    default:
      return state;
  }
}
 
 /**
  * Actions
  */
 export const fetchBoardInfo = (path, boardPreview) => ({ type: GET_BOARD_INFO_REQUEST, payload: { path, boardPreview } });
 export const closeBoardInfoModal = () => ({ type: BOARD_INFO_CLOSE_MODAL });
 export const getPreviewContent = (typedContent) => ({ type: GET_BOARD_INFO_PREVIEW_REQUEST, payload: { html: typedContent } })
 export const resetBoardInfoModal = () => ({ type: BOARD_INFO_RESET })

/**
 * Selectors
 */

 export const stateSelector = state => state[moduleName];

 export const activeModalSelector = createSelector(
  stateSelector,
  state => state.activeModal
)

export const loadingSelector = createSelector(
  stateSelector,
  state => state.loading
)
export const entitiesSelector = createSelector(
  stateSelector,
  state => state.entities
);

export const contentSelector = createSelector(
  entitiesSelector,
  entities => entities && entities.content
);


export const errorSelector = createSelector(
  stateSelector,
  state => state.err
)


/**
* Sagas
*/


export const fetchBoardInfoSaga = function* () {
  while(true) {
    const {payload: { path, boardPreview }} = yield take(GET_BOARD_INFO_REQUEST);

    try {
      const { data } = yield call(api.getBoardInfo, path);
      
      if (boardPreview)  data.content = transformAttachmentIdToImgPath(data.attachments, data.content);

      yield put({ type: GET_BOARD_INFO_SUCCESS, payload: data })
    } catch(err) {
      yield put({ type: GET_BOARD_INFO_FAIL, err })
    }
  }
}


export const fetchBoardInfoPreviewSaga = function* () {
  while(true) {
    const { payload } = yield take(GET_BOARD_INFO_PREVIEW_REQUEST);

    try {
      const { data } = yield call(api.getPreviewContent, payload);
      yield put({ type: GET_BOARD_INFO_PREVIEW_SUCCESS, payload: data });
    } catch(err) {
      yield put({ type: GET_BOARD_INFO_PREVIEW_FAIL, err });
    }
  }
}


export const saga = function* () {
  yield spawn(fetchBoardInfoSaga)
  yield spawn(fetchBoardInfoPreviewSaga)
}