import api from '../services/contents-service';
import { createSelector } from 'reselect';
import { Record } from 'immutable';
import { takeEvery } from 'redux-saga/effects';
import { LOCATION_CHANGE } from 'connected-react-router';
import { action, fetching, freeze, suffix, prefix as stringPrefix, or } from '../components/utils/redux';
import { appName } from '../config';

/**
 * Constants
 */
export const moduleName = 'choose-category';
export const prefix = `${appName}/${moduleName}`;

export const CHOOSE_CATEGORY_SET = `${prefix}/SET`;
export const CHOOSE_CATEGORY_RESET = `${prefix}/RESET`;
export const chooseCategory = payload => action(CHOOSE_CATEGORY_SET, payload);
export const resetChooseCategory = () => action(CHOOSE_CATEGORY_RESET);

export const fetchPrefix = `${prefix}/fetch`;
export const CHOOSE_CATEGORY_FETCH_REQUEST = `${fetchPrefix}/REQUEST`;
export const CHOOSE_CATEGORY_FETCH_RECEIVE = `${fetchPrefix}/RECEIVE`;
export const requestFetchChooseCategory = path => action(CHOOSE_CATEGORY_FETCH_REQUEST, path);

export function selectChooseCategory(state) {
  return state[moduleName];
}

const ChooseCategoryRecord = Record({
  queryPath: '',
  path: '',
  title: '',
  categories: [],
  boards: [],
  breadcrumbs: []
});

export const fetchChooseCategory = fetching(
  path => [api.getContent, path],
  CHOOSE_CATEGORY_FETCH_RECEIVE
);

function concatArrays(array, name) {
  return [].concat(...array.map(item => item[name] || []));
}

const extractBreadcrumbs = ({ path, title }) => ({ path, title });
const isResetPathString = or(stringPrefix('/admin/board/merge'), suffix('/move'));

export default function reducer(state = ChooseCategoryRecord(), { type, payload }) {
  switch (type) {
    case CHOOSE_CATEGORY_SET: {
      const newState = state.set('queryPath', payload);
      if (!payload) {
        return newState.set('breadcrumbs', []);
      }
      return newState;
    }
    case CHOOSE_CATEGORY_RESET:
      return ChooseCategoryRecord();
    case LOCATION_CHANGE: {
      // Dirty workaround
      if (state.get('queryPath') && isResetPathString(payload.location.pathname)) {
        return state.set('queryPath', '');
      }
      return state;
    }
    case CHOOSE_CATEGORY_FETCH_RECEIVE: {
      const boards = payload.boards ? payload.boards.items.map(extractBreadcrumbs) : [];
      let categories;
      if (Array.isArray(payload.categories) && payload.categories.length > 0) {
        if (Array.isArray(payload.categories[0].links)) {
          const notEmptyCategories = payload.categories
            .filter(category => Array.isArray(category.items) && category.items.length > 0);
          categories = concatArrays(notEmptyCategories, 'links');
        } else if (Array.isArray(payload.categories[0].items)) {
          categories = concatArrays(payload.categories, 'items').map(extractBreadcrumbs);
        } else {
          categories = payload.categories;
        }
      } else {
        categories = [];
      }
      return state
        .set('breadcrumbs', payload.breadcrumbs ? payload.breadcrumbs.links : [])
        .set('path', payload.path)
        .set('title', payload.title)
        .set('boards', freeze(boards))
        .set('categories', freeze(categories));
    }
    default:
      return state;
  }
}

export function* saga() {
  yield takeEvery(CHOOSE_CATEGORY_FETCH_REQUEST, fetchChooseCategory);
}

export const selectChooseCategoryCategories = createSelector(
  selectChooseCategory,
  state => state.get('categories')
);

export const selectChooseCategoryBreadcrumbs = createSelector(
  selectChooseCategory,
  state => state.get('breadcrumbs')
);

export const selectChooseCategoryBoards = createSelector(
  selectChooseCategory,
  state => state.get('boards')
);

export const selectChooseCategoryQueryPath = createSelector(
  selectChooseCategory,
  state => state.get('queryPath')
);
