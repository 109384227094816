export function TrackEvent() {
    // Debug mock
    const unifiedTrackingInstanceMock = {
        trackUserBehavior: (eventName, eventData) => console.log('[UBE]', eventName, eventData)
    };
    const trackingPayload = {
        clickType: 'request_join_beta',
        clickSubType: null,
        clickLocation: 'banner',
        familyCircleId: null,
        flow: 'community_group',
        schemaVersion: 24
    };
    // To do: Create unifiedTrackingInstance only once globally
    const unifiedTrackingInstance = window.unifiedTrackingFactory ? window.unifiedTrackingFactory({ stackId: 'boards-ui' }) : unifiedTrackingInstanceMock;
    unifiedTrackingInstance.trackUserBehavior('CoreUI_FamilyCircleObjectClicked', trackingPayload);
}