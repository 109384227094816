import { ELLIPSIS, MAX_SEARCH_TEXT_SIZE, SEARCH_MATCH_OFFSET } from './constants';

export function toTextFragment(text, keywords) {
  const lowercased = text.toLocaleLowerCase();
  for(const keyword of keywords) {
    const index = lowercased.indexOf(keyword);
    if (index >= 0) {
      let substring;
      if (index + keyword.length > MAX_SEARCH_TEXT_SIZE && index > SEARCH_MATCH_OFFSET) {
        const offset = index - SEARCH_MATCH_OFFSET;
        substring = text.slice(offset, index + MAX_SEARCH_TEXT_SIZE);
        if (offset + substring.length < text.length) {
          substring = `${substring}${ELLIPSIS}`;
        }
        substring = ELLIPSIS + substring;
      } else {
        if (text.length > MAX_SEARCH_TEXT_SIZE) {
          substring = text.slice(0, index + MAX_SEARCH_TEXT_SIZE) + ELLIPSIS;
        } else {
          substring = text;
        }
      }
      return substring;
    }
  }
  return text.length > MAX_SEARCH_TEXT_SIZE
    ? text.slice(0, MAX_SEARCH_TEXT_SIZE) + ELLIPSIS
    : text;
}

export function toKeywords(string) {
  const s = string.trim();
  if (s) {
    return s
      .toLowerCase()
      .split(/\s+/);
  }
  return [];
}

export function reduceSpaces(string) {
  return string.trim().replace(/\s+/g, ' ');
}
