import { call, put, select, takeEvery } from 'redux-saga/effects';
import { appName } from '../config';
import api from '../services/searches-service';
import { Record } from 'immutable';
import { createSelector } from 'reselect';
import PageLinkModel from '../components/common/old-pagination/model';

export const moduleName = 'advanced-search';
export const prefix = `${appName}/${moduleName}`;

function messageOf(e) {
  return e.response && e.response.data && e.response.data.message ? e.response.data.message : e.message;
}

const action = type => (payload = null) => ({
  type,
  payload
});

export const ADVANCED_SEARCH_REQUEST = `${prefix}/REQUEST`;
export const ADVANCED_SEARCH_SUCCESS = `${prefix}/SUCCESS`;
export const ADVANCED_SEARCH_ERROR = `${prefix}/ERROR`;
export const ADVANCED_SEARCH_RESET_RESULTS = `${prefix}/RESET_RESULTS`;

export const requestAdvancedSearch = (query, path) => action(ADVANCED_SEARCH_REQUEST)({ query, path });
export function receiveAdvancedSearch(items, query, description) {
  return {
    type: ADVANCED_SEARCH_SUCCESS,
    payload: { items, query, description }
  };
}
export const setAdvancedSearchError = action(ADVANCED_SEARCH_ERROR);
export const resetAdvancedSearchResults = action(ADVANCED_SEARCH_RESET_RESULTS);

export const initialState = {
  description: '',
  items: null,
  query: null
};

export function selectState(state) {
  return state[moduleName];
}

export const selectAdvancedSearchQuery = createSelector(
  selectState,
  state => new PageLinkModel(state.get('query') || {})
);

export function* fetchAdvancedSearch({ payload: { query, path = '' } }) {
  try {
    const state = yield select();
    if (!query) {
      query = selectAdvancedSearchQuery(state);
    }
    const {
      data: {
        boards: { items, paging, description }
      }
    } = yield call(api.getAdvancedSearch, query, path);
    query.assignPaging(paging);
    yield put(receiveAdvancedSearch(items, query, description));
  } catch (e) {
    yield put(setAdvancedSearchError(messageOf(e)));
  }
}

export const ReducerRecord = new Record(initialState);

export default function reducer(state = ReducerRecord({}), { type, payload }) {
  switch (type) {
    case ADVANCED_SEARCH_SUCCESS:
      return state
        .set('description', payload.description)
        .set('items', payload.items)
        .set('query', payload.query.toRecord());
    case ADVANCED_SEARCH_RESET_RESULTS:
      return state
        .set('items', null);
    default:
      return state;
  }
}

export function* saga() {
  yield takeEvery(ADVANCED_SEARCH_REQUEST, fetchAdvancedSearch);
}

export const selectAdvancedSearchItems = createSelector(
  selectState,
  state => state.get('items')
);

export const selectAdvancedSearchDescription = createSelector(
  selectState,
  state => state.get('description')
);
