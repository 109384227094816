import { spawn, take, call, put, select, takeEvery } from 'redux-saga/effects';
import { Record, OrderedSet } from 'immutable';
import { createSelector } from 'reselect';
import api from '../services/favorites-service';
import { appName } from '../config';
import { errorHandlerSaga } from './error'
/**
 * Constants
 */

export const moduleName = 'favorites';
export const prefix = `${appName}/${moduleName}`;

export const GET_ALL_FAVORITES_REQUEST = `${prefix}/GET_ALL_FAVORITES_REQUEST`;
export const GET_ALL_FAVORITES_SUCCESS = `${prefix}/GET_ALL_FAVORITES_SUCCESS`;
export const GET_ALL_FAVORITES_FAIL = `${prefix}/GET_ALL_FAVORITES_FAIL`;

export const DELETE_FAVORITE_REQUEST = `${prefix}/DELETE_FAVORITE_REQUEST`;
export const DELETE_FAVORITE_SUCCESS = `${prefix}/DELETE_FAVORITE_SUCCESS`;
export const DELETE_FAVORITE_FAIL = `${prefix}/DELETE_FAVORITE_FAIL`;

export const GET_FAVORITES_BY_TYPE_REQUEST = `${prefix}/GET_FAVORITES_BY_TYPE_REQUEST`;
export const GET_FAVORITES_BY_TYPE_SUCCESS = `${prefix}/GET_FAVORITES_BY_TYPE_SUCCESS`;
export const GET_FAVORITES_BY_TYPE_FAIL = `${prefix}/GET_FAVORITES_BY_TYPE_FAIL`;

export const RESET_FAVORITES_STATE = `${prefix}/RESET_FAVORITES_STATE`;

/**
 * Reducer
 */


export const TotalRecord = Record({
  categories: null,
  boards: null,
  authors: null,
  threads: null
})

export const PagingRecord = Record({
  currentPage: null,
  totalPages: null,
  itemsPerPage: null
})

export const EntitiesRecord = Record({
  categories: OrderedSet([]),
  boards: OrderedSet([]),
  authors: OrderedSet([]),
  threads: OrderedSet([]),
  totalCount: TotalRecord({})
})

export const EntriesByType = Record({
  favorites: OrderedSet([]),
  paging: PagingRecord({})
})

export const ReducerRecord = Record({
  loading: false,
  deleting: false,
  entities: {},
  entriesByType: null,
  err: null
})



export default function reducer(state = ReducerRecord(), { type, payload, err }) {
  switch(type) {
    // Case for get all

    case GET_ALL_FAVORITES_REQUEST:
      return state.set('loading', true);

    case GET_ALL_FAVORITES_SUCCESS:
        return state
                  .set('loading', false)
                  .set('entities', EntitiesRecord(payload));

    case GET_ALL_FAVORITES_FAIL:
        return state
                  .set('loading', false)
                  .set('entities', EntitiesRecord({}))
                  .set('err', err);


    // Case for delete favorite
    case DELETE_FAVORITE_REQUEST:
        return state.set('deleting', true)

    case DELETE_FAVORITE_SUCCESS:
        return state
          .set('deleting', false)
          .set('entities', EntitiesRecord(payload))

    case DELETE_FAVORITE_FAIL:
        return state
                .set('err', err)
                .set('deleting', false)


    case GET_FAVORITES_BY_TYPE_REQUEST:
      return state.set('loading',  true)

    case GET_FAVORITES_BY_TYPE_SUCCESS:
        return state
          .set('loading',  false)
          .set('entriesByType', EntriesByType(payload))

    case GET_FAVORITES_BY_TYPE_FAIL:
        return state
          .set('loading', false)
          .set('entriesByType', null)
          .set('err', err)

    case RESET_FAVORITES_STATE:
      return state.clear()

    default:
      return state;
  }
}


/**
 * Actions
 */

export const fetchAllFavorites = () => ({ type: GET_ALL_FAVORITES_REQUEST });
export const fetchByTypeFavorites = (type, itemsPerPage, page) => ({
  type: GET_FAVORITES_BY_TYPE_REQUEST,
  payload: { type, itemsPerPage, page }
})
export const deleteFavorites = ({ type, item, listByType }) => ({ type: DELETE_FAVORITE_REQUEST, payload: { type, item, listByType } })
export const clearFavoritesState = _ => ({ type: RESET_FAVORITES_STATE })

/**
 * Selectors
 */

export const stateSelector = state => state[moduleName];
export const loadingSelector = createSelector(
  stateSelector, state => state.loading
)

export const deletingSelector = createSelector(
  stateSelector, state => state.deleting
);
export const entitiesSelector = createSelector(
  stateSelector, state => state.entities
)

export const errorSelector = createSelector(
  stateSelector, state => state.err
)

// Selectors by type
export const entriesByTypeSelector = createSelector(
  stateSelector, state => state.entriesByType || {}
)

export const favoritesByTypeSelector = createSelector(
  entriesByTypeSelector, entriesByType => entriesByType.favorites
)

export const favoritesPagingSelector = createSelector(
  entriesByTypeSelector, entriesByType => entriesByType.paging
)



/**
 * Sagas
 */

export const fetchAllFavoritesSaga = function* () {
  while(true) {
    yield take(GET_ALL_FAVORITES_REQUEST);
    try {
      const { data } = yield call(api.getAllFavorites);
      yield put({ type: GET_ALL_FAVORITES_SUCCESS, payload: data })
    } catch(err) {
      yield call(errorHandlerSaga, GET_ALL_FAVORITES_FAIL, err)
    }
  }
}


export const deleteFavoriteSaga = function* ({payload: { type, item, listByType }}) {
  try {
    switch(type) {
      case 'authors':
        yield call(api.deleteFavoritesAuthor, item.userId);
        break;
      case 'threads':
        yield call(api.deleteFavoritesThread, item.path, item.id);
        break;
      default:
        yield call(api.removeFromFavorites, item.path)
    }

    if (listByType) {
      const { location: { pathname, query: { itemsPerPage, page } } } = yield select(state => state.router);
      // set properties as type favorites and query parameters of paging if exists
      const queryParams = {type: pathname.replace('/favorites/', ''), itemsPerPage, page }
      yield put({ type: GET_FAVORITES_BY_TYPE_REQUEST, payload: { ...queryParams }});
      yield put({ type: DELETE_FAVORITE_SUCCESS });
      return;
    }

    const { data } = yield call(api.getAllFavorites);
    yield put({ type: DELETE_FAVORITE_SUCCESS, payload: data});

  } catch(err) {
    yield put({ type: DELETE_FAVORITE_FAIL, err });
  }
}


export const fetchByTypeFavoritesSaga = function* () {
  while(true) {
    const { payload: { type, itemsPerPage, page } } = yield take(GET_FAVORITES_BY_TYPE_REQUEST);

    try {
      const { data } = yield call(api.getByTypeFavorites, type, itemsPerPage, page);
      yield put({ type: GET_FAVORITES_BY_TYPE_SUCCESS, payload: data })
    } catch(err) {
      yield put({ type: GET_FAVORITES_BY_TYPE_FAIL, err })
    }
  }

}


export const saga = function* () {
  yield spawn(fetchAllFavoritesSaga);
  yield takeEvery(DELETE_FAVORITE_REQUEST, deleteFavoriteSaga);
  yield spawn(fetchByTypeFavoritesSaga);
}
