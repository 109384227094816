import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { FormattedMessage } from 'react-intl';

// data for listening
import {
  // selector
  addedAuthorSuccessSelector, addedAuthorFailSelector,
  removedAuthorSuccessSelector, removedAuthorFailSelector,
  // action
  resetAuthorFavoriteState
} from '../../ducks/author-favorite';
import m from './message';


export default function AuthorNotice() {
  const dispatch = useDispatch();
  // data from redux
  const addSuccess = useSelector(addedAuthorSuccessSelector);
  const addFail = useSelector(addedAuthorFailSelector);
  const removeSuccess = useSelector(removedAuthorSuccessSelector);
  const removeFail = useSelector(removedAuthorFailSelector);

  const active = [addSuccess, addFail, removeSuccess, removeFail].find(state => state);
  if (!active) return null;


  const isSuccess = addSuccess || removeSuccess;
  const isError = addFail || removeFail;
   
  
  function getTitleMessage() {
    if (isSuccess) {
      return addSuccess 
        ? <FormattedMessage {...m.success_add} values={{ name: 'Author' }} />
        : <FormattedMessage {...m.success_remove} values={{ name: 'Author' }} />
    } 

    if (isError) {
      return addFail
        ? <FormattedMessage {...m.fail_add} values={{ name: 'Author' }} />
        : <FormattedMessage {...m.fail_remove} values={{ name: 'Author' }} />
    }

    return null;
  }

  return (
    <div className={`notice-favorite alert${ isError ? ' notice-favorite--error' : ''}`}>
      { getTitleMessage() }
      <button className='notice-favorite__btn-hide' onClick={() => dispatch(resetAuthorFavoriteState())}/>
    </div>
  )
}