import React, { useEffect } from 'react';
import { useSelector, useDispatch }  from 'react-redux';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';

import m from './messages';
import { getLastAdminNews, newsLoadingSelector, newsDataSelector, newsErrorSelector, isActiveToolbar } from '../../../ducks/admin-panel';
import { userEntitiesSelector, errorSelector, asAdminAuthSelector } from '../../../ducks/user';
import { showPopup } from '../../../ducks/popup';
import ButtonExit from './button-exit';
import AdminNodes from './admin-nodes';
import LastNews from './last-news';
import Menu, { MenuItem } from '../../menu';

import { getSiteSettingsClientSide } from '../../utils';
import Progress from '../../common/progress';
import { ROLE_TYPES } from '../../../constants';



export default function AdminToolbar(_) {
  //data from redux
  const { pathname } = useSelector(state => state.router.location);
  const path = pathname.split('/')[1];
  const id = pathname.split('/')[2];

  // user data
  const { adminNodes, role } = useSelector(userEntitiesSelector);
  const errFetchUserData = useSelector(errorSelector);
  const isSuperAdminAndAdmin = useSelector(asAdminAuthSelector);
  const activeToolbar = useSelector(isActiveToolbar);

  // news data
  const loadingNews = useSelector(newsLoadingSelector);
  const newsData = useSelector(newsDataSelector);
  const newsError = useSelector(newsErrorSelector);

  // redux dispatch
  const dispatch = useDispatch();

  const emptyNodeList = !adminNodes || adminNodes.length === 0;

  useEffect(() => {
    if (activeToolbar && isSuperAdminAndAdmin) dispatch(getLastAdminNews());
  }, [activeToolbar, path, id])

  if (!activeToolbar || !isSuperAdminAndAdmin) return null;
  if (errFetchUserData) return <div>Oops, something happen wrong</div>;

  const siteSettingsClientSide = getSiteSettingsClientSide();
  const partnerName = (siteSettingsClientSide.partnerId || null) === 3 ? 'RootsWeb' : 'Ancestry';

  return (
    <div className="admin__board">
      <header className="admin-board__header">
        <strong>
          <span className="partner-name">{partnerName}</span>
          <span className="title"><FormattedMessage {...m.title} /></span>
        </strong>
        <ButtonExit />
      </header>
      <Menu>
        <MenuItem to="/alerts"><FormattedMessage {...m.myAlerts} /></MenuItem>
        <MenuItem to="/"><FormattedMessage {...m.listBoards} /></MenuItem>
        <MenuItem to="/admin/manage/objections">
         <FormattedMessage {...m.reviewObjections} />
        </MenuItem>
       { role === ROLE_TYPES.superAdmin &&
        (<>
          <MenuItem to="/admin/manage/rights"><FormattedMessage {...m.reviewRights} /></MenuItem>
          <MenuItem to="/admin/manage/boards"><FormattedMessage {...m.reviewBoards} /></MenuItem>
          <MenuItem to="/admin/manage/spam"><FormattedMessage {...m.manageSpam} /></MenuItem>
        </>)
       }
       <MenuItem classModificator='link' onClick={() => dispatch(showPopup('ADMIN_INFO'))}>
        <FormattedMessage {...m.adminInfo} />
      </MenuItem>
      </Menu>
      { loadingNews ?  <Progress small /> : <LastNews newsError={newsError} newsData={newsData} /> }
      { adminNodes && !emptyNodeList && <AdminNodes adminNodes={adminNodes} /> }
    </div>
  );
}



AdminToolbar.propTypes = {
  adminNodes: PropTypes.oneOf([PropTypes.array, PropTypes.object ]), // object === null
  email: PropTypes.string,
  role: PropTypes.string,
  userId: PropTypes.string,
  username: PropTypes.string,
  errFetchUserData: PropTypes.object, // err object or null
}
