// This file can be import in client side only

const defaults = {
  isClientSide: false,
  partnerId: 0,
  omniture: 'ancestry us'
};
const element = typeof document === 'object' && document
  ? document.getElementById('boards-ui-config')
  : null;
const config = element ? { ...defaults, ...JSON.parse(element.innerHTML) } : defaults;
config.partnerName = config.partnerId === 3 ? 'RootsWeb' : 'Ancestry';

export const appName = config.appName;
export const basename = config.basename;
export const serviceOrigin = `${basename}/api`;
export const BoardsPathHeader = 'Boards-Path';
export const bannerDisplay = config.bannerDisplay;
export default Object.freeze(config);
