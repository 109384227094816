import React from 'react';
import classNames from 'classnames';
import Breadcrumbs from '../old_breadcrumbs';
import { PageType, WidgetDefaults } from '../utils/prop-types';
import { spaceless } from '../../utils/strings';

export default function PageView(
  {
    breadcrumbs,
    children,
    classes,
    description,
    title
  }) {
  const hasDefaultBreadcrumbs = !breadcrumbs;
  if (hasDefaultBreadcrumbs) {
    breadcrumbs = [
      { path: '' },
      { title }
    ];
  }

  const className = classNames(spaceless(`page ${classes}`), {
    'has-title': title,
    'has-description': description,
    'has-breadcrumbs': breadcrumbs && breadcrumbs.length > 0,
    'has-empty-breadcrumbs': breadcrumbs && breadcrumbs.length === 0,
    'has-default-breadcrumbs': hasDefaultBreadcrumbs
  });
  return (
    <div className={className}>
      <Breadcrumbs items={breadcrumbs} />
      <header className="pageHeader">
        <h1 className="pageTitle">{title}</h1>
        {description ? <p className="pageIntro">{description}</p> : null}
      </header>
      <div className="content">{children}</div>
    </div>
  );
}

PageView.propTypes = PageType;
PageView.defaultProps = WidgetDefaults;