import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';

import m from './messages';


export default function Breadcrumbs({ links, titlePage, aloneLink }) {
  let crumbItems = [...links]; // copy
  const homeCrumb = {
    title: <FormattedMessage {...m.messageBoards} />,
    path: '' // slash will be added by default in Link "to" prop
  }

  if (!aloneLink) crumbItems = [homeCrumb, ...crumbItems]; // add first crumb home link

  if (!links.length) crumbItems = [...crumbItems, { title: titlePage }]; // add last crumb current page
  const lastIndex = crumbItems.length -1;
  

  const renderCrumbLink = (crumb, index) => {
    if (aloneLink) return <Link className='breadcrumb__link' to={`/${crumb.path}`}>{ crumb.title }</Link>
    
    return (index !== lastIndex)
      ? <Link className='breadcrumb__link' to={`/${crumb.path}`}>{ crumb.title }</Link>
      :  <span className='breadcrumb__link current'>{ crumb.title }</span>
  }

  const renderItems = () => crumbItems.map((crumb, index)=> (
    <li className='breadcrumb__item' key={crumb.path || index}>
      { renderCrumbLink(crumb, index) }
    </li>
  ))

  return (
    <nav aria-label='breadcrumb' className='breadcrumbs'>
      <ul className='breadcrumbs__links'>
        { renderItems() }
      </ul>
    </nav>
  )
}



Breadcrumbs.defaultProps = {
  links: [],
  titlePage: ''
}

Breadcrumbs.propType= {
  links: PropTypes.array,
  titlePage: PropTypes.string,
  aloneLink: PropTypes.bool,
}
