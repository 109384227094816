import React, { useEffect, useState } from 'react';
import classNames from 'classnames';
import { bool, string } from 'prop-types';
import PageModel from './model';
import PageLink from './link';
import { prettyNumber } from '../../../utils/strings';

const INPUT_PADDING = 16;

export default function PageNumber({ editable, classes, ...props }) {
  const { model } = PageModel.initProps(props);
  const className = classNames(`page-number ${classes}`.trim(), {
    editable,
    readonly: !editable,
    single: model.isSingle
  })
    .replace(/\s+/g, ' ');
  const numberRef = React.createRef();
  const totalPagesRef = React.createRef();

  const [number, setNumber] = useState(1);

  function calculateWidth() {
    if (numberRef.current && totalPagesRef.current) {
      let width = Math.max(6, totalPagesRef.current.scrollWidth);
      if (width > 0) {
        width = Math.ceil(width) + INPUT_PADDING;
        numberRef.current.style.width = `${width}px`;
      }
    }
  }

  useEffect(() => {
    setNumber(model.currentPage);
    if (editable) {
      setTimeout(calculateWidth, 50);
    }
  }, [model.totalPages, model.itemsPerPage, model.currentPage]);

  function changePage(e) {
    if (e.key === 'Enter' || e.keyCode === 13) {
      if (number !== model.currentPage && number > 0 && number <= model.totalPages) {
        model.change(model.createSibling(number));
      }
      calculateWidth();
    }
  }

  function changePageNumber(e) {
    const n = +e.target.value;
    if (isFinite(n)) {
      setNumber(n);
    }
  }

  let pageNumber;
  if (editable) {
    pageNumber = (
      <input
        value={number}
        type={number}
        min={1}
        max={model.totalPages}
        onChange={changePageNumber}
        onKeyPress={changePage}
        disabled={model.busy}
        className="current-page-number"
        ref={numberRef}
      />
    );
  } else {
    pageNumber = <span className="current-page-number">{prettyNumber(model.currentPage)}</span>;
  }

  return (
    <div className={className}>
      <PageLink
        model={model.createSibling(model.currentPage - 1)}
        classes="page-previous ancBtn silver icon iconArrowLeft"
      />
      <span className="current-page">
        {pageNumber}
        <span className="current-page-of">of</span>
        <span className="current-page-total" ref={totalPagesRef}>
          {prettyNumber(model.totalPages)}
        </span>
      </span>
      <PageLink
        model={model.createSibling(model.currentPage + 1)}
        classes="page-next ancBtn silver icon iconArrowRight"
      />
    </div>
  );
}

PageNumber.propTypes = {
  editable: bool,
  classes: string
};

PageNumber.defaultProps = {
  editable: false,
  classes: ''
};
