import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';
import m from './messages';

import {
  // actions
  getThreadById,
  changeThreadLayout,
  clearThreadData,
  // selectors
  loadingSelector,
  entitiesSelector,
  errorSelector,
  layoutSelector
} from '../../ducks/thread-page';
import { isSuperAdminRoleSelector } from '../../ducks/user';

// hooks
import useQuery from '../../hooks/use-query-params';
// templates
import PageLayout from '../../components/hoc/page-layout';
import ThreadView from '../../components/thread';
import AdminTool, { ToolsTypes } from '../../components/admin/tools';
import KeywordsSearch from '../../components/searches/advanced/keywords-search';
import SearchBoards from '../../components/searches/boards';
// config
import config from '../../config';
import { trackPageView } from '../../utils/tealium';
import { Redirect } from 'react-router';

export default function ThreadPage() {
  const [called, setcalled] = useState(false);
  const dispatch = useDispatch();

  // routing params
  const { path, threadId } = useParams();
  let { itemsPerPage, page, viewType, sortDirection } = useQuery();

  // redux data
  const loading = useSelector(loadingSelector);
  const entities = useSelector(entitiesSelector);
  const error = useSelector(errorSelector);

  // redux user data
  const isSuperAdmin = useSelector(isSuperAdminRoleSelector);

  const [redirect, setRedirect] = useState(false);
  let redirectTimeout;

  const viewTypeStorage = useSelector(layoutSelector);
  viewType = viewType || viewTypeStorage; // viewType get from url or from local storage if not exists in url

  useEffect(() => {
    // all properties(dependencies) in priority are taken from url (query)
    // or by default set in action creator getThreadById
    dispatch(getThreadById(path, threadId, itemsPerPage, page, viewType, sortDirection));
    setcalled(false);
  }, [path, threadId, viewType, itemsPerPage, page, sortDirection]);

  // get current view property from url (new choice) or if not exists form localstorage (last chosen)
  useEffect(() => {
    setcalled(false);
    if (viewType && viewType !== viewTypeStorage) {
      dispatch(changeThreadLayout(viewType));
    }
  }, [viewType]);

  useEffect(() => () => {
    setcalled(false);
    clearTimeout(redirectTimeout);
    dispatch(clearThreadData());
  }, []);


  // tealium tracking
  /*useEffect(() => {
    if(entities.breadcrumbs && 
      entities.breadcrumbs.links && 
      entities.breadcrumbs.links.some((link)=>link.path === path)){
      trackPageView(`board : ${path}`)
    }
  });
  */
  /*u
  useEffect(() => {
    return()=>{
      trackPageView(`board : ${path}`)
    }  
  });*/
  /*
  useEffect(() => {
    setTimeout(() => trackPageView(`board : ${path}`),10000);    
  });
*/

 
  const sectionTitle = <FormattedMessage {...m.title} />;
  const moved = entities && entities.thread && entities.thread.moved;
  const link = moved && `/${entities.thread.moved.path}/${entities.thread.moved.threadId}`;
  const getError = () => {
    if (error) return error;

    const deletedThread = entities && entities.thread && entities.thread.deleted;

    if (moved) {
      redirectTimeout = setTimeout(() => setRedirect(true), 10000);
      return ({ link: link, linkSubject: entities.thread.subject, movedThread: true });
    }
    return deletedThread ? ({ messageKey: 'deletedThread' }) : null;
  };
  //tealium tracking
  const body= entities && entities.thread && entities.thread.body;
  const currentPage= entities && entities.repliesPaging && entities.repliesPaging.currentPage;  
  const dartContentSide=document.getElementById('dartContentSide');
  const dartContentTop=document.getElementById('dartContentSide');
  useEffect(() => {
    try {
      if(entities.breadcrumbs  && !called){
      if(entities.breadcrumbs.links){
        //alert('inside thread new use effect');
        trackPageView(`board : ${path}`);
        setcalled(true);
      }
      }
    } catch (err) {
    console.log(err)
    }
   //},[currentPage,body]);
  },[dartContentSide,dartContentTop,currentPage,body]);
  // tealium tracking


  if (redirect && link) return <Redirect to={link} />;
  return (
    <PageLayout
      title={sectionTitle}
      loading={loading}
      error={getError()}
      classModificator={`thread${(isSuperAdmin || !config.institution) ? ' admin-tool' : ''}`}
      breadcrumbs={entities && entities.breadcrumbs}
      advertOpt={{
        show: true,
        position: 'top'
      }}
    >
      { entities && <KeywordsSearch {...entities} /> }
      { entities && <ThreadView {...entities} /> }
      { entities && <SearchBoards {...entities} /> }
      <AdminTool type={ToolsTypes.thread} />
    </PageLayout>
  );

  
}
