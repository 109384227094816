import { appName } from '../config';
import { Record } from 'immutable';
import { takeEvery, } from 'redux-saga/effects';
import api from '../services/suggestions-service';
import { createSelector } from 'reselect';
import {
  action,
  assignQueryPaging,
  fetching,
  freeze,
  modification,
  queryFetching
} from '../components/utils/redux';
import QueryModel from './common/query-model';

export const moduleName = 'suggestion';
export const prefix = `${appName}/${moduleName}`;
export const fetchSinglePrefix = `${prefix}/single/fetch`;
export const SUGGESTION_SINGLE_REQUEST = `${fetchSinglePrefix}/REQUEST`;
export const SUGGESTION_SINGLE_RECEIVE = `${fetchSinglePrefix}/RECEIVE`;
export const fetchPrefix = `${prefix}/fetch`;
export const SUGGESTION_FETCH_REQUEST = `${fetchPrefix}/REQUEST`;
export const SUGGESTION_FETCH_RECEIVE = `${fetchPrefix}/RECEIVE`;
export const createPrefix = `${prefix}/create`;
export const SUGGESTION_CREATE_REQUEST = `${createPrefix}/REQUEST`;
export const SUGGESTION_CREATE_SUCCESS = `${createPrefix}/SUCCESS`;
export const judgePrefix = `${prefix}/judge`;
export const SUGGESTION_JUDGE_REQUEST = `${judgePrefix}/REQUEST`;
export const SUGGESTION_JUDGE_SUCCESS = `${judgePrefix}/SUCCESS`;
export const SUGGESTION_MODAL = `${prefix}/MODAL`;
export const SUGGESTION_RESET = `${prefix}/RESET`;

export const JudgeOperations = {
  Accept: 'ACCEPT',
  Lock: 'LOCK',
  Reject: 'REJECT',
  Remove: 'REMOVE',
  Unlock: 'UNLOCK'
};
export const DataJudgeOperations = [JudgeOperations.Accept];
export const SuggestionModalHidden = '';
export const administerMessage = '*** I would like to administer this board. *** ';

export const setSuggestionModalId = id => action(SUGGESTION_MODAL, id);
export const requestSingleSuggestion = (id, path) => action(SUGGESTION_SINGLE_REQUEST, { id, path });
export const requestSuggestionFetch = query => action(SUGGESTION_FETCH_REQUEST, query);
export const requestCreateSuggestion = (boardName, comment, administer, next) => action(SUGGESTION_CREATE_REQUEST, {
  boardName,
  comment: ((administer ? administerMessage : '') + comment).trim()
}, next);

export const requestJudgeSuggestion = (payload, next) => action(SUGGESTION_JUDGE_REQUEST, payload, next);

export const selectState = state => state[moduleName];

export const selectSuggestionsItems = createSelector(
  selectState,
  state => state.get('items')
);

export const selectSingleSuggestion = createSelector(
  selectState,
  state => state.get('single')
);

export const selectModalId = createSelector(
  selectState,
  state => state.get('modalId')
);

export const selectSuggestionsQuery = createSelector(
  selectState,
  state => state.get('query')
);

export const selectSuggestionsItemsWithNumber = createSelector(
  selectSuggestionsItems,
  selectSuggestionsQuery,
  (items, query) => !items ? [] : items.map((item, i) => ({
    ...item,
    number: (query ? (query.currentPage - 1) * query.itemsPerPage : 0) + i + 1
  }))
);

export const fetchSingleSuggestion = fetching(
  ({ id, path }) => [api.getSingleSuggestion, id, path],
  SUGGESTION_SINGLE_RECEIVE
);

export const createSuggestion = modification(
  payload => [api.createBoardSuggestion, payload],
  SUGGESTION_CREATE_SUCCESS
);

export const fetchSuggestions = queryFetching(
  state => new QueryModel(selectSuggestionsQuery(state)),
  query => [api.getSuggestions, query],
  SUGGESTION_FETCH_RECEIVE,
  assignQueryPaging('suggestions')
);

export const judgeSuggestion = modification(
  ({ id, path, operation, data }) => {
    if (operation === JudgeOperations.Lock || operation === JudgeOperations.Unlock) {
      return [api.judgeSuggestions, id, path, operation, data];
    }
    if (data.type === 'objections') {
      return operation === JudgeOperations.Remove
        ? [api.deleteThreadBySuggestionId, id, path]
        : [api.judgeSuggestions, id, path, operation, data];
    }
    if (data.type === 'rights') {
      return [api.judgeSuggestions, id, path, operation, data];
    }
    if (data.type === 'boards') {
      return [api.judgeSuggestions, id, path, operation, data];
    }
    alert('Unknown review page type');
  },
  SUGGESTION_JUDGE_SUCCESS
);

const SuggestionRecord = Record({
  authorId: '',
  authorName: '',
  boardPath: '',
  classification: '',
  comment: '',
  creatorId: '',
  creatorName: '',
  email: '',
  id: -1,
  isLocked: false,
  lockerName: '',
  message: '',
  name: '',
  path: '',
  posted: -1,
  requestDate: '',
  subject: '',
  type: ''
});

const SuggestionState = Record({
  modalId: SuggestionModalHidden, // path-id of suggestion in modal
  single: null,
  items: null,
  query: null
});

export default function reducer(state = SuggestionState({ query: QueryModel.createRecord() }), { type, payload }) {
  switch (type) {
    case SUGGESTION_FETCH_RECEIVE: {
      payload.items.forEach(item => {
        item.uid = `${item.path}-${item.id}`;
      });
      return state
        .set('items', freeze(payload.items))
        .set('query', payload.query.toRecord());
    }
    case SUGGESTION_RESET:
      return state
        .set('modalId', SuggestionModalHidden)
        .set('items', null)
        .set('query', null);
    case SUGGESTION_SINGLE_RECEIVE:
      return state.set('single', SuggestionRecord(payload));
    case SUGGESTION_JUDGE_SUCCESS:
      return state.set('modalId', SuggestionModalHidden);
    case SUGGESTION_MODAL:
      return state.set('modalId', payload);
    default:
      return state;
  }
}

export function* saga() {
  yield takeEvery(SUGGESTION_CREATE_REQUEST, createSuggestion);
  yield takeEvery(SUGGESTION_FETCH_REQUEST, fetchSuggestions);
  yield takeEvery(SUGGESTION_JUDGE_REQUEST, judgeSuggestion);
  yield takeEvery(SUGGESTION_SINGLE_REQUEST, fetchSingleSuggestion);
}
