import { defineMessages } from 'react-intl';

export default defineMessages({
  category: 'Category',
  exists: 'already exists',
  newPath: 'New Parent Directory',
  notCategory: 'is not category',
  required: 'is required',
  success: 'Board moved',
  title: 'Move Board'
});
