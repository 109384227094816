import React from 'react';
import { connect } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import m from './messages';
import ManageList from '..';
import Objection from './objection';
import { selectSuggestionsItemsWithNumber } from '../../../../ducks/suggestion';

export function ManageObjections({ items }) {
  let objections;
  if (items || Object.keys(items[0]).length) {
    objections = items.map(item => <li key={item.uid}><Objection {...item} /></li>);
  }
  return (
    <ManageList title={<FormattedMessage {...m.title} />} allowModerator>
      {objections}
    </ManageList>
  );
}

export function mapStateToProps(state) {
  return {
    items: selectSuggestionsItemsWithNumber(state)
  };
}

export function mapDispatchToProps() {
  return {
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(ManageObjections);
