import React from 'react';
import { useSelector } from 'react-redux';
import { FormattedMessage } from 'react-intl';

import { nodeNameSelector } from '../../../../ducks/search-adv-result';
import { entitiesSelector } from '../../../../ducks/node-path';
import m from '../messages';

export default function RadioFieldLocation({ locPathValue, checkedValue, name = 'path', radioBtnValueAll = 'All', handleChange, advFormPage }) {
  // title for search result page
  const nodeNameTitle = useSelector(nodeNameSelector) || '';
  // title for advance form page
  const nodePathEntities = useSelector(entitiesSelector)
  if (!nodeNameTitle && !nodePathEntities) return null;

  const renderLabelValue = (itemValue) => {
    if (itemValue === radioBtnValueAll) return <FormattedMessage {...m.allBoards} />;
    if (!advFormPage) return <FormattedMessage {...m.chooseCategorySearch} values={{ name: nodeNameTitle }} />;

    if (nodePathEntities) return (
      <>
        <span className='r-location__text-name'>{nodePathEntities.name}</span>
        <span className='r-location__text-type'>{nodePathEntities.type}</span>
      </>
    )

    return '';
  }
  return (
    <div className='r-location'>
      {[radioBtnValueAll, locPathValue].map(itemValue => (
        <div className='r-location__item' key={itemValue}>
          <label className='r-location__label'>
            <input 
              type='radio'
              className='r-location__radio'
              checked={checkedValue === itemValue}
              onChange={() => {
                handleChange({ target: { name, value: itemValue } })}
              }
            />
          <span className='r-location__btn' />
          <span className='r-location__text'>
            { renderLabelValue(itemValue) }
          </span>
        </label>
      </div>
      ))}
    </div>
  )

}