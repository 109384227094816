import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';

import m from './messages';
import url from '../../url-service';


export default function Attachments({ attachments }) {
  if (!Array.isArray(attachments) || !attachments.length) return null;

  const renderImages = () => attachments.map((imageItem) => {
    const imagePath = url.getAttachmentLinkFromId(imageItem.id);
    const keyID = imageItem.id + imageItem.name;
    return (
      <a className='attachments-thread__item' key={keyID} href={imagePath} target='_blank'>
        <img src={imagePath} alt='' />
      </a>
    )
  })

  return (
    <div className='attachments-thread'>
      <h4 className='attachments-thread__title'>
        <FormattedMessage {...m.attachments} />:
      </h4>
      <div className='attachments-thread__list'>
        { renderImages() }
      </div>
    </div>
  )
}


Attachments.propTypes = {
  attachments: PropTypes.array
}