import { call, put, spawn, take, takeEvery, select } from 'redux-saga/effects';
import { Record } from 'immutable';
import { createSelector } from 'reselect';
import { push } from 'connected-react-router'

import apiNodes from '../services/nodes-service';
import apiThread from '../services/threads-service';
import apiMedia from '../services/medias-service';
import url from '../url-service';

import { appName } from '../config';
import { newAddedFilesSelector, RESET_STATE as RESET_STATE_FILES } from './attachments';
import { Http } from '../constants';


/**
 * Constants
 */

export const moduleName= 'thread-create';
export const prefix = `${appName}/${moduleName}`

export const CHECK_EXISTS_PATH_REQUEST = `${prefix}/CHECK_EXISTS_PATH_REQUEST`;
export const CHECK_EXISTS_PATH_SUCCESS = `${prefix}/CHECK_EXISTS_PATH_SUCCESS`;
export const CHECK_EXISTS_PATH_FAIL = `${prefix}/CHECK_EXISTS_PATH_FAIL`;


export const CREATE_THREAD_REQUEST = `${prefix}/CREATE_THREAD_REQUEST`;
export const CREATE_THREAD_SUCCESS = `${prefix}/CREATE_THREAD_SUCCESS`;
export const CREATE_THREAD_FAIL = `${prefix}/CREATE_THREAD_FAIL`;



export const CheckPathRecord = Record({
  loading: true,
  locName: '',
  err: false
})


export const ReducerRecord = Record({
  checkPath: CheckPathRecord(),
  loading: false,
  err: false
})

/**
 * Reducer
 */




export default function reducer(state = ReducerRecord(), { type, payload, err }) {
  switch(type) {
    case CHECK_EXISTS_PATH_SUCCESS:
      return state
              .setIn(['checkPath', 'loading'], false)
              .setIn(['checkPath', 'locName'], payload)
              .setIn(['checkPath', 'err'], false);

    case CHECK_EXISTS_PATH_FAIL:
        return state
                .setIn(['checkPath', 'loading'], false)
                .setIn(['checkPath', 'locName'], '')
                .setIn(['checkPath', 'err'], err);


    case CREATE_THREAD_REQUEST:
      return state.set('loading', true)

    case CREATE_THREAD_SUCCESS: // success case redirect to new thread post, this state need cleen
        return state.clear()

    case CREATE_THREAD_FAIL:
          return state
                  .set('loading', false)
                  .set('err', err)

    default:
      return state;
  }

}


/**
* Actions
*/

export const checkExistsPath = (path) => ({ type: CHECK_EXISTS_PATH_REQUEST, payload: path });

export const createThread = (path, body) => ({ type: CREATE_THREAD_REQUEST, payload: { path, body } })




/**
 * Selectors
 */

export const stateSelector = state => state[moduleName];

export const loadingSubmitSelector = createSelector(
  stateSelector, state => state.loading
)
export const errSubmitSelector = createSelector(
  stateSelector, state => state.loading
)



// === exists path
export const checkPathSelector = createSelector(
  stateSelector, state => state.checkPath
)

export const checkPathLoadingSelector = createSelector(
  checkPathSelector, path => path.loading
)

export const pathLocationNameSelector = createSelector(
  checkPathSelector, path => path.locName
)
export const checkPathErrorSelector = createSelector(
  checkPathSelector, path => path.err
)

/**
 * Saga
 */

export const checkExistsPathSaga = function* () {
  while(true) {
    const { payload: path } = yield take(CHECK_EXISTS_PATH_REQUEST);

    try {
      const { data } = yield call(apiNodes.getNodes, path);
      yield put({ type: CHECK_EXISTS_PATH_SUCCESS, payload: data.name })
    } catch(err) {
      if (err.response.status === Http.NotFound) err.message = 'The current board path does not exist!';
      yield put({ type: CHECK_EXISTS_PATH_FAIL,  err })
    }
  }
}


export const createThreadSaga = function* ({ payload: { path, body } }) {
    const filesStreams = yield select(newAddedFilesSelector);

    try {
      if (filesStreams.length) {
        const { data: filesIds } = yield call(apiMedia.uploadFiles, filesStreams);
        body = {...body, attachmentIds: [...filesIds] }
      }
      const { headers } = yield call(apiThread.createThread, path, body);

      if (headers.location) {
        const location = headers.location.split('/');
        const threadId = location[location.length - 1];
        if (threadId) {
          const link = url.createPath('thread', path, threadId);
          yield put(push(link));
          yield put({ type: RESET_STATE_FILES })
          return yield put({ type: CREATE_THREAD_SUCCESS })
        }
      }

      throw new Error('Thread url address is not created');

    } catch(err) {
      yield put({ type: CREATE_THREAD_FAIL, err })
    }
}


export const saga = function* () {
  yield spawn(checkExistsPathSaga);
  yield takeEvery(CREATE_THREAD_REQUEST, createThreadSaga);
}
