import React from 'react';
import classNames from 'classnames';
import { element, func, oneOfType, string } from 'prop-types';
import { options } from '../utils/render';
import { spaceless } from '../../utils/strings';
import { ChildrenType } from '../utils/prop-types';
import { old_defaults as defaults } from '../../constants';

/**
 * Renders input tag
 * @param {string} inputName
 * @param {node[]} content
 * @param {string} label
 * @param about
 * @param {function} setValue
 * @param {string} type
 * @param {string} name
 * @param {string} value
 * @param {string} message
 * @param {string} classes
 * @param validationClasses
 * @param inputClasses
 * @param {string} id
 * @param {function} format
 * @param {object} other
 * @returns {node}
 */
export function field(inputName, content, {
  label,
  about,
  setValue,
  type,
  name,
  value,
  message,
  classes = '',
  validationClasses = '',
  inputClasses = '',
  id,
  format = v => v,
  ...other
}) {
  if (!id) {
    id = `field-${name}`;
  }
  const isCheckbox = type === 'checkbox';

  const onChange = isCheckbox
    ? e => setValue(e.target.checked)
    : e => {
      const v = format(e.target.value);
      if (v !== value) {
        setValue(v);
      }
    };

  const inputProps = { id,
    type,
    name,
    onChange,
    [isCheckbox ? 'checked' : 'value']: value,
    ...other
  };
  const input = React.createElement(inputName, { ...inputProps, className: inputClasses }, content);

  const labelNode = (
    <label htmlFor={id} className={classNames(validationClasses, { error: !!message })}>
      <span className="label-text">{label}</span>
      {message ? <span className="errorMessage icon iconWarning">{message}</span> : null}
    </label>
  );

  const contentNodes = type === 'checkbox'
    ? <>{input}{labelNode}</>
    : <>{labelNode}{input}</>;

  const isEmpty = undefined === value || null === value || '' === value;
  const className = classNames(spaceless(`widget field type-${type} field-${name} ${classes}`), {
    'has-empty-value': isEmpty,
    'has-value': !isEmpty
  });

  return (
    <div className={className}>
      {contentNodes}
      {about ? <span className="about">{about}</span> : null}
    </div>
  );
}

export default function Field({ type = 'text', items, ...props }) {
  switch (type) {
    case 'select':
      return field('select', options(items), { type, ...props });
    case 'textarea':
      return field('textarea', null, {
        ...defaults.input.type.textarea,
        type,
        ...props
      });
    default:
      return field('input', null, {
        ...(defaults.input.type[type] || {}),
        ...(defaults.input.name[props.name] || {}),
        type,
        ...props
      });
  }
}

Field.propTypes = {
  classes: string,
  id: string,
  label: oneOfType([string, element]).isRequired,
  message: ChildrenType,
  name: string.isRequired,
  placeholder: string,
  setValue: func.isRequired,
  type: string
};

Field.defaultProps = {
  about: '',
  classes: '',
  id: '',
  message: '',
  placeholder: '',
  type: 'text'
};
