import { defineMessages } from 'react-intl';

export default defineMessages({
  banners: 'Enable Banners',
  cancel: 'Cancel',
  employeePosts: 'Callout Ancestry Employee Posts',
  gatewayAddress: 'Gateway Address',
  index: 'Include in Index',
  mailListGateway: 'Enable Mail List Gateway',
  moderation: 'Enable Moderation (posts must be approved by board administrator prior to public viewing)',
  posts: 'Enable Posts',
  soundex: 'Enable Soundex',
  submit: 'Submit',
  title: 'Edit Options',
  titleName: 'Edit Options for {name}',
  virtualList: 'Enable VirtualList',
});
