import React from 'react';
import { Link } from 'react-router-dom';
import { FormattedHTMLMessage,FormattedMessage } from 'react-intl';
import m from './messages';

function SurnameLetters({ alphabet }) {
  const [ letters ]  = alphabet;

  if (!letters || !letters.length) return null;
  return (
    <div className='search__letters'>
      <h3 className='search__letters-title'><FormattedMessage {...m.surnames} /></h3>
      <p className="surname-letters__desc">
        <FormattedHTMLMessage {...m.tipSurnames} />
      </p>
      <ul className='search__letters-list'>
        { letters.map(letter => (
          <li key={letter} className='search__letters-item'>
            <Link to={`/search/surname?sname=${letter}`}>{ letter }</Link>
          </li>
        ))
        }
      </ul>
    </div>
  )

}

SurnameLetters.defaultProps = {
  alphabet: []
};

export default SurnameLetters