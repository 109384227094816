import { combineReducers } from 'redux';
import { connectRouter } from 'connected-react-router';

import reduceAdmin, { moduleName as adminModule } from '../ducks/admin-panel';
import reduceAuthorPosts, { moduleName as authorPostsOldModule } from '../ducks/old_author-posts';
import reducerAdvancedSearch, { moduleName as searchAdvancedOldModule } from '../ducks/advanced-search';
import reducerAlertsPage, { moduleName as alertsPageModule } from '../ducks/alerts'; 
import reducerOldCommonAlert, { moduleName as alertOldCommonModule } from '../ducks/common/alert';
import reducerBoardInfo, { moduleName as boardInfoModule } from '../ducks/board-info';
import reducerBoardInfoEdit, { moduleName as boardInfoEditModule } from '../ducks/board-info-edit';
import reducerCategoryOld, { moduleName as categoryOldModule } from '../ducks/category';
import reducerBusy, { moduleName as busyOldModule } from '../ducks/common/busy';
import reducerChooseCategory, { moduleName as selectChooseModule } from '../ducks/choose-category';
import reducerFavorites, { moduleName as favoritesModule } from '../ducks/favorites';
import reducerCategoryFavorite, { moduleName as categoryFavoriteModule } from '../ducks/category-favorite';
import reducerThreadFavorite, { moduleName as threadFavoriteModule } from '../ducks/thread-favorite';
import reducerThreadEdit, { moduleName as threadEditModule } from '../ducks/thread-edit';
import reducerThreadOld, { moduleName as threadOldModule } from '../ducks/old_thread';
import reducerMediaOld, { moduleName as mediaOldModule } from '../ducks/old-media';
import reducerThreadCreate, { moduleName as threadCreateModule } from '../ducks/thread-create';
import reducerThreadReply, { moduleName as threadReplyModule } from '../ducks/thread-reply';
import reducerAuthorFavorite, { moduleName as authorFavoriteModule } from '../ducks/author-favorite';
import reducerAlertFavorite, { moduleName as alertFavoriteModule } from '../ducks/alert-favorite';
import reducerContent, { moduleName as contentModule } from '../ducks/home-category-page';
import reducerAttachments, { moduleName as attachmentsModule } from '../ducks/attachments';
import reducerManageSpam, { moduleName as manageSpamModule } from '../ducks/manage-spam';
import reducerObjection, { moduleName as objectionOldModule } from '../ducks/old_objection';
import reducerOldOptions, { moduleName as optionsOldModule } from '../ducks/options';
import reducerPopup, { moduleName as popupModule } from '../ducks/popup';
import reducerRecycleBin, { moduleName as recycleBinModule } from '../ducks/recycle-bin';
import reducerSearch, { moduleName as searchModule } from '../ducks/search';
import reducerSuggestion, { moduleName as suggestionModule } from '../ducks/suggestion';
import reducerThread, { moduleName as threadModule } from '../ducks/thread-page';
import reducerUser, { moduleName as userModule } from '../ducks/user';
import reducerOldUser, { moduleName as userOldModule } from '../ducks/_user';
import reducerOldAdmin, { moduleName as adminOldModule } from '../ducks/old_admin';
import reducerReportAbuse, { moduleName as reportAbuseModule } from '../ducks/report-abuse';
import reducerRequestBoards, { moduleName as requestBoardsModule } from '../ducks/request-boards';
import reducerBecomeVolunteer, { moduleName as becomeVolunteerModule } from '../ducks/volunteer';
import reducerAdvSearchResult, { moduleName as resultAdvSearchModule } from '../ducks/search-adv-result';
import reducerNodePath, { moduleName as nodePathModule } from '../ducks/node-path';

export default history => combineReducers({
  router: connectRouter(history),
  [adminModule]: reduceAdmin,
  [adminOldModule]: reducerOldAdmin,
  [resultAdvSearchModule]: reducerAdvSearchResult,
  [nodePathModule]: reducerNodePath,
  [alertsPageModule]: reducerAlertsPage,
  [alertOldCommonModule]: reducerOldCommonAlert,
  [authorPostsOldModule]: reduceAuthorPosts,
  [boardInfoModule]: reducerBoardInfo,
  [boardInfoEditModule]: reducerBoardInfoEdit,
  [busyOldModule]: reducerBusy,
  [favoritesModule]: reducerFavorites,
  [contentModule]: reducerContent,
  [categoryFavoriteModule]: reducerCategoryFavorite,
  [threadFavoriteModule]: reducerThreadFavorite,
  [threadEditModule]: reducerThreadEdit,
  [threadCreateModule]: reducerThreadCreate,
  [authorFavoriteModule]: reducerAuthorFavorite,
  [alertFavoriteModule] : reducerAlertFavorite,
  [attachmentsModule] : reducerAttachments,
  [threadReplyModule] : reducerThreadReply,
  [categoryOldModule] : reducerCategoryOld,
  [threadOldModule] : reducerThreadOld,
  [mediaOldModule]: reducerMediaOld,
  [manageSpamModule]: reducerManageSpam,
  [objectionOldModule]: reducerObjection,
  [optionsOldModule]: reducerOldOptions,
  [popupModule]: reducerPopup,
  [recycleBinModule]: reducerRecycleBin,
  [searchAdvancedOldModule]: reducerAdvancedSearch,
  [searchModule]: reducerSearch,
  [selectChooseModule]: reducerChooseCategory,
  [suggestionModule]: reducerSuggestion,
  [threadModule]: reducerThread,
  [userModule]: reducerUser,
  [userOldModule]: reducerOldUser,
  [reportAbuseModule]: reducerReportAbuse,
  [requestBoardsModule]: reducerRequestBoards,
  [becomeVolunteerModule]: reducerBecomeVolunteer
});
