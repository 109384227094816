import { defineMessages } from 'react-intl';
import React from 'react';


export default defineMessages({
  addFavorite: 'Add Category to Favorites',
  addFavoriteBoard: 'Add Board to Favorites',
  addFavoriteThread: 'Add Thread to Favorites',
  addToAlerts: 'Add Board to Alerts',

  removeFavorite: 'Remove Category from Favorites',
  removeFavoriteBoard: 'Remove Board from Favorites',
  removeFavoriteThread: 'Remove Thread from Favorites',
  removeFromAlerts: 'Remove Board from Alerts',
  seeAll: 'See All',
  viewPosts: 'View Posts',

  createBoard: 'Create New Board',
  createCategory: 'Create New Category',
  deleteCategory: 'Delete This Category',
  moveCategory: 'Move This Category',

  renameCategory: 'Rename This Category',

  // links anchors
  listAdmins: 'List Admins',
  editOptions: 'Edit Options',
  recycleBin: 'Recycle Bin',

  addPath: 'Add Additional Path',

  boardInformation: 'Board Information',
  addminTool: 'Admin Tools',
  confirm: 'Are you sure to want to remove from favorites?',
  deleteBoard: 'Delete This Board',
  editBoard: 'Edit Board Info',
  emailStaff: 'E-mail Staff',
  mergeBoard: 'Merge Board',
  renameBoard: 'Rename Board',
  moveBoard: 'Move Board',
  subscribe: 'Subscribe to RSS',
  title: 'Tools',
  volunteer: 'Volunteer to Admin'
});

