import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';

import PageView from '../../common/page-view';
import Alert from '../../common/alert';
import AdvancedSearchForm from './form';

import { withRouter } from 'react-router-dom';
import m from './messages';
import { FormattedMessage } from 'react-intl';
import { trackPageView } from '../../../utils/tealium';
import { resetAdvancedSearchResults } from '../../../ducks/advanced-search';


export function AdvancedSearchPage() {
  const dispatch = useDispatch()
  const title = <FormattedMessage {...m.advancedSearch} />;
  const homeBreadCrumb = <FormattedMessage {...m.messageBoards} />
  const className = 'advanced-search mode-advanced aside-search';

  useEffect(() => trackPageView('search: advanced'), []);

  useEffect(() => () => dispatch(resetAdvancedSearchResults()), []);



  const breadcrumbs = [
    { path: '' , homeBreadCrumb },
    { title }
  ];

  return (
    <PageView
      title={title}
      classes={className}
      breadcrumbs={breadcrumbs}
    >
      <Alert />
      <AdvancedSearchForm
        hasSimpleSearchForm
      />
    </PageView>
  );
}

export default withRouter(AdvancedSearchPage);
