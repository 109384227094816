import React from 'react';
import classNames from 'classnames';
import { FormattedMessage } from 'react-intl';
import m from './messages';
import Modal from '../../common/modal/index';
import { spaceless } from '../../../utils/strings';
import { bool, func, number, oneOfType, string } from 'prop-types';
import { ChildrenType } from '../../utils/prop-types';
import { selectModalId, setSuggestionModalId, SuggestionModalHidden } from '../../../ducks/suggestion';
import { connect } from 'react-redux';
import Alert from '../../common/alert';

export function Judge(
  {
    children,
    classes,
    id,
    isLocked,
    modalId,
    onClose,
    onOpen,
    setModalId,
    title
  }) {
  function changeVisibility(value) {
    if (value) {
      onOpen();
    } else {
      onClose();
    }
    setModalId(value ? id : SuggestionModalHidden);
  }
  const visible = !!modalId && id === modalId;
  const className = classNames(spaceless(`judge admin ${classes}`), {
    'mode-button': !visible,
    'mode-modal': visible,
    locked: isLocked
  });
  if (isLocked) {
    children = <FormattedMessage {...m.locked} />;
  }
  return (
    <div className={className}>
      <button type="button" className="judge-button ancBtn" onClick={() => changeVisibility(true)}>{title}</button>
      <Modal classes="judge-modal admin" title={title} visible={visible} close={() => changeVisibility(false)}>
        <Alert />
        <div className="judge-modal-content">{children}</div>
      </Modal>
    </div>
  );
}

Judge.propTypes = {
  children: ChildrenType,
  classes: string,
  id: oneOfType([number, string]).isRequired,
  isLocked: bool,
  modalId: string.isRequired,
  onClose: func,
  onOpen: func,
  setModalId: func.isRequired,
  title: ChildrenType
};

Judge.defaultProps = {
  children: '',
  classes: '',
  isLocked: false,
  onClose: () => {},
  onOpen: () => {},
  title: ''
};

function mapStateToProps(state) {
  return {
    modalId: selectModalId(state)
  };
}

function mapDispatchToProps(dispatch) {
  return {
    setModalId(id) {
      dispatch(setSuggestionModalId(id));
    }
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(Judge);
