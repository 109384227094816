import HttpServices from './index';
import { stringifyQueryUrl } from '../components/utils';

class NodesService extends HttpServices {
  getRecycleBin = (page, path) => this.http.get(`/nodes/recycle-bin?itemsPerPage=ALL&page=${page}`,
    this._setHeaderBoardsPath(path));

  restoreNode = path => this.http.patch('/nodes/restore', {}, this._setHeaderBoardsPath(path));

  getNodes = path => this.http.get('/nodes', this._setHeaderBoardsPath(path));

  createOptions = (path, type, data) => {
    const payload = { ...data.toJS(), type };
    return this.http.post(`/nodes?type=${type}`, payload, this._setHeaderBoardsPath(path));
  }
  updateOptions = (path, data) => this.http.patch('/nodes/options', data, this._setHeaderBoardsPath(path));
  changeNode = (path, operation, params) => this.http.put(
    `/nodes/${operation}?${stringifyQueryUrl(params)}`, null, this._setHeaderBoardsPath(path));
    
  removeNode = path => this.http.delete('/nodes', this._setHeaderBoardsPath(path));
}

export default new NodesService();
