export default {
  fifty: [
    { value: 'TEN', name: 10 },
    { value: 'TWENTY_FIVE', name: 25 },
    { value: 'FIFTY', name: 50 },
  ],
  hundred: [
    { value: 'TEN', name: 10 },
    { value: 'TWENTY_FIVE', name: 25 },
    { value: 'FIFTY', name: 50 },
    { value: 'HUNDRED', name: 100 }
  ],
  thousand: [
    { value: 'TEN', name: 10 },
    { value: 'TWENTY_FIVE', name: 25 },
    { value: 'FIFTY', name: 50 },
    { value: 'HUNDRED', name: 100 },
    { value: 'THOUSAND', name: 1000 }
  ]
}